import React, { useState } from 'react'
import { Image, ImageResizeMode } from 'react-native'

export type ImgProps = {
	source: string
	style: any
	hideDefault?: boolean
	resizeMode?: ImageResizeMode
}
/**
 * Web-only compoenent to display images
 * Use standard React Native Image component as FastImage isn't compatible with web
 */
export default function ImageObject(props: ImgProps) {
	const { source, style, hideDefault, resizeMode = 'cover' } = props
	const [loading, setLoading] = useState(true)

	const handleLoad = () => setLoading(false)

	const [imageWidth, setImageWidth] = useState(1.5)
	const [imageHeight, setImageHeight] = useState(1)

	const imageSizeRetrieved = (width, height) => {
		setImageWidth(width)
		setImageHeight(height)
	}

	if (source) {
		Image.getSize(source, imageSizeRetrieved, null)
	}

	if (hideDefault) {
		return (
			<>
				<Image
					resizeMethod={'resize'}
					resizeMode={resizeMode}
					source={{ uri: source }}
					style={[
						style,
						{
							display: loading ? 'none' : 'flex',
							alignContent: 'flex-start',
							aspectRatio: imageWidth / imageHeight,
						},
					]}
					onLoadEnd={handleLoad}
				/>
			</>
		)
	} else {
		return (
			<>
				{loading && (
					<Image
						resizeMethod={'auto'}
						resizeMode={'cover'}
						source={require('../../assets/images/shimmer.gif')}
						style={[
							style,
							{
								aspectRatio: imageWidth / imageHeight,
							},
						]}
					/>
				)}
				<Image
					resizeMethod={'auto'}
					resizeMode={resizeMode}
					source={{ uri: source }}
					style={[
						style,
						{ display: loading ? 'none' : 'flex', aspectRatio: imageWidth / imageHeight },
					]}
					onLoadEnd={handleLoad}
				/>
			</>
		)
	}
}

export function preLoadImage(source = '') {
	return null
}
