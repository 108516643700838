import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Item, User } from '../types'
import { utcToZonedTime } from 'date-fns-tz'
import { add, intervalToDuration } from 'date-fns'

export type DeliveryEstimate = {
	restaurantId: string
	userAddress: string
	estimate: {
		fee: number
		timeToDeliver: number
		totalFee: number
	}
}
// persisted data is maintained on refresh / app close
const initialState = {
	cartPrep: {
		prepTimeDuration: '',
		pickupTime: '',
		deliveryTime: '',
		isScheduled: false,
		longestPrepTime: 15,
		deliveryPrepTime: 15,
	},
	cart: [] as Item[],
	isDelivery: true,
	restaurantLocation: {} as any,
	showLocationModal: false,
	showPickupPointModal: false,
	showDeliveryModal: false,
	pickupPointModalVisible: false,
	selectedLocation: {} as any,
	activeRID: '',
	squareLocationId: '',
	selectedPickupPoint: {} as any,
	contactlessDropoff: true,
	deliveryEstimate: {
		restaurantId: '',
		estimate: {
			fee: -1,
			timeToDeliver: -1,
			totalFee: -1,
		},
	} as DeliveryEstimate,
	deliveryEstimateList: [] as DeliveryEstimate[],
	redeemedRewards: [] as any[],
	isTestMode: false,
}

type StateTypes = typeof initialState

const slice = createSlice({
	name: 'persisted',
	initialState,
	reducers: {
		setStates: (state, action: PayloadAction<Partial<StateTypes>>) => {
			Object.assign(state, action.payload)
		},
		addItem: (state, action: PayloadAction<Item>) => {
			if (!state.cart) {
				state.cart = []
			}
			state.cart.push(action.payload)
		},
		removeItem: (state, action: PayloadAction<{ id: string; key: string }>) => {
			const index = state.cart.findIndex(
				item => item.id === action.payload.id && item.idempotencyKey === action.payload.key
			)
			if (index !== -1) {
				state.cart.splice(index, 1)
			}
		},
		addDeliveryEstimates: (state, action: PayloadAction<DeliveryEstimate[]>) => {
			state.deliveryEstimateList.push(...action.payload)
		},
		removeAllItems: state => {
			state.cart = []
		},
		resetState: state => {
			Object.assign(state, initialState)
		},
		redeemReward: (
			state,
			action: PayloadAction<{
				rewardId: string
				pointsRedeemed: number
				totalDiscount: number
				restaurantId: string
				orderItemIdRedeemed: string
			}>
		) => {
			state.redeemedRewards.push(action.payload)
		},
		resetCartPrep: (
			state,
			action: PayloadAction<{ localTimezone: string; getLongestPrepTime: number }>
		) => {
			const { localTimezone, getLongestPrepTime } = action.payload
			const currentTime = utcToZonedTime(new Date(), localTimezone)
			const pickupTime = add(currentTime, { minutes: getLongestPrepTime })
			const duration = intervalToDuration({
				start: currentTime,
				end: pickupTime,
			})

			state.cartPrep = {
				isScheduled: false,
				pickupTime: state.isDelivery ? '' : pickupTime.toISOString(),
				deliveryTime: state.isDelivery ? pickupTime.toISOString() : '',
				prepTimeDuration: `P${duration.days}DT${duration.hours}H${duration.minutes}M${duration.seconds}S`,
				longestPrepTime: getLongestPrepTime,
				deliveryPrepTime: 15,
			}
		},
	},
})

const {
	setStates,
	addItem,
	removeItem,
	resetState,
	removeAllItems,
	resetCartPrep,
	addDeliveryEstimates,
	redeemReward,
} = slice.actions

export const setPersistedState = (updates: Partial<StateTypes>) => setStates(updates)
export const addItemToCart = (item: Item) => addItem(item)
export const removeItemFromCart = (id: string, key: string) => removeItem({ id, key })
export const clearCart = () => removeAllItems()
export const resetPersistedState = () => resetState()
export const addNewDeliveryEstimates = (newEstimates: DeliveryEstimate[]) =>
	addDeliveryEstimates(newEstimates)
export const redeemLoyaltyReward = (
	rewardId: string,
	pointsRedeemed: number,
	totalDiscount: number,
	restaurantId: string,
	orderItemIdRedeemed: string
) => redeemReward({ rewardId, pointsRedeemed, totalDiscount, restaurantId, orderItemIdRedeemed })

export const resetCartPrepState = ({ localTimezone, getLongestPrepTime }) =>
	resetCartPrep({ localTimezone, getLongestPrepTime })
export default slice.reducer
