import * as React from 'react'
import { StyleSheet, View, Button } from 'react-native'
import { StarXpandCommand } from 'react-native-star-io10'
import { CutType } from 'react-native-star-io10/src/StarXpandCommand/Printer/CutType'
import { useState, useRef } from 'react'
import { captureRef } from 'react-native-view-shot'
import ViewShot from 'react-native-view-shot'
import { StarReceiptView } from './StarReceiptView.native'

export default function StarPrintReceipt({ item, printer }) {
	const [isPrinting, setIsPrinting] = useState(false)
	const [isError, setIsError] = useState(false)

	const viewRef = useRef()

	const capture = async () => {
		const uri = await captureRef(viewRef, {
			format: 'png',
			quality: 1,
			result: 'data-uri',
		})
		return uri
	}

	const processUrl = url => {
		if (url) {
			return url.replace('data:image/png;base64,', '')
		} else {
			return null
		}
	}

	const ReceiptView = () => {
		return (
			<ViewShot ref={viewRef} style={{ position: 'absolute', left: 10000 }}>
				<StarReceiptView orderData={item} />
			</ViewShot>
		)
	}

	const onPress = async () => {
		setIsError(false)
		setIsPrinting(true)
		setTimeout(async () => {
			const url = await capture()
			try {
				const builder = new StarXpandCommand.StarXpandCommandBuilder()
				builder.addDocument(
					new StarXpandCommand.DocumentBuilder().addPrinter(
						new StarXpandCommand.PrinterBuilder()
							.actionPrintImage(
								new StarXpandCommand.Printer.ImageParameter(`${processUrl(url)}`, 800)
							)

							.actionCut(CutType.Partial)
					)
				)

				var commands = await builder.getCommands()

				await printer.open()
				await printer.print(commands)
				console.log('Success')
			} catch (error) {
				console.log(`Error: ${String(error)}`)
				setIsError(true)
			} finally {
				await printer.close()
				await printer.dispose()
			}
			setIsPrinting(false)
		}, 50)
	}
	if (global.pos) {
		return (
			<>
				<ReceiptView />

				<View>
					<Button
						title={
							isPrinting ? 'Printing...' : isError ? 'Error printing receipt' : 'Print Receipt'
						}
						disabled={!printer || isPrinting}
						color={!printer ? 'gray' : global.orgColor}
						onPress={onPress}
					/>
				</View>
			</>
		)
	} else {
		return null
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	box: {
		width: 60,
		height: 60,
		marginVertical: 20,
	},
})
