import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import React from 'react'
import { View, TextInput, StyleSheet, Dimensions, Keyboard, Platform } from 'react-native'
import { largeTextSize, mlTextSize, titleTextSize } from '../../../../constants/GlobalStyle'
import { Text } from '../../../../components/Themed'

const CustomerName = ({ value, setValue, isTakeOut, showTableNumber, children }) => {
	const descriptor = global.pos ? 'THE' : 'YOUR'
	if (isTakeOut || !showTableNumber) {
		return (
			<View
				style={[styles.container, Platform.OS === 'web' && { paddingTop: '5%' }]}
				onTouchStart={() => {
					Keyboard.dismiss()
				}}
			>
				<Text style={[styles.mainHeader, { color: global.orgColor }]}>CHECKOUT</Text>

				{children}

				<Text
					style={[styles.subHeader, Platform.OS === 'web' && { marginTop: '2.5%' }]}
				>{`PLEASE ENTER ${descriptor} NAME ${Platform.OS === 'web' ? '' : 'FOR PICKUP'}`}</Text>

				<View style={[styles.inputContainer, Platform.OS === 'web' && { marginBottom: '5%' }]}>
					<Ionicons name="person" size={largeTextSize} color="#DEDEDE" style={styles.inputIcon} />
					<TextInput
						style={styles.input}
						placeholder={Platform.OS === 'web' ? 'Name' : 'Name for Pickup'}
						placeholderTextColor="#aaa"
						value={value}
						onChangeText={setValue}
						returnKeyType={'done'}
					/>
				</View>
			</View>
		)
	} else {
		return (
			<View
				style={styles.container}
				onTouchStart={() => {
					Keyboard.dismiss()
				}}
			>
				<Text style={[styles.mainHeader, { color: global.orgColor }]}>CHECKOUT</Text>

				{children}

				<Text style={styles.subHeader}>{`PLEASE ENTER ${descriptor} TABLE NUMBER`}</Text>

				<View style={[styles.inputContainer, { width: '40%' }]}>
					<MaterialCommunityIcons
						name="silverware-fork-knife"
						size={largeTextSize + 1}
						color="#DEDEDE"
						style={styles.inputIcon}
					/>
					<TextInput
						style={styles.input}
						placeholder="Table #"
						placeholderTextColor="#aaa"
						value={value}
						onChangeText={setValue}
						returnKeyType={'done'}
					/>
				</View>
			</View>
		)
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		backgroundColor: '#fff',
		paddingTop: '15%',
	},
	mainHeader: {
		fontSize: titleTextSize,
		textAlign: 'center',
		marginBottom: Platform.OS === 'web' ? 10 : 50,
		fontWeight: '600',
	},
	subHeader: {
		fontSize: mlTextSize - 2,
		color: '#4E4E4E',
		textAlign: 'center',
		marginBottom: 25,
		fontWeight: '600',
		marginTop: '10%',
	},
	inputContainer: {
		flexDirection: 'row',
		borderColor: 'grey',
		borderWidth: 1,
		borderRadius: 8,
		paddingHorizontal: 10,
		paddingVertical: 10,
		alignItems: 'center',
		width: '75%',
	},
	inputIcon: {
		marginRight: 10,
	},
	input: {
		flex: 1,
		paddingVertical: 10,
		fontSize: mlTextSize,
	},
})

export default CustomerName
