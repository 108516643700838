import * as React from 'react'
import { TouchableOpacity, Image, Platform, SectionList } from 'react-native'
import Colors from '../../constants/Colors'
import { Text, View } from '../../components/Themed'
import { useCallback, useEffect, useState } from 'react'
import { auth } from '../../firebase/config'
import { Spacer } from '../../components/Spacer'
import { RootTabScreenProps } from '../../navigation/types'
import GlobalStyle, { largeTextSize, titleTextSize } from '../../constants/GlobalStyle'
import { formatInTimeZone } from 'date-fns-tz'
import { DisplayOrder } from '../../components/OrderCart/DisplayOrder'
import { TriangleHeader } from '../../components/Headers/TriangleHeader'
import EpsonPrintReceipt from '../../components/Kiosk/Receipts/EpsonPrintReceipt.native'
import StarPrintReceipt from '../../components/Kiosk/Receipts/StarPrintReceipt.native'
import PrinterStatus from '../../components/Kiosk/Receipts/PrinterStatus.native'
import CopyrightFooter from '../../components/Footers/CopyrightFooter'
import POSNavBar from '../../navigation/POSNavBar'
import { convertDoordashStatus } from '../../components/Delivery/OrderBanner'
import DoorDashTracking from '../../components/Delivery/DoorDashTracking'
import { useAppSelector } from '../../state/hooks'
import { subscribeToPOSOrders, subscribeToUserOrders } from './Helpers/subscribeToOrders'
import { itemOrItems, orderQuantity } from './Helpers/functions'
import { styles } from './Helpers/style'

export default function OrdersScreen({ navigation }: RootTabScreenProps<'TabOrders'>) {
	const { localTimezone } = useAppSelector(state => state.settings)
	const { loadedData, organizationLogo, receiptPrinter, printerType } = useAppSelector(
		state => state.data
	)
	const { deviceHasInternet } = useAppSelector(state => state.user)

	const [reset, setReset] = useState([])
	const [ordersView, setOrdersView] = useState([])
	const [loading, setLoading] = useState(true)

	//POS
	const [lastOrderDate, setLastOrderDate] = useState(null) // To keep track of the last order for pagination on POS
	const [filterType, setFilterType] = useState()

	const ACTIVE_WINDOW_MS = 3600000 // 1 hour

	const MemoizedSetView = React.memo(SetView)

	const noOrderText = global.pos
		? 'Past orders from this POS will appear here once an order is placed.'
		: 'Your past orders will appear here once you place your first order.'

	const [expandedSections, setExpandedSections] = useState({
		'Active Orders': true,
		'Past Orders': true,
		'Scheduled Orders': true,
	})

	const toggleSection = section => {
		setExpandedSections(prev => ({
			...prev,
			[section]: !prev[section],
		}))
	}

	const today = new Date()
	const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime()
	const todayEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).getTime()

	// Scheduled orders
	const scheduledOrders = ordersView.filter(singleOrder => {
		const orderPickupTime = singleOrder.pickupDate?.seconds * 1000
		return (
			singleOrder.scheduled === true &&
			(orderPickupTime < todayStart || orderPickupTime >= todayEnd)
		)
	})

	// Active Orders
	const activeOrders = ordersView.filter(singleOrder => {
		const orderPickupTime = singleOrder.pickupDate?.seconds * 1000
		const now = new Date().getTime()

		return (
			// Orders that still within the active window
			(singleOrder.pickupStatus !== 'Completed' &&
				convertDoordashStatus(singleOrder.deliveryStatus) !== 'Delivered' &&
				convertDoordashStatus(singleOrder.deliveryStatus) !== 'Cancelled' &&
				singleOrder.pickupStatus !== 'Cancelled' &&
				orderPickupTime + ACTIVE_WINDOW_MS > now &&
				!singleOrder.scheduled) ||
			// Scheduled orders that are for today
			(singleOrder.scheduled === true &&
				orderPickupTime >= todayStart &&
				orderPickupTime < todayEnd)
		)
	})

	// Past Orders
	const pastOrders = ordersView.filter(
		order => !activeOrders.includes(order) && !scheduledOrders.includes(order)
	)

	const sections = [
		{ data: activeOrders, sectionKey: 'Active Orders' },
		{ data: scheduledOrders, sectionKey: 'Scheduled Orders' },
		{ data: pastOrders, sectionKey: 'Past Orders' },
	]

	const unsubscribeRef = React.useRef(null) // Ref to hold the unsubscribe function

	useEffect(() => {
		const unsubscribeNavigation = navigation.addListener('focus', () => {
			setLoading(true)
			if (auth.currentUser && deviceHasInternet) {
				// Clear existing orders and fetch them again when focused
				setOrdersView([])
				subscribeToUserOrders({
					unsubscribeRef: unsubscribeRef,
					setOrdersView: setOrdersView,
					setLoading: setLoading,
				})
			} else {
				setOrdersView([])
				setReset([])
				setLoading(false)
			}
		})

		return () => {
			unsubscribeNavigation()
			if (unsubscribeRef.current) {
				unsubscribeRef.current()
			}
		}
	}, [navigation])

	const FilterModeSwitcher = () => {
		const handleModeChange = mode => {
			setFilterType(mode)
			subscribeToPOSOrders({
				mode: mode,
				filterType: filterType,
				lastOrderDate: lastOrderDate,
				setLastOrderDate: setLastOrderDate,
				setLoading: setLoading,
				setOrdersView: setOrdersView,
			})
		}

		return (
			<View style={styles.filterContainer}>
				<TouchableOpacity
					style={[styles.button, filterType === 'default' && { backgroundColor: global.orgColor }]}
					onPress={() => handleModeChange('default')}
				>
					<Text style={[styles.buttonText, filterType === 'default' && styles.activeButtonText]}>
						All Orders
					</Text>
				</TouchableOpacity>

				<TouchableOpacity
					style={[styles.button, filterType === 'pos_only' && { backgroundColor: global.orgColor }]}
					onPress={() => handleModeChange('pos_only')}
				>
					<Text style={[styles.buttonText, filterType === 'pos_only' && styles.activeButtonText]}>
						POS Only
					</Text>
				</TouchableOpacity>

				<TouchableOpacity
					style={[styles.button, filterType === 'app_only' && { backgroundColor: global.orgColor }]}
					onPress={() => handleModeChange('app_only')}
				>
					<Text style={[styles.buttonText, filterType === 'app_only' && styles.activeButtonText]}>
						App Only
					</Text>
				</TouchableOpacity>
			</View>
		)
	}

	// Load More button handler
	const handleLoadMore = () => {
		subscribeToPOSOrders({
			loadMore: true, //load more orders
			filterType: filterType,
			lastOrderDate: lastOrderDate,
			setLastOrderDate: setLastOrderDate,
			setLoading: setLoading,
			setOrdersView: setOrdersView,
		})
	}

	function PrinterView({ singleOrder }) {
		if (global.pos) {
			if (printerType === 'star') {
				return <StarPrintReceipt item={singleOrder.rawObject} printer={receiptPrinter} />
			} else {
				return <EpsonPrintReceipt item={singleOrder.rawObject} printer={receiptPrinter} />
			}
		}
		return null
	}

	function SetView({ singleOrder, sectionKey }) {
		//expand details for first order if active
		const deliveryStatus = singleOrder.deliveryStatus
			? convertDoordashStatus(singleOrder.deliveryStatus)
			: ''

		const [isCollapsed, setIsCollapsed] = useState(
			singleOrder.pos === 0 &&
				singleOrder.pickupStatus !== 'Completed' &&
				deliveryStatus !== 'Delivered' &&
				deliveryStatus !== 'Cancelled' &&
				singleOrder.pickupStatus !== 'Cancelled' &&
				sectionKey === 'Active Orders'
				? false
				: true
		)

		const handlePress = useCallback(() => {
			setIsCollapsed(prevState => !prevState)
		}, [])

		const readyTime = formatInTimeZone(
			singleOrder.pickupDate?.seconds * 1000,
			localTimezone,
			"EEE MMM d 'at' h:mm a"
		)
		const rList = [...new Set(singleOrder.restaurants)]
		let rReturn = ''
		const quantity = orderQuantity(singleOrder)

		rList.forEach((rId: string, i) => {
			if (i == rList.length - 1 && i !== 0) {
				rReturn = rReturn.slice(0, rReturn.length - 2)
				rReturn += ' & ' + loadedData[rId]?.name
			} else if (i == rList.length - 1) {
				rReturn += loadedData[rId]?.name
			} else {
				rReturn += loadedData[rId]?.name + ', '
			}
		})

		return (
			<View key={singleOrder.orderID + '_view'}>
				<TouchableOpacity onPress={handlePress}>
					<View
						style={[
							GlobalStyle.collapsibleHeading,
							{ padding: 20, paddingBottom: 0, flexDirection: 'row' },
						]}
					>
						<View style={GlobalStyle.ordersHeading}>
							<Text style={GlobalStyle.responsiveTextSize}>{rReturn}</Text>
						</View>

						<View style={[GlobalStyle.ordersHeading, { flex: 1.25, alignItems: 'flex-end' }]}>
							<Text style={[GlobalStyle.responsiveTextSize, { textAlign: 'center' }]}>
								<Text style={{ fontWeight: '300' }}>
									{formatInTimeZone(singleOrder.orderDate.seconds * 1000, localTimezone, 'MMM d')}
								</Text>
							</Text>
						</View>
					</View>

					<View
						style={[
							GlobalStyle.collapsibleHeading,
							{ padding: 20, paddingTop: 7, flexDirection: 'row' },
						]}
					>
						<View style={[GlobalStyle.ordersHeading, { flex: 0.75, alignItems: 'flex-start' }]}>
							<Text style={[GlobalStyle.responsiveTextSize, { fontWeight: '300' }]}>
								{quantity} {itemOrItems(quantity)}
								{' ·'} ${(singleOrder.prices.total / 100).toFixed(2)}
							</Text>
						</View>

						<View style={[GlobalStyle.ordersHeading, { alignItems: 'flex-end' }]}>
							<Text style={[GlobalStyle.responsiveTextSize, { fontWeight: '300' }]}>
								{singleOrder.deliveryStatus !== ''
									? convertDoordashStatus(singleOrder.deliveryStatus)
									: singleOrder.pickupStatus}
							</Text>
						</View>
					</View>
				</TouchableOpacity>
				<PrinterView singleOrder={singleOrder} />
				{!isCollapsed && (
					<View style={styles.orderContainer}>
						<View
							style={{
								flexDirection: 'row',
								paddingHorizontal: 15,
								display: singleOrder.deliveryTrackingURL ? 'none' : 'flex',
							}}
						>
							<View style={[{ flex: 1.25 }]}>
								<Text style={[GlobalStyle.responsiveTextSize]}>{'Placed on  '}</Text>
								<Text style={[GlobalStyle.responsiveTextSize, { fontWeight: '300' }]}>
									{formatInTimeZone(
										singleOrder.orderDate.seconds * 1000,
										localTimezone,
										"EEE MMM d 'at' h:mm a"
									)}
								</Text>
							</View>

							<View style={[{ flex: 1.25, alignItems: 'flex-end' }]}>
								<Text style={[GlobalStyle.responsiveTextSize]}>{'Pickup on'}</Text>
								<Text style={[GlobalStyle.responsiveTextSize, { fontWeight: '300' }]}>
									{readyTime}
								</Text>
							</View>
						</View>
						<View
							style={{
								paddingTop: 7,
								paddingBottom: 15,
								paddingHorizontal: 15,
								display: singleOrder.deliveryTrackingURL ? 'none' : 'flex',
							}}
						>
							<Text style={[GlobalStyle.responsiveTextSize]}>
								{'Order no. '}
								{singleOrder.orderNumber}
							</Text>
						</View>
						{singleOrder.deliveryTrackingURL !== null &&
							singleOrder.deliveryTrackingURL !== '' &&
							!isCollapsed && (
								<>
									<DoorDashTracking
										url={singleOrder?.deliveryTrackingURL}
										status={singleOrder?.deliveryStatus}
									/>
								</>
							)}
						<DisplayOrder
							items={singleOrder.orderItems}
							prices={singleOrder.prices}
							isCheckout={false}
							isConfirmed={false}
							navigation={navigation}
							deletePopup={null}
							loadedData={loadedData}
							trackingURL={singleOrder.deliveryTrackingURL}
						/>
					</View>
				)}
				<Spacer size={15} />
			</View>
		)
	}

	const renderSectionHeader = ({ section: { sectionKey } }) => {
		const section = sections.find(section => section.sectionKey === sectionKey)
		if (section.data.length === 0) {
			return null
		}
		return (
			<TouchableOpacity style={styles.sectionContainer} onPress={() => toggleSection(sectionKey)}>
				<Text
					style={[
						GlobalStyle.titleText,
						{ color: global.orgColor2, fontSize: largeTextSize, paddingBottom: 0 },
					]}
				>
					{sectionKey}
				</Text>
				<Text style={{ fontSize: largeTextSize, color: global.orgColor2 }}>
					{expandedSections[sectionKey] ? '▲' : '▼'}
				</Text>
			</TouchableOpacity>
		)
	}

	const renderItem = ({ item, section: { sectionKey } }) => {
		return expandedSections[sectionKey] ? (
			<View style={{ paddingHorizontal: '2%' }}>
				<MemoizedSetView singleOrder={item} sectionKey={sectionKey} />
			</View>
		) : null
	}

	function LoggedInView() {
		if (auth.currentUser) {
			if (ordersView.length === 0 && !loading) {
				return (
					<View key={'no_orders'} style={styles.noOrderContainer}>
						<Text style={GlobalStyle.medBoldText}>{noOrderText}</Text>
					</View>
				)
			} else if (loading) {
				return (
					<View key={'loading'} style={styles.noOrderContainer}>
						{global.pos && <FilterModeSwitcher />}
						<Image
							style={{
								width: 100,
								height: 100,
								alignSelf: 'center',
								marginTop: -20,
							}}
							source={require('../../assets/images/loadImg.gif')}
						/>
					</View>
				)
			} else {
				return (
					<View style={styles.container}>
						<View style={styles.innerContainer}>
							{!global.pos && Platform.OS === 'web' && (
								<Text
									style={[
										{
											color: global.orgColor2,
											fontSize: titleTextSize,
											paddingVertical: '2%',
											fontWeight: '700',
											paddingHorizontal: '2%',
										},
									]}
								>
									{'ORDERS'}
								</Text>
							)}

							<SectionList
								ListHeaderComponent={
									global.pos ? (
										<>
											<FilterModeSwitcher />
											<PrinterStatus receiptPrinter={receiptPrinter} printerType={printerType} />
										</>
									) : null
								}
								sections={sections}
								keyExtractor={(item, index) => item.orderID + '_' + (index + 1).toString()}
								renderSectionHeader={({ section }) => renderSectionHeader({ section })}
								renderItem={renderItem}
								initialNumToRender={10}
								maxToRenderPerBatch={10}
								viewabilityConfig={{ itemVisiblePercentThreshold: 50 }}
								ListFooterComponent={
									global.pos ? (
										<TouchableOpacity onPress={handleLoadMore} style={styles.loadMoreButton}>
											<Text style={{ color: 'white', fontSize: 16 }}>LOAD MORE</Text>
										</TouchableOpacity>
									) : null
								}
							/>

							<SectionList
								sections={sections}
								keyExtractor={(item, index) => item.orderID + '_' + (index + 1).toString()}
								renderSectionHeader={({ section }) => renderSectionHeader({ section })}
								renderItem={renderItem}
								initialNumToRender={10}
								maxToRenderPerBatch={10}
								viewabilityConfig={{ itemVisiblePercentThreshold: 50 }}
							/>
						</View>
					</View>
				)
			}
		} else {
			return (
				<View key={'signed_out'} style={styles.signedOutContainer}>
					<Text style={[GlobalStyle.medBoldText, { marginTop: -150 }]}>
						Please sign in to view orders
					</Text>
				</View>
			)
		}
	}

	return (
		<>
			<TriangleHeader
				logo={organizationLogo}
				background={
					ordersView.length > 0 && !loading && auth.currentUser
						? 'white'
						: Colors.custom.appBackground
				}
				navigation={navigation}
				title={'ORDERS'}
				backFunction={
					Platform.OS === 'web'
						? () => {
								if (navigation.canGoBack()) {
									navigation.goBack()
								} else {
									navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
								}
							}
						: null
				}
			/>
			<View style={{ backgroundColor: Colors.custom.white, flexGrow: 1 }}>
				<LoggedInView />
			</View>
			{global.pos ? <POSNavBar navigation={navigation} screenName={'Orders'} /> : null}
			{Platform.OS === 'web' && <CopyrightFooter />}
		</>
	)
}
