//hide points temporarily until order is placed
export function pointsTempRemove(redeemedRewards: any) {
	if (redeemedRewards) {
		const points: number = redeemedRewards.reduce((acc, reward) => {
			return acc + reward.pointsRedeemed
		}, 0)
		return points
	}
	return 0
}
