import '../../global'
import { Platform } from 'react-native'
import { preLoadImage } from '../../components/Image/ImageObject'
import { DATABASE_NAME } from '@env'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { convertHours } from '../../helpers/prepTimeFunctions'

function formatCuisines(cuisines) {
	if (cuisines) {
		return cuisines.map(cuisine => {
			return cuisine.toLowerCase()
		})
	}
}

export function formatRestaurantData(data: any) {
	return {
		heroImg: data.images && data.images.hero ? data.images.hero : '',
		logoImg: data.images && data.images.logo ? data.images.logo : '',
		name: data.name,
		multiIdentifier: data.multiIdentifier,
		id: data.id,
		hours: convertHours(data.hours, data.prepTime ?? 15),
		timezone: data.timezone,
		address: data.address,
		prepTime: data.prepTime ?? 15,
		foodType: data.foodType ?? '',
		couponCodes: data.couponCodes,
		social: data.social ?? {},
		schedulingJump: data.scheduleJump ?? 15,
		isSnoozed: data.isSnoozed ?? false,
		snoozeUntil: data.snoozeUntil ?? -1,
		menus: data.menus,
		cuisines: formatCuisines(data.cuisines) ?? [],
		isTest: data.isTest ?? false,
	}
}

export const getRestaurantData = () => {
	return new Promise((resolve, reject) => {
		const restaurantData = {}
		const restaurantRef = collection(doc(db, DATABASE_NAME, global.org), 'Restaurants')
		onSnapshot(
			restaurantRef,
			snapshot => {
				snapshot.docChanges().forEach((change, i) => {
					const data = change.doc.data()
					if (data !== undefined && !data.isDeleted && data.isEnabled) {
						global.timezone = data.timezone
						global.lat = data.address.latitude
						global.lng = data.address.longitude
						const restoData = formatRestaurantData(data)

						if (Platform.OS !== 'web' && restoData.heroImg !== '') {
							preLoadImage(restoData.heroImg)
						}
						if (Platform.OS !== 'web' && restoData.logoImg !== '') {
							preLoadImage(restoData.logoImg, false)
						}

						restaurantData[data.id] = restoData
						if (data.isDefault && data.isDefault === true) {
							restaurantData['defaultRestaurant'] = restoData
						} else if (restaurantData['defaultRestaurant'] === undefined) {
							restaurantData['defaultRestaurant'] = restoData
						}
					}
				})

				resolve(restaurantData)
			},
			reject
		)
	})
}
