import React, { useCallback, useEffect, useState } from 'react'
import {
	ScrollView,
	View,
	TouchableOpacity,
	Dimensions,
	Platform,
	TextInput,
	StyleSheet,
	Keyboard,
} from 'react-native'
import { Text } from '../../../components/Themed'
import { countQtySelected, findValidModListIds, getModListQuantity } from '../Helpers/functions'
import { ModifierItemCard } from './ModifierItemCard'
import { calculateOptimalCardWidth } from '../../../helpers/calculateOptimalCardWidth'
import GlobalStyle, {
	SMALL_HEIGHT,
	SMALL_WIDTH,
	formStyle,
	largeTextSize,
	mediumTextSize,
	titleTextSize,
} from '../../../constants/GlobalStyle'
import Colors from '../../../constants/Colors'
import QuantitySelector from './QuantitySelector'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import {
	addToModSum,
	setItemState,
	setModListQuantity,
	setSingleSelectionId,
	updateModMulti,
	updateModSingle,
} from '../../../state/Slices/itemSlice'

const screenWidth = SMALL_WIDTH
	? Dimensions.get('window').width * 0.95
	: Dimensions.get('window').width * 0.85

const card = calculateOptimalCardWidth(screenWidth > 720 ? 720 : screenWidth) //200, 10
const CARD_WIDTH = card.width

const selectionText = (list, quantitySelected) => {
	let titleText = ''
	if (list.max - quantitySelected === 1 && quantitySelected > 0) {
		titleText = `SELECT ONE MORE`
	} else if (list.max - quantitySelected === 0 && quantitySelected > 0) {
		titleText = 'PRESS NEXT TO CONTINUE'
	} else if (list.min === list.max && list.min !== -1) {
		titleText = `CHOOSE ${list.min - quantitySelected}`
	} else if (list.min === -1 && list.max === -1) {
		titleText = ''
	} else if (list.min !== -1 && list.max === -1) {
		titleText = `SELECT AT LEAST ${list.min - quantitySelected}`
	} else if (list.min === -1 && list.max !== -1) {
		titleText = `SELECT UP TO ${list.max - quantitySelected}`
	} else if (list.min !== -1 && list.max !== -1) {
		titleText = `SELECT UP TO ${list.max - quantitySelected}`
	}
	return titleText
}

const ModifierList = ({ list, index, scrollViewRef, isLoyalty, children }) => {
	const { visibleModList, visibleModListIndex, showMinErrorText, itemNote, modList } =
		useAppSelector(state => state.item)
	const dispatch = useAppDispatch()
	const qty = countQtySelected(list, getModListQuantity(modList, 0, list))
	const selectErrorText = ` \u00B7 ${list.min} ${list.min === 1 ? 'ITEM' : 'ITEMS'} REQUIRED`
	const title = list.name ? list.name.toUpperCase() : 'SELECT AN OPTION'

	const validModListIds = findValidModListIds(modList)

	const selectText = selectionText(list, qty)

	useEffect(() => {
		dispatch(setModListQuantity(list.id, getModListQuantity(modList, qty, list)))
	}, [visibleModListIndex])

	return (
		<View key={list.id}>
			<View style={{ display: visibleModListIndex < index ? 'none' : 'flex', paddingBottom: '2%' }}>
				<View
					style={[
						styles.collapsibleHeading,
						{
							paddingTop: SMALL_HEIGHT ? 0 : '4%',
						},
					]}
				>
					<Text style={[styles.stepTextStyle, { color: global.orgColor2 }]}>
						<Text
							style={{
								color: 'black',
								fontSize: titleTextSize - 5,
							}}
						>
							{list.id !== 'special_instructions'
								? `STEP ${validModListIds.findIndex(modListId => list.id === modListId) + 1}. `
								: 'SPECIAL INSTRUCTIONS'}
						</Text>

						{title}
					</Text>

					<TouchableOpacity
						style={[
							styles.editButtonStyle,
							{ display: visibleModListIndex == index ? 'none' : 'flex' },
						]}
						onPress={() => {
							dispatch(
								setItemState({
									visibleModList: list.id,
									visibleModListIndex: index,
								})
							)
						}}
					>
						<Text
							style={{
								fontSize: largeTextSize + 2,
								fontWeight: '400',
								color: 'black',
								textDecorationLine: 'underline',
								textAlignVertical: 'center',
								paddingTop: 3,
							}}
						>
							{'EDIT'}
						</Text>
					</TouchableOpacity>
				</View>
				{selectText !== '' && visibleModListIndex == index && list.id !== 'special_instructions' ? (
					<Text style={styles.selectTextStyle}>
						{selectText}
						<Text style={{ color: Colors.custom.pinkRed }}>
							{showMinErrorText ? selectErrorText : ''}
						</Text>
					</Text>
				) : null}
			</View>
			{visibleModList === list.id && (
				<>
					{list.id === 'special_instructions' ? (
						<View style={{ marginHorizontal: '3%' }}>
							<TextInput
								returnKeyType={'done'}
								style={[
									formStyle.textInput,
									{
										height: mediumTextSize * 2 + 60,
										paddingTop: Platform.OS === 'android' ? 0 : 12,
										paddingBottom: Platform.OS === 'android' ? 35 : 0,
										fontSize: mediumTextSize,
										fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont',
										marginBottom: 30,
										marginTop: 5,
									},
								]}
								placeholder={'Special instructions '}
								placeholderTextColor={Colors.greyscale[5]}
								value={itemNote}
								onChangeText={value => dispatch(setItemState({ itemNote: value }))}
								numberOfLines={2}
								multiline={true}
								maxLength={200}
								blurOnSubmit={true}
								onSubmitEditing={() => Keyboard.dismiss()}
								onFocus={() => {
									if (scrollViewRef.current) {
										const i = index <= 0 ? 1 : index
										const appHeight = Dimensions.get('window').height * ((i * 1.5) / 7.5)
										const webHeight = Dimensions.get('window').height * ((i * 1.5) / 15)
										setTimeout(() => {
											scrollViewRef.current.scrollTo({
												x: 0,
												y: Platform.OS === 'web' ? webHeight : appHeight,
												animated: true,
											})
										}, 300)
									}
								}}
							/>
							{!isLoyalty && <QuantitySelector />}
						</View>
					) : (
						<View style={styles.optionsContainerStyle}>{children}</View>
					)}
				</>
			)}
		</View>
	)
}

const onLayoutEntry = (event, index, setYPos, visibleModListIndex) => {
	const { y } = event.nativeEvent.layout
	if (index === visibleModListIndex + 1) {
		setTimeout(() => {
			setYPos(y - 50 + index * 180)
		}, 300)
	}
}

const ModifierEntry = React.memo(
	({ mod, toggleOption, index, setYPos, scrollViewRef, isLoyalty = false }: any) => {
		const { visibleModListIndex, modList } = useAppSelector(state => state.item)
		const listHasImage = mod.options.some(mod => mod.imageUrl && mod.imageUrl !== '')
		const quantitySelected = countQtySelected(mod, getModListQuantity(modList, 0, mod))
		let selectionType = mod.selectionType
		if (mod.max === 1 && mod.min === 1) {
			selectionType = 'SINGLE'
		}
		const isMaxIncrement = mod.max - quantitySelected === 0 && quantitySelected > 0 ? true : false
		const generatePlaceholders = options => {
			const placeholders = []
			const itemsInLastRow = options.length % card.cardsPerRow
			if (itemsInLastRow > 0 && itemsInLastRow < card.cardsPerRow) {
				const placeholdersCount = card.cardsPerRow - itemsInLastRow
				for (let i = 0; i < placeholdersCount; i++) {
					placeholders.push(
						<View key={`placeholder-${i}`} style={{ width: CARD_WIDTH, height: 0 }} />
					)
				}
			}
			return placeholders
		}

		return (
			<View onLayout={event => onLayoutEntry(event, index, setYPos, visibleModListIndex)}>
				<ModifierList list={mod} index={index} scrollViewRef={scrollViewRef} isLoyalty={isLoyalty}>
					{mod.options.map(option => {
						return (
							<ModifierItemCard
								id={option.id}
								name={option.name}
								price={option.price}
								imageUrl={option.imageUrl}
								isRecommended={option.isRecommended}
								isMulti={false}
								showImage={listHasImage}
								key={option.id + option.name}
								isSelected={option.isSelected}
								selectionType={selectionType}
								handleSelection={toggleOption}
								modList={mod}
								mod={option}
								cardWidth={CARD_WIDTH}
								isMaxIncrement={isMaxIncrement}
								isVariation={mod.variation}
							/>
						)
					})}
					{generatePlaceholders(mod.options)}
				</ModifierList>
			</View>
		)
	},
	(prevProps, nextProps) => {
		return prevProps.mod === nextProps.mod && prevProps.toggleOption === nextProps.toggleOption
	}
)

export const ModifierLists = ({ setYPos, scrollViewRef, isLoyalty = false }) => {
	const { modList, modListsQuantity, singleSelectionId } = useAppSelector(state => state.item)
	const dispatch = useAppDispatch()
	const [reset, setReset] = useState([])

	const handleSingleSelection = (list, mod) => {
		let toRemove = 0
		let toAdd = 0
		const remove = list.options.find(option => option.id === singleSelectionId[list.id])

		if (remove) {
			toRemove += remove.price ?? 0
		}

		const updatedOptions = list.options.map(m => {
			if (mod.id === m.id) {
				dispatch(setSingleSelectionId(list.id, m.id))
				setReset([])
				toAdd = m.price ?? 0
				return { ...m, isSelected: true, quantity: 1 }
			} else if (m.isSelected) {
				return { ...m, isSelected: false, quantity: 0 }
			}

			return m
		})

		dispatch(addToModSum(toAdd - toRemove))

		return { options: updatedOptions }
	}

	const handleMultipleSelection = (list, mod, isIncrement, quantity, setQuantity) => {
		const updatedOptions = list.options.map(m => {
			if (mod.id === m.id) {
				const selected = countQtySelected(list, getModListQuantity(modList, 0, list))

				if (isIncrement) {
					// increment modifier count
					const canSelect = list.max > selected || list.max < 0
					if (canSelect) {
						const minNotReached = list.min > 0 && selected + 1 < list.min
						if (minNotReached === false) {
							dispatch(setItemState({ showMinErrorText: false }))
						}
						const updatedQuantity = quantity ? quantity + 1 : 1
						dispatch(setModListQuantity(list.id, selected + 1))
						setQuantity(updatedQuantity)
						dispatch(addToModSum(mod.price ?? 0))

						return { ...m, isSelected: updatedQuantity > 0, quantity: updatedQuantity }
					} else {
						return { ...m, isSelected: m.quantity > 0, quantity: m.quantity }
					}
				} else {
					// decrement modifier count
					const updatedQuantity = quantity && quantity > 0 ? quantity - 1 : 0
					setQuantity(updatedQuantity)
					dispatch(addToModSum(-mod.price ?? 0))
					dispatch(setModListQuantity(list.id, selected - 1))
					return {
						...m,
						isSelected: updatedQuantity > 0,
						quantity: updatedQuantity,
					}
				}
			}
			return m
		})

		const updatedOption = updatedOptions.find(option => option.id === mod.id)

		return updatedOption
	}

	const toggleOption = useCallback(
		(modId, optionId, list, mod, isIncrement, quantity, setQuantity) => {
			let updatedOptions = null
			if (list.selectionType === 'SINGLE' || (list.max === 1 && list.min === 1)) {
				updatedOptions = handleSingleSelection(list, mod)
				dispatch(updateModSingle(modId, updatedOptions))
			} else if (list.selectionType === 'MULTIPLE') {
				updatedOptions = handleMultipleSelection(list, mod, isIncrement, quantity, setQuantity)
				dispatch(updateModMulti(modId, optionId, updatedOptions))
			}
		},
		[modList, modListsQuantity]
	)
	return (
		<ScrollView ref={scrollViewRef}>
			{modList.map((mod, index) => {
				const parentModifierId = mod.id.split('-')[1]
				const showNested = modList.some(modifierList =>
					modifierList.options.some(
						modifier => modifier.isSelected && modifier.id === parentModifierId
					)
				)

				if (mod.isNested && !showNested) {
					return null
				}

				return (
					<View key={mod.id}>
						<ModifierEntry
							key={index}
							mod={mod}
							index={index}
							toggleOption={toggleOption}
							modifiers={modList}
							setYPos={setYPos}
							scrollViewRef={scrollViewRef}
							isLoyalty={isLoyalty}
						/>
					</View>
				)
			})}
		</ScrollView>
	)
}

ModifierEntry.displayName = 'ModifierEntry'

const styles = StyleSheet.create({
	collapsibleHeading: {
		...GlobalStyle.collapsibleHeading,
		alignItems: 'flex-start',
	},
	stepTextStyle: {
		flexDirection: 'row',
		alignItems: 'center',
		flex: 1,
		flexWrap: 'wrap',
		marginRight: 30,
		fontSize: titleTextSize - 6,
		fontWeight: '400',
		color: Colors.greyscale[6],
		marginLeft: '3%',
	},
	selectTextStyle: {
		flexDirection: 'row',
		alignItems: 'center',
		flex: 1,
		fontSize: largeTextSize,
		fontWeight: '300',
		color: Colors.greyscale[5],
		marginTop: 5,
		marginHorizontal: '3%',
	},
	editButtonStyle: {
		paddingBottom: 25,
		paddingLeft: 30,
		marginRight: '3%',
	},
	optionsContainerStyle: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		marginBottom: 20,
		marginTop: SMALL_HEIGHT ? 0 : '2%',
		marginHorizontal: '3%',
	},
})
