import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
	selectedRestaurantId: '',
	userId: '',
	loadedData: {} as any,
	organizationLogo: '',
	stripePublishableKey: '',
	organizationLogoDark: '',
	webAppURL: '',
	iosAppId: '',
	restaurants: {} as any,
	referralCode: '',
	showKioskStartScreen: true,
	selectedPickupPoint: null as any,
	activeOrder: null as any,
	receiptPrinter: null as any,
	printerType: '',
	allDataLoaded: false,
	restaurantList: [] as any[],
	dataIsDoneLoading: false,
	disableIdleTimer: false,
	navContext: null as any,
	updateData: [] as any,
	userCurrentLocation: {} as any,
}

type StateTypes = typeof initialState

const slice = createSlice({
	name: 'data',
	initialState,
	reducers: {
		setStates: (state, action: PayloadAction<Partial<StateTypes>>) => {
			Object.assign(state, action.payload)
		},
		updateLoadedData: (state, action: PayloadAction<Partial<typeof state.loadedData>>) => {
			const updates = action.payload
			for (const key in updates) {
				if (Object.prototype.hasOwnProperty.call(updates, key)) {
					state.loadedData[key] = updates[key] // Add or replace the key in loadedData
				}
			}
		},
	},
})

const { setStates, updateLoadedData } = slice.actions

export const setDataState = (updates: Partial<StateTypes>) => setStates(updates)

export const setLoadedData = (updates: Partial<typeof initialState.loadedData>) =>
	updateLoadedData(updates)

export default slice.reducer
