import { useEffect } from 'react'
import { convertHours } from '../../../helpers/prepTimeFunctions'
import { utcToZonedTime } from 'date-fns-tz'
import Formatter from '../../../helpers/Formatter'
import { checkSnooze } from '../../../helpers/checkSnooze'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setMenuState } from '../../../state/Slices/menuSlice'
import { setDataState } from '../../../state/Slices/dataSlice'
import { isRestaurantOpen } from '../../../helpers/restaurantHours'

const useMenuData = ({ rIdToken, checkItemAvailability, navigation }) => {
	const dispatch = useAppDispatch()

	const { allDataLoaded, loadedData } = useAppSelector(state => state.data)
	const { localTimezone, allowOrdersOutsideOrgHours } = useAppSelector(state => state.settings)
	const { cartPrep } = useAppSelector(state => state.persisted)
	const { storeOpen } = useAppSelector(state => state.menu)

	useEffect(() => {
		if (!rIdToken.startsWith('category')) {
			let menuItemIds: any[] = []
			if (loadedData) {
				if (loadedData[rIdToken]) {
					const restaurantOpen = isRestaurantOpen(
						loadedData[rIdToken],
						loadedData[global.org].hours ?? null,
						allowOrdersOutsideOrgHours,
						localTimezone
					)
					dispatch(
						setMenuState({
							storeOpen: restaurantOpen,
						})
					)
				}
				if (loadedData['menus_for_' + rIdToken]) {
					loadedData['menus_for_' + rIdToken].forEach(data => {
						const currentDate = new Date().getTime()
						const activeDatesArray = data.activeDateRanges || []
						const activeTimes = data.activeTimesOfWeek
							? convertHours(data.activeTimesOfWeek, loadedData[rIdToken].prepTime)
							: {}

						const itemData = getMenuItemData(data.menuContent, loadedData, checkItemAvailability)
						if (
							data.id == 'DefaultMenu-' + rIdToken ||
							data.default === true ||
							data.activeDateRanges.length <= 0 ||
							data.activeTimesOfWeek.length <= 0
						) {
							global.menuId = data.id
							menuItemIds = itemData.itemList
						} else {
							if (activeDatesArray.length <= 0) {
								const d = cartPrep.isScheduled
									? new Date(cartPrep.pickupTime)
									: utcToZonedTime(new Date(), localTimezone)
								//convert current time to decimal i.e. 2:30 = 14.5
								const dec = d.getMinutes() / 60
								const decString = (dec + '').slice(2)
								const currentTime = d.getHours() + '.' + decString
								const currentDay = d.getDay()
								for (const [key, value] of Object.entries(activeTimes)) {
									//key = 0 -> 6 (day of week)
									//value = {startTimes:[], endTimes: []}
									//times are in number format i.e. 3:45 = 15.75
									if (parseInt(key) === currentDay) {
										var startTimes = value['startTimes']
										var endTimes = value['endTimes']
										startTimes.forEach((time, i) => {
											if (startTimes[i] <= currentTime && endTimes[i] >= currentTime) {
												global.menuId = data.id

												menuItemIds = itemData.itemList
											}
										})
									}
								}
							}

							// Check if valid date range
							activeDatesArray.forEach(dateRange => {
								if (
									(dateRange.startDate !== 0 &&
										dateRange.endDate !== 0 &&
										dateRange.startDate.toMillis() <= currentDate &&
										dateRange.endDate.toMillis() >= currentDate) ||
									(dateRange.startDate === 0 && dateRange.endDate === 0)
								) {
									const d = cartPrep.isScheduled
										? new Date(cartPrep.pickupTime)
										: utcToZonedTime(new Date(), localTimezone)
									//convert current time to decimal i.e. 2:30 = 14.5
									const dec = d.getMinutes() / 60
									const decString = (dec + '').slice(2)
									const currentTime = d.getHours() + '.' + decString
									const currentDay = d.getDay()
									for (const [key, value] of Object.entries(activeTimes)) {
										//key = 0 -> 6 (day of week)
										//value = {startTimes:[], endTimes: []}
										//times are in number format i.e. 3:45 = 15.75
										if (parseInt(key) === currentDay) {
											const startTimes = value['startTimes']
											const endTimes = value['endTimes']
											startTimes.forEach((time, i) => {
												if (startTimes[i] <= currentTime && endTimes[i] >= currentTime) {
													global.menuId = data.id

													menuItemIds = itemData.itemList
												}
											})
										}
									}
								}
							})
						}
					})
				}
			} else {
				navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
			}
			const localCtgTokens = {}
			let tokenAmts = 0
			menuItemIds.forEach(item => {
				const itemData = loadedData[item.id]
				tokenAmts++
				if (itemData && !checkSnooze(itemData.isSnoozed, itemData.snoozeUntil)) {
					if (item.category in localCtgTokens) {
						localCtgTokens[item.category].push({
							id: item.id,
							name: itemData.name, //toUpperCase
							desc: itemData.description,
							price: Formatter.currencyFormat(itemData.price / 100),
							image: itemData.imageUri ? itemData.imageUri : '',
							promoPrice: itemData.promo ? Formatter.currencyFormat(itemData.promo / 100) : '',
							menuId: global.menuId,
							category: item.category,
							thumbnail: itemData.thumbnailUri,
							rId: rIdToken,
							variationMinPrice:
								itemData.variationMinPrice !== -1
									? Formatter.currencyFormat((itemData.variationMinPrice + itemData.price) / 100)
									: -1,
							variationMaxPrice:
								itemData.variationMaxPrice !== -1
									? Formatter.currencyFormat((itemData.variationMaxPrice + itemData.price) / 100)
									: -1,
						})
					} else {
						localCtgTokens[item.category] = [
							{
								id: item.id,
								name: itemData.name, //toUpperCase
								desc: itemData.description,
								price: Formatter.currencyFormat(itemData.price / 100),
								image: itemData.imageUri ? itemData.imageUri : '',
								promoPrice: itemData.promo ? Formatter.currencyFormat(itemData.promo / 100) : '',
								menuId: global.menuId,
								category: item.category,
								thumbnail: itemData.thumbnailUri,
								rId: rIdToken,
								variationMinPrice:
									itemData.variationMinPrice !== -1
										? Formatter.currencyFormat((itemData.variationMinPrice + itemData.price) / 100)
										: -1,
								variationMaxPrice:
									itemData.variationMaxPrice !== -1
										? Formatter.currencyFormat((itemData.variationMaxPrice + itemData.price) / 100)
										: -1,
							},
						]
					}
				}
			})
			dispatch(setMenuState({ ctgTokens: localCtgTokens }))
		}
	}, [navigation, rIdToken, loadedData, allDataLoaded])
	return
}

// Make object of each item {cat, id}
// Add categories to array
function getMenuItemData(menuContent, data, checkItemAvailability) {
	const categories = []
	const items = []
	const featuredItems = []
	menuContent.forEach(catGroup => {
		if (!catGroup.isDeleted) {
			categories.push(catGroup.categoryName)
			catGroup.itemIds.forEach(itemId => {
				if (data[itemId]) {
					const isAvailable = checkItemAvailability(data[itemId])
					if (isAvailable) {
						items.push({ category: catGroup.categoryName, id: itemId })
					}
				}
			})
		}
	})
	return {
		categoryList: categories,
		itemList: items,
		featuredItems: featuredItems,
	}
}

export default useMenuData
