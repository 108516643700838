import React from 'react'
import { Dimensions, View } from 'react-native'
import { CheckoutTriangle } from '../../../components/Headers/Triangle'
import ImageObject from '../../../components/Image/ImageObject'
import { responsiveHeight } from 'react-native-responsive-dimensions'
import ExitIcon from '../../../components/Headers/UIButtons'

// Show on mobile & kiosk only -> WebHeader component is shown on web
export const CartHeader = ({ onPress, organizationLogo }) => {
	return (
		<>
			{global.kiosk && <ExitIcon onPress={onPress} />}

			<CheckoutTriangle />
			<View
				style={[
					{
						borderRadius: 100,
						position: 'absolute',
						top: Dimensions.get('window').height * 0.075,
						left: '5%',
					},
				]}
			>
				<ImageObject
					resizeMode="contain"
					source={organizationLogo}
					style={{
						borderRadius: 20,
						width: Dimensions.get('window').height * 0.12,
						height: Dimensions.get('window').height * 0.12,
						maxHeight: responsiveHeight(15),
						maxWidth: responsiveHeight(15),
						//position: 'absolute',
						//top: Dimensions.get('window').height * 0.075,
						//left: '5%',
					}}
				/>
			</View>
		</>
	)
}
