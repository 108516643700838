import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import GlobalStyle, { largeTextSize } from '../../constants/GlobalStyle'
import Colors from '../../constants/Colors'
import { Text } from '../Themed'
export type FooterProps = {
	onNextPress?: any
	onBackPress?: any
	blurNext?: boolean
	nextText?: string
	style?: any
	disabled?: boolean
}

export default function CheckoutNavFooter(props: FooterProps) {
	const {
		onNextPress = null,
		onBackPress = null,
		nextText = 'NEXT',
		blurNext = false,
		style = null,
		disabled = false,
	} = props

	return (
		<View
			style={[
				{
					position: 'absolute',
					bottom: 0,
					left: 0,
					right: 0,
					justifyContent: 'space-between',
					alignItems: 'center',
					flexDirection: 'row',
				},
				style,
			]}
		>
			{onBackPress ? (
				<TouchableOpacity
					style={[
						GlobalStyle.v2ButtonRightArrow,
						{
							backgroundColor: global.orgColor,
							paddingLeft: '5%',
						},
					]}
					onPress={onBackPress}
				>
					<AntDesign name="left" size={largeTextSize - 3} color="white" />
					<Text style={[styles.buttonText, { marginLeft: 10 }]}>BACK</Text>
				</TouchableOpacity>
			) : (
				<View style={GlobalStyle.v2ButtonRightArrow}></View>
			)}

			<TouchableOpacity
				style={[
					GlobalStyle.v2ButtonRightArrow,
					{
						backgroundColor: blurNext ? Colors.greyscale[5] : global.orgColor,
						paddingRight: '5%',
					},
				]}
				onPress={onNextPress}
				disabled={disabled}
			>
				<Text style={[styles.buttonText, { marginRight: 10 }]}>{nextText}</Text>
				<AntDesign name="right" size={largeTextSize - 3} color="white" />
			</TouchableOpacity>
		</View>
	)
}

const styles = StyleSheet.create({
	buttonText: {
		color: 'white',
		fontWeight: '400',
		fontSize: largeTextSize,
	},
})
