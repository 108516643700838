import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModifierList } from '../types'

const initialState = {
	objects: {} as { [key: string]: boolean },
	collapsibles: {} as any,
	itemId: '',
	itemName: '',
	itemTaxes: [] as any[],
	ageVerificationRequired: false,
	itemNote: '',
	idempotencyKey: '',
	itemDesc: '',
	posId: '',
	itemPrice: 0,
	promoPrice: 0,
	discountPrice: 0,
	quantity: 1,
	imageUri: '',
	modList: [] as Array<ModifierList>,
	modListLoaded: false,
	isModList: false,
	restId: '',
	allowAdd: false,
	allowAddMulti: false,
	visibleModList: '',
	visibleModListIndex: 0,
	totalModPrice: 0,
	showMinErrorText: false,
	variationIds: [] as any[],
	modListsQuantity: {} as any,
	singleSelectionId: {} as any,
	itemHours: {} as any,
}

type StateTypes = typeof initialState

const slice = createSlice({
	name: 'item',
	initialState,
	reducers: {
		setStates: (state, action: PayloadAction<Partial<StateTypes>>) => {
			Object.assign(state, action.payload)
		},
		incrementQuantity: state => {
			state.quantity += 1
		},
		decrementQuantity: state => {
			state.quantity = state.quantity > 1 ? state.quantity - 1 : 1
		},
		addToTotalModPrice: (state, action) => {
			state.totalModPrice = state.totalModPrice + action.payload
		},
		singleModSelection: (state, action) => {
			const { modId, updatedOptions } = action.payload
			state.modList = state.modList.map(mod =>
				mod.id === modId
					? {
							...mod,
							options: updatedOptions.options,
						}
					: mod
			)
		},
		multiModSelection: (state, action) => {
			const { modId, optionId, updatedOptions } = action.payload
			state.modList = state.modList.map(mod =>
				mod.id === modId
					? {
							...mod,
							options: mod.options.map(option =>
								option.id === optionId ? { ...option, ...updatedOptions } : option
							),
						}
					: mod
			)
		},
		updateSingleSelectionId: (
			state,
			action: PayloadAction<{ listId: string; selectedOptionId: string }>
		) => {
			const { listId, selectedOptionId } = action.payload
			state.singleSelectionId[listId] = selectedOptionId
		},
		updateModListQuantity: (state, action: PayloadAction<{ listId: string; quantity: number }>) => {
			const { listId, quantity } = action.payload
			if (!state.modListsQuantity[listId]) {
				state.modListsQuantity[listId] = 0
			}
			state.modListsQuantity[listId] = quantity
		},
	},
})

const {
	setStates,
	incrementQuantity,
	decrementQuantity,
	addToTotalModPrice,
	singleModSelection,
	multiModSelection,
	updateSingleSelectionId,
	updateModListQuantity,
} = slice.actions

export const setItemState = (updates: Partial<StateTypes>) => setStates(updates)
export const incrementItemQuantity = () => incrementQuantity()
export const decrementItemQuantity = () => decrementQuantity()
export const addToModSum = (amount: number) => addToTotalModPrice(amount)
export const updateModSingle = (modId: string, updatedOptions: any) =>
	singleModSelection({ modId, updatedOptions })
export const updateModMulti = (modId: string, optionId: string, updatedOptions: any) =>
	multiModSelection({ modId, optionId, updatedOptions })
export const setSingleSelectionId = (listId: string, selectedOptionId: string) =>
	updateSingleSelectionId({ listId, selectedOptionId })
export const setModListQuantity = (listId: string, quantity: number) =>
	updateModListQuantity({ listId, quantity })

export default slice.reducer
