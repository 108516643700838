import React, { useState, useRef, useEffect } from 'react'
import { View, ScrollView, TouchableOpacity, Dimensions, Platform, Animated } from 'react-native'
import Colors from '../../../constants/Colors'
import { smallTextSize } from '../../../constants/GlobalStyle'
import { RestaurantList } from './RestaurantList'
import { checkSnooze } from '../../../helpers/checkSnooze'
import { cuisineImages } from '../../../assets/images/cuisines/content'
import CopyrightFooter from '../../../components/Footers/CopyrightFooter'
import { MaterialIcons } from '@expo/vector-icons'
import { Text } from '../../../components/Themed'
import { useAppSelector } from '../../../state/hooks'

export default function CuisineSelector({ navigation }) {
	const CONTENT_MARGIN = 20
	const [selectedCuisine, setSelectedCuisine] = useState(null)
	const [scrollX, setScrollX] = useState(0)
	const [isAtEnd, setIsAtEnd] = useState(false)
	const [contentWidth, setContentWidth] = useState(0)
	const scrollViewRef = useRef(null)
	const [filteredRestaurants, setFilteredRestaurants] = useState([])
	const windowWidth = Dimensions.get('window').width

	const { loadedData, restaurants } = useAppSelector(state => state.data)
	const { isTestMode } = useAppSelector(state => state.persisted)
	const { showCuisines, isSandbox } = useAppSelector(state => state.settings)

	useEffect(() => {
		const filterRestaurants = () => {
			const updatedFilteredRestaurants = restaurants.filter(restaurant => {
				const isNotSnoozed = !checkSnooze(restaurant.isSnoozed, restaurant.snoozeUntil)
				const isValidTestRestaurant = !restaurant.isTest || isSandbox || isTestMode
				const cuisineIncluded = selectedCuisine
					? restaurant.cuisines.includes(selectedCuisine.toLowerCase())
					: true
				return cuisineIncluded && isNotSnoozed && isValidTestRestaurant
			})
			setFilteredRestaurants(updatedFilteredRestaurants)
		}

		filterRestaurants()
	}, [isTestMode, selectedCuisine])

	const handleCuisinePress = cuisine => {
		setSelectedCuisine(selectedCuisine === cuisine ? null : cuisine)
	}

	const handleScroll = event => {
		const { contentOffset, contentSize, layoutMeasurement } = event.nativeEvent

		if (contentOffset.x + layoutMeasurement.width >= contentSize.width) {
			setIsAtEnd(true)
		} else {
			setIsAtEnd(false)
		}
		setScrollX(event.nativeEvent.contentOffset.x)
	}

	const scrollRight = () => {
		if (scrollViewRef.current) {
			scrollViewRef.current.scrollTo({ x: scrollX + 200, animated: true })
		}
	}

	const scrollLeft = () => {
		if (scrollViewRef.current) {
			scrollViewRef.current.scrollTo({ x: scrollX - 200, animated: true })
		}
	}

	// Sort cuisines by ordinal
	const sortedCuisines = [...loadedData.cuisines].sort((a, b) => {
		if (a.ordinal === undefined) return 1
		if (b.ordinal === undefined) return -1
		return a.ordinal - b.ordinal
	})

	// Filter out cuisines that aren't available in any restaurant
	const availableCuisines = sortedCuisines.filter(cuisine =>
		restaurants.some(restaurant => restaurant.cuisines.includes(cuisine.id.toLowerCase()))
	)

	// Height of the ScrollView content area
	const scrollViewHeight = 140
	const buttonSize = 40

	const isAtStart = scrollX <= 0

	return (
		<>
			<View
				style={{
					flex: 1,
					marginTop: Platform.OS === 'web' ? 0 : -50,
					maxWidth: 1325,
					alignSelf: 'center',
					width: '100%',
					marginBottom: 20,
				}}
			>
				{Platform.OS === 'web' && !isAtStart && (
					<TouchableOpacity
						onPress={scrollLeft}
						style={{
							display:
								availableCuisines.length > 0 &&
								showCuisines &&
								contentWidth + CONTENT_MARGIN >= windowWidth
									? 'flex'
									: 'none',
							position: 'absolute',
							left: Dimensions.get('window').width <= 1225 ? 0 : buttonSize,
							top: scrollViewHeight / 2 - buttonSize / 2 + 10,
							zIndex: 2,
							backgroundColor: 'white',
							width: buttonSize,
							height: buttonSize,
							justifyContent: 'center',
							alignItems: 'center',
							borderRadius: buttonSize / 2,
							shadowColor: '#000',
							shadowOffset: { width: 0, height: 2 },
							shadowOpacity: 0.25,
							shadowRadius: 3.84,
							elevation: 5,
						}}
					>
						<MaterialIcons name="chevron-left" size={24} color="black" />
					</TouchableOpacity>
				)}
				<ScrollView
					ref={scrollViewRef}
					horizontal={true}
					showsHorizontalScrollIndicator={false}
					style={{
						display: availableCuisines.length > 0 && showCuisines ? 'flex' : 'none',
						paddingVertical: 20,
						maxHeight: scrollViewHeight,
						paddingHorizontal: Dimensions.get('window').width <= 1225 ? '4%' : 0,
						marginLeft: Dimensions.get('window').width <= 1225 ? 0 : 10,
						maxWidth: 1215,
						alignSelf: 'center',
						width: contentWidth + CONTENT_MARGIN >= windowWidth ? '100%' : null,
					}}
					onScroll={handleScroll}
					onContentSizeChange={(w, h) => setContentWidth(w)}
					scrollEventThrottle={16}
				>
					{availableCuisines.map((cuisine, index) => {
						const cuisineName = cuisine.title
						const isSelected = selectedCuisine === cuisineName
						const rotation = isSelected ? '15deg' : '0deg'
						return (
							<TouchableOpacity
								key={cuisine.id}
								onPress={() => handleCuisinePress(cuisineName)}
								style={{
									paddingVertical: 8,
									paddingRight: 15,
									alignItems: 'center',
								}}
							>
								<View
									style={{
										width: 80,
										height: 80,
										borderRadius: 40,
										backgroundColor: isSelected ? Colors.greyscale[2] : Colors.custom.appBackground,
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Animated.Image
										source={
											cuisine.imageUrl ? { uri: cuisine.imageUrl } : cuisineImages[cuisine.id]
										}
										style={{
											width: 50,
											height: 50,
											transform: [{ rotate: rotation }],
										}}
									/>
								</View>
								<Text style={{ fontSize: smallTextSize, fontWeight: '500' }}>{cuisineName}</Text>
							</TouchableOpacity>
						)
					})}
				</ScrollView>
				{Platform.OS === 'web' && !isAtEnd && (
					<TouchableOpacity
						onPress={scrollRight}
						style={{
							display:
								contentWidth + CONTENT_MARGIN >= windowWidth && showCuisines ? 'flex' : 'none',
							position: 'absolute',
							right: Dimensions.get('window').width <= 1225 ? 0 : buttonSize - 10,
							top: scrollViewHeight / 2 - buttonSize / 2 + 10,
							zIndex: 2,
							backgroundColor: 'white',
							width: buttonSize,
							height: buttonSize,
							justifyContent: 'center',
							alignItems: 'center',
							borderRadius: buttonSize / 2,
							shadowColor: '#000',
							shadowOffset: { width: 0, height: 2 },
							shadowOpacity: 0.25,
							shadowRadius: 3.84,
							elevation: 5,
						}}
					>
						<MaterialIcons name="chevron-right" size={24} color="black" />
					</TouchableOpacity>
				)}
				{/* Restaurant List */}
				<RestaurantList navigation={navigation} visibleRestaurants={filteredRestaurants} />
				{Platform.OS !== 'web' && (
					<View
						style={{
							display: availableCuisines.length > 0 && showCuisines ? 'flex' : 'none',
						}}
					>
						<Text style={{ textAlign: 'center', opacity: 0.3, paddingTop: 10 }}>
							Icons by Freepik
						</Text>
					</View>
				)}
			</View>
			{Platform.OS === 'web' && (
				<CopyrightFooter showIcons={availableCuisines.length > 0 && showCuisines} />
			)}
		</>
	)
}
