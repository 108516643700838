import Fuse from 'fuse.js'
import _ from 'lodash'
import { logSearchMenuAnalytics } from '../../../firebase/analytics'
import { MED_WIDTH, SIDEBAR_WIDTH, SMALL_WIDTH } from '../../../constants/GlobalStyle'
import { Dimensions } from 'react-native'
import { calculateOptimalCardWidth } from '../../../helpers/calculateOptimalCardWidth'

// Search
export const createFuse = items => {
	const options = {
		threshold: 0.45,
		keys: [
			{ name: 'name', weight: 0.45 },
			{ name: 'desc', weight: 0.3 },
			{ name: 'category', weight: 0.25 },
		],
	}
	return new Fuse(items, options)
}

// Search
export const groupResultsByCategoryOrder = (results, searchCardsPerRow) => {
	const groupedByCategory = {}
	const orderedCategories = []

	results.forEach(({ item }) => {
		const category = item.category
		if (!groupedByCategory[category]) {
			groupedByCategory[category] = []
			orderedCategories.push(category)
		}
		groupedByCategory[category].push(item)
	})

	return orderedCategories.map(categoryName => ({
		name: categoryName,
		items: addExtraComponents(groupedByCategory[categoryName], searchCardsPerRow),
	}))
}
// Search
export const createDebouncedSearch = (fuse, setMenuItems, setLoading, searchCardsPerRow) => {
	return _.debounce(text => {
		if (text.length > 0) {
			const result = fuse.search(text)
			setMenuItems(groupResultsByCategoryOrder(result, searchCardsPerRow))
			logSearchMenuAnalytics(text)
			setLoading(false)
		} else {
			setMenuItems([])
			setLoading(false)
		}
	}, 400)
}

export const addExtraComponents = (list, cardsPerRow) => {
	const newList = [...list]
	if (list.length % cardsPerRow > 1) {
		for (let i = 0; i < cardsPerRow; i++) {
			newList.push({ id: `invisible_${i}`, invisible: true })
		}
	}
	return newList
}

export function getBrandCardsLayout(singleBrandEnabled, searchResults) {
	if (searchResults) {
		const searchScreenWidth =
			Dimensions.get('window').width > 1450
				? 1300 * 0.9
				: SMALL_WIDTH
					? Dimensions.get('window').width * 0.9
					: Dimensions.get('window').width * 0.8
		const searchCard = calculateOptimalCardWidth(
			searchScreenWidth,
			!SMALL_WIDTH && MED_WIDTH ? 180 : 200
		)
		return searchCard
	} else {
		const multiplier = singleBrandEnabled ? 0.1 : 0
		const screenWidth = SMALL_WIDTH
			? Dimensions.get('window').width * 0.93
			: Dimensions.get('window').width > 1450 && !MED_WIDTH && singleBrandEnabled
				? Dimensions.get('window').width * 0.95 - SIDEBAR_WIDTH - 20
				: Dimensions.get('window').width > 1450 && !MED_WIDTH
					? 1225 - SIDEBAR_WIDTH
					: !MED_WIDTH
						? Dimensions.get('window').width * (0.85 + multiplier) - SIDEBAR_WIDTH - 20
						: Dimensions.get('window').width * (0.82 + multiplier)

		const card = calculateOptimalCardWidth(
			screenWidth,
			!SMALL_WIDTH && MED_WIDTH && !singleBrandEnabled ? 180 : 200
		)
		return card
	}
}

export function getCategoryCardsLayout() {
	const screenWidth = SMALL_WIDTH
		? Dimensions.get('window').width * 0.93 //-40
		: Dimensions.get('window').width > 1450
			? 1300 * 0.93
			: Dimensions.get('window').width * 0.82

	const card = calculateOptimalCardWidth(screenWidth, !SMALL_WIDTH && MED_WIDTH ? 180 : 200)
	return card
}
