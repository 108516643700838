import * as React from 'react'
import { View } from 'react-native'
import { RootStackParamList, RootTabScreenProps } from '../../navigation/types'
import { SMALL_WIDTH } from '../../constants/GlobalStyle'
import { RouteProp, useRoute } from '@react-navigation/native'
import useMenuData from './Hooks/useMenuData'
import CategoryMenu from './Components/CategoryMenu'
import useMenuItemListener from '../../firebase/fbDataRetrieval/ListenerHooks/useMenuItemListener'
import useMenuListener from '../../firebase/fbDataRetrieval/ListenerHooks/useMenuListener'
import useModItemListener from '../../firebase/fbDataRetrieval/ListenerHooks/useModItemListener'
import useModListListener from '../../firebase/fbDataRetrieval/ListenerHooks/useModListListener'
import BrandMenu from './Components/BrandMenu'
import { getHoursStatus, isRestaurantOpen } from '../../helpers/restaurantHours'
import Colors from '../../constants/Colors'
import useUserListener from '../../firebase/fbDataRetrieval/ListenerHooks/useUserListener'
import useRestaurantListener from '../../firebase/fbDataRetrieval/ListenerHooks/useRestaurantListener'
import OrderBanner from '../../components/Delivery/OrderBanner'
import { useAppSelector } from '../../state/hooks'

export default function RestaurantScreen({ navigation }: RootTabScreenProps<'restaurant'>) {
	const { loadedData, restaurantList } = useAppSelector(state => state.data)
	const { isSingleBrand, allowOrdersOutsideOrgHours, localTimezone } = useAppSelector(
		state => state.settings
	)
	const { logoImage, heroImage } = useAppSelector(state => state.menu)
	const { selectedLocation } = useAppSelector(state => state.persisted)

	const { ctgTokens, storeOpen } = useAppSelector(state => state.menu)

	const defaultRestaurantId = restaurantList.length > 0 ? restaurantList[0].id : ''
	let rIdToken =
		useRoute<RouteProp<RootStackParamList, 'Menu'>>().params?.rId || defaultRestaurantId

	if (global.kiosk && selectedLocation && selectedLocation.id !== undefined) {
		rIdToken = selectedLocation.id
	}

	if (rIdToken === '') {
		return <></>
	}

	let restaurantHoursStatus, restaurantName

	const tokenParts = rIdToken.split('_')

	if (isSingleBrand) {
		useRestaurantListener()
		useUserListener()
	}

	const restaurantAddress = () => {
		const location = loadedData[rIdToken]
		const formattedAddressLine = `${location.address.addressLine1} ${
			location.address.addressLine2 ? location.address.addressLine2 : ''
		}`
		const formattedCityLine = `${location.address.city}, ${location.address.provinceState} ${location.address.postCode}`

		return {
			line1: formattedAddressLine,
			line2: formattedCityLine,
		}
	}

	if (tokenParts[0] !== 'category') {
		if (loadedData[rIdToken]) {
			restaurantName = loadedData[rIdToken].name
			restaurantHoursStatus = getHoursStatus(
				loadedData[rIdToken],
				loadedData[global.org].hours ?? null,
				allowOrdersOutsideOrgHours,
				localTimezone
			)
		}
	}

	function checkItemAvailability(data) {
		if (data.hours !== null) {
			return isRestaurantOpen(
				data,
				loadedData[global.org].hours ?? null,
				allowOrdersOutsideOrgHours,
				localTimezone
			)
		} else {
			return true
		}
	}

	/**
	 * Listen for changes in database and update
	 */
	useMenuItemListener()
	useMenuListener()
	useModItemListener()
	useModListListener()

	/**
	 * Load restaurant menu data
	 */
	useMenuData({
		rIdToken: rIdToken,
		navigation: navigation,
		checkItemAvailability: checkItemAvailability,
	})

	// useEffect(() => {
	// 	const unsubscribe = navigation.addListener('focus', () => {
	// 		setReset([])
	// 	})
	// 	return () => {
	// 		unsubscribe()
	// 	}
	// }, [navigation])

	function renderPage() {
		const tokens = {}
		let isCategory = false
		let ctgName = ''
		if (tokenParts[0] === 'category') {
			isCategory = true
			ctgName = tokenParts[1].replace(/\+|%2B/g, ' ') // url decoding
			const ctg = loadedData['menuItemCategories'].find(
				cat => cat.name.toLowerCase() === ctgName || cat.name.toLowerCase() === ctgName
			)
			if (ctg) {
				const items = []
				ctg.items.forEach(item => {
					const isAvailable = checkItemAvailability(item)
					if (isAvailable) {
						items.push(item)
					}
				})
				tokens[ctg.name] = items
			}
		}

		return (
			<>
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(0,0,0,0.5)',
					}}
				>
					<View
						style={{
							overflow: 'hidden',
							maxWidth: isSingleBrand ? 9999 : 1300,
							width: !SMALL_WIDTH && !isSingleBrand ? '90%' : '100%',
							height: !SMALL_WIDTH && !isSingleBrand ? '90%' : '100%',
							backgroundColor: Colors.custom.appBackground,
							borderRadius: !SMALL_WIDTH && !isSingleBrand ? 10 : 0,
						}}
					>
						{isSingleBrand && <OrderBanner navigation={navigation} />}

						{isCategory ? (
							<CategoryMenu
								navigation={navigation}
								ctgTokens={tokenParts[0] === 'category' ? tokens : ctgTokens}
								storeOpen={storeOpen}
								isCategory={isCategory}
								logoImageURI={logoImage}
								heroImageURI={heroImage}
								categoryName={ctgName}
							/>
						) : (
							<BrandMenu
								navigation={navigation}
								ctgTokens={tokenParts[0] === 'category' ? tokens : ctgTokens}
								storeOpen={storeOpen}
								logoImageURI={loadedData[rIdToken].logoImg}
								heroImageURI={loadedData[rIdToken].heroImg}
								rName={restaurantName}
								rId={rIdToken}
								hoursText={restaurantHoursStatus}
								address={restaurantAddress()}
							/>
						)}
					</View>
				</View>
			</>
		)
	}

	return <>{renderPage()}</>
}
