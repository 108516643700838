import { auth, db } from '../../../firebase/config'
import {
	formatDiscountForSquare,
	formatItemsForFirebase,
	formatItemsForSquare,
	formatServiceChargesForSquare,
	getRestaurantSpecificDiscounts,
} from './itemFormatFunctions'
import { getTaxes } from './priceFunctions'
import { Platform } from 'react-native'
import { DATABASE_NAME } from '@env'
import { doc, collection } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { add, sub } from 'date-fns'
/**
 * Place order functions
 */

const functions = getFunctions()

export const findUserID = (userId, isGuestCheckout) => {
	// userId needs to be refactored out of ConfirmOrder.tsxy
	var uId = ''
	if (global.kiosk) {
		uId = userId
	} else if (!isGuestCheckout) {
		uId = auth.currentUser.uid
	} else {
		if (userId !== '') {
			uId = userId
		} else {
			uId = 'Guest'
		}
	}

	return uId
}

export async function processOrder({
	paymentProvider,
	prices,
	items,
	orderNote,
	discount,
	tip,
	userId,
	isGuestCheckout,
	restaurants,
	tableNumber,
	isDineIn,
	pickupName,
	restaurantDiscountAmount,
	selectedMethods,
	posExtraData,
	redeemedRewards,
	pointsEarned,
	timestampData,
	pickupPointObject,
	isSandbox,
	cartPrep,
	FCMToken,
	loadedData,
	isDelivery,
}) {
	const orderLineItems = formatItemsForSquare(items)

	//item data for db orders collection
	const dbOrderItems = formatItemsForFirebase(items)

	const discountObj = getRestaurantSpecificDiscounts(items, prices, [], [])

	const taxArray = getTaxes(items)

	const discountView = formatDiscountForSquare(
		items,
		restaurants,
		discount ? discount.orgCoverage : 0,
		discount,
		prices.subTotal,
		prices.noCodeDiscount,
		prices.discount
	)

	const serviceChargeView = formatServiceChargesForSquare(tip, prices.serviceCharge)

	let displayName = auth.currentUser ? auth.currentUser.displayName : 'Guest'
	if (displayName == null) {
		displayName = 'APP ORDER'
	}

	const note = orderNote
	let orderSource = 'App Pickup'
	if (Platform.OS === 'web') {
		orderSource = 'Web Pickup'
	} else if (global.kiosk) {
		if (isDineIn) {
			orderSource = 'Dine In'
		} else {
			orderSource = 'Kiosk Pickup'
		}
	}

	const functions = getFunctions()

	const id = doc(collection(db, DATABASE_NAME, global.org, 'Orders')).id
	const priceReturn: any = { ...prices }
	delete priceReturn.noCodeDiscount
	delete priceReturn.deliveryFee
	for (const key in priceReturn) {
		if (key !== 'taxesList') {
			if (key == 'discount') {
				//if discount, get rid of negative
				priceReturn[key] = Math.abs(Math.round(priceReturn[key] * 100))
			} else {
				priceReturn[key] = Math.round(priceReturn[key] * 100)
			}
		} else {
			//if taxes list, go through each entry
			if (priceReturn[key].length > 0) {
				priceReturn[key] = priceReturn[key].map(tax => ({
					...tax,
					amount: Math.round(tax.amount),
				}))
			}
		}
	}
	priceReturn.currency = 'CAD'

	const uId = findUserID(userId, isGuestCheckout)

	let channelData = null
	if (global.pos && posExtraData.type === 'thirdParty') {
		channelData = {
			orderType: isDineIn ? 'dineIn' : 'takeOut',
			appType: 'pos',
			posPaymentType: posExtraData.type,
			thirdPartyPaymentInfo: {
				name: posExtraData.name,
				id: posExtraData.firebaseId,
				paymentNote: posExtraData.note,
			},
		}
	} else if (global.pos) {
		channelData = {
			orderType: isDineIn ? 'dineIn' : 'takeOut',
			appType: 'pos',
			posPaymentType: posExtraData.type,
		}
	} else {
		channelData = {
			orderType: isDineIn ? 'dineIn' : isDelivery ? 'delivery' : 'takeOut',
			appType: Platform.OS === 'web' ? 'web' : global.kiosk ? 'kiosk' : 'mobile',
		}
	}

	if (pickupPointObject && pickupPointObject.id) {
		channelData.pickupPointId = pickupPointObject.id
	}

	let loyaltyData = {}
	if (pointsEarned > 0) {
		loyaltyData = {
			userId: uId,
			pointsEarned: pointsEarned,
		}
	}

	let pickupDate = null
	if (isDelivery && cartPrep.isScheduled) {
		pickupDate = sub(new Date(cartPrep.deliveryTime), {
			minutes: cartPrep.deliveryPrepTime - cartPrep.longestPrepTime,
		})
	} else if (cartPrep.isScheduled) {
		pickupDate = cartPrep.pickupTime
	} else {
		pickupDate = add(new Date(), { minutes: cartPrep.longestPrepTime })
	}

	const orderObj = {
		id: id,
		userId: uId,
		orderNote: note,
		prepTime: cartPrep.prepTimeDuration,
		orderItems: dbOrderItems,
		orderDate: new Date(),
		pickupDate: pickupDate,
		priceData: priceReturn,
		channelData: channelData,
		manualInput: false,
		status: 'Pending',
		tableNumber: tableNumber !== '' && tableNumber !== null ? 'table ' + tableNumber : pickupName,
		textOrderUpdates:
			selectedMethods.phoneString !== '' &&
			selectedMethods.textUpdates &&
			selectedMethods.textMessage &&
			prices.deliveryFee <= 0,
		//orderNumber: orderProcessed.orderNumber,
		appliedDiscount: discount ? discount.id : null,
		receiptData: {
			emailAddress: selectedMethods.email ? selectedMethods.emailString : '',
			phoneNumber: selectedMethods.textMessage ? selectedMethods.phoneString : '',
			printed: selectedMethods.print,
		},
		loyaltyData: loyaltyData,
		timestampData: timestampData,
		scheduled: cartPrep.isScheduled,
		isTestOrder: isSandbox,
		FCMToken: FCMToken,
	}
	const phoneNumber = selectedMethods.phoneString !== '' ? '+1' + selectedMethods.phoneString : ''

	const processOrderForSquare = httpsCallable(functions, 'processOrderForSquareV6')
	let orderProcessed: any = await processOrderForSquare({
		prepTime: cartPrep.prepTimeDuration,
		paymentProvider,
		orderNote: note,
		orderItems: orderLineItems,
		discounts: discountView,
		serviceCharges: serviceChargeView,
		source: orderSource,
		uid: userId,
		orgId: global.org,
		taxes: taxArray,
		orderId: id,
		orgDiscountPercentCoverage: discount ? discount.orgCoverage : 0,
		restaurantSpecificDiscountObj: discountObj,
		phoneNumber: phoneNumber,
		tableNumber: tableNumber,
		isDineIn: isDineIn,
		tipAmount: prices.tip,
		priceData:
			global.kiosk && paymentProvider === 'square' ? { ...prices, serviceCharge: 0 } : prices,
		pickupName: pickupName,
		discountId: discount ? discount.id : '',
		orderObj: orderObj,
		isGuestCheckout: isGuestCheckout,
		redeemedRewards: redeemedRewards,
		isKiosk: global.kiosk,
		isBankersRounding: loadedData[global.org].taxRoundingMethod === 'bankers' || false,
	}).catch(e => {
		console.log(e)
		return 'PaymentError'
	})

	orderProcessed =
		orderProcessed === 'PaymentError'
			? 'PaymentError'
			: { ...orderProcessed, orderNumDisplay: orderProcessed.orderNumber, created: true }

	return orderProcessed
}

export async function addCouponForReferrer(couponCode, userId) {
	if (couponCode.startsWith('refer-')) {
		const addReferrerCoupon = httpsCallable(functions, 'addCouponForReferrer')

		addReferrerCoupon({
			orgId: global.org,
			userId: userId,
			couponCode: couponCode,
		})
			.then(() => {
				console.log('Referrer coupon added')
			})
			.catch(e => {
				console.log('Error adding referrer coupon ' + e)
			})
	}
}
