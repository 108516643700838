import React, { useEffect, useState } from 'react'
import { View, TouchableOpacity, StyleSheet, Share, Clipboard, Platform } from 'react-native'
import ShareRow from './Components/ShareRow'
import Colors from '../../constants/Colors'
import { auth } from '../../firebase/config'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { TriangleHeader } from '../../components/Headers/TriangleHeader'
import GlobalStyle, { titleTextSize } from '../../constants/GlobalStyle'
import CopyrightFooter from '../../components/Footers/CopyrightFooter'
import { Text } from '../../components/Themed'
import { useAppSelector } from '../../state/hooks'

/**
 * Refer a friend screen
 * Show if referralEnabled in database
 */
export const ReferScreen = ({ route, navigation }) => {
	const referralCode = route.params.referralCode
	const referralString = route.params.referral
	const referrerString = route.params.referrer

	const { loadedData, organizationLogo } = useAppSelector(state => state.data)
	const [referralCodeState, setReferralCode] = useState(referralCode)

	let url = ''
	if (loadedData[global.org] && loadedData[global.org].webURL) {
		url = loadedData[global.org].webURL
	}

	const orgName = loadedData[global.org] ? loadedData[global.org].organizationName : ''

	const functions = getFunctions()

	async function getDBUser() {
		const getUserInDB = httpsCallable(functions, 'getUserInDB')
		console.log(auth.currentUser.uid)
		const user = await getUserInDB({
			orgId: global.org,
			userId: auth.currentUser.uid,
		})

		if (user.data !== null) {
			console.log('User found.')
			const data = user.data as any
			if (data.referralCode) {
				console.log('Referral code: ' + data.referralCode)
				setReferralCode(data.referralCode)
			}
			return user.data
		} else {
			console.log('User was not found.')
			return null
		}
	}

	useEffect(() => {
		let userData
		const fetchDBUser = async () => {
			if (auth.currentUser) {
				console.log('Getting user.')
				userData = await getDBUser()
			}
		}
		if (referralCode === null || referralCode === 'null') {
			fetchDBUser()
		}
	}, [])

	const shortUrl = `${url}/?referralCode=${referralCodeState}`

	const [copied, setCopied] = useState(false)

	const handleShare = async platform => {
		try {
			const message = `Check out ${orgName} and use my referral code ${referralCodeState}: ${shortUrl}`
			switch (platform) {
				case 'twitter':
					await Share.share({ message, url: shortUrl }, { dialogTitle: 'Share on Twitter' })
					break
				case 'facebook':
					await Share.share({ message, url: shortUrl }, { dialogTitle: 'Share on Facebook' })
					break
				case 'telegram':
					await Share.share({ message, url: shortUrl }, { dialogTitle: 'Share on Telegram' })
					break
				case 'whatsapp':
					await Share.share({ message, url: shortUrl }, { dialogTitle: 'Share on WhatsApp' })
					break
				case 'messenger':
					await Share.share({ message, url: shortUrl }, { dialogTitle: 'Share on Messenger' })
					break
				case 'email':
					try {
						const parts = referralCodeState.split('-')
						const name = parts[0]
						const capName = name.charAt(0).toUpperCase() + name.slice(1)

						await Share.share({
							message: `${capName} has invited you to join ${orgName} with their code ${referralCodeState}!`,
							title: `${capName} has invited you to join ${orgName}!`,
							url: shortUrl,
						})
					} catch (error) {
						console.log(error)
					}
					break
				case 'link':
					await Share.share(
						{
							message: `Check out ${orgName} and use my referral code ${referralCodeState}!`,
							url: shortUrl,
						},
						{ dialogTitle: 'Share link' }
					)
					break
				default:
					break
			}
		} catch (error) {
			console.error(error)
		}
	}

	const onCopyLink = async () => {
		try {
			if (Platform.OS === 'web') {
				Clipboard.setString(referralCodeState)
				setCopied(true)
			} else {
				await Share.share({
					message: shortUrl,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const styles = StyleSheet.create({
		container: {
			flex: 1,
			backgroundColor: '#fff',
		},
		title: {
			fontSize: 17,
			fontWeight: '500',
			marginBottom: 15,
		},
		referralBox: {
			backgroundColor: '#FFF',
			padding: 20,
			borderTopWidth: 1,
			borderTopColor: '#E0E0E0',
			paddingBottom: 50,
		},
		urlContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			borderWidth: 1,
			borderColor: '#ccc',
			borderRadius: 5,
			paddingHorizontal: 10,
			paddingVertical: 5,
			marginBottom: 20,
			maxWidth: '100%',
			overflow: 'hidden',
		},
		urlText: {
			flex: 1,
			fontSize: 15,
			color: '#333',
		},
		button: {
			backgroundColor: global.orgColor,
			paddingHorizontal: 20,
			paddingVertical: 10,
			borderRadius: 5,
			marginBottom: 20,
		},
		buttonText: {
			color: '#fff',
			fontSize: 18,
			fontWeight: 'bold',
		},
		shareRow: {
			flexDirection: 'row',
			marginBottom: 20,
		},
		shareButton: {
			backgroundColor: '#f0f0f0',
			paddingHorizontal: 20,
			paddingVertical: 10,
			borderRadius: 5,
			marginRight: 10,
		},
		shareButtonText: {
			color: '#333',
			fontSize: 16,
		},
		shortUrlContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			backgroundColor: '#f0f0f0',
			borderRadius: 5,
			paddingHorizontal: 10,
			paddingVertical: 5,
		},
		shortUrlText: {
			flex: 1,
			fontSize: 16,
		},
		copyButton: {
			backgroundColor: global.orgColor,
			paddingHorizontal: 20,
			paddingVertical: 10,
			borderRadius: 5,
			marginLeft: 10,
		},
		copyButtonText: {
			color: '#fff',
			fontSize: 16,
		},
	})

	return (
		<>
			<TriangleHeader
				title={'REFER A FRIEND'}
				backFunction={() => {
					navigation.navigate('AccountStack', { screen: 'Account' })
				}}
				navigation={navigation}
				logo={organizationLogo}
			/>
			<View
				style={[styles.container, { padding: 20, backgroundColor: Colors.custom.appBackground }]}
			>
				{Platform.OS === 'web' && (
					<Text
						style={[
							GlobalStyle.titleText,
							{ color: global.orgColor2, fontSize: titleTextSize, paddingVertical: '3%' },
						]}
					>
						{'REFER A FRIEND, EARN ' + referrerString}
					</Text>
				)}
				<Text
					style={{
						fontSize: 19,
						color: Colors.custom.mediumGrey,
						marginBottom: 30,
						marginTop: Platform.OS === 'web' ? 0 : -50,
					}}
				>
					Program details:
				</Text>
				<Text
					style={{
						fontSize: 16,
						color: Colors.custom.mediumGrey,
						marginBottom: 15,
					}}
				>
					{`Use your unique link to refer someone to ${orgName}.`}
				</Text>
				<Text
					style={{
						fontSize: 16,
						color: Colors.custom.mediumGrey,
						marginBottom: 15,
					}}
				>
					They will get {referralString} off their first order and when they place their first
					order, you will also receive a FREE {referrerString} coupon in your account that you can
					use towards any order.
				</Text>
				<Text
					style={{
						fontSize: 16,
						color: Colors.custom.mediumGrey,
						marginBottom: 15,
					}}
				>
					There is no limit, so you can earn as much as you want by sending your link to as many
					people as you would like!
				</Text>
			</View>

			<View>
				<View style={styles.referralBox}>
					<Text style={styles.title}>Your unique referral code:</Text>
					<View style={styles.urlContainer}>
						<Text style={styles.urlText} selectable={true}>
							{referralCodeState}
						</Text>
						<TouchableOpacity style={styles.copyButton} onPress={onCopyLink}>
							<Text style={styles.copyButtonText}>{copied ? 'Copied!' : 'Copy'}</Text>
						</TouchableOpacity>
					</View>
					<Text style={styles.title}>Share your link:</Text>
					<ShareRow
						onPress={handleShare}
						referralCode={referralCodeState}
						url={url}
						orgName={orgName}
					/>
				</View>
			</View>
			{Platform.OS === 'web' && <CopyrightFooter />}
		</>
	)
}
