import React from 'react'
import { Modal, View, TouchableOpacity, StyleSheet, ScrollView, Dimensions } from 'react-native'
import Colors from '../constants/Colors'
import { mediumTextSize, smallTextSize, xlTextSize } from '../constants/GlobalStyle'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Text } from './Themed'
import { getHoursStatus } from '../helpers/restaurantHours'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import { setPersistedState } from '../state/Slices/persistedSlice'

export function LocationSelectKioskModal({
	visible,
	setVisible,
	restaurantObject,
	setShowPinpads,
}) {
	const { selectedLocation } = useAppSelector(state => state.persisted)
	const { allowOrdersOutsideOrgHours, localTimezone } = useAppSelector(state => state.settings)
	const dispatch = useAppDispatch()

	const restaurantList = restaurantObject ? Object.values(restaurantObject) : []
	const handleConfirm = () => {
		setVisible(false)
	}

	const handleCancel = () => {
		setVisible(false)
		setShowPinpads(false)
	}
	const LocationCard = ({ location }) => {
		let addressSpace = ''
		const address = location.address
		if (address.addressLine2 !== '') {
			addressSpace = ' '
		}
		const formattedAddressLine = `${location.address.addressLine1} ${
			location.address.addressLine2 ? location.address.addressLine2 : ''
		}`
		const formattedCityLine = `${location.address.city}, ${location.address.provinceState} ${location.address.postCode}`
		const distance = `${Math.round(location.distance)} km away`

		const hoursStatusMessage = getHoursStatus(
			restaurantObject[location.id],
			null,
			allowOrdersOutsideOrgHours,
			localTimezone
		)

		return (
			<View style={styles.container}>
				<TouchableOpacity
					onPress={() => {
						dispatch(setPersistedState({ selectedLocation: location }))
						AsyncStorage.setItem('location', JSON.stringify(location))
					}}
					style={styles.row}
					activeOpacity={0.7}
				>
					<MaterialCommunityIcons
						name={
							selectedLocation.id === location.id
								? 'checkbox-marked-circle-outline'
								: 'checkbox-blank-circle-outline'
						}
						size={24}
						color={'black'}
						style={styles.icon}
					/>

					<View style={styles.textContainer}>
						<Text style={styles.boldText}>{formattedAddressLine}</Text>
						<Text style={styles.boldText}>{formattedCityLine}</Text>
						<Text style={styles.normalText}>{location.distance ? distance : ''}</Text>
					</View>
					<Text style={[styles.openUntilText, { color: 'black' }]}>{hoursStatusMessage}</Text>
				</TouchableOpacity>
			</View>
		)
	}
	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={visible}
			onRequestClose={() => {
				setVisible(false)
				setShowPinpads(false)
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<Text
						style={{
							marginVertical: 30,
							justifyContent: 'center',
							fontSize: xlTextSize,
							fontWeight: '700',
							letterSpacing: 1,
							color: Colors.greyscale[6],
						}}
					>
						SELECT YOUR LOCATION
					</Text>
					<View style={styles.separator} />

					<ScrollView
						style={{ maxHeight: Dimensions.get('window').height * 0.5, maxWidth: '100%' }}
					>
						{restaurantList.map((location, i) => {
							return (
								<View key={'location_' + i}>
									<LocationCard key={i} location={location} />
									{i === restaurantList.length - 1 ? null : <View style={styles.separator} />}
								</View>
							)
						})}
					</ScrollView>
					<View style={styles.separator} />

					<View style={styles.buttonRow}>
						<TouchableOpacity
							style={{ ...styles.button, backgroundColor: Colors.greyscale[5] }}
							onPress={handleCancel}
						>
							<Text style={styles.buttonText}>Cancel</Text>
						</TouchableOpacity>
						<TouchableOpacity
							style={{ ...styles.button, backgroundColor: 'black' }}
							onPress={handleConfirm}
						>
							<Text style={styles.buttonText}>Confirm</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</Modal>
	)
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: 'white',
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		padding: 15,
	},
	icon: {
		width: '10%',
	},
	textContainer: {
		width: '50%',
	},
	boldText: {
		fontSize: mediumTextSize,
		fontWeight: 'bold',
		color: 'black',
	},
	normalText: {
		fontSize: mediumTextSize,
		color: 'grey',
		marginTop: 5,
	},
	openUntilText: {
		marginLeft: 5,
		paddingRight: 5,
		alignSelf: 'flex-start',
		fontSize: smallTextSize,
		width: '40%',
		textAlign: 'right',
	},
	separator: {
		height: 1,
		backgroundColor: Colors.greyscale[4],
		width: '100%',
	},

	centeredView: {
		flex: 1,
		justifyContent: 'center',
		paddingTop: 22,
		paddingHorizontal: '5%',
		backgroundColor: 'rgba(0,0,0,0.5)',
	},
	modalView: {
		backgroundColor: 'white',
		borderRadius: 20,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	button: {
		borderRadius: 10,
		padding: 10,
		paddingHorizontal: 25,
	},
	buttonText: {
		fontSize: xlTextSize,
		color: 'white',
		fontWeight: '500',
		textAlign: 'center',
	},
	buttonRow: {
		paddingHorizontal: 20,
		paddingVertical: 15,
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
})
