import { useEffect } from 'react'
import { addMods } from '../Helpers/itemFormatFunctions'
import { getTaxOnItem } from '../Helpers/priceFunctions'
import { CheckoutItem } from '../Helpers/types'
import { auth } from '../../../firebase/config'
import { Platform } from 'react-native'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { userCanRedeem } from '../Helpers/checkCouponCode'
import { logViewCartAnalytics } from '../../../firebase/analytics'
import { formatRestaurantId } from '../../../helpers/formatRestaurantId'
import { checkSnooze } from '../../../helpers/checkSnooze'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'

const useCheckout = ({ navigation }) => {
	const functions = getFunctions()
	const dispatch = useAppDispatch()
	const { loadedData } = useAppSelector(state => state.data)
	const { cart } = useAppSelector(state => state.persisted)

	const { couponRefresh, validCouponCodes, modalKey, userAvailableCodes, userRedeemedCodes } =
		useAppSelector(state => state.checkout)

	const platform = global.pos
		? 'pos'
		: global.kiosk
			? 'kiosk'
			: Platform.OS === 'web'
				? 'web'
				: 'mobile'

	function getCouponCount(list: any) {
		let count = 0
		if (list && list.length > 0) {
			list.forEach(promo => {
				if (!promo.codeOnly && promo.type !== 'staffMeal') {
					if (!promo.userSpecific) {
						count++
					} else if (auth.currentUser && userCanRedeem(promo, userRedeemedCodes)) {
						if (userAvailableCodes.length > 0) {
							userAvailableCodes.forEach(code => {
								if (promo.id == code) {
									count++
								}
							})
						}
					}
				}
			})
		}
		return count
	}

	useEffect(() => {
		const unsubscribe = navigation.addListener('focus', () => {
			auth.onAuthStateChanged(user => {
				if (user) {
					// User is signed in
					dispatch(setCheckoutState({ isUser: true }))
				} else {
					dispatch(setCheckoutState({ isUser: false }))
				}
			})
			dispatch(
				setCheckoutState({
					spinner: false,
					orderPlaced: false,
					awaitingTerminalInput: false,
					paymentError: false,
				})
			)
		})

		return () => {
			unsubscribe()
		}
	}, [navigation, dispatch])

	useEffect(() => {
		dispatch(setCheckoutState({ orderCreated: false }))

		const unsubscribe = navigation.addListener('focus', async () => {
			const localItems = []
			const localAnalyticsItems = []
			let analyticsTotal = 0

			// Process loadedData for BOGO and freeItem
			if (loadedData['BOGO']) {
				loadedData['BOGO'].forEach(bogo => {
					if (bogo.codeOnly) {
						validCouponCodes.push(bogo)
					}
				})
			}
			if (loadedData['freeItem']) {
				loadedData['freeItem'].forEach(free => {
					if (free.codeOnly) {
						validCouponCodes.push(free)
					}
				})
			}

			const getCouponList = httpsCallable(functions, 'getCouponListTest')
			const fetchData = async () => {
				const couponList = await getCouponList({
					orgId: global.org,
					platform: platform,
				})
				dispatch(
					setCheckoutState({
						validCouponCodes: couponList.data as any,
						visibleCouponCount: getCouponCount(couponList.data),
					})
				)
			}

			dispatch(setCheckoutState({ modalKey: modalKey + 1 }))

			fetchData()

			if (cart.length > 0 && cart[0].rId) {
				const rId = cart[0].rId
				dispatch(
					setCheckoutState({
						isRestaurantSnoozed: checkSnooze(
							loadedData[rId].isSnoozed,
							loadedData[rId].snoozeUntil
						),
					})
				)
			}

			// Process cart items
			cart.forEach((item, index) => {
				const modifierArray = addMods(item)
				const taxObj = getTaxOnItem(
					item.taxes,
					item.promoPrice === -1 || isNaN(item.promoPrice)
						? item.amount + item.modPriceSum
						: item.promoPrice + item.modPriceSum,
					item.qty
				)

				const localItem: CheckoutItem = {
					id: item.id,
					posId: item.posId,
					qty: item.qty,
					name: item.name,
					ppu: item.promoPrice === -1 || isNaN(item.promoPrice) ? item.amount : item.promoPrice, //not including modifiers
					total:
						item.promoPrice === -1 || isNaN(item.promoPrice)
							? item.amount + item.modPriceSum
							: item.promoPrice + item.modPriceSum, //including modifiers
					rId: item.rId,
					mods: modifierArray,
					modList: item.modList,
					note: item.note,
					taxes: item.taxes,
					noPromo: item.amount + item.modPriceSum,
					menuId: item.menuId,
					menuItemCategory: item.menuItemCategory,
					idempotencyKey: item.idempotencyKey,
					imageUri: item.imageUri,
					appliedCoupon: item.appliedCoupon,
					taxAmt: taxObj.total,
					taxList: taxObj.taxes,
					hours: item.hours,
					ageVerificationRequired: item.ageVerificationRequired,
				}

				const listInfo = formatRestaurantId(item.menuId, loadedData)
				localAnalyticsItems.push({
					item_id: item.id,
					item_name: item.name,
					item_location_id: item.rId,
					quantity: item.qty,
					price: (item.amount * item.qty) / 100,
					item_category: item.menuItemCategory,
					item_list_id: listInfo.id,
					item_list_name: listInfo.name,
				})

				analyticsTotal += (item.amount * item.qty) / 100
				localItems.push(localItem)

				if (index === cart.length - 1) {
					dispatch(
						setCheckoutState({
							analyticsItems: localAnalyticsItems,
							items: localItems,
						})
					)
				}
			})

			if (cart.length === 0) {
				dispatch(setCheckoutState({ items: [] }))
			}

			logViewCartAnalytics(analyticsTotal, localAnalyticsItems)
		})

		return () => {
			unsubscribe()
		}
	}, [navigation, couponRefresh, cart, dispatch])
}

export default useCheckout
