import React, { useEffect, useRef } from 'react'
import { View, Platform, Dimensions } from 'react-native'
import { AwaitingPayment } from './AwaitingPayment'
import { GuestCheckout } from './GuestCheckout.web'
import { ConfirmOrder } from './ConfirmOrder'
import { DineInOrTakeout } from './DineInOrTakeout'
import { SelectPOSMethod } from './SelectPOSMethod'
import { PlaceOrderModal } from './PlaceOrderModal'
import Receipt from './Receipt'
import OrderConfirmed from './OrderConfirmed'
import { auth } from '../../../../firebase/config'
import { AddTip } from '../AddTip/AddTip'
import Spinner from '../../../../components/LoadingSpinner'
import crashlytics from '../../../../components/Crashlytics/crashlyticsLog'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { resetReceiptMethods, setCheckoutState } from '../../../../state/Slices/checkoutSlice'

/**
 * Consider state variables to conditionally set component shown in modal for checkout process
 */
export const OrderModals = ({
	toggleConfirmModal,
	navigation,
	tipFuncData,
	goHome,
	getSchedInterval,
	promoFuncData,
}) => {
	const {
		awaitingTerminalInput,
		paymentError,
		spinner,
		orderPlaced,
		isGuestCheckout,
		showDineInOrTakeout,
		showPOSMethods,
		selectedMethods,
		prices,
		showAddTip,
		isConfirmModalVisible,
		isDigitalPaymentWindowOpen,
		modalTimestamps,
	} = useAppSelector(state => state.checkout)

	const dispatch = useAppDispatch()

	const logStateToCrashlytics = state => {
		if (Platform.OS === 'web') {
			crashlytics().log(JSON.stringify(state))
		}
	}

	const logModalTimestamp = modalName => {
		const timestamp = new Date().toISOString()
		dispatch(
			setCheckoutState({
				modalTimestamps: {
					...modalTimestamps,
					[modalName]: timestamp,
				},
			})
		)
		logStateToCrashlytics({ modalName, timestamp })
	}

	const currentModal = useRef(null)

	const determineCurrentModal = () => {
		// Handle kiosk-specific logic first
		if (global.kiosk) {
			if (showPOSMethods && global.pos) return 'SelectPOSMethod'
			if (awaitingTerminalInput) return 'AwaitingTerminalInput'
			if (showDineInOrTakeout) return 'KioskNameEntry'
		}

		// General modal logic
		if (Platform.OS === 'web' && !auth.currentUser && !isGuestCheckout) {
			return 'LogInOrGuestCheckout'
		}
		if (paymentError) return 'PaymentError'
		if (isDigitalPaymentWindowOpen) {
			return Platform.OS === 'web'
				? 'DigitalPaymentWindow'
				: Platform.OS === 'ios'
					? 'ApplePayWindow'
					: 'GooglePayWindow'
		}
		if (!spinner && showAddTip) return 'AddTip'
		if (!spinner && !isDigitalPaymentWindowOpen) return 'SelectReceipt'
		if (orderPlaced) return 'OrderConfirmed'
		if (spinner) return 'PlacingOrder'
		return 'AddTip'
	}

	useEffect(() => {
		if (!isConfirmModalVisible) return

		const newModal = determineCurrentModal()

		if (newModal !== currentModal.current) {
			currentModal.current = newModal
			if (newModal) {
				logModalTimestamp(newModal)
			}
		}
	}, [
		awaitingTerminalInput,
		paymentError,
		spinner,
		orderPlaced,
		isGuestCheckout,
		showDineInOrTakeout,
		showPOSMethods,
		selectedMethods,
		prices,
		showAddTip,
		isConfirmModalVisible,
		isDigitalPaymentWindowOpen,
	])
	if (!global.kiosk) {
		const height = Dimensions.get('window').height
		const modalSize = height > 1000 ? height * 0.65 : height > 720 ? height * 0.9 : height * 0.95

		const receiptHeight = auth.currentUser ? height * 0.6 : height * 0.8

		return (
			<>
				{!isConfirmModalVisible ? (
					<></>
				) : Platform.OS === 'web' && !auth.currentUser && !isGuestCheckout ? (
					<PlaceOrderModal
						toggleConfirmModal={toggleConfirmModal}
						height={modalSize / 2}
						allowClickAway={true}
					>
						<GuestCheckout navigation={navigation} toggleConfirmModal={toggleConfirmModal} />
					</PlaceOrderModal>
				) : paymentError ? (
					<PlaceOrderModal
						toggleConfirmModal={toggleConfirmModal}
						height={modalSize}
						allowClickAway={false}
						smallHeight={true}
					>
						<AwaitingPayment title={'Payment error. Please cancel and try again.'} />
					</PlaceOrderModal>
				) : !spinner && showAddTip ? (
					<PlaceOrderModal
						toggleConfirmModal={toggleConfirmModal}
						height={450}
						allowClickAway={true}
					>
						<AddTip
							prices={prices}
							tipFunctions={tipFuncData}
							toggleConfirmModal={toggleConfirmModal}
						/>
					</PlaceOrderModal>
				) : !spinner && !isDigitalPaymentWindowOpen ? (
					<PlaceOrderModal
						toggleConfirmModal={toggleConfirmModal}
						allowClickAway={true}
						showNumPad={selectedMethods.showNumPad ? true : false}
						height={receiptHeight}
						style={{ marginVertical: (height - receiptHeight) / 4 }}
					>
						<Receipt>
							<ConfirmOrder
								navigation={navigation}
								toggleConfirmModal={toggleConfirmModal}
								getSchedInterval={getSchedInterval}
							/>
						</Receipt>
					</PlaceOrderModal>
				) : orderPlaced ? (
					<PlaceOrderModal
						toggleConfirmModal={() => {
							goHome(true)
							dispatch(resetReceiptMethods())
						}}
						height={Dimensions.get('window').height * 0.8}
						allowClickAway={false}
					>
						<OrderConfirmed goHome={goHome} />
					</PlaceOrderModal>
				) : (
					<Spinner
						visible={spinner}
						overlayColor="rgba(0, 0, 0, 0.65)"
						headerHeight={0}
						footerHeight={0}
						color="lightgrey"
					/>
					// <Modal
					// 	isVisible={isConfirmModalVisible}
					// 	onBackButtonPress={null}
					// 	onBackdropPress={null}
					// 	avoidKeyboard={false}
					// 	animationIn={'fadeIn'}
					// 	animationOut={'fadeOut'}
					// 	style={{ justifyContent: 'center', alignItems: 'center' }}
					// >
					// 	<View
					// 		style={{
					// 			width: Dimensions.get('window').width,
					// 			height: Dimensions.get('window').height,
					// 			backgroundColor: 'white',
					// 			justifyContent: 'center',
					// 			alignItems: 'center',
					// 		}}
					// 	>
					// 		<PlacingOrder />
					// 	</View>
					// </Modal>
				)}
			</>
		)
	} else {
		const height = Dimensions.get('window').height
		const modalSize =
			height > 1200
				? height * 0.65
				: height > 1000
					? height * 0.85
					: height > 720
						? height * 0.9
						: height * 0.95
		if (isConfirmModalVisible) {
			return (
				<>
					<View>
						{showPOSMethods && global.pos ? (
							<PlaceOrderModal
								toggleConfirmModal={toggleConfirmModal}
								height={modalSize}
								allowClickAway={true}
							>
								<SelectPOSMethod
									toggleConfirmModal={toggleConfirmModal}
									tipData={tipFuncData}
									promoData={promoFuncData}
									navigation={navigation}
								/>
							</PlaceOrderModal>
						) : showDineInOrTakeout ? (
							<PlaceOrderModal
								toggleConfirmModal={toggleConfirmModal}
								height={modalSize}
								allowClickAway={true}
							>
								<DineInOrTakeout toggleConfirmModal={toggleConfirmModal}>
									<ConfirmOrder
										navigation={navigation}
										toggleConfirmModal={toggleConfirmModal}
										getSchedInterval={getSchedInterval}
									/>
								</DineInOrTakeout>
							</PlaceOrderModal>
						) : paymentError ? (
							<PlaceOrderModal
								toggleConfirmModal={toggleConfirmModal}
								height={modalSize}
								allowClickAway={false}
								smallHeight={true}
							>
								<AwaitingPayment title={'Payment error. Please cancel and try again.'} />
							</PlaceOrderModal>
						) : !spinner ? (
							<PlaceOrderModal
								toggleConfirmModal={toggleConfirmModal}
								height={modalSize}
								allowClickAway={true}
								showNumPad={selectedMethods.showNumPad ? true : false}
							>
								<Receipt>
									<ConfirmOrder
										navigation={navigation}
										toggleConfirmModal={toggleConfirmModal}
										getSchedInterval={getSchedInterval}
									/>
								</Receipt>
							</PlaceOrderModal>
						) : orderPlaced ? (
							<PlaceOrderModal
								toggleConfirmModal={() => {
									goHome(true)
									dispatch(resetReceiptMethods())
								}}
								height={Dimensions.get('window').height * 0.8}
								allowClickAway={false}
							>
								<OrderConfirmed goHome={goHome} />
							</PlaceOrderModal>
						) : awaitingTerminalInput ? (
							<PlaceOrderModal
								toggleConfirmModal={toggleConfirmModal}
								height={modalSize}
								allowClickAway={false}
								smallHeight={true}
							>
								<AwaitingPayment title={'Please follow instructions on pinpad to pay now.'} />
							</PlaceOrderModal>
						) : (
							<Spinner
								visible={spinner}
								overlayColor="rgba(0, 0, 0, 0.65)"
								headerHeight={0}
								footerHeight={0}
								color="lightgrey"
							/>
						)}
					</View>
				</>
			)
		} else {
			return <></>
		}
	}
}
