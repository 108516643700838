import React, { useEffect, useRef, useState } from 'react'
import { View, TextInput, TouchableOpacity, Animated, FlatList, Image } from 'react-native'
import { Text } from '../../../components/Themed'
import { FontAwesome5 } from '@expo/vector-icons'
import Colors from '../../../constants/Colors'
import { styles } from '../Helpers/style'
import { createFuse, createDebouncedSearch, getBrandCardsLayout } from '../Helpers/functions'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setMenuState } from '../../../state/Slices/menuSlice'
import GlobalStyle from '../../../constants/GlobalStyle'
import RowItems from './RowItems'

const Search = ({
	ctgTokens,
	rName,
	rId,
	isSnoozedState,
	logSelectItemAnalytics,
	navigation,
	storeOpen,
}) => {
	const [fuse, setFuse] = useState(null)
	const [menuItems, setMenuItems] = useState([])
	const [loading, setLoading] = useState(false)
	const [slideAnim] = useState(new Animated.Value(-100))
	const inputRef = useRef(null)
	const dispatch = useAppDispatch()
	const { searchText, showResults, showDropdown, isSearchActive } = useAppSelector(
		state => state.menu
	)
	const { isSingleBrand } = useAppSelector(state => state.settings)

	useEffect(() => {
		const items = Object.entries(ctgTokens).flatMap(([name, tokens]: [string, any[]]) =>
			tokens
				.filter(token => !token.id.startsWith('invisible_'))
				.map(token => ({ ...token, category: name }))
		)
		setFuse(createFuse(items))
	}, [ctgTokens])

	const card = getBrandCardsLayout(isSingleBrand, true)

	useEffect(() => {
		if (fuse) {
			const debouncedSearch = createDebouncedSearch(
				fuse,
				setMenuItems,
				setLoading,
				card.cardsPerRow
			)
			if (searchText.trim() !== '') {
				setLoading(true)
				debouncedSearch(searchText)
			}
			return () => debouncedSearch.cancel()
		}
	}, [fuse, searchText])

	useEffect(() => {
		Animated.timing(slideAnim, {
			toValue: isSearchActive ? 0 : -100,
			duration: 300,
			useNativeDriver: false,
		}).start()
	}, [isSearchActive, slideAnim])

	const toggleDropdown = () => {
		if (showDropdown) {
			dispatch(setMenuState({ showResults: false }))
			setTimeout(() => {
				inputRef.current?.blur()
				dispatch(setMenuState({ showDropdown: false }))
			}, 300)
		} else {
			inputRef.current?.focus()
			dispatch(setMenuState({ showDropdown: true }))
		}
	}

	const onSearchTextChange = (text: string) => {
		if (text.trim() !== '') {
			dispatch(setMenuState({ showResults: true, searchText: text }))
		} else {
			dispatch(setMenuState({ showResults: false, searchText: '' }))
		}
	}

	return (
		<View style={[styles.searchOverlay, { display: showDropdown ? 'flex' : 'none' }]}>
			<Animated.View style={[styles.searchDropdown, { marginTop: slideAnim }]}>
				<TextInput
					ref={inputRef}
					autoFocus={isSearchActive}
					placeholder={`Search ${rName}`}
					onChangeText={onSearchTextChange}
					value={searchText}
					style={styles.searchInput}
				/>
				<TouchableOpacity
					onPress={() => {
						toggleDropdown()
						onSearchTextChange('')
						dispatch(setMenuState({ isSearchActive: false }))
					}}
				>
					<Text style={{ fontSize: 14 }}>Cancel</Text>
				</TouchableOpacity>
			</Animated.View>

			{/* Loading indicator */}
			{loading && showResults && (
				<View style={styles.searchResultContainer}>
					<Image
						style={{ width: 100, height: 100, alignSelf: 'center', marginTop: '40%' }}
						source={require('../../../assets/images/loadImg.gif')}
					/>
				</View>
			)}

			{/* Search results */}
			{!loading && showResults && menuItems.length > 0 && (
				<View style={styles.searchResultContainer}>
					<FlatList
						data={menuItems}
						renderItem={({ item }) => (
							<View
								key={item.name + item.items[0].id}
								style={{ marginRight: '5%', marginLeft: '5%' }}
							>
								{item.name && (
									<Text style={[GlobalStyle.titleText, { color: global.orgColor2 }]}>
										{item.name.toUpperCase()}
									</Text>
								)}
								<RowItems
									name={item.name}
									rowItems={item.items}
									storeOpen={storeOpen}
									navigation={navigation}
									logSelectItemAnalytics={logSelectItemAnalytics}
									rId={rId}
									rName={rName}
									isSnoozedState={isSnoozedState}
									isBrandMenu={true}
								/>
							</View>
						)}
						keyExtractor={item => item.items[0].id}
						contentContainerStyle={{ paddingTop: 100, paddingBottom: 100 }}
					/>
				</View>
			)}

			{/* No results */}
			{!loading && showResults && menuItems.length === 0 && searchText.trim() !== '' && (
				<View
					style={[styles.searchResultContainer, { alignItems: 'center', justifyContent: 'center' }]}
				>
					<FontAwesome5
						name="drumstick-bite"
						size={24}
						color={Colors.greyscale[7]}
						style={{ alignSelf: 'center' }}
					/>
					<Text style={{ fontSize: 16, color: Colors.greyscale[7], marginTop: 10 }}>
						{"We couldn't find a match"}
					</Text>
					<Text style={{ fontSize: 14, color: Colors.greyscale[5], marginTop: 3 }}>
						{'Please try a new search'}
					</Text>
				</View>
			)}
		</View>
	)
}

export default Search
