import {
	codeIsAvailable,
	restaurantDiscount,
	restaurantIsPresent,
	sameCodeGroupNotRedeemed,
	userCanRedeem,
} from './checkCouponCode'
import { getDiscountPrice } from '../../Item/Helpers/functions'
import { auth } from '../../../firebase/config'
import { roundTaxAmount } from '../../../helpers/roundTaxAmount'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'

export function priceSetter(data) {
	var subDisc

	const totalBagFee = data.bagFee && data.bagFee.totalAmount ? Number(data.bagFee.totalAmount) : 0

	const promoAmount = 0
	let discAmt = 0
	data.items.forEach(item => {
		if (item.appliedCoupon) {
			discAmt += getDiscountPrice(item.appliedCoupon, data.loadedData[item.id], item.qty)
		}
	})

	const i = data.couponVals
		.map(function (e) {
			return e.couponCode.trim().toLowerCase()
		})
		.indexOf(data.code.trim().toLowerCase())

	//checks if user specific code can be redeemed (in users table)

	const isAvailable = codeIsAvailable(data.couponVals[i], data.userAvailableCodes)

	//checks if code already passed max redemption amount
	const canRedeem = userCanRedeem(data.couponVals[i], data.userRedeemedCodes)

	//checks if code is valid for restaurants in cart
	const restaurantPresent = restaurantIsPresent(data.couponVals[i], data.items)

	//check that code has not been excluded due to previous code redemptions being part of the same group
	const isNotExcluded = sameCodeGroupNotRedeemed(data.couponVals[i], data.userRedeemedCodes)

	let spendMore = false

	let discountType, discountNum, discountCondition, discountConditionThreshold
	//apply discount code
	if (i != -1 && isAvailable && canRedeem && isNotExcluded && restaurantPresent) {
		discountType = data.couponVals[i]['rate']['type']

		const rDiscAmount = Math.round(restaurantDiscount(data.couponVals[i], data.items))

		discountNum = rDiscAmount === -1 ? data.couponVals[i]['rate']['amount'] : rDiscAmount / 100

		if (data.couponVals[i]['minSpend']) {
			discountCondition == 'gte'
			discountConditionThreshold = data.couponVals[i]['minSpend']
			if (discountConditionThreshold > data.prices.subTotal - data.prices.discount) {
				spendMore = true
				discountNum = 0
				discountType = ''
			}
		} else {
			discountCondition == ''
			discountConditionThreshold = 0
		}
	}
	//discount code is not applicable
	else {
		discountNum = 0
		discountType = ''
		discountCondition == ''
		discountConditionThreshold = 0
	}
	subDisc = calculateSubtotal(
		data.items,
		discountNum,
		discountType,
		discountConditionThreshold,
		promoAmount
	)

	const taxFunc = calculateTaxes(
		data.items,
		discountNum,
		discountType,
		discountConditionThreshold,
		promoAmount,
		data.isBankersRounding
	)
	let orgDiscount = 0
	if (data.couponInfo[0]) {
		orgDiscount = Math.abs((subDisc[1] - discAmt) * (data.couponInfo[0].orgCoverage / 100))
	}
	const discountVal = Math.round(((subDisc[1] - discAmt) / -1) * 100) / 100
	const subTotalAmount = subDisc[0] - subDisc[1] - discAmt
	data.dispatch(
		setCheckoutState({
			prices: {
				noCodeDiscount: discAmt,
				discount: discountVal * -1,
				organizationDiscount: orgDiscount,
				subTotal: subTotalAmount,
				subTotalAfterDiscount: subDisc[0] - subDisc[1] - discountVal,
				serviceCharge:
					subDisc[0] * (data.serviceFee / 100) +
					subDisc[0] * (data.serviceFee / 100) * (data.serviceFeeTax / 100),
				taxes: taxFunc.total,
				taxesList: taxFunc.taxArray,
				total: calculateTotal(
					data.items,
					discountNum,
					discountType,
					discountConditionThreshold,
					data.tipCount,
					subDisc[0] * (data.serviceFee / 100) +
						subDisc[0] * (data.serviceFee / 100) * (data.serviceFeeTax / 100) +
						totalBagFee,
					discAmt,
					data.isBankersRounding,
					Math.round(data.freeDeliveryPrice - subTotalAmount) > 0 ? data.deliveryFee : 0
				),
				tip: data.tipCount,
				bagFee: data.bagFee && data.bagFee.subTotal ? Number(data.bagFee.subTotal) : 0,
				bagFeeTax: data.bagFee && data.bagFee.taxAmount ? Number(data.bagFee.taxAmount) : 0,
				deliveryFee: Math.round(data.freeDeliveryPrice - subTotalAmount) > 0 ? data.deliveryFee : 0,
				deliveryFeeOrgCoverage:
					Math.round(data.freeDeliveryPrice - subTotalAmount) > 0
						? data.deliveryFeeOrgCoverage
						: data.deliveryFeeOrgCoverage + data.deliveryFee,
			},
		})
	)

	const couponCodeViewDispatch = (title = null) => {
		data.dispatch(setCheckoutState({ couponCodeDisplay: title }))
	}

	if (subDisc[1] < 0) {
		couponCodeViewDispatch(`Promo code "${data.couponCode}" was applied to your order.`)
	} else if (spendMore === true) {
		couponCodeViewDispatch(`"${data.couponCode}" cannot be applied to your cart.`)
	} else if (isNotExcluded === false) {
		couponCodeViewDispatch(`"${data.couponCode}" cannot be redeemed.`)
	} else if (canRedeem === false && !auth.currentUser) {
		couponCodeViewDispatch(`Please sign in to redeem "${data.couponCode}".`)
	} else if (canRedeem === false) {
		couponCodeViewDispatch(`"${data.couponCode}" has already been used on this account.`)
	} else if (data.couponCode === '') {
		couponCodeViewDispatch()
	} else {
		if (!restaurantPresent) {
			couponCodeViewDispatch(`"${data.couponCode}" cannot be applied to the items in your cart.`)
		} else {
			couponCodeViewDispatch(`"${data.couponCode}" is not a valid promo code.`)
		}
	}

	return subDisc
}

export function showModPrice(price) {
	if (price !== 0) {
		return (price / 100).toFixed(2)
	}
}

export function getTaxes(items) {
	const taxArray = []

	items.forEach((item, i) => {
		if (item.taxes !== undefined) {
			item.taxes.forEach(tax => {
				taxArray.push({
					name: tax.name,
					percentage: tax.amount.toString(),
					calculation_phase: tax.isCompound ? 'TAX_TOTAL_PHASE' : 'TAX_SUBTOTAL_PHASE',
					//type: data.taxData.inclusionType,
					uid: tax.id,
					//catalog_object_id: data.id,
					scope: 'LINE_ITEM',
				})
			})
		}
	})
	const arrUniq = [...new Map(taxArray.map(v => [v.uid, v])).values()]

	return arrUniq
}

/**
 * Converts a number into a currency-formatted string: $#.##
 * @param num the number to convert into a currency-formatted string
 * @returns currency-formatted string
 */
export function currencyFormat(num): string {
	if (num == undefined) {
		return '...'
	} else {
		return '$ ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}
}

export function getTaxOnItem(taxes, total, qty) {
	const item = {
		taxes: taxes,
		total: total,
		qty: qty,
	}
	let totalTaxAmt = 0
	let itemTaxAmt = 0
	let compoundTaxAmt = 0
	let totalCompoundTaxAmt = 0

	const taxArr = []
	if (item.taxes !== undefined) {
		item.taxes.forEach(tax => {
			if (!tax.isCompound) {
				const taxRate = tax.amount
				if (taxRate !== undefined) {
					if (item.total === 0) {
						totalTaxAmt += 0
					} else {
						totalTaxAmt += (item.total * item.qty * taxRate) / 100

						taxArr.push({
							id: tax.id,
							name: tax.name,
							amount: (item.total * item.qty * taxRate) / 100,
						})

						itemTaxAmt = (item.total * taxRate) / 100
					}
				} else {
					totalTaxAmt += 0
				}
			}
		})

		//second loop calculates tax based on total (if compound enabled)
		item.taxes.forEach(tax => {
			if (tax.isCompound) {
				const taxRate = tax.amount
				if (taxRate !== undefined) {
					if (item.total === 0) {
						totalCompoundTaxAmt += 0
					} else {
						totalCompoundTaxAmt +=
							(((item.total + itemTaxAmt) * item.qty - (item.total + itemTaxAmt) * item.qty) *
								taxRate) /
							100

						compoundTaxAmt =
							(((item.total + itemTaxAmt) * item.qty - (item.total + itemTaxAmt) * item.qty) *
								taxRate) /
							100
					}
				} else {
					totalCompoundTaxAmt += 0
				}
			}
		})
	}
	return { total: totalTaxAmt, taxes: taxArr }

	//get item total - discount rate and multiply by tax rate
}

//Calculates total tax based on each item's individual tax rate
function calcTax(cart, num, type, threshold, promoAmount, isBankersRounding) {
	const subDisc = calculateSubtotal(cart, num, type, threshold, promoAmount)
	const discountRate = subDisc[1] / (subDisc[1] - subDisc[0]) //discount rate (i.e. $8 cart -$2 disc = 25%)

	let totalTaxAmt = 0
	let itemTaxAmt = 0
	let compoundTaxAmt = 0
	let totalCompoundTaxAmt = 0

	const taxArr = []

	cart.forEach(item => {
		//first loop of taxes calculates non-compounding taxes
		if (item.taxes !== undefined) {
			item.taxes.forEach(tax => {
				if (!tax.isCompound) {
					const taxRate = tax.amount
					if (taxRate !== undefined) {
						if (item.total === 0) {
							totalTaxAmt += 0
						} else {
							totalTaxAmt +=
								((item.total * item.qty - item.total * item.qty * discountRate) * taxRate) / 100

							itemTaxAmt =
								((item.total * item.qty - item.total * item.qty * discountRate) * taxRate) / 100
						}
					} else {
						totalTaxAmt += 0
					}
					const index = taxArr.findIndex(t => t.id === tax.id)
					if (index >= 0) {
						taxArr[index] = {
							id: tax.id,
							name: tax.name,
							rawAmount: taxArr[index].rawAmount + itemTaxAmt,
							amount: 0,
							//amount: taxArr[index].amount + roundTaxAmount(itemTaxAmt, isBankersRounding),
						}
					} else {
						//doesnt exist
						taxArr.push({
							id: tax.id,
							name: tax.name,
							rawAmount: itemTaxAmt,
							amount: 0,
							//amount: roundTaxAmount(itemTaxAmt, isBankersRounding),
						})
					}
				}
			})

			taxArr.forEach(tax => {
				tax.amount = roundTaxAmount(tax.rawAmount, isBankersRounding)
			})

			//second loop calculates tax based on total (if compound enabled)
			item.taxes.forEach(tax => {
				if (tax.isCompound) {
					const taxRate = tax.amount
					if (taxRate !== undefined) {
						if (item.total === 0) {
							totalCompoundTaxAmt += 0
						} else {
							totalCompoundTaxAmt +=
								(((item.total + itemTaxAmt) * item.qty -
									(item.total + itemTaxAmt) * item.qty * discountRate) *
									taxRate) /
								100

							compoundTaxAmt =
								(((item.total + itemTaxAmt) * item.qty -
									(item.total + itemTaxAmt) * item.qty * discountRate) *
									taxRate) /
								100
						}
					} else {
						totalCompoundTaxAmt += 0
					}

					const index = taxArr.findIndex(t => t.id === tax.id)
					if (index >= 0) {
						taxArr[index] = {
							id: tax.id,
							name: tax.name,
							amount: taxArr[index].amount + compoundTaxAmt,
						}
					} else {
						//doesnt exist
						taxArr.push({
							id: tax.id,
							name: tax.name,
							amount: compoundTaxAmt,
						})
					}
				}
			})
		}
		//get item total - discount rate and multiply by tax rate
	})
	const roundedTax = roundTaxAmount(totalTaxAmt + totalCompoundTaxAmt, isBankersRounding)
	return { taxArray: taxArr, total: roundedTax / 100 }
}

export function calculateSubtotal(cart, num, type, threshold, promoAmount) {
	let subtotal = 0

	let subDisc = []
	//0 = subtotal... 1 = discount amt

	cart.forEach(item => {
		subtotal += item.total * item.qty
	})

	//promoAmount *= 100;

	if (type === 'percent') {
		subDisc = [
			subtotal / 100 - promoAmount - (subtotal / 100 - promoAmount) * (num / 100),
			-((subtotal - promoAmount * 100) / 100) * (num / 100) - promoAmount,
		]
	} else if (type === 'fixed') {
		if (subtotal / 100 < num) {
			subDisc = [0, -subtotal / 100 - promoAmount]
		} else {
			subDisc = [subtotal / 100 - num - promoAmount, -num - promoAmount]
		}
	} else {
		subDisc = [subtotal / 100 - promoAmount, -promoAmount]
	}

	if (threshold > subDisc[0] - subDisc[1]) {
		subDisc = [subtotal / 100 - promoAmount, -promoAmount]
	}
	return subDisc
}
export function calculateTaxes(cart, num, type, threshold, promoAmount, isBankersRounding) {
	return calcTax(cart, num, type, threshold, promoAmount, isBankersRounding)
	//return (calculatePSTDiscount(cart, num, type, threshold) + calculateGSTDiscount(cart, num, type, threshold));
}

export function calculateTotal(
	cart,
	num,
	type,
	threshold,
	tip,
	serviceFee,
	promoAmount,
	isBankersRounding,
	deliveryFee
) {
	const subtotal = Math.round(calculateSubtotal(cart, num, type, threshold, promoAmount)[0] * 100)
	const taxes = Math.round(
		calculateTaxes(cart, num, type, threshold, promoAmount, isBankersRounding).total * 100
	)
	const tipAmt = tip * 100
	const total = Math.round(serviceFee * 100 + tipAmt + taxes + subtotal + deliveryFee)
	return total / 100
}

export function calculateDeliveryFee() {
	return 0
}

export function calculateServiceCharge() {
	return 0
}

export function calculateBagFee(data) {
	if (data.enabled) {
		const subTotal = Math.round(data.amount) / 100
		const taxAmount = Math.round(data.amount * (data.taxPercent / 100)) / 100

		return {
			totalAmount: subTotal + taxAmount,
			subTotal: subTotal,
			taxAmount: taxAmount,
		}
	} else {
		return {
			totalAmount: 0,
			subTotal: 0,
			taxAmount: 0,
		}
	}
}
