import React from 'react'
import { View } from 'react-native'
import Modal from 'react-native-modal/dist/modal'
import GlobalStyle from '../../../../constants/GlobalStyle'
import { styles } from '../../Helpers/style'
import { useAppSelector } from '../../../../state/hooks'

export function PromoModal({ togglePromoModal, height, children }) {
	const { isPromoModalVisible } = useAppSelector(state => state.checkout)
	return (
		<Modal
			style={[GlobalStyle.modalStyle, { marginTop: 0 }]}
			isVisible={isPromoModalVisible}
			onBackButtonPress={togglePromoModal}
			onBackdropPress={togglePromoModal}
			avoidKeyboard={false}
			useNativeDriver={true}
		>
			<View
				style={[
					styles.modal,
					{
						paddingTop: 30,
						height: height,
					},
				]}
			>
				{children}
			</View>
		</Modal>
	)
}
