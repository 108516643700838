/** Match the Firebase ID in "Cuisines" collection with the image */

export const cuisineImages = {
	Burger: require('../../../assets/images/cuisines/burgers.png'),
	Soup: require('../../../assets/images/cuisines/soup.png'),
	Pizza: require('../../../assets/images/cuisines/pizza.png'),
	Barbecue: require('../../../assets/images/cuisines/bbq.png'),
	Chinese: require('../../../assets/images/cuisines/chinese.png'),
	Greek: require('../../../assets/images/cuisines/greek.png'),
	Mexican: require('../../../assets/images/cuisines/mexican.png'),
	Sushi: require('../../../assets/images/cuisines/sushi.png'),
	Smoothie: require('../../../assets/images/cuisines/smoothies.png'),
	Sandwich: require('../../../assets/images/cuisines/sandwiches.png'),
	Salad: require('../../../assets/images/cuisines/salads.png'),
	Vegan: require('../../../assets/images/cuisines/vegan.png'),
	Poke: require('../../../assets/images/cuisines/poke.png'),
	Dessert: require('../../../assets/images/cuisines/desserts.png'),
	Chicken: require('../../../assets/images/cuisines/chicken.png'),
	'Bubble Tea': require('../../../assets/images/cuisines/bubbletea.png'),
	Bakery: require('../../../assets/images/cuisines/bakery.png'),
	'Coffee & Tea': require('../../../assets/images/cuisines/coffeetea.png'),
	Donair: require('../../../assets/images/cuisines/donair.png'),
	Japanese: require('../../../assets/images/cuisines/japanese.png'),
	'Middle Eastern': require('../../../assets/images/cuisines/middleeastern.png'),
	Persian: require('../../../assets/images/cuisines/persian.png'),
	Vietnamese: require('../../../assets/images/cuisines/vietnamese.png'),
	Ramen: require('../../../assets/images/cuisines/ramen.png'),
	Pasta: require('../../../assets/images/cuisines/pasta.png'),
	'Asian Fusion': require('../../../assets/images/cuisines/asianfusion.png'),
	Indian: require('../../../assets/images/cuisines/indian.png'),
	Seafood: require('../../../assets/images/cuisines/seafood.png'),
	'Ice Cream': require('../../../assets/images/cuisines/icecream.png'),
	Lebanese: require('../../../assets/images/cuisines/lebanese.png'),
	Carribean: require('../../../assets/images/cuisines/carribean.png'),
	Healthy: require('../../../assets/images/cuisines/healthy.png'),
	Korean: require('../../../assets/images/cuisines/korean.png'),
	Steak: require('../../../assets/images/cuisines/steak.png'),
	Breakfast: require('../../../assets/images/cuisines/breakfast.png'),
	Italian: require('../../../assets/images/cuisines/italian.png'),
	Thai: require('../../../assets/images/cuisines/thai.png'),
	'Fast Food': require('../../../assets/images/cuisines/fastfood.png'),
}
