import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import '../../../global'
import { useEffect, useState } from 'react'
import { formatRestaurantData } from '../getRestaurantData'
import { DATABASE_NAME } from '@env'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setDataState, setLoadedData } from '../../../state/Slices/dataSlice'

const useRestaurantListener = () => {
	const { loadedData, restaurants } = useAppSelector(state => state.data)

	const dispatch = useAppDispatch()

	useEffect(() => {
		const restaurantRef = collection(doc(db, DATABASE_NAME, global.org), 'Restaurants')

		const unsubscribe = onSnapshot(restaurantRef, snapshot => {
			snapshot.docChanges().forEach(change => {
				const data = change.doc.data()
				if (change.type === 'modified' && data !== undefined && !data.isDeleted && data.isEnabled) {
					const newRestaurants = Array.isArray(restaurants) ? [...restaurants] : []
					const restoData = formatRestaurantData(data)

					const index = newRestaurants.findIndex(item => item.id === restoData.id)

					if (index !== -1) {
						newRestaurants[index] = restoData
					}

					dispatch(setLoadedData({ restaurants: newRestaurants }))
					dispatch(setDataState({ restaurants: newRestaurants }))
				}
			})
		})

		// Clean up function
		return () => {
			unsubscribe()
		}
	}, [])
}

export default useRestaurantListener
