import React from 'react'
import { Image, TouchableOpacity, StyleSheet } from 'react-native'
import { View, Text } from '../Themed'
import { xlTextSize } from '../../constants/GlobalStyle'
import Colors from '../../constants/Colors'
import MaterialCommunityIcons from '@expo/vector-icons/build/MaterialCommunityIcons'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { setUserState } from '../../state/Slices/userSlice'

const CARD_BRAND_ICON = {
	visa: require('../../assets/images/wallet/visa.png'),
	mastercard: require('../../assets/images/wallet/mastercard.png'),
	amex: require('../../assets/images/wallet/amex.png'),
	googlepay: require('../../assets/images/wallet/googlepayborder.png'),
	applepay: require('../../assets/images/wallet/applepay.png'),
}

type PaymentMethodsRowProps = {
	id: string
	card_brand: string
	last_4?: string
	exp_month?: string
	exp_year?: string
	setCardId?: (cardId: string) => void | undefined
	onPress: any
	isCheckout: boolean
	isDefault: boolean
	isChecked: boolean
	name?: string
}

// Component for each payment method in the list
export default function PaymentMethodsRow(props: PaymentMethodsRowProps) {
	const {
		id,
		card_brand,
		last_4,
		exp_month,
		exp_year,
		onPress,
		isCheckout,
		isDefault,
		isChecked,
		name = '',
	} = props
	const { deviceHasInternet } = useAppSelector(state => state.user)
	const dispatch = useAppDispatch()

	function handleOnPress() {
		dispatch(setUserState({ paymentMethodId: id }))
		dispatch(
			setUserState({
				paymentMethodId: id,
			})
		)
		if (isCheckout) {
			onPress()
		}
	}

	const expYear = exp_year?.toString().slice(-2)
	const expMonth = exp_month?.toString().padStart(2, '0')

	function CardRow() {
		return (
			<View style={styles.inputRow}>
				<View style={[styles.input, isDefault && { height: 55 }]}>
					<View style={styles.cardInfoContainer}>
						<Image
							style={styles.cardImg}
							resizeMode={'contain'}
							source={CARD_BRAND_ICON[card_brand.toLowerCase()]}
						/>
						<Text style={styles.buttonText}>
							{name === '' ? (
								<>
									<Text style={{ fontWeight: '900' }}>
										{'\u00B7'}
										{'\u00B7'}
										{'\u00B7'}
										{'\u00B7'} {'\u00B7'}
										{'\u00B7'}
										{'\u00B7'}
										{'\u00B7'} {'\u00B7'}
										{'\u00B7'}
										{'\u00B7'}
										{'\u00B7'}
									</Text>{' '}
									{last_4}
								</>
							) : (
								name
							)}
						</Text>
					</View>
					{!isCheckout && name === '' ? (
						<>
							<Text>{`Exp. ${expMonth}/${expYear}`}</Text>
							<MaterialCommunityIcons
								name="trash-can-outline"
								size={20}
								style={{ paddingLeft: 15 }}
								onPress={() => {
									!deviceHasInternet ? null : onPress(id)
								}}
								color={!deviceHasInternet ? Colors.greyscale[6] : 'red'}
							/>
						</>
					) : isDefault ? (
						<>
							<TouchableOpacity onPress={onPress} activeOpacity={0.9}>
								<Text style={styles.buttonText}>Change</Text>
							</TouchableOpacity>
						</>
					) : isChecked ? (
						<MaterialCommunityIcons
							name="checkbox-marked-circle"
							size={20}
							color={'black'}
							style={{ paddingLeft: 15 }}
						/>
					) : (
						<MaterialCommunityIcons
							name="checkbox-blank-circle-outline"
							size={20}
							color="grey"
							style={{ paddingLeft: 15 }}
						/>
					)}
				</View>
			</View>
		)
	}

	return (
		<>
			{!isCheckout ? (
				<TouchableOpacity onPress={handleOnPress} activeOpacity={0.7}>
					<CardRow />
				</TouchableOpacity>
			) : (
				<CardRow />
			)}
		</>
	)
}

const styles = StyleSheet.create({
	inputRow: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	input: {
		minHeight: 48,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexGrow: 1,
		padding: 7,
		paddingHorizontal: 15,
		borderWidth: 1,
		borderColor: Colors.greyscale[5],
		borderRadius: 8,
		backgroundColor: '#fff',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,

		elevation: 5,
	},
	cardInfoContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
	},
	cardImg: {
		height: xlTextSize * 2,
		width: xlTextSize * 2,
		marginRight: 15,
	},
	buttonText: {
		marginRight: 10,
		color: 'black',
		fontWeight: '500',
	},
})
