export async function getNotificationPermission() {
	console.log('getNotificationPermission stub')
	return
}

export async function getFCMToken() {
	console.log('getFCMToken stub')
	return
}

export async function onMessageReceived(message) {
	console.log('onMessageReceived stub')
	return
}

export async function requestiOSUserPermission() {
	console.log('requestiOSUserPermission stub')
	return
}

// android requires notification channel to handle foreground notifications
export async function createNotificationChannel() {
	console.log('createNotificationChannel stub')
	return
}
