import React from 'react'
import { View, ScrollView, TouchableOpacity, StyleSheet, Platform } from 'react-native'
import { Text } from '../../../../components/Themed'
import PaymentMethodsList from '../../../../components/Stripe/PaymentMethodsList'
import GlobalStyle, { largeTextSize, mediumTextSize } from '../../../../constants/GlobalStyle'
import { responsiveHeight } from 'react-native-responsive-dimensions'
import Colors from '../../../../constants/Colors'
import { AntDesign } from '@expo/vector-icons'
import ExitIcon from '../../../../components/Headers/UIButtons'
import { useAddCard } from '../../../../components/Square/Hooks/useAddCard'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { setUserState } from '../../../../state/Slices/userSlice'
import { setCheckoutState } from '../../../../state/Slices/checkoutSlice'

export function SelectPaymentMethodView({ toggleModal }) {
	const { loading, selectedCard, isDigitalWalletEnabled, digitalWalletType } = useAppSelector(
		state => state.checkout
	)
	const { paymentMethodId, paymentMethods } = useAppSelector(state => state.user)
	const { paymentProvider } = useAppSelector(state => state.settings)
	const dispatch = useAppDispatch()

	const { startCustomerCardEntry } = useAddCard()

	return (
		<View style={{ flex: 1 }}>
			<ExitIcon onPress={toggleModal} style={{ top: -responsiveHeight(2) }} />
			<View style={styles.container}>
				<View style={styles.titleSection}>
					<Text style={GlobalStyle.headerText}>SELECT PAYMENT METHOD</Text>
				</View>
				<ScrollView style={{ width: '90%', flex: 1 }}>
					<PaymentMethodsList
						paymentMethods={paymentMethods}
						isDefault={false}
						cardId={paymentMethodId}
						loading={loading}
						onPress={() => {
							null
						}}
						isCheckout={true}
						selectedCard={selectedCard}
						isDigitalWalletEnabled={isDigitalWalletEnabled}
						digitalWalletType={digitalWalletType}
					/>
				</ScrollView>
				<View style={styles.buttonContainer}>
					<TouchableOpacity
						style={[styles.button, { backgroundColor: 'black' }]}
						onPress={() => {
							if (selectedCard !== null) {
								dispatch(
									setUserState({
										paymentMethodId: selectedCard,
									})
								)
							}
							toggleModal()
						}}
					>
						<Text style={styles.buttonText}>USE SELECTED CARD</Text>
						<AntDesign name="right" size={largeTextSize - 3} color="white" />
					</TouchableOpacity>
					<TouchableOpacity
						style={[styles.button, { backgroundColor: Colors.greyscale[6] }]}
						onPress={() => {
							paymentProvider === 'square' && Platform.OS !== 'web'
								? startCustomerCardEntry()
								: dispatch(setCheckoutState({ isAddingCard: true }))
						}}
					>
						<Text style={styles.buttonText}>ADD A DIFFERENT CARD</Text>
						<AntDesign name="right" size={largeTextSize - 3} color="white" />
					</TouchableOpacity>
					<TouchableOpacity
						style={styles.cancel}
						onPress={() => {
							toggleModal()
						}}
					>
						<Text style={styles.cancelText}>CANCEL</Text>
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	titleSection: {
		padding: 20,
	},

	buttonContainer: {
		width: '90%',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'column',
		paddingBottom: 20,
	},
	button: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 15,
		borderRadius: 5,
		marginTop: 10,
		width: '100%',
	},
	buttonText: {
		color: 'white',
		fontSize: 16,
	},
	cancel: {
		marginTop: 20,
		marginBottom: 0,
	},
	cancelText: {
		color: 'black',
		fontWeight: '300',
		fontSize: mediumTextSize,
	},
})
