import React, { useEffect } from 'react'
import InternetConnectionBanner from './InternetConnectionBanner'
import { useNetInfo } from '@react-native-community/netinfo'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import { setUserState } from '../state/Slices/userSlice'

const InternetConnection = ({ children }) => {
	const dispatch = useAppDispatch()
	const { deviceHasInternet } = useAppSelector(state => state.user)
	const { isConnected } = useNetInfo()

	useEffect(() => {
		dispatch(setUserState({ deviceHasInternet: isConnected }))
	}, [isConnected])

	return (
		<>
			<InternetConnectionBanner hide={deviceHasInternet} />
			{children}
		</>
	)
}

export default InternetConnection
