import * as React from 'react'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import { Item } from '../state/types'
import GlobalStyle from '../constants/GlobalStyle'
import Button from '../components/Button'
import { View } from '../components/Themed'

var iso8601DurationRegex =
	/(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/

export function parsePrepTime(iso8601Duration) {
	//if (iso8601Duration) {
	var matches = iso8601Duration.match(iso8601DurationRegex)
	return {
		years: matches[2] === undefined ? 0 : matches[2],
		months: matches[3] === undefined ? 0 : matches[3],
		weeks: matches[4] === undefined ? 0 : matches[4],
		days: matches[5] === undefined ? 0 : matches[5],
		hours: matches[6] === undefined ? 0 : matches[6],
		minutes: matches[7] === undefined ? 0 : matches[7],
		seconds: matches[8] === undefined ? 0 : matches[8],
	}
	// }
	// return {
	// 	years: 0,
	// 	months: 0,
	// 	weeks: 0,
	// 	days: 0,
	// 	hours: 0,
	// 	minutes: 0,
	// 	seconds: 0,
	// }
}

export function prepTimeDur(prepTime) {
	var hours = 0
	var minutes = 0
	if (prepTime >= 60) {
		hours = prepTime / 60
		minutes = prepTime % 60
	} else {
		minutes = prepTime
	}

	return 'P0DT' + hours + 'H' + minutes + 'M0S'
}

export function prepTimeTotal(prep) {
	return parseInt(prep.days) * 1440 + parseInt(prep.hours) * 60 + parseInt(prep.minutes)
}

export function getLatestPrepTime(cart: Item[], prepTimeList: any) {
	var latestPrepTime = 0

	cart.forEach(item => {
		var itemPrep = prepTimeList.find((timing: any) => timing.id === item.rId)

		if (itemPrep && itemPrep.prepTime > latestPrepTime) {
			latestPrepTime = itemPrep.prepTime
		}
	})
	return latestPrepTime
}

export function getLatestPrepTimeCheckout(cart: Item[], loadedData: any) {
	var latestPrepTime = 0
	cart.forEach(item => {
		if (loadedData[item.rId]) {
			var itemPrep = loadedData[item.rId].prepTime
			if (itemPrep > latestPrepTime) {
				latestPrepTime = itemPrep
			}
		}
	})
	return latestPrepTime
}

export function getPrepRange(restaurants) {
	var maxPrep = 0
	var minPrep = 9999
	var allPrepTimes = []
	restaurants.forEach(r => {
		allPrepTimes.push({
			id: r.id,
			prepTime: r.prepTime,
		})
		if (r.prepTime < minPrep) {
			minPrep = r.prepTime
		}
		if (r.prepTime > maxPrep) {
			maxPrep = r.prepTime
		}
	})
	return {
		min: minPrep,
		max: maxPrep,
		allPrepTimes: allPrepTimes,
	}
}

export function prepTimeDisplay(
	prepTime,
	isRestoSnoozed,
	pickupMessage,
	storeOpen,
	hours,
	localTimezone,
	cart,
	prepTimeList
) {
	if (cart.length > 0) {
		var latest = getLatestPrepTime(cart, prepTimeList)
		if (latest > prepTime) {
			prepTime = latest
		}
	}

	var prepTitle = prepTime === 0 ? 'Pickup Now' : 'Pickup in ' + prepTime + ' minutes'
	if (!global.hideScheduleOrder) {
		if (!isRestoSnoozed) {
			if (storeOpen) {
				var title = pickupMessage === '' ? prepTitle : pickupMessage
				return (
					<View>
						<Button
							buttonStyle={GlobalStyle.pickupBtn}
							textStyle={GlobalStyle.pickupBtnText}
							title={title}
							onPress={null}
							isLoading={false}
							icon={undefined}
						/>
					</View>
				)
			}
		} else {
			return (
				<View>
					<Button
						buttonStyle={GlobalStyle.pickupBtn}
						textStyle={GlobalStyle.pickupBtnText}
						title={'Not accepting new orders'}
						onPress={null}
						isLoading={false}
						icon={undefined}
					/>
				</View>
			)
		}
	}
}

export function convertHours(hours, prepTime) {
	var hoursObj: any = {
		0: {},
		1: {},
		2: {},
		3: {},
		4: {},
		5: {},
		6: {},
	}
	Object.entries(hours).forEach(x => {
		var key: any = x[0]
		var obj: any = x[1]

		var days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

		var day = days.indexOf(key)

		var tempStart: any[] = []
		var tempEnd: any[] = []

		if (obj.length === 0) {
			// Consider empty object for the day as open 24 hours
			tempStart.push(0)
			tempEnd.push(24)
		} else {
			obj.forEach(o => {
				let endNum = parseFloat(o.end)
				let startNum = parseFloat(o.start)
				if (isNaN(startNum)) {
					startNum = 0
				}
				if (isNaN(endNum)) {
					endNum = 24
				}
				var end = minusPrepTime(endNum, prepTime)
				tempStart.push(startNum)
				tempEnd.push(endNum === 24 ? endNum : end)
			})
		}

		hoursObj[day] = { startTimes: tempStart, endTimes: tempEnd }
	})
	return hoursObj
}

//remove prep time from closing hour
//i.e. if restaurant closes at 8,
//show it closing at 7:45 (8 - prep time of 15 mins)
function minusPrepTime(endTime, prepMins) {
	if (endTime === 0) {
		return endTime
	} else {
		if (prepMins === 0) {
			return endTime
		} else {
			if (global.kiosk) {
				return endTime
			} else {
				return endTime - prepMins / 60 /* - 5 */ / 60 //extra 5 minutes so people aren't collecting orders right at close
			}
		}
	}
}

export function showTZ(date, d, localTimezone) {
	let showTimezone = ''
	const currHourNoTZ = new Date().getHours()
	var timezone = formatInTimeZone(date, localTimezone, ' zzz')

	const currHour = d.getHours()

	//if user in different timezone, show timezone abbreviation (i.e. "EST")
	if (currHourNoTZ !== currHour) {
		showTimezone = timezone
	}
	return showTimezone
}

export function setPickupTime(mins, curr, prepTimeDefault, localTimezone) {
	var millis = prepTimeTotal(parsePrepTime(mins)) * 60000

	var tot = Date.parse(curr) + millis

	var def = prepTimeDur(prepTimeDefault)

	const date = new Date(tot)
	const d = utcToZonedTime(tot, localTimezone)

	const showTimezone = showTZ(date, d, localTimezone)

	if (def == mins) {
		return formatInTimeZone(date, localTimezone, "EEE MMM d 'at' h:mm a")
		//return AMPM(d.toString().slice(0, Platform.OS === 'web' ? -36 : -18), "pickup") + show_timezone;
	} else {
		return formatInTimeZone(date, localTimezone, "EEE MMM d 'at' h:mm a")
		//return AMPM(date.toString().slice(0, Platform.OS === 'web' ? -36 : -18), "pickup") + show_timezone;
	}
}

//prepTime = P0DT0 --> preptime based on live or scheduled
//prepTimeNum = original prep time in minutes (number)
export function checkIfOpen(hours, prepTime, prepTimeNum, tz) {
	var isOpen = true
	//live order, not scheduled (if scheduled we know shop will be open)
	if (hours && hours[0] && hours[0].startTimes.length > 0) {
		var parsedPrepTime = parsePrepTime(prepTime)
		var prepHours = !global.kiosk ? parseInt(parsedPrepTime.hours) : 0
		var prepMinutes = !global.kiosk ? parseInt(parsedPrepTime.minutes) : 0
		var prepDays = parseInt(parsedPrepTime.days)
		const d = utcToZonedTime(new Date(), tz)

		//add prep time to current time and convert to decimal (for comparison)
		var curr_prep_hours = d.getHours()
		let curr_prep_mins = d.getMinutes()

		if (prepMinutes > 0) {
			curr_prep_mins += prepMinutes
		}

		if (curr_prep_mins >= 60) {
			var x = Math.floor(curr_prep_mins / 60)
			curr_prep_mins -= x * 60
			curr_prep_hours += x
		}

		if (prepHours > 0) {
			curr_prep_hours += prepHours
		}

		if (curr_prep_hours >= 24) {
			var x = Math.floor(curr_prep_hours / 24)
			curr_prep_hours -= x * 24
			prepDays += x
		}
		//current time:
		//open (doesn't add for prep time) vs closing (adds for prep time)

		var closingMins = curr_prep_mins /*+ prepMinutes*/ //enable if you want restaurant to close at closingHr - prepTime
		var openMins = curr_prep_mins

		var openHours = curr_prep_hours
		var closingHours = curr_prep_hours

		var openTotalMins = 0
		var closingTotalMins = 0

		if (openMins > 60) {
			openHours += Math.trunc(openMins / 60)

			openTotalMins = openMins % 60
		} else {
			openTotalMins = openMins
		}

		if (closingMins > 60) {
			closingHours += Math.trunc(closingMins / 60)

			closingTotalMins = closingMins % 60
		} else {
			closingTotalMins = closingMins
		}

		const openCurrTime = openHours + '.' + (openTotalMins / 60 + '').slice(2)
		const closingCurrTime = closingHours + '.' + (closingTotalMins / 60 + '').slice(2)

		//let curr_time = curr_prep_hours + "." + (total_mins / 60 + "").slice(2);
		const curr_day = (d.getDay() + prepDays) % 7
		var dayHours = Object.entries(hours)[curr_day][1]
		var startTimes = dayHours['startTimes']
		var endTimes = dayHours['endTimes']

		var keepLooping = true
		startTimes.forEach((time, i) => {
			if (closingCurrTime >= endTimes[i]) {
				//store already closed for the day
				isOpen = false
			} else {
				//curr day
				if (closingCurrTime < endTimes[i] && openCurrTime >= startTimes[i]) {
					//currently open
					keepLooping = false

					isOpen = true
				} else if (openCurrTime < startTimes[i] && keepLooping) {
					//opening later today
					isOpen = false
					keepLooping = false
				}
			}
		})

		console.log(isOpen)
		return global.kiosk ? true : isOpen
	} else {
		return true
	}
}
