import { useEffect } from 'react'
import { countQtySelected, getDiscountPrice, getModListQuantity } from '../Helpers/functions'
import { checkSnooze } from '../../../helpers/checkSnooze'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { addToModSum, setItemState, setSingleSelectionId } from '../../../state/Slices/itemSlice'

const useItem = ({ appliedCoupon, token, editing, loyaltyPrice }) => {
	const { loadedData } = useAppSelector(state => state.data)
	const { quantity, modList, objects, modListLoaded, itemTaxes } = useAppSelector(
		state => state.item
	)

	let modPrice = 0

	const dispatch = useAppDispatch()

	function setChecked(modList) {
		const updatedObjects = {}

		modList.forEach(list => {
			list.options.forEach(mod => {
				updatedObjects[mod.id] = mod.isSelected
				if (mod.isSelected) modPrice += mod.price
			})
		})

		// Dispatch the updated object
		dispatch(
			setItemState({
				objects: updatedObjects,
			})
		)
	}

	useEffect(() => {
		if (appliedCoupon) {
			const data = loadedData[token]

			const disc = getDiscountPrice(appliedCoupon, data, quantity)
			dispatch(setItemState({ discountPrice: disc }))
		}
	}, [quantity])

	useEffect(() => {
		const data = loadedData[token]

		if (editing && editing.modList) {
			const modReturn = editing.modList

			setChecked(modReturn)

			dispatch(
				setItemState({
					visibleModListIndex: modReturn.length - 1, // If editing, jump to the last step
					quantity: editing.qty,
					itemNote: editing.note,
					idempotencyKey: editing.idempotencyKey,
				})
			)
		} else {
			dispatch(
				setItemState({
					visibleModListIndex: 0,
					quantity: 1,
					itemNote: '',
					idempotencyKey: data.idempotencyKey,
				})
			)
		}

		dispatch(
			setItemState({
				posId: data.posId || '',
				variationIds: data.variationIds || [],
				itemId: data.id,
				itemName: data.name || '',
				ageVerificationRequired: data.ageVerificationRequired || false,
				itemDesc: data.description || '',
				restId: data.restaurantId || '',
				itemPrice: data.price,
				promoPrice: loyaltyPrice !== undefined ? loyaltyPrice : data.promo,
				imageUri: data.imageUri,
				itemTaxes: [...itemTaxes, ...data.taxIds.map(taxId => loadedData[taxId])],
				totalModPrice: modPrice,
				modList: [],
				singleSelectionId: {},
				modListsQuantity: {},
				allowAdd: false,
				allowAddMulti: false,
				visibleModList: '',
				modListLoaded: false,
				itemHours: data.hours,
			})
		)

		const info = data.modifierLists
		// Initialize a set to store current modifier ids for O(1) existence checks
		const currentModIds = new Set(modList.map(mod => mod.id))
		if (info.length > 0) {
			dispatch(setItemState({ isModList: true }))

			const localModList = []

			let defaultIndex = 0

			const infoWithNestedModifiers = []

			info.forEach((modListForItem, index, array) => {
				const modListData = loadedData[modListForItem.modifierListId]
				infoWithNestedModifiers.push(modListForItem)
				if (data && !currentModIds.has(data.id) && modListData) {
					const modifiers = loadedData['items_for_' + modListForItem.modifierListId] ?? []
					modifiers.forEach(mod => {
						if (mod.modLists?.length > 0) {
							for (const nestedModList of mod.modLists) {
								infoWithNestedModifiers.push({
									...nestedModList,
									isNested: true,
									parentModifierId: mod.id,
									parentModifierName: mod.name,
								})
							}
						}
					})
				}
			})

			infoWithNestedModifiers.forEach((modListForItem, index) => {
				const modListData = loadedData[modListForItem.modifierListId]
				if (data && !currentModIds.has(data.id) && modListData) {
					const isSingle = modListData.selectionType !== 'MULTIPLE'
					const min =
						modListForItem.minSelectedModifiers === -1
							? modListData.min
							: modListForItem.minSelectedModifiers
					const max =
						modListForItem.maxSelectedModifiers === -1
							? modListData.max
							: modListForItem.maxSelectedModifiers

					const options = loadedData['items_for_' + modListForItem.modifierListId] ?? []
					const localOptions = options
						.filter(m => !checkSnooze(m.isSnoozed, m.snoozeUntil) && !m.isDeleted)
						.sort((a, b) => a.ordinal - b.ordinal)
						.map((m, index) => {
							const isSelected = (isSingle || (max === 1 && min === 1)) && index === 0
							// Update the total modification price if the current item is selected
							if (isSelected) {
								dispatch(setSingleSelectionId(modListData.id, m.id))
								dispatch(addToModSum(m.amount || 0))
							}
							return {
								id: m.id,
								name: m.name,
								price: m.amount || 0,
								currency: 'CAD',
								isSelected: isSelected,
								isSnoozed: m.isSnoozed,
								snoozeUntil: m.snoozeUntil,
								imageUrl: m.imageUrl,
								isRecommended: m.isRecommended,
								ordinal: m.ordinal,
								variation: m.variation || false,
								parentModifierId: modListForItem?.isNested ? modListForItem.parentModifierId : '',
							}
						})
					const localData = {
						id: modListForItem?.isNested
							? modListData.id + '-' + modListForItem.parentModifierId
							: modListData.id,
						name: modListForItem?.isNested
							? modListData.name + ' for ' + modListForItem.parentModifierName
							: modListData.name,
						selectionType: modListData.selectionType,
						ordinal: 1,
						options: localOptions,
						min: min,
						max: max,
						variation: modListData.variation,
						selectedId: '',
						isNested: modListForItem?.isNested ?? false,
					}
					if (localOptions.length > 0) {
						localModList.push(localData)
						if (!editing && index === defaultIndex) {
							dispatch(setItemState({ visibleModList: modListData.id }))
						}
					} else {
						defaultIndex++
					}
				}
			})

			if (editing && editing.isEditing && editing.modList) {
				const modReturn = editing.modList
				dispatch(
					setItemState({
						modList: modReturn,
						visibleModList: 'special_instructions',
					})
				)
			} else {
				//Add special instructions input
				localModList.push({
					id: 'special_instructions',
					name: ' ',
					options: [],
				})

				dispatch(setItemState({ modList: localModList }))
			}
			if (localModList.length === info.length) {
				dispatch(setItemState({ modListLoaded: true }))
			}
		} else {
			dispatch(
				setItemState({
					modList: [
						{
							id: 'special_instructions',
							name: ' ',
							options: [],
						},
					],
					visibleModList: 'special_instructions',
					modListLoaded: true,
				})
			)
		}
	}, [token])

	useEffect(() => {
		let count = 0
		let multiAdd = true
		let singleAdd = true
		modList.forEach(list => {
			count = countQtySelected(list, getModListQuantity(modList, 0, list))
			if (list.selectionType === 'MULTIPLE' && list.max !== 1) {
				if (multiAdd && count >= list.min && (count <= list.max || list.max === -1)) {
					multiAdd = true
				} else if (count < list.min) {
					multiAdd = false
				} else if (count > list.max && list.max !== -1) {
					multiAdd = false
				}
			} else {
				//single
				if (count >= 1 && singleAdd) {
					singleAdd = true
				} else {
					singleAdd = false
				}
			}
		})
		dispatch(setItemState({ allowAdd: singleAdd, allowAddMulti: multiAdd }))
	}, [objects, modListLoaded, token])
}

export default useItem
