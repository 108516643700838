import { useEffect } from 'react'
import { auth, db } from '../../firebase/config'
import { getUserData, serializeUserTimestamps } from './getUserData'
import { collection, doc, onSnapshot, orderBy, query, where, limit } from 'firebase/firestore'
import { DATABASE_NAME } from '@env'
import { useAppDispatch } from '../../state/hooks'
import { setDataState } from '../../state/Slices/dataSlice'

// Retrieve an active order to show in OrderBanner
const useAuthOrders = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		const unsubscribeAuth = auth.onAuthStateChanged(user => {
			if (user) {
				const fetchData = async () => {
					try {
						// Retrieve recent order for order banner
						const orderRef = collection(doc(db, DATABASE_NAME, global.org), 'Orders')
						const timeWindow = new Date(Date.now() - 2 * 60 * 60 * 1000)

						const q = query(
							orderRef,
							where('userId', '==', user.uid),
							where('orderDate', '>=', timeWindow),
							orderBy('orderDate', 'desc'),
							limit(3)
						)

						// Set up a snapshot listener for real-time updates
						const unsubscribeOrders = onSnapshot(q, snapshot => {
							let foundOrder = null
							for (const doc of snapshot.docs) {
								const data = doc.data()
								if (
									data.status !== 'Pending' &&
									data.status !== 'Failed' &&
									data.status !== 'Cancelled'
								) {
									foundOrder = data
									break
								}
							}

							if (foundOrder) {
								foundOrder.pickupDate = foundOrder.pickupDate.seconds
								foundOrder.orderDate = foundOrder.orderDate.seconds
								dispatch(setDataState({ activeOrder: foundOrder }))
							} else {
								dispatch(setDataState({ activeOrder: null }))
							}
						})

						// Clean up the snapshot listener when the component unmounts
						return () => unsubscribeOrders()
					} catch (error) {
						console.error('Failed to fetch user data:', error)
					}
				}

				fetchData()
			}
		})

		return () => unsubscribeAuth()
	}, [dispatch])
}

export default useAuthOrders
