import React, { useState, useEffect } from 'react'
import {
	View,
	TouchableOpacity,
	Animated,
	StyleSheet,
	TouchableWithoutFeedback,
	Dimensions,
} from 'react-native'
import Colors from '../../constants/Colors'
import { SMALL_WIDTH, smallTextSize } from '../../constants/GlobalStyle'
import { Text } from '../Themed'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { resetUserLocation, resetUserState } from '../../state/Slices/userSlice'

// Disappears/appears when user presses the menu icon in web header
const WebDropdownMenu = ({ isVisible, onClose, auth, navigation }) => {
	const { loadedData } = useAppSelector(state => state.data)
	const { deviceHasInternet } = useAppSelector(state => state.user)

	const referralEnabled = loadedData[global.org].referralEnabled || false
	const loyaltyEnabled = loadedData[global.org].loyaltyEnabled || false
	const [animation, setAnimation] = useState(new Animated.Value(0))
	const dispatch = useAppDispatch()

	const { earnString, makeString } = getReferInfo(loadedData)

	useEffect(() => {
		if (isVisible) {
			Animated.timing(animation, {
				toValue: 1,
				duration: 300,
				useNativeDriver: false,
			}).start()
		} else {
			Animated.timing(animation, {
				toValue: 0,
				duration: 300,
				useNativeDriver: false,
			}).start()
		}
	}, [isVisible])

	const menuItems = []
	if (referralEnabled && auth.currentUser !== null) {
		menuItems.push('Refer a Friend, earn ' + makeString)
	}
	if (loyaltyEnabled && auth.currentUser !== null) {
		menuItems.push('View Rewards')
	}

	if (auth.currentUser !== null) {
		menuItems.push('Orders', 'Payment Methods', 'Account Settings', 'Sign out')
	} else {
		menuItems.push('Sign up', 'Log in')
	}

	const handleMenuItemPress = item => {
		switch (item) {
			case 'Refer a Friend, earn ' + makeString:
				navigation.navigate('AccountStack', {
					screen: 'Refer',
					params: {
						referralCode: null,
						referral: earnString,
						referrer: makeString,
					},
				})
				break
			case 'View Rewards':
				navigation.navigate('Rewards')
				break
			case 'Orders':
				navigation.navigate('TabOrders')
				break
			case 'Payment Methods':
				navigation.navigate('AccountStack', { screen: 'Wallet' })
				break
			case 'Account Settings':
				navigation.navigate('AccountStack', { screen: 'Settings' })
				break
			case 'Sign out':
				auth.signOut()
				dispatch(resetUserState())
				dispatch(resetUserLocation())
				navigation.replace('AccountStack', { screen: 'Account' })
				break
			case 'Sign up':
				navigation.replace('AccountStack', { screen: 'Account', params: { signup: true } })
				break
			case 'Log in':
				navigation.replace('AccountStack', { screen: 'Account' })
				break
			default:
				break
		}
		onClose() // Close the menu after handling the press
	}

	const padding = menuItems.length * 36 + 20
	const menuHeight = animation.interpolate({
		inputRange: [0, 1],
		outputRange: [0, padding + smallTextSize * menuItems.length],
	})

	return (
		<View style={styles.container}>
			<TouchableWithoutFeedback onPress={onClose}>
				<View style={styles.overlay} />
			</TouchableWithoutFeedback>
			<Animated.View
				style={[
					styles.menu,
					{ height: menuHeight },
					SMALL_WIDTH && { width: '100%', right: 0, paddingLeft: 15 },
				]}
			>
				{menuItems.map((item, index) => (
					<TouchableOpacity
						key={index}
						onPress={() => handleMenuItemPress(item)}
						style={styles.menuItem}
						disabled={item === 'Sign out' && !deviceHasInternet ? true : false}
					>
						<Text
							style={[
								styles.menuItemText,
								item === 'Sign out' && !deviceHasInternet && { color: Colors.greyscale[6] },
							]}
						>
							{item}
						</Text>
					</TouchableOpacity>
				))}
			</Animated.View>
		</View>
	)
}

const getReferInfo = loadedData => {
	var earnAmount = 10
	var earnType = '$'
	var earnString = '$10'

	var makeAmount = 10
	var makeType = '$'
	var makeString = '$10'
	if (loadedData[global.org] && loadedData[global.org].referralAmount) {
		earnAmount = loadedData[global.org].referralAmount.amount / 100
		earnType = loadedData[global.org].referralAmount.type === 'percent' ? '%' : '$'
	}
	if (earnType === '$') {
		earnString = earnType + earnAmount
	} else {
		earnString = earnAmount + earnType
	}

	if (loadedData[global.org] && loadedData[global.org].referrerAmount) {
		makeAmount = loadedData[global.org].referrerAmount.amount
		makeType = loadedData[global.org].referrerAmount.type === 'percent' ? '%' : '$'
	}
	if (makeType === '$') {
		makeString = makeType + makeAmount
	} else {
		makeString = makeAmount + makeType
	}
	return { earnString, makeString }
}

const styles = StyleSheet.create({
	container: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1000,
	},
	overlay: {
		flex: 1,
		backgroundColor: 'transparent',
	},
	menu: {
		position: 'absolute',
		top: 100,
		right:
			Dimensions.get('window').width > 1300 ? (Dimensions.get('window').width - 1300) / 2 : '2%',
		backgroundColor: Colors.greyscale[10],
		paddingVertical: 10,
		paddingRight: 15,
	},

	menuItem: {
		padding: 15,
	},
	menuItemText: {
		color: 'white',
		fontSize: smallTextSize,
	},
})

export default WebDropdownMenu
