import React, { useRef, useState } from 'react'
import { View, TextInput, TouchableOpacity, StyleSheet, Platform } from 'react-native'
import Colors from '../../../constants/Colors'
import PaymentMethodsList from '../../../components/Stripe/PaymentMethodsList'
import RewardsList from '../../../components/Rewards/RewardsList'
import { auth } from '../../../firebase/config'
import { MAX_CHECKOUT_WIDTH } from '../../../constants/GlobalStyle'
import { responsiveWidth } from 'react-native-responsive-dimensions'
import NewAddressModal from '../../../components/Delivery/NewAddressModal'
import { Text } from '../../../components/Themed'
import { PickupPointModal } from '../../../components/PickupPoint'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'

/**
 * Boxes that appear on checkout page (non-kiosk only)
 */
export const RewardsBox = ({ navigation }) => {
	const { userData } = useAppSelector(state => state.user)
	if (auth.currentUser !== null && userData !== null) {
		return (
			<View style={styles.container}>
				<Text
					style={[
						styles.label,
						{
							marginHorizontal: '5%',
							alignSelf: 'center',
							width: responsiveWidth(90),
							maxWidth: MAX_CHECKOUT_WIDTH,
						},
					]}
				>
					{'REWARDS'}
				</Text>
				<RewardsList
					showAll={false}
					onlyRedeemable={true}
					navigation={navigation}
					isCheckout={true}
				/>
			</View>
		)
	}
}

export const ChangeLocationBox = ({ toggleLocationModal, currentAddress }) => {
	return (
		<View style={styles.container}>
			<View style={styles.inputGroup}>
				<Text style={styles.label}>{'PICKUP LOCATION'}</Text>
				<View style={styles.inputRow}>
					<View style={styles.input}>
						<>
							<Text>{currentAddress}</Text>
						</>
					</View>

					<TouchableOpacity onPress={toggleLocationModal} style={styles.button}>
						<Text style={styles.buttonText}>Change</Text>
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}

export const PickupTimeBox = ({ toggleTimeModal, pickupText, isOpen }) => {
	const { cartPrep, isDelivery, deliveryEstimate } = useAppSelector(state => state.persisted)
	const deliveryTime =
		deliveryEstimate && deliveryEstimate.estimate ? deliveryEstimate.estimate.timeToDeliver : 60

	return (
		<View style={styles.container}>
			<View style={styles.inputGroup}>
				<Text style={styles.label}>{isDelivery ? 'ESTIMATED DELIVERY TIME' : 'PICKUP TIME'}</Text>
				<View style={styles.inputRow}>
					<View style={styles.input}>
						{cartPrep.isScheduled ? (
							<>
								<Text>{pickupText}</Text>
							</>
						) : isOpen && isDelivery && deliveryTime >= 0 ? (
							<>
								<Text>{'ASAP (IN ' + deliveryTime + ' MINUTES)'}</Text>
							</>
						) : isOpen && isDelivery ? (
							<>
								<Text>{'NEXT AVAILABLE TIME'}</Text>
							</>
						) : isOpen ? (
							<>
								<Text>{'ASAP (IN ' + cartPrep.longestPrepTime + ' MINUTES)'}</Text>
							</>
						) : (
							<>
								<Text>{'CLOSED'}</Text>
							</>
						)}
					</View>

					<TouchableOpacity onPress={toggleTimeModal} style={styles.button}>
						<Text style={styles.buttonText}>
							{isOpen || cartPrep.isScheduled ? 'Change' : 'Schedule Order'}
						</Text>
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}

export const PickupPointDetailsBox = ({ showModal, setShowModal, numOfRestaurants }) => {
	const { selectedPickupPoint, isDelivery } = useAppSelector(state => state.persisted)
	const { pickupPointsEnabled } = useAppSelector(state => state.settings)

	if (
		pickupPointsEnabled &&
		selectedPickupPoint !== null &&
		selectedPickupPoint.name &&
		!isDelivery
	) {
		let addressSpace = ''
		const address = selectedPickupPoint.address
		let formattedAddressLine = ''
		let formattedCityLine = ''
		if (address) {
			if (address.addressLine2 && address.addressLine2 !== '') {
				addressSpace = ' '
			}
			formattedAddressLine = `${address.addressLine1}${
				address.addressLine2 ? ' ' + address.addressLine2 : ''
			}`
			formattedCityLine = `${address.city}, ${address.provinceState} ${address.postCode}`
		}
		return (
			<>
				<View style={styles.container}>
					<View style={styles.inputGroup}>
						<Text style={styles.label}>{'PICKUP DETAILS'}</Text>
						<View style={styles.inputRow}>
							<View style={[styles.input, { width: '80%' }]}>
								<Text style={{ width: '80%' }}>
									{selectedPickupPoint.name}
									{selectedPickupPoint.id === 'store_pickup' && numOfRestaurants > 1 && 's'}
									{selectedPickupPoint.address
										? ': ' + formattedAddressLine + ', ' + formattedCityLine
										: ''}
								</Text>
							</View>

							<TouchableOpacity
								onPress={() => {
									setShowModal(!showModal)
								}}
								style={styles.button}
							>
								<Text style={styles.buttonText}>{'Change'}</Text>
							</TouchableOpacity>
						</View>
					</View>
				</View>
				<PickupPointModal visible={showModal} setVisible={setShowModal} onChange={true} />
			</>
		)
	}
	return <></>
}

export const DeliveryDetailsBox = ({ showModal, setShowModal }) => {
	const { isDelivery } = useAppSelector(state => state.persisted)
	const { userLocation } = useAppSelector(state => state.user)

	if (isDelivery) {
		return (
			<>
				<View style={styles.container}>
					<View style={styles.inputGroup}>
						<Text style={styles.label}>{'DELIVERY DETAILS'}</Text>
						<View style={styles.inputRow}>
							<View style={[styles.input, { width: '80%' }]}>
								<Text style={{ width: '80%' }}>
									{userLocation && userLocation.longAddress ? userLocation.longAddress : ''}
								</Text>
							</View>

							<TouchableOpacity
								onPress={() => {
									setShowModal(!showModal)
								}}
								style={styles.button}
							>
								<Text style={styles.buttonText}>{'Change'}</Text>
							</TouchableOpacity>
						</View>
					</View>
				</View>
				<NewAddressModal modalVisible={showModal} setModalVisible={setShowModal} />
			</>
		)
	}
	return <></>
}

export const OrderNoteBox = () => {
	const dispatch = useAppDispatch()
	const inputRef = useRef(null)
	const [renderKey, setRenderKey] = useState(0)
	const handleReRender = () => {
		if (renderKey === 0 && Platform.OS === 'android') {
			setRenderKey(k => k + 1)
		}
	}

	return (
		<View style={styles.container}>
			<View style={styles.inputGroup}>
				<Text style={styles.label}>{'ORDER NOTES'}</Text>
				<View style={styles.inputRow}>
					<TextInput
						ref={inputRef}
						style={{
							...styles.input,
							fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont',
							paddingRight: 80,
						}}
						placeholder="None"
						placeholderTextColor={Colors.greyscale[5]}
						onChangeText={value => {
							dispatch(setCheckoutState({ orderNote: value }))
						}}
						key={renderKey}
						onLayout={handleReRender}
						maxLength={200}
					/>

					<TouchableOpacity
						onPress={() => {
							inputRef.current && inputRef.current.focus()
						}}
						style={styles.button}
					>
						<Text style={styles.buttonText}>Change</Text>
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}

export const PromoCodeBox = ({ togglePromoModal, couponCode, couponName }) => {
	return (
		<View style={styles.container}>
			<View style={styles.inputGroup}>
				<Text style={styles.label}>{'PROMO CODE'}</Text>
				<View style={styles.inputRow}>
					<View style={styles.input}>
						{couponCode !== '' && couponName !== '' ? (
							<Text>{`${couponName} (${couponCode})`}</Text>
						) : (
							<Text style={{ color: Colors.greyscale[5] }}>{'None'}</Text>
						)}
					</View>

					<TouchableOpacity onPress={togglePromoModal} style={styles.button}>
						<Text style={styles.buttonText}>Change</Text>
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}

export function PaymentMethodBox({ onPress }) {
	const { isUser, loading, selectedCard, isDigitalWalletEnabled, digitalWalletType } =
		useAppSelector(state => state.checkout)
	const { paymentMethodId, paymentMethods } = useAppSelector(state => state.user)

	const platform = Platform.OS === 'ios' ? 'applePay' : 'googlePay'
	return (
		<View style={styles.container}>
			<View style={styles.inputGroup}>
				<Text style={styles.label}>{'PAYMENT METHOD'}</Text>
				<View style={styles.inputRow}>
					{loading ? (
						<View style={styles.input}>
							<Text style={{ color: Colors.greyscale[5] }}>Loading cards...</Text>
						</View>
					) : (paymentMethods?.length > 0 || paymentMethodId === digitalWalletType) && isUser ? (
						<View style={styles.cardView}>
							<PaymentMethodsList
								paymentMethods={
									paymentMethods.findIndex(card => card.id === paymentMethodId) === -1
										? []
										: [
												paymentMethods[
													paymentMethods.findIndex(card => card.id === paymentMethodId)
												],
											]
								}
								isDefault={true}
								cardId={paymentMethodId}
								loading={loading}
								onPress={onPress} //for toggling modal
								isCheckout={true}
								selectedCard={selectedCard}
								isDigitalWalletEnabled={isDigitalWalletEnabled}
								digitalWalletType={digitalWalletType}
							/>
						</View>
					) : (
						<>
							<View style={styles.input}>
								<Text style={{ color: Colors.greyscale[5] }}>None</Text>
							</View>

							<TouchableOpacity onPress={onPress} style={styles.button}>
								<Text style={styles.buttonText}>Change</Text>
							</TouchableOpacity>
						</>
					)}
				</View>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	inputGroup: {
		marginBottom: 20,
	},
	label: {
		fontWeight: 'bold',
		marginBottom: 10,
	},
	inputRow: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	cardView: {
		flexGrow: 1,
		justifyContent: 'center',
	},
	input: {
		flexGrow: 1,
		height: 55,
		justifyContent: 'center',
		padding: 15,
		borderWidth: 1,
		borderColor: Colors.greyscale[5],
		borderRadius: 8,
		backgroundColor: '#fff',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	button: {
		position: 'absolute',
		right: 10,
		padding: 15,
	},
	buttonText: {
		color: 'black',
		fontWeight: '500',
	},
})
