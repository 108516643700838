import React from 'react'
import { View, TouchableOpacity, StyleSheet, Platform } from 'react-native'
import { MaterialCommunityIcons, FontAwesome5, MaterialIcons } from '@expo/vector-icons'
import GlobalStyle, { sTextSize } from '../constants/GlobalStyle'
import Colors from '../constants/Colors'
import { Text } from '../components/Themed'
import { useAppSelector } from '../state/hooks'

const POSNavBar = ({ screenName, navigation }) => {
	const isActive = name => name === screenName

	const { cart } = useAppSelector(state => state.persisted)

	const cartCount = cart.reduce((count, item) => count + item.qty, 0)

	return (
		<View style={styles.navBar}>
			<TouchableOpacity
				onPress={() => {
					navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
				}}
			>
				<View style={styles.iconContainer}>
					<MaterialCommunityIcons
						name="silverware-fork-knife"
						size={27}
						color={isActive('HomeStack') ? global.orgColor : Colors.greyscale[8]}
					/>
					<Text style={[styles.iconText, isActive('HomeStack') && { color: global.orgColor }]}>
						Home
					</Text>
				</View>
			</TouchableOpacity>
			<TouchableOpacity onPress={() => navigation.navigate('TabOrders')}>
				<View style={styles.iconContainer}>
					<FontAwesome5
						name="history"
						size={25}
						color={isActive('Orders') ? global.orgColor : Colors.greyscale[8]}
						style={{ marginBottom: 2 }}
					/>
					<Text style={[styles.iconText, isActive('Orders') && { color: global.orgColor }]}>
						Orders
					</Text>
				</View>
			</TouchableOpacity>
			<TouchableOpacity
				onPress={() =>
					navigation.reset({
						index: 0,
						routes: [{ name: 'TabCheckout' }],
					})
				}
			>
				<View style={styles.iconContainer}>
					<View>
						{cartCount > 0 ? (
							<View style={[GlobalStyle.fabTO3, { backgroundColor: global.orgColor }]}>
								<Text style={{ fontSize: 11, color: 'white' }}>{cartCount}</Text>
							</View>
						) : null}
						<MaterialCommunityIcons
							name="shopping-outline"
							size={29}
							color={isActive('Cart') ? global.orgColor : Colors.greyscale[8]}
						/>
					</View>

					<Text style={[styles.iconText, isActive('Cart') && { color: global.orgColor }]}>
						Cart
					</Text>
				</View>
			</TouchableOpacity>
			<TouchableOpacity onPress={() => navigation.navigate('AccountStack')}>
				<View style={styles.iconContainer}>
					<MaterialIcons
						name="account-circle"
						size={30}
						color={isActive('Account') ? global.orgColor : Colors.greyscale[8]}
					/>
					<Text style={[styles.iconText, isActive('Account') && { color: global.orgColor }]}>
						Account
					</Text>
				</View>
			</TouchableOpacity>
		</View>
	)
}

const styles = StyleSheet.create({
	navBar: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		backgroundColor: 'white',
		paddingVertical: 10,
		borderTopWidth: 1,
		borderColor: '#ccc',
		paddingTop: 10,
		paddingBottom: Platform.OS === 'ios' ? 25 : 15,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.12,
		shadowRadius: 4,
		elevation: 3,
	},
	iconContainer: {
		alignItems: 'center',
	},
	iconText: {
		fontSize: sTextSize,
		fontWeight: '500',
		color: Colors.greyscale[8],
	},
	activeText: {
		color: 'red',
	},
})

export default POSNavBar
