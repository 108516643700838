import { useEffect } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'

const useStripeDigitalWallet = () => {
	const { prices } = useAppSelector(state => state.checkout)
	const dispatch = useAppDispatch()
	const stripe = useStripe()

	useEffect(() => {
		if (stripe) {
			const pr = stripe.paymentRequest({
				country: 'CA',
				currency: 'cad',
				total: {
					label: 'Payment:',
					amount: Math.round(prices.total * 100),
				},
				requestPayerName: true,
				requestPayerEmail: true,
			})

			// Check the availability of the Payment Request API.
			pr.canMakePayment()
				.then(result => {
					if (result) {
						if (result.applePay === true) {
							dispatch(
								setCheckoutState({ isDigitalWalletEnabled: true, digitalWalletType: 'applePay' })
							)
						} else if (result.googlePay === true) {
							dispatch(
								setCheckoutState({ isDigitalWalletEnabled: true, digitalWalletType: 'googlePay' })
							)
						}
						dispatch(setCheckoutState({ paymentRequest: pr }))
					}
				})
				.catch(error => {
					console.log(error)
				})
		}
	}, [stripe, prices])
}

export default useStripeDigitalWallet
