import React, { useEffect, useState } from 'react'
import { View, Platform, TouchableOpacity, StyleSheet, Image } from 'react-native'
import GlobalStyle, {
	mediumTextSize,
	sTextSize,
	smallTextSize,
} from '../../../../constants/GlobalStyle'
import Colors from '../../../../constants/Colors'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import ExitIcon from '../../../../components/Headers/UIButtons'
import { MobileCardElement } from '../../../../App'
import { WebCardElement } from '../../../../App.web'
import { auth } from '../../../../firebase/config'
import { useSquareCheckout } from '../../../../components/Square/Hooks/useSquareCheckout'
import { useSquare } from '../../../../components/Square/Hooks/useSquare'
import Spinner from '../../../../components/LoadingSpinner'
import { DigitalWalletButton } from './DigitalWalletButton'
import CardSeparator from './CardSeparator'
import SquarePaymentButton from './SquarePaymentButton'
import { ButtonContainer } from './ButtonContainer'
import { Text } from '../../../../components/Themed'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { setUserState } from '../../../../state/Slices/userSlice'
import { setCheckoutState } from '../../../../state/Slices/checkoutSlice'

export function AddPaymentMethodView({
	toggleModal,
	isGuestCheckout = false,
	allowSave = false,
	closeModal = null,
}) {
	const { cardElementVisible, saveCard, showSquarePayment, digitalWalletType } = useAppSelector(
		state => state.checkout
	)

	const { stripeId, paymentMethodId, userData } = useAppSelector(state => state.user)
	const { paymentProvider } = useAppSelector(state => state.settings)

	const [spinner, setSpinner] = useState(false)

	const { chargeWebCardNonce, onStartCardEntry, onStartDigitalWallet, hideSquareCard } =
		useSquareCheckout()
	const { createCustomerCard } = useSquare()
	const dispatch = useAppDispatch()

	const setIsCardValid = bool => {
		dispatch(setCheckoutState({ isCardValid: bool }))
	}

	useEffect(() => {
		if (paymentMethodId === '') {
			dispatch(setCheckoutState({ useNewPaymentMethod: true }))
		} else {
			dispatch(setCheckoutState({ useNewPaymentMethod: false }))
		}
	}, [paymentMethodId])

	useEffect(() => {
		if (Platform.OS === 'web' && auth.currentUser === null) {
			dispatch(setCheckoutState({ isGuestCheckout: true }))
		} else {
			dispatch(setCheckoutState({ isGuestCheckout: false }))
		}
	}, [auth.currentUser])

	useEffect(() => {
		const unsubscribe = () => {
			setIsCardValid(false)
		}
		return unsubscribe
	}, [])

	const title = allowSave ? 'PAYMENT METHOD' : 'ADD PAYMENT METHOD'
	return (
		<>
			<Spinner
				visible={spinner}
				overlayColor="rgba(0, 0, 0, 0.65)"
				headerHeight={0}
				footerHeight={0}
				color="black"
				customIndicator={
					<Image
						style={{ width: 100, height: 100, alignSelf: 'center', marginTop: -20 }}
						source={require('../../../../assets/images/loadImg.gif')}
					/>
				}
			/>

			<View style={{ flex: 1 }}>
				<ExitIcon
					onPress={
						allowSave
							? () => {
									dispatch(
										setCheckoutState({
											cardElementVisible: !cardElementVisible,
											showSquarePayment: false,
										})
									)
								}
							: toggleModal
					}
					style={{
						top: allowSave ? 0 : -10,
						display: isGuestCheckout && !allowSave ? 'none' : 'flex',
					}}
				/>
				<View style={[styles.titleSection]}>
					<Text style={GlobalStyle.headerText}>{title}</Text>
					{allowSave && (
						<Text
							style={[
								GlobalStyle.headerText,
								{ fontSize: smallTextSize, marginTop: 10, marginBottom: 0 },
							]}
						>
							{'How would you like to pay today?'}
						</Text>
					)}
				</View>
				<View style={styles.container}>
					{paymentProvider === 'stripe' && digitalWalletType ? (
						<>
							<DigitalWalletButton
								type={digitalWalletType}
								onPress={() => {
									dispatch(
										setUserState({
											paymentMethodId: digitalWalletType,
										})
									)
									toggleModal()
									if (auth.currentUser === null) {
										dispatch(setCheckoutState({ isGuestCheckout: true }))
									}
								}}
							/>
							<CardSeparator />
						</>
					) : (
						<View style={{ height: '25%' }}></View>
					)}

					<View
						style={{
							width: '100%',
						}}
					>
						{Platform.OS === 'web' ? (
							<View style={{ width: '85%', alignSelf: 'center', marginBottom: 10, marginTop: 25 }}>
								<WebCardElement
									setIsCardValid={setIsCardValid}
									paymentProvider={paymentProvider}
									title={showSquarePayment ? 'PAY NOW' : 'ADD CARD'}
									onPress={async token => {
										if (showSquarePayment) {
											chargeWebCardNonce(token)
										} else {
											setSpinner(true)
											await createCustomerCard(token, null, false)
											setSpinner(true)
										}

										toggleModal()
										if (isGuestCheckout) {
											dispatch(setCheckoutState({ isGuestCheckout: true }))
										}
									}}
								/>
							</View>
						) : paymentProvider === 'stripe' ? (
							<View
								style={{
									width: '95%',
									alignItems: 'center',
									alignSelf: 'center',
									marginLeft: -8,
								}}
							>
								<MobileCardElement setIsCardValid={setIsCardValid} />
							</View>
						) : showSquarePayment ? (
							<SquarePaymentButton
								onPress={async () => {
									await onStartCardEntry()
									hideSquareCard()
								}}
								title={'PAY NOW'}
								style={{ width: '80%', alignSelf: 'center' }}
							/>
						) : null}
						<View style={[{ width: '90%', alignSelf: 'center' }]}>
							{allowSave && (
								<>
									<TouchableOpacity
										onPress={() => dispatch(setCheckoutState({ saveCard: !saveCard }))}
										style={{
											flexDirection: 'row',
											alignItems: 'center',
											marginTop: Platform.OS === 'web' ? 20 : 0,
										}}
									>
										<MaterialCommunityIcons
											name={saveCard ? 'checkbox-marked-circle' : 'checkbox-blank-circle-outline'}
											size={26}
											color={saveCard ? global.orgColor : '#818181'}
											style={{
												marginLeft: '2.5%',
											}}
										/>
										<Text
											style={{ flex: 1, marginLeft: 5, fontSize: smallTextSize, fontWeight: '700' }}
										>
											{'Save credit card for future payments.'}
										</Text>
									</TouchableOpacity>
									<Text
										style={{
											color: Colors.greyscale[8],
											fontSize: sTextSize,
											marginLeft: '2.5%',
											paddingLeft: 31, //icon size + margin
											display: saveCard ? 'flex' : 'none',
										}}
									>
										Your card details will be securely saved by{' '}
										{paymentProvider.slice(0, 1).toUpperCase() + paymentProvider.slice(1)}.
									</Text>
								</>
							)}
						</View>
					</View>
					<View style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
						<ButtonContainer
							allowSave={allowSave}
							stripeId={
								stripeId && stripeId !== ''
									? stripeId
									: userData && userData.user && userData.user.stripeId
										? userData.user.stripeId
										: ''
							}
							toggleModal={toggleModal}
							closeModal={closeModal}
						/>
					</View>
				</View>
			</View>
		</>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	titleSection: {
		padding: 20,
		alignItems: 'center',
	},
	buttonContainer: {
		flexDirection: 'column',
		width: '100%',
		alignItems: 'center',
	},
	button: {
		minHeight: 45,
		width: '80%',
	},
	cancel: {
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 20,
	},
	cancelText: {
		color: 'black',
		fontWeight: '300',
		fontSize: mediumTextSize,
	},
})
