import '../../../global'
import { useEffect, useState } from 'react'
import { formatMenuItems } from '../getMenuItemData'
import { DATABASE_NAME } from '@env'
import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setDataState } from '../../../state/Slices/dataSlice'

const useMenuItemListener = () => {
	const { loadedData } = useAppSelector(state => state.data)

	const [itemData] = useState({})
	const dispatch = useAppDispatch()

	useEffect(() => {
		const menuItemRef = collection(doc(db, DATABASE_NAME, global.org), 'MenuItems')
		const unsubscribe = onSnapshot(menuItemRef, snapshot => {
			snapshot.docChanges().forEach(change => {
				const data = change.doc.data()
				if (!data || data.isDeleted) return

				if (change.type === 'modified') {
					const itemReturn = formatMenuItems(data)
					console.log(itemReturn.name)
					if (itemReturn.thumbnailUri === '' && itemReturn.imageUri !== '') {
						itemReturn.thumbnailUri = itemReturn.imageUri
					}

					itemData[data.id] = itemReturn
					const updatedData = _.merge({}, loadedData, itemData)
					dispatch(
						setDataState({
							loadedData: updatedData,
						})
					)
					//rerender screen
					// if (Object.keys(itemData).length > 0 && !_.isEqual(updatedData, loadedData)) {
					// 	dispatch(setDataState({ updateData: [] }))
					// }
				}
			})
		})

		// Clean up function
		return () => {
			unsubscribe()
		}
	}, [])
}

export default useMenuItemListener
