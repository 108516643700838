import React from 'react'
import { StyleSheet, TouchableOpacity, Platform } from 'react-native'
import { Text } from '../Themed'
import { SafeAreaView } from 'react-native-safe-area-context'
import { mediumTextSize, mlTextSize } from '../../constants/GlobalStyle'
import { useAppSelector } from '../../state/hooks'

const formatTime = date => {
	const options = { hour: 'numeric', minute: 'numeric' }
	const timeString = date.toLocaleTimeString([], options)
	return `${timeString}`
}

const firestoreTimestampToDate = timestamp => {
	return new Date(timestamp)
}

export const convertDoordashStatus = status => {
	switch (status) {
		case 'created':
			return 'Being prepared'
		case 'DASHER_CONFIRMED':
			return 'Being prepared'
		case 'DASHER_CONFIRMED_PICKUP_ARRIVAL':
			return 'Driver is waiting at restaurant'
		case 'DASHER_PICKED_UP':
			return 'Driver picked up order'
		case 'DASHER_CONFIRMED_DROPOFF_ARRIVAL':
			return 'Driver has arrived'
		case 'DASHER_DROPPED_OFF':
			return 'Order was delivered'
		case 'DELIVERY_CANCELLED':
			return 'Cancelled'
		case 'DASHER_ENROUTE_TO_DROPOFF':
			return 'Driver is on the way to you'
		case 'DASHER_ENROUTE_TO_PICKUP':
			return 'Driver is going to restaurant'
		default:
			return status
	}
}

const OrderBanner = ({ navigation }) => {
	const { loadedData, activeOrder } = useAppSelector(state => state.data)
	const { deliveryEnabled } = useAppSelector(state => state.settings)

	if (!activeOrder || !loadedData[global.org]) {
		return null
	}

	const readyTime =
		activeOrder &&
		activeOrder.channelData &&
		activeOrder.channelData.doordash &&
		activeOrder.channelData.doordash.dropoffTimeEstimated
			? formatTime(
					firestoreTimestampToDate(activeOrder.channelData.doordash.dropoffTimeEstimated)
				) + ' estimated arrival'
			: activeOrder && activeOrder.pickupDate
				? formatTime(firestoreTimestampToDate(activeOrder.pickupDate)) + ' estimated pickup time'
				: ''
	const orgName = loadedData[global.org].organizationName
	const status =
		activeOrder &&
		activeOrder.channelData &&
		activeOrder.channelData.doordash &&
		activeOrder.channelData.doordash.deliveryStatus
			? convertDoordashStatus(activeOrder.channelData.doordash.deliveryStatus)
			: activeOrder && activeOrder.status
				? activeOrder.status
				: ''
	if (
		activeOrder &&
		activeOrder.channelData &&
		activeOrder.channelData.doordash &&
		activeOrder.channelData.doordash.deliveryStatus !== 'DASHER_DROPPED_OFF' &&
		activeOrder.channelData.doordash.deliveryStatus !== 'DELIVERY_CANCELLED' &&
		deliveryEnabled
	) {
		return (
			<SafeAreaView
				edges={['left', 'right', 'top']}
				style={[styles.container, { backgroundColor: global.orgColor }]}
			>
				<TouchableOpacity
					style={[styles.container, { justifyContent: 'center', alignItems: 'center' }]}
					onPress={() => {
						navigation.navigate('TabOrders')
					}}
				>
					<Text style={styles.estimatedArrivalText}>{`${readyTime}`}</Text>
					<Text style={styles.preparingText}>{`${status} • ${orgName}`}</Text>
				</TouchableOpacity>
			</SafeAreaView>
		)
	} else {
		return null
	}
}

const styles = StyleSheet.create({
	container: {
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: Platform.OS === 'web' ? 5 : 0,
		paddingBottom: 10,
	},
	estimatedArrivalText: {
		color: '#FFFFFF',
		fontSize: mlTextSize,
		fontWeight: 'bold',
	},
	preparingText: {
		color: '#FFFFFF',
		fontSize: mediumTextSize,
	},
})

export default OrderBanner
