import React from 'react'
import { View, Dimensions } from 'react-native'
import { styles } from '../../Helpers/style'
import Button from '../../../../components/Button'
import { MobileCardElement } from '../../../../App'
import { AddTip } from '../AddTip/AddTip'
import { receiptText } from '../AddTip/TipModalFunctions'
import Colors from '../../../../constants/Colors'
import { AddPromo } from '../AddPromo/AddPromo'
import { CashPayment } from './CashPayment'
import { FontAwesome } from '@expo/vector-icons'
import ThirdPartyPaymentList from '../../Components/ThirdPartyPaymentList.pos'
import GlobalStyle from '../../../../constants/GlobalStyle'
import CheckoutNavFooter from '../../../../components/Footers/CheckoutNavFooter'
import { Text } from '../../../../components/Themed'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { setCheckoutState } from '../../../../state/Slices/checkoutSlice'

/**
 * POS screen only - allows different payment methods other than credit/debit
 */
export function SelectPOSMethod({ toggleConfirmModal, tipData, promoData, navigation }) {
	const { typePOS, showTips, prices } = useAppSelector(state => state.checkout)
	const { loadedData } = useAppSelector(state => state.data)

	promoData.isStaffMeal = true

	const dispatch = useAppDispatch()

	const setIsCardValid = bool => {
		dispatch(setCheckoutState({ isCardValid: bool }))
	}

	const setPOSData = type => {
		dispatch(
			setCheckoutState({
				posExtraData: {
					name: '',
					firebaseId: '',
					note: '',
					type: type,
				},
			})
		)
	}

	const handleManualCCPress = () => {
		dispatch(setCheckoutState({ typePOS: 'manual', showTips: false }))
		setPOSData('manual')
	}

	const handlePinpadPress = () => {
		dispatch(setCheckoutState({ typePOS: 'pinpad', showPOSMethods: false }))
		setPOSData('pinpad')
	}

	const handleCashPress = () => {
		dispatch(setCheckoutState({ typePOS: 'cash' }))
		setPOSData('cash')
	}

	const handleThirdPartyPress = () => {
		dispatch(setCheckoutState({ typePOS: 'external' }))
	}

	const handleStaffMealPress = () => {
		dispatch(setCheckoutState({ typePOS: 'staffMeal' }))
		setPOSData('staffMeal')
	}

	const handleContinuePress = () => {
		dispatch(setCheckoutState({ showTips: true, useNewPaymentMethod: true }))
	}

	const hideButtons = ['manual', 'staffMeal', 'cash', 'external']

	const icon = () => {
		return (
			<FontAwesome
				name="dollar"
				size={global.kiosk ? 35 : 20}
				color={'white'}
				style={{ alignSelf: 'center', justifyContent: 'center' }}
			/>
		)
	}

	function BottomRowButtons() {
		return (
			<CheckoutNavFooter
				onBackPress={() => {
					dispatch(setCheckoutState({ typePOS: '', showPOSMethods: true }))
					setPOSData('')
				}}
				onNextPress={() => {
					dispatch(
						setCheckoutState({
							showPOSMethods: false,
							phoneNumberInput: true,
							showPhoneNumber: false,
						})
					)
				}}
				style={{ marginBottom: '10%' }}
			/>
		)
	}

	return (
		<>
			<View style={styles.container}>
				<View
					style={[
						{
							display: hideButtons.includes(typePOS) ? 'none' : 'flex',
							marginHorizontal: 30,
						},
					]}
				>
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Text
							style={[styles.buttonText, { color: Colors.custom.darkGrey, fontWeight: 'bold' }]}
						>
							TOTAL DUE:
						</Text>
						<Text
							style={[styles.buttonText, { color: Colors.custom.darkGrey, fontWeight: 'bold' }]}
						>
							{'$' + prices.total.toFixed(2)}
						</Text>
					</View>
					<Text
						style={[
							styles.buttonText,
							{
								color: Colors.custom.darkGrey,
								fontWeight: 'bold',
								textAlign: 'center',
								paddingTop: 40,
								paddingBottom: 20,
							},
						]}
					>
						SELECT A PAYMENT METHOD:
					</Text>
					<Button
						title="SEND TO PINPAD"
						onPress={handlePinpadPress}
						buttonStyle={[
							GlobalStyle.button,
							{ backgroundColor: global.orgColor, borderColor: global.orgColor, marginBottom: 30 },
						]}
						textStyle={[styles.buttonText, { color: 'white' }]}
						isLoading={false}
						icon={undefined}
					/>
					<Button
						title="ENTER CC MANUALLY"
						onPress={handleManualCCPress}
						buttonStyle={[
							GlobalStyle.button,
							{ backgroundColor: '#818181', borderColor: '#818181', marginBottom: 30 },
						]}
						textStyle={[styles.buttonText, { color: 'white' }]}
						isLoading={false}
						icon={undefined}
					/>
					<Button
						title="THIRD PARTY PAYMENT"
						onPress={handleThirdPartyPress}
						buttonStyle={[
							GlobalStyle.button,
							{ backgroundColor: '#818181', borderColor: '#818181', marginBottom: 30 },
						]}
						textStyle={[styles.buttonText, { color: 'white' }]}
						isLoading={false}
						icon={undefined}
					/>
					<Button
						title="STAFF MEAL"
						onPress={handleStaffMealPress}
						buttonStyle={[
							GlobalStyle.button,
							{ backgroundColor: '#818181', borderColor: '#818181', marginBottom: 30 },
						]}
						textStyle={[styles.buttonText, { color: 'white' }]}
						isLoading={false}
						icon={undefined}
					/>

					<Button
						title="CASH / CHEQUE"
						onPress={handleCashPress}
						buttonStyle={[
							GlobalStyle.button,
							{ backgroundColor: '#818181', borderColor: '#818181', marginBottom: 50 },
						]}
						textStyle={[styles.buttonText, { color: 'white' }]}
						isLoading={false}
						icon={undefined}
					/>
				</View>

				{typePOS === 'manual' ? (
					!showTips ? (
						<View
							style={{
								width: '100%',
								flex: 1,
								justifyContent: 'space-between',
								marginBottom: 30,
								paddingHorizontal: 30,
							}}
						>
							<Text
								style={[
									GlobalStyle.headerText,
									{
										textAlign: 'center',
										marginVertical: 50,
										flexGrow: 0.1,
									},
								]}
							>
								ENTER A CREDIT CARD NUMBER
							</Text>
							<MobileCardElement setIsCardValid={setIsCardValid} />

							<View style={{ flex: 1 }}></View>
							<CheckoutNavFooter
								onBackPress={() => {
									dispatch(setCheckoutState({ showPOSMethods: true, typePOS: '' }))
								}}
								onNextPress={handleContinuePress}
								style={{ marginBottom: '5%' }}
							/>
						</View>
					) : (
						<View
							style={{
								width: '100%',
								flex: 1,
								justifyContent: 'space-between',
							}}
						>
							<AddTip prices={prices} tipFunctions={tipData} isPOS={true} />
							<CheckoutNavFooter
								onBackPress={handleManualCCPress}
								onNextPress={() => {
									dispatch(
										setCheckoutState({
											showPOSMethods: false,
											phoneNumberInput: true,
											showPhoneNumber: false,
										})
									)
								}}
								style={{ marginBottom: 30 }}
							/>
						</View>
					)
				) : (
					<></>
				)}

				{typePOS === 'external' ? (
					<>
						<Text
							style={[
								GlobalStyle.headerText,
								{
									textAlign: 'center',
									marginVertical: 20,
									flexGrow: 0.1,
								},
							]}
						>
							SELECT A 3RD PARTY BRAND
						</Text>
						<ThirdPartyPaymentList brands={loadedData['thirdPartyTypes']} />
						<BottomRowButtons />

						<View style={{ paddingBottom: 30 }}></View>
					</>
				) : (
					<></>
				)}

				{typePOS === 'staffMeal' ? (
					<View
						style={{
							width: Dimensions.get('window').width * 0.7,
							flex: 1,
							justifyContent: 'space-between',
						}}
					>
						<View style={{ flex: 1, justifyContent: 'center' }}>
							<View style={{ marginHorizontal: 30, marginBottom: 50 }}>
								<AddPromo promoFuncData={promoData} staffMeal={true} />
							</View>
							{receiptText('TOTAL', prices.total.toFixed(2), icon())}
						</View>
						<View style={{ marginHorizontal: 20 }}>
							<BottomRowButtons />
						</View>
					</View>
				) : (
					<></>
				)}

				{typePOS === 'cash' ? (
					<>
						<CashPayment />
						<BottomRowButtons />
					</>
				) : (
					<></>
				)}
			</View>
		</>
	)
}
