import React, { useState, useRef, useEffect } from 'react'
import { TouchableOpacity, StyleSheet, View, Text } from 'react-native'
import { getOrgData } from '../../firebase/fbDataRetrieval/getOrgData'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { setDataState } from '../../state/Slices/dataSlice'
import { setPersistedState } from '../../state/Slices/persistedSlice'

// Allow user to toggle test mode by tapping 10 times in quick succession
const TapForTestMode = () => {
	const { isTestMode } = useAppSelector(state => state.persisted)
	const dispatch = useAppDispatch()
	const [tapCount, setTapCount] = useState(0)
	const timeoutRef = useRef<any>()

	const handleTap = async () => {
		// Increment the tap count
		setTapCount(prevCount => prevCount + 1)
		const orgData: any = await getOrgData()

		// If 10 taps detected in quick succession
		if (tapCount + 1 === 10) {
			console.log(`changing isTestmode from ${isTestMode} to ${!isTestMode}`)
			dispatch(setPersistedState({ isTestMode: !isTestMode }))
			setTapCount(0)
			clearTimeout(timeoutRef.current)
			dispatch(
				setDataState({
					stripePublishableKey: isTestMode
						? orgData.stripe.publishableKey.sandbox
						: orgData.stripe.publishableKey.production,
				})
			)
		} else {
			// If user doesn't tap 10 times within a span of 3.5 seconds, reset tap count
			clearTimeout(timeoutRef.current)
			timeoutRef.current = setTimeout(() => {
				setTapCount(0)
			}, 3500) // You can adjust this delay as needed
		}
	}

	// Clean up timer on unmount
	useEffect(() => {
		return () => {
			clearTimeout(timeoutRef.current)
		}
	}, [])

	return (
		<View>
			<TouchableOpacity style={styles.hiddenArea} onPress={handleTap} activeOpacity={1} />
		</View>
	)
}

const styles = StyleSheet.create({
	hiddenArea: {
		zIndex: 99999,
		position: 'absolute',
		top: -150,
		right: -50,
		width: 100,
		height: 100,
		backgroundColor: 'transparent',
	},
})

export default TapForTestMode
