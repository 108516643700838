import * as React from 'react'
import { TouchableOpacity, View, StyleSheet } from 'react-native'
import { PriceMask } from '../../../../components/TextInput/TextInputMask'
import GlobalStyle, {
	screenValue,
	mediumTextSize,
	largeTextSize,
} from '../../../../constants/GlobalStyle'
import Colors from '../../../../constants/Colors'
import { ForwardButton } from '../../../../components/Headers/UIButtons'
import { Text } from '../../../../components/Themed'
import { useAppSelector } from '../../../../state/hooks'

export function receiptText(name, price, icon) {
	return (
		<>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'center',
					marginBottom: '3%',
				}}
			>
				<View
					style={{
						flex: 0.125,
						justifyContent: 'center',
					}}
				>
					<View
						style={{
							backgroundColor: global.orgColor ? global.orgColor : Colors.custom.restaurantMain,
							borderRadius: 30,
							width: global.kiosk ? 40 : 30,
							height: global.kiosk ? 40 : 30,
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{icon}
					</View>
				</View>

				<View style={{ flex: 0.4, justifyContent: 'center' }}>
					<Text style={GlobalStyle.boldedText}>{name}</Text>
				</View>

				<View
					style={{
						flex: 0.3,
						marginLeft: 15,
						justifyContent: 'center',
						alignItems: 'flex-end',
					}}
				>
					<Text
						style={[
							GlobalStyle.boldedText,
							Math.sign(parseFloat(price)) === -1 &&
								name === 'CHANGE' && { color: 'red', fontSize: largeTextSize },
						]}
					>
						{Math.sign(parseFloat(price)) === -1 && name === 'CHANGE'
							? `NOT ENOUGH`
							: `$${parseFloat(price).toFixed(2)}`}
					</Text>
				</View>
			</View>
		</>
	)
}

//button to select tip percent
function tipSelectButton(percent, prices, tipFunc) {
	const { selectedTip } = useAppSelector(state => state.checkout)
	const isSelected = selectedTip === percent
	var name = percent === 0 ? 'No Tip' : percent + '%'
	var amount =
		percent === 0
			? '$0.00'
			: '$' + ((prices.subTotal + prices.discount) * (percent / 100)).toFixed(2)

	return (
		<TouchableOpacity
			onPress={tipFunc}
			style={[
				styles.button,
				{ shadowColor: global.orgColor, paddingHorizontal: global.kiosk ? 30 : 15 },
				isSelected && { ...styles.selected, borderColor: global.orgColor },
			]}
		>
			<Text
				style={[
					styles.buttonText,
					isSelected && { color: global.orgColor },
					{
						textAlign: 'left',
					},
				]}
			>
				{name}
			</Text>

			<Text
				style={[
					styles.buttonText,
					{
						textAlign: 'right',
						color: global.orgColor ? global.orgColor : Colors.custom.restaurantMain,
					},
				]}
			>
				{amount}
			</Text>
		</TouchableOpacity>
	)
}

//custom tip button
function tipCustom(setCustomTip, prices) {
	const { selectedTip } = useAppSelector(state => state.checkout)
	const isSelected = selectedTip === 9999
	return (
		<View
			style={[
				styles.button,
				{ shadowColor: global.orgColor, paddingHorizontal: global.kiosk ? 30 : 15 },
				isSelected && { ...styles.selected, borderColor: global.orgColor },
			]}
		>
			<Text
				style={[
					styles.buttonText,
					isSelected && { color: global.orgColor },
					{
						textAlign: 'left',
					},
				]}
			>
				{'Custom'}
			</Text>

			<PriceMask prices={prices} setCustomTip={setCustomTip} />
		</View>
	)
}

//The buttons to select a tip, either 0, 5%, 10%, 15%, or a custom amount
export function tipAmountButtons(prices, tipFuncData) {
	return (
		<View style={styles.buttonContainer}>
			<View style={styles.buttonRow}>
				{global.tipLarge
					? tipSelectButton(20, prices, tipFuncData.tip20)
					: tipSelectButton(0, prices, tipFuncData.tip0)}
				{global.tipLarge
					? tipSelectButton(18, prices, tipFuncData.tip18)
					: tipSelectButton(5, prices, tipFuncData.tip5)}
			</View>
			<View style={styles.buttonRow}>
				{global.tipLarge
					? tipSelectButton(15, prices, tipFuncData.tip15)
					: tipSelectButton(10, prices, tipFuncData.tip10)}

				{global.tipLarge
					? tipSelectButton(0, prices, tipFuncData.tip0)
					: tipSelectButton(15, prices, tipFuncData.tip15)}
			</View>
			<View style={styles.buttonRow}>{tipCustom(tipFuncData.setCustomTip, prices)}</View>
		</View>
	)
}

export function continueButtonKiosk(setPhoneNumberInput, setShowPhoneNumber, setShowPOSMethods?) {
	return (
		<View
			style={{
				flexDirection: 'row',
				marginHorizontal: 25,
				marginBottom: 25,
			}}
		>
			<View style={{ flex: 1 }}></View>
			<ForwardButton
				onPress={() => {
					if (setShowPOSMethods !== undefined) {
						setShowPOSMethods(false)
					}
					setPhoneNumberInput(true)
					setShowPhoneNumber(false)
				}}
				color={global.orgColor ? global.orgColor : Colors.custom.restaurantMain}
				title={'NEXT'}
				style={{ flex: 1 }}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	buttonContainer: {
		//flex: 1,
		justifyContent: 'space-between',
		width: '90%',
		minHeight: 200,
		height: '30%',
		maxHeight: 300,
	},
	buttonText: {
		color: 'black',
		fontSize: mediumTextSize,
		flex: 1,
		fontWeight: '500',
	},
	buttonRow: {
		flexDirection: 'row',
		height: '30%',
		columnGap: screenValue(20),
	},
	button: {
		flex: 1,
		borderWidth: 1,
		shadowRadius: 8,
		shadowOffset: { height: 3, width: 0 },
		borderRadius: 15,
		flexDirection: 'row',
		backgroundColor: 'white',
		alignItems: 'center',
		elevation: 0,
		shadowOpacity: 0,
		borderColor: Colors.greyscale[5],
	},
	selected: {
		elevation: 3,
		shadowOpacity: 0.15,
	},
})
