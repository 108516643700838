import React from 'react'
import { View, TouchableOpacity, Platform } from 'react-native'
import Triangle from './Triangle'
import Colors from '../../constants/Colors'
import { AntDesign } from '@expo/vector-icons'
import { headerTextSize } from '../../constants/GlobalStyle'
import { WebHeader } from './WebHeader'
import { Text } from '../Themed'

export const TriangleHeader = ({
	title,
	backFunction,
	logo = null,
	navigation = null,
	background = null,
}) => {
	if (Platform.OS === 'web') {
		return <WebHeader logo={logo} navigation={navigation} />
	}
	return (
		<>
			<View
				style={{
					height: 160,
					backgroundColor: global.orgColor ? global.orgColor : Colors.custom.restaurantMain,
					flexDirection: 'row',
					alignItems: 'center',
				}}
			>
				{backFunction !== null ? (
					<TouchableOpacity
						style={{ paddingLeft: 20, paddingRight: 10, marginTop: -5 }}
						onPress={backFunction}
					>
						<AntDesign
							name="left"
							size={headerTextSize + 5}
							color={Colors.custom.restaurantMainText}
						/>
					</TouchableOpacity>
				) : (
					<View style={{ paddingLeft: 20, paddingRight: 10 }} />
				)}

				<Text
					style={{
						fontSize: headerTextSize,
						color: Colors.custom.restaurantMainText,
						marginTop: -10,
					}}
				>
					{title}
				</Text>
			</View>
			<View style={{ backgroundColor: background ? background : Colors.custom.appBackground }}>
				<Triangle background={background} />
			</View>
		</>
	)
}
