import { useEffect } from 'react'
import * as Location from 'expo-location'
import { Platform } from 'react-native'
import Geocoder from 'react-native-geocoding'
import { GOOGLE_API_KEY } from '@env'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { setUserState } from '../../state/Slices/userSlice'
import { setDataState } from '../../state/Slices/dataSlice'

export const getDeliveryEstimate = async (
	customerAddress,
	pickupTime,
	activeRID,
	deliveryIsSandbox,
	rIdList = null
) => {
	const functions = getFunctions()
	const getDeliveryEstimate = httpsCallable(functions, 'getDoorDashDeliveryQuote')
	if (rIdList) {
		const deliveryEstimate = await getDeliveryEstimate({
			customerAddress: customerAddress,
			pickupTime: pickupTime,
			orgId: global.org,
			isSandbox: deliveryIsSandbox,
			restaurantIdList: rIdList,
		})
		return deliveryEstimate.data
	} else {
		const deliveryEstimate = await getDeliveryEstimate({
			customerAddress: customerAddress,
			pickupTime: pickupTime,
			orgId: global.org,
			restaurantId: activeRID,
			isSandbox: deliveryIsSandbox,
		})
		return deliveryEstimate.data
	}
}

export default function RetrieveLocation() {
	const { restaurantList } = useAppSelector(state => state.data)
	const { userLocation } = useAppSelector(state => state.user)
	const { isDelivery } = useAppSelector(state => state.persisted)
	const { isSingleBrand, deliveryEnabled } = useAppSelector(state => state.settings)
	const dispatch = useAppDispatch()

	const getAddressFromCoordinates = async (latitude, longitude) => {
		try {
			const response = await Geocoder.from(latitude, longitude)
			const addressComponents = response.results[0].address_components

			let streetAddress = ''
			let city = ''
			let province = ''
			let postalCode = ''

			addressComponents.forEach(component => {
				if (component.types.includes('route')) {
					streetAddress += component.long_name
				}
				if (component.types.includes('street_number')) {
					streetAddress = component.long_name + ' ' + streetAddress
				}
				if (component.types.includes('locality')) {
					city = component.long_name
				}
				if (component.types.includes('administrative_area_level_1')) {
					province = component.long_name
				}
				if (component.types.includes('postal_code')) {
					postalCode = component.long_name
				}
			})

			return {
				shortAddress: streetAddress === '' ? response.results[0].formatted_address : streetAddress,
				longAddress: `${streetAddress}, ${city}, ${province}, ${postalCode}`,
				addressObject: {
					streetAddress: streetAddress,
					streetAddress2: '',
					city: city,
					province: province,
					postalCode: postalCode,
				},
			}
		} catch (error) {
			console.error(error)
			return null
		}
	}

	useEffect(() => {
		Geocoder.init(GOOGLE_API_KEY, { language: 'en' })
	})

	useEffect(() => {
		;(async () => {
			if ((isSingleBrand && restaurantList.length > 1) || (isDelivery && deliveryEnabled)) {
				if (Platform.OS !== 'web') {
					const { status } = await Location.requestForegroundPermissionsAsync()
					if (status !== 'granted') {
						dispatch(setUserState({ userLocation: { error: 'LOCATION_PERMISSION_DENIED' } }))

						return
					}
				}
				const location = await Location.getCurrentPositionAsync({})
				const userAddress = await getAddressFromCoordinates(
					location.coords.latitude,
					location.coords.longitude
				)
				const locationObj: any = {
					coords: location.coords,
					formattedAddress: userAddress.shortAddress,
					longAddress: userAddress.longAddress,
					addressObject: userAddress.addressObject,
				}
				dispatch(setDataState({ userCurrentLocation: locationObj }))
			}
		})()
	}, [])

	return null
}
