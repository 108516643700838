import * as React from 'react'
import { ScrollView, Animated, Dimensions, Platform } from 'react-native'
import { View } from '../../components/Themed'
import { useEffect, useState } from 'react'
import { RootTabScreenProps } from '../../navigation/types'
import RetrieveData from '../../firebase/fbDataRetrieval/RetrieveData'
import useRestaurantListener from '../../firebase/fbDataRetrieval/ListenerHooks/useRestaurantListener'
import TapToLogout from '../../components/Kiosk/TapToLogout'
import KioskStartScreen from './Components/KioskStartScreen'
import { OrgLogoHeader } from '../../components/Headers/OrgLogoHeader'
import { BrandList } from './Components/BrandList'
import { Categories } from './Components/Categories'
import NavFooter from '../../components/Footers/NavFooter'
import { WebHeader } from '../../components/Headers/WebHeader'
import CopyrightFooter from '../../components/Footers/CopyrightFooter'
import { LoyaltyHeader } from './Components/LoyaltyHeader'
import useUserListener from '../../firebase/fbDataRetrieval/ListenerHooks/useUserListener'
import POSNavBar from '../../navigation/POSNavBar'
import { PickupPointModal } from '../../components/PickupPoint'
import { smallTextSize } from '../../constants/GlobalStyle'
import CuisineSelector from './Components/CuisineSelector'
import OrderBanner from '../../components/Delivery/OrderBanner'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { setDataState } from '../../state/Slices/dataSlice'

export default function RestaurantSelectScreen({ navigation }: RootTabScreenProps<any>) {
	const { loadedData, organizationLogo, dataIsDoneLoading } = useAppSelector(state => state.data)
	const { homeLayout, pickupPointsEnabled } = useAppSelector(state => state.settings)
	const { selectedPickupPoint } = useAppSelector(state => state.persisted)

	const [showKioskStart, setShowKioskStart] = useState(false)

	const [pickupPointModalVisible, setPickupPointModalVisible] = useState(
		selectedPickupPoint === null
	)
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (dataIsDoneLoading && loadedData[global.org].singleBrandEnabled && global.kiosk) {
			navigation.navigate('HomeStack', {
				screen: 'Menu',
				params: { rId: loadedData['defaultRestaurant'].id },
			})
		}
	}, [dataIsDoneLoading])

	//listener for restaurant snooze, hours update
	useUserListener()
	useRestaurantListener()
	function renderPage() {
		if (loadedData[global.org].singleBrandEnabled) {
			navigation.navigate('HomeStack', {
				screen: 'Menu',
				params: { rId: loadedData['defaultRestaurant'].id },
			})

			return <></>
		}
		return (
			<>
				{Platform.OS === 'web' && <WebHeader logo={organizationLogo} navigation={navigation} />}
				{pickupPointsEnabled && !global.kiosk && (
					<PickupPointModal
						visible={pickupPointModalVisible}
						setVisible={setPickupPointModalVisible}
					/>
				)}
				<OrderBanner navigation={navigation} />
				<ScrollView
					contentContainerStyle={[
						{ justifyContent: 'space-between' },
						Platform.OS === 'web' && { flexGrow: 1 },
					]}
				>
					{Platform.OS !== 'web' && (
						<OrgLogoHeader
							logo={organizationLogo}
							padding={homeLayout === 'restaurants' ? smallTextSize * 2 : 50}
						/>
					)}

					{homeLayout === 'restaurants' ? (
						<CuisineSelector navigation={navigation} />
					) : (
						<>
							{loadedData[global.org].loyaltyEnabled && !global.kiosk && (
								<LoyaltyHeader navigation={navigation} />
							)}
							<Categories navigation={navigation} />
							<BrandList navigation={navigation} />
						</>
					)}

					<View
						style={{
							height:
								homeLayout === 'restaurants' && Platform.OS === 'web'
									? 0
									: Dimensions.get('window').height * 0.12,
						}}
					></View>
					{Platform.OS === 'web' && homeLayout !== 'restaurants' && <CopyrightFooter />}
				</ScrollView>
				{global.kiosk && !global.pos ? (
					<NavFooter navigation={navigation} checkoutBar={true} />
				) : null}
				{global.pos ? <POSNavBar navigation={navigation} screenName={'HomeStack'} /> : null}
			</>
		)
	}
	if (global.kiosk && global.org !== undefined && !dataIsDoneLoading) {
		const setDataIsDoneLoading = value => {
			dispatch(setDataState({ dataIsDoneLoading: value }))
		}
		return (
			<>
				<RetrieveData referralCode={''} loadingTracker={setDataIsDoneLoading}>
					<TapToLogout navigation={navigation} />
					{dataIsDoneLoading ? (
						<Animated.View style={{ flex: 1 }}>
							{showKioskStart && !global.pos ? (
								<KioskStartScreen
									defaultLogo={organizationLogo}
									imageList={loadedData.promoImages ? loadedData.promoImages : []}
									setVisible={setShowKioskStart}
								/>
							) : (
								renderPage()
							)}
						</Animated.View>
					) : (
						<></>
					)}
				</RetrieveData>
			</>
		)
	} else {
		return (
			<>
				{global.kiosk && <TapToLogout navigation={navigation} />}
				{renderPage()}
			</>
		)
	}
}
