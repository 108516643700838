import React, { useEffect } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { largeTextSize, xsTextSize } from '../../../constants/GlobalStyle'
import Colors from '../../../constants/Colors'
import { Text } from '../../../components/Themed'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'

const BagFeeButton = ({ cost, priceSet, couponCode }) => {
	const { bagFeeSelected } = useAppSelector(state => state.checkout)
	const dispatch = useAppDispatch()

	useEffect(() => {
		priceSet(couponCode)
	}, [bagFeeSelected])

	return (
		<TouchableOpacity
			style={styles.bagFeeButton}
			onPress={() => {
				dispatch(setCheckoutState({ bagFeeSelected: !bagFeeSelected }))
			}}
		>
			<MaterialCommunityIcons
				name={bagFeeSelected ? 'checkbox-marked' : 'checkbox-blank-outline'}
				size={largeTextSize}
				color={bagFeeSelected ? '#e1191a' : Colors.greyscale[4]}
			/>
			<Text style={styles.bagFeeText}>{`Include a bag for $${cost}`}</Text>
		</TouchableOpacity>
	)
}

const styles = StyleSheet.create({
	bagFeeButton: {
		marginLeft: '25%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: 8,
		paddingVertical: 8,
		paddingHorizontal: 12, // Adjust as needed
		borderWidth: 1,
		borderColor: 'white',
		marginBottom: 20,
		backgroundColor: Colors.greyscale[9],
	},
	bagFeeText: {
		color: 'white',
		fontSize: xsTextSize,
		fontWeight: '300',
		marginLeft: 5,
	},
})

export default BagFeeButton
