import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import AsyncStorage from '@react-native-async-storage/async-storage'

import menuReducer from './Slices/menuSlice'
import itemReducer from './Slices/itemSlice'
import dataReducer from './Slices/dataSlice'
import settingsReducer from './Slices/settingsSlice'
import persistedReducer from './Slices/persistedSlice'
import checkoutReducer from './Slices/checkoutSlice'
import userReducer from './Slices/userSlice'

const rootReducer = combineReducers({
	menu: menuReducer,
	item: itemReducer,
	data: dataReducer,
	settings: settingsReducer,
	persisted: persistedReducer,
	checkout: checkoutReducer,
	user: userReducer,
})

const persistConfig = {
	key: 'root',
	storage: AsyncStorage,
	whitelist: ['persisted', 'user'],
}

const persistedRootReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
	reducer: persistedRootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
	devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
