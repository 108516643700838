import React from 'react'
import {
	View,
	TextInput,
	Keyboard,
	StyleSheet,
	TouchableOpacity,
	KeyboardAvoidingView,
	Platform,
} from 'react-native'
import { largeTextSize, mediumTextSize } from '../../../constants/GlobalStyle'
import { AntDesign } from '@expo/vector-icons'
import { Text } from '../../../components/Themed'
import { useAppDispatch } from '../../../state/hooks'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'

/**
 * Text input box for entering order notes on kiosk/pos only
 */
export function NotesInput({ orderNote, onClose }) {
	const dispatch = useAppDispatch()
	function setOrderNote(value) {
		dispatch(setCheckoutState({ orderNote: value }))
	}
	return (
		<KeyboardAvoidingView behavior={'padding'} style={styles.overlay}>
			<TouchableOpacity
				style={styles.overlayTouchable}
				activeOpacity={1}
				onPressOut={() => {
					onClose(orderNote)
					Keyboard.dismiss()
				}}
			/>
			<View style={styles.container}>
				<Text
					style={{
						fontSize: largeTextSize,
						fontWeight: '300',
						letterSpacing: 1,
						color: global.orgColor2,
					}}
				>
					ORDER NOTE
				</Text>

				<View style={styles.notesContainer}>
					<TextInput
						autoFocus={true}
						returnKeyType={'done'}
						style={styles.input}
						value={orderNote}
						multiline={true}
						numberOfLines={4}
						onChangeText={setOrderNote}
						placeholder={'Order note '}
						onSubmitEditing={() => {
							onClose(orderNote)
							Keyboard.dismiss()
						}}
					/>
					<TouchableOpacity
						onPress={() => {
							onClose(orderNote)
							Keyboard.dismiss()
						}}
						style={styles.closeButton}
					>
						<AntDesign name="right" size={largeTextSize} color="black" />
					</TouchableOpacity>
				</View>
			</View>
		</KeyboardAvoidingView>
	)
}

const styles = StyleSheet.create({
	overlay: {
		flex: 1,
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.7)',
		justifyContent: 'center',
	},
	overlayTouchable: {
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
	},
	container: {
		backgroundColor: 'white',
		height: Platform.OS === 'ios' ? 220 : 260,
		padding: '3%',
		marginHorizontal: '3%',
		borderRadius: 20,
	},
	notesContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	input: {
		flex: 1,
		borderWidth: 1,
		borderColor: 'grey',
		padding: 20,
		paddingTop: Platform.OS === 'ios' ? 20 : 0,
		height: 120,
		borderRadius: 5,
		fontSize: mediumTextSize,
	},
	closeButton: {
		padding: 10,
	},
})
