import { doc, onSnapshot } from 'firebase/firestore'
import { auth, db } from '../../../firebase/config'
import '../../../global'
import { useEffect } from 'react'
import { DATABASE_NAME } from '@env'
import { useAppDispatch } from '../../../state/hooks'
import { setUserState } from '../../../state/Slices/userSlice'
import { serializeUserTimestamps } from '../getUserData'

const useUserListener = () => {
	const dispatch = useAppDispatch()
	useEffect(() => {
		if (auth.currentUser !== null && auth.currentUser.uid) {
			const docRef = doc(db, DATABASE_NAME, global.org, 'Users', auth.currentUser.uid)

			const unsubscribe = onSnapshot(
				docRef,
				doc => {
					if (doc.exists()) {
						const userData = serializeUserTimestamps({ user: doc.data() })
						dispatch(
							setUserState({
								userData: userData,
							})
						)
					} else {
						console.log('Not signed in...')
					}
				},
				error => {
					console.log('Error getting document:', error)
				}
			)

			return () => {
				unsubscribe()
			}
		}
	}, [])
}

export default useUserListener
