import { StatusBar } from 'expo-status-bar'
import React, { useCallback, useState } from 'react'
import {
	View,
	TextInput,
	TouchableOpacity,
	ScrollView,
	KeyboardAvoidingView,
	Linking,
	Image,
	Platform,
} from 'react-native'
import { Text } from '../../components/Themed'
import { auth } from '../../firebase/config'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Formik } from 'formik'
import * as yup from 'yup'
import { OrganizationLogoAccountPage } from '../../components/OrganizationLogoHeader'
import { Spacer } from '../../components/Spacer'
import GlobalStyle, { formStyle } from '../../constants/GlobalStyle'
import registerUser, { User } from './registerUser'
import Button from '../../components/Button'
import { styles } from './style'
import VerificationInput from './VerificationInput'
import { alertResponse, alertResponseSingle } from '../../components/Alerts/Alerts'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import Formatter from '../../helpers/Formatter'
import Colors from '../../constants/Colors'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { setDataState } from '../../state/Slices/dataSlice'
import { setUserState } from '../../state/Slices/userSlice'
import parsePhoneNumberFromString from 'libphonenumber-js'

//Method for checking if valid Canadian number
yup.addMethod(yup.string, 'phone', function () {
	return this.test('phone', function (value) {
		const { path, createError } = this
		const cleanedValue = value?.replace(/\D/g, '')
		if (!cleanedValue || cleanedValue.length < 10) {
			return createError({ path, message: 'Phone number is required' })
		}

		// Validate phone number only if it has exactly 10 digits
		const phoneNumber = parsePhoneNumberFromString(value, 'CA')
		return phoneNumber && phoneNumber.isValid() && phoneNumber.country === 'CA'
			? true
			: createError({ path, message: 'Please enter a valid Canadian phone number' })
	})
})

// Yup schema for signup form validation
export const signupSchema = yup.object({
	email: yup.string().email('Invalid email').required('Email is required'),
	password: yup
		.string()
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_?!@#$%^&*])(?=.{8,})/,
			'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
		)
		.required('Password is required'),
	firstName: yup.string().required('First name is required'),
	lastName: yup.string().required('Last name is required'),
	phone: (yup.string() as any).phone().required('Phone number is required'),
})

type SignupScreenProps = {
	onStateChange: any
	onCheckout: boolean
	navigation: any
	kioskNum: any
	kioskPass: any
	kioskFunction: any
	isPOS: boolean
	returnTo?: string
	loyaltyId?: string
}

var signupFailure = false

/**
 * User sign up screen
 * Creates in firebase auth, adds user to 'Users' table, and creates customer in Square & Stripe
 */
export default function SignupScreen(props: SignupScreenProps) {
	const { onStateChange, onCheckout, navigation, kioskNum, kioskPass, kioskFunction, isPOS } = props

	const { loadedData, organizationLogo } = useAppSelector(state => state.data)
	const { referralCode, deviceHasInternet } = useAppSelector(state => state.user)
	const { paymentProvider } = useAppSelector(state => state.settings)

	const orgLogoDark = loadedData[global.org]?.organizationLogoDark
		? loadedData[global.org].organizationLogoDark
		: ''

	const referralEnabled: boolean =
		loadedData[global.org] && loadedData[global.org].referralEnabled !== undefined
			? loadedData[global.org].referralEnabled
			: false

	const [errorText, setErrorText] = useState('')
	const [showPhoneVerification, setShowPhoneVerification] = useState(false)
	const [phoneNumber, setPhoneNumber] = useState('')
	const [inputValues, setInputValues] = useState({
		email: '',
		password: '',
		firstName: '',
		lastName: '',
		phone: '',
		referralCode: referralCode,
	})
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useAppDispatch()

	const functions = getFunctions()

	const orgName = loadedData[global.org] ? loadedData[global.org].organizationName : ''

	var margin = 15

	const InviteBanner = () => {
		const firstName = referralCode !== undefined ? referralCode.split('-')[0] : null
		const str =
			firstName !== null ? firstName.charAt(0).toUpperCase() + firstName.slice(1) : 'A user'

		return (
			<View style={[styles.banner, { backgroundColor: global.orgColor }]}>
				<Text style={styles.bannerText}>{`${str} invited you to join ${orgName}!`}</Text>
			</View>
		)
	}

	function errorMsg() {
		if (signupFailure) {
			return <Text style={[formStyle.errorText, { marginTop: margin }]}>{errorText}</Text>
		}
	}

	const changeParent = useCallback(
		toLogin => {
			onStateChange(false)
			if (!toLogin) {
				if (onCheckout) {
					navigation.navigate('TabCheckout')
				} else {
					navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
				}
			}
		},
		[onStateChange]
	)
	async function addReferralCoupon(userId, referralCode) {
		const addReferral = httpsCallable(functions, 'addReferralCoupon')

		await addReferral({
			orgId: global.org,
			userId: userId,
			referralCode: referralCode.toLowerCase(),
		})
	}

	async function checkForUniquePhoneNumberAndEmail(phoneNumber: string, email: string) {
		const verifyUniquePhoneNumberAndEmail = httpsCallable(
			functions,
			'verifyUniquePhoneNumberAndEmail'
		)
		setIsLoading(true)
		const isUnique: { data: { uniqueEmail: boolean; uniquePhone: boolean; message: string } } =
			(await verifyUniquePhoneNumberAndEmail({
				phoneNumber: phoneNumber,
				orgId: global.org,
				email: email,
			})) as any

		const uniqueEmail = isUnique.data.uniqueEmail
		const uniquePhone = isUnique.data.uniquePhone
		const message = isUnique.data.message
		if ((uniqueEmail && uniquePhone) || (uniqueEmail && phoneNumber === '16472383618')) {
			//Send verification code
			alertResponse(
				'Verify your phone number to finish creating your account',
				`A verification code will be sent to ${Formatter.phoneNumber(phoneNumber)}`,
				'Send Code',
				Platform.OS === 'web' ? 'Change' : 'Enter different phone number',
				'default',
				'default',
				async () => {
					await sendVerificationCodeToPhoneNumber(phoneNumber)
				},
				() => {
					setIsLoading(false)
				}
			)
		} else if (!uniqueEmail && !uniquePhone) {
			setIsLoading(false)
			signupFailure = true
			setErrorText(message)
		} else if (!uniqueEmail) {
			setIsLoading(false)
			signupFailure = true
			setErrorText(message)
		} else if (!uniquePhone) {
			setIsLoading(false)
			signupFailure = true
			setErrorText(message)
		} else {
			setIsLoading(false)
			signupFailure = true
			setErrorText('Could not verify phone number.')
		}
	}

	async function sendVerificationCodeToPhoneNumber(phoneNumber: string) {
		console.log('SENDING VERIFICATION CODE TO ' + phoneNumber)
		setPhoneNumber(phoneNumber)
		setShowPhoneVerification(true)
		const sendVerificationCode = httpsCallable(functions, 'sendVerificationCode')
		const verificationStatus: any = await sendVerificationCode({
			phoneNumber: phoneNumber,
		})
		console.log('VERIFICATION STATUS: ' + verificationStatus.data.status)
	}

	function createAccount(
		email: string,
		password: string,
		firstName: string,
		lastName: string,
		phone: string,
		referralCode: string,
		setDisplay: any = null,
		setLoading: any = null
	) {
		// Generate email to save in auth
		// Replace @ symbol with %
		const generatedEmail = global.kiosk ? email : Formatter.encodedEmail(email)
		// Attempt to create user with the provided email and password
		createUserWithEmailAndPassword(auth, generatedEmail, password)
			.then(async () => {
				const uid = auth.currentUser ? auth.currentUser.uid : ''

				dispatch(setUserState({ userId: uid }))

				updateProfile(auth.currentUser, {
					displayName: global.kiosk
						? firstName + ' ' + lastName + '_' + global.org + '_' + global.readerId
						: firstName + ' ' + lastName,
				})

				const userData: User = {
					displayName: `${firstName} ${lastName}`,
					firstName: firstName,
					lastName: lastName,
					email: email.toLowerCase(),
					generatedEmail: generatedEmail,
					phone: '1' + phone,
					uid: uid,
					role: 'customer',
				}

				dispatch(setDataState({ referralCode: '' }))

				await registerUser(
					userData,
					paymentProvider,
					loadedData[global.org].organizationName,
					orgLogoDark ? orgLogoDark : loadedData[global.org].organizationLogo
				)

				await addReferralCoupon(uid, referralCode)
				changeParent(false)
			})
			.catch(error => {
				signupFailure = true
				console.log('ERROR ' + error)
				if (error.code === 'auth/email-already-in-use') {
					setIsLoading(false)
					if (setLoading !== null) {
						setLoading(false)
					}
					if (kioskFunction === undefined) {
						setErrorText('That email address is already in use!')
						alertResponseSingle('Email already in use!', '', 'Return to sign up', 'default', () => {
							if (setDisplay !== null) {
								setDisplay(false)
							}
						})
					} else {
						if (isPOS) {
							setErrorText('POS # already in use.')
						} else {
							setErrorText('Kiosk # already in use.')
						}
					}
				} else {
					setErrorText('Error signing up.')
					console.log(error)
				}
			})
	}

	const removePhoneNumberFormatting = value => {
		let val = value.replace(/[^\d]/g, '')
		if (val.length > 10) {
			val = val.slice(1)
		}
		return val
	}

	const formatPhoneNumber = inputString => {
		let numbers = inputString.replace(/\D/g, '') // Remove non-digits
		if (numbers.length > 10) {
			numbers = numbers.slice(1)
		}
		const char = { 0: '(', 3: ')-', 6: '-' }
		let phone = ''
		for (let i = 0; i < numbers.length; i++) {
			phone += (char[i] || '') + numbers[i]
		}
		return phone
	}

	if (isPOS) {
		return (
			<View>
				<Button
					buttonStyle={[GlobalStyle.addItemBtn, { alignSelf: 'center', paddingHorizontal: 25 }]}
					textStyle={GlobalStyle.addItemBtnText}
					title={'Register POS'}
					isLoading={false}
					icon={undefined}
					onPress={async () => {
						var email = 'pos' + kioskNum.trim() + '_' + global.org + '@nextgenkitchens.com'
						var firstName = 'POS'
						var lastName = kioskNum.trim()
						var phone = '0000000000'
						var password = kioskPass
						kioskFunction()
						createAccount(email, password, firstName, lastName, phone, '')
					}}
				/>
				<View style={{ flexDirection: 'row', justifyContent: 'center' }}>{errorMsg()}</View>
			</View>
		)
	} else if (global.kiosk) {
		return (
			<View>
				<Button
					buttonStyle={[GlobalStyle.addItemBtn, { alignSelf: 'center', paddingHorizontal: 25 }]}
					textStyle={GlobalStyle.addItemBtnText}
					title={'Register Kiosk'}
					isLoading={false}
					icon={undefined}
					onPress={async () => {
						var email = 'kiosk' + kioskNum.trim() + '_' + global.org + '@nextgenkitchens.com'
						var firstName = 'Kiosk'
						var lastName = kioskNum.trim()
						var phone = '0000000000'
						var password = kioskPass
						kioskFunction()
						createAccount(email, password, firstName, lastName, phone, '')
					}}
				/>
				<View style={{ flexDirection: 'row', justifyContent: 'center' }}>{errorMsg()}</View>
			</View>
		)
	} else if (!showPhoneVerification) {
		return (
			<KeyboardAvoidingView behavior={'height'} style={{ height: '100%' }}>
				<ScrollView contentContainerStyle={formStyle.container}>
					<StatusBar style="auto" />

					{referralCode !== '' ? InviteBanner() : null}

					<Spacer size={25} />

					<OrganizationLogoAccountPage imageUri={orgLogoDark ? orgLogoDark : organizationLogo} />

					<Formik
						initialValues={{
							email: inputValues.email,
							password: '',
							firstName: inputValues.firstName,
							lastName: inputValues.lastName,
							phone: inputValues.phone,
							referralCode: inputValues.referralCode,
						}}
						validationSchema={signupSchema}
						onSubmit={values => {
							// 1. Check phone number is unique
							// 2. Send verification code
							// 3. Show verification input
							// 4. Verify code
							// 5. Create user
							setInputValues(values)
							checkForUniquePhoneNumberAndEmail('1' + values.phone, values.email)
						}}
					>
						{props => (
							<View style={{ width: '90%' }}>
								<TextInput
									returnKeyType={'done'}
									style={[
										formStyle.textInput,
										{ fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont' },
									]}
									placeholder="Email"
									placeholderTextColor="#000000"
									onChangeText={props.handleChange('email')}
									value={props.values.email}
									onBlur={props.handleBlur('email')}
									onKeyPress={() => {
										setErrorText('')
										margin = -30
									}}
								/>
								<Text style={formStyle.errorText}>{props.touched.email && props.errors.email}</Text>
								<Spacer size={10} />
								<TextInput
									returnKeyType={'done'}
									style={[
										formStyle.textInput,
										{ fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont' },
									]}
									placeholder="Password"
									placeholderTextColor="#000000"
									secureTextEntry={true}
									onChangeText={props.handleChange('password')}
									value={props.values.password}
									onBlur={props.handleBlur('password')}
								/>
								<Text style={formStyle.errorText}>
									{props.touched.password && props.errors.password}
								</Text>
								<Spacer size={10} />
								<View style={GlobalStyle.flexRow}>
									<View style={formStyle.leftInput}>
										<TextInput
											returnKeyType={'done'}
											style={[
												formStyle.textInput,
												{ fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont' },
											]}
											placeholder={'First name'}
											placeholderTextColor="#000000"
											onChangeText={props.handleChange('firstName')}
											value={props.values.firstName}
											onBlur={props.handleBlur('firstName')}
										/>
										<Text style={formStyle.errorText}>
											{props.touched.firstName && props.errors.firstName}
										</Text>
									</View>
									<View style={formStyle.rightInput}>
										<TextInput
											returnKeyType={'done'}
											style={[
												formStyle.textInput,
												{ fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont' },
											]}
											placeholder={'Last name'}
											placeholderTextColor="#000000"
											onChangeText={props.handleChange('lastName')}
											value={props.values.lastName}
											onBlur={props.handleBlur('lastName')}
										/>
										<Text style={formStyle.errorText}>
											{props.touched.lastName && props.errors.lastName}
										</Text>
									</View>
								</View>

								<Spacer size={10} />

								<TextInput
									returnKeyType={'done'}
									keyboardType={'numeric'}
									maxLength={14} // Adjusted to accommodate the formatted value
									style={[
										formStyle.textInput,
										{ fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont' },
									]}
									placeholder="Phone number"
									autoComplete="tel"
									placeholderTextColor="#000000"
									onChangeText={text => {
										props.handleChange('phone')(removePhoneNumberFormatting(text))
									}}
									value={formatPhoneNumber(props.values.phone)}
									onBlur={props.handleBlur('phone')}
									onKeyPress={() => {
										setErrorText('')
									}}
								/>
								<Text style={formStyle.errorText}>{props.touched.phone && props.errors.phone}</Text>

								<Spacer size={10} />
								{referralEnabled && (
									<TextInput
										returnKeyType={'done'}
										style={[
											formStyle.textInput,
											{ fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont' },
										]}
										placeholder="Referral code"
										placeholderTextColor="#000000"
										onChangeText={props.handleChange('referralCode')}
										value={props.values.referralCode}
										onBlur={props.handleBlur('referralCode')}
									/>
								)}

								<View style={{ marginLeft: 5, marginVertical: 10 }}>
									<TouchableOpacity
										onPress={() =>
											Linking.openURL('https://nextgenkitchens.com/app-privacy-policy/')
										}
									>
										<Text>
											By submitting this form you agree to our
											<Text style={{ color: 'mediumblue' }}> Privacy Policy</Text>.
										</Text>
									</TouchableOpacity>
								</View>

								<View style={formStyle.btnContainer}>
									<TouchableOpacity
										style={[
											formStyle.button,
											{
												backgroundColor: !deviceHasInternet ? Colors.greyscale[6] : global.orgColor,
											},
										]}
										onPress={props.handleSubmit as any}
										disabled={!deviceHasInternet}
									>
										{isLoading ? (
											<Image
												style={{
													width: 90,
													height: 90,
													marginTop: -34,
													marginBottom: -34,
												}}
												source={require('../../assets/images/loadImg.gif')}
											/>
										) : (
											<Text style={formStyle.btnText}>SIGN UP</Text>
										)}
									</TouchableOpacity>
								</View>
							</View>
						)}
					</Formik>

					<View style={{ flexDirection: 'row', justifyContent: 'center' }}>
						<Text style={[formStyle.errorText, { marginTop: margin }]}>{errorText}</Text>
					</View>

					<Spacer size={30} />

					<View style={{ alignItems: 'center' }}>
						<Text style={GlobalStyle.text}>Already have an account?</Text>

						<TouchableOpacity
							onPress={() => {
								changeParent(true)
							}}
						>
							<Text style={formStyle.link}>Log in</Text>
						</TouchableOpacity>
					</View>
					<View style={{ height: 30 }}></View>
				</ScrollView>
			</KeyboardAvoidingView>
		)
	} else {
		return (
			<>
				<Spacer size={25} />
				<ScrollView style={{ paddingBottom: 30 }}>
					<OrganizationLogoAccountPage imageUri={orgLogoDark ? orgLogoDark : organizationLogo} />
					<VerificationInput
						phoneNumber={phoneNumber}
						sendCode={sendVerificationCodeToPhoneNumber}
						values={inputValues}
						createAccount={createAccount}
						setDisplay={setShowPhoneVerification}
					/>
				</ScrollView>
			</>
		)
	}
}
