import React from 'react'
import { Dimensions, View } from 'react-native'
import { MAX_CHECKOUT_WIDTH } from '../../../../constants/GlobalStyle'
import { SelectPaymentMethodView } from './SelectPaymentMethod'
import { AddPaymentMethodView } from './AddPaymentMethod'
import ExitIcon from '../../../../components/Headers/UIButtons'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { setCheckoutState } from '../../../../state/Slices/checkoutSlice'

export function PaymentMethodModal({ toggleModal, height, toggleConfirmModal, allowSave = false }) {
	const { isPaymentMethodModalVisible, isAddingCard, cardElementVisible, digitalWalletType } =
		useAppSelector(state => state.checkout)
	const { paymentMethods } = useAppSelector(state => state.user)

	const dispatch = useAppDispatch()

	const showPayments = paymentMethods && paymentMethods.length > 0 && !isAddingCard

	height = showPayments ? height : 400

	const { width } = Dimensions.get('window')

	return (
		<View
			style={{
				display: cardElementVisible || isPaymentMethodModalVisible ? 'flex' : 'none',
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				backgroundColor: 'rgba(0, 0, 0, 0.65)',
				alignItems: 'center',
				justifyContent: 'center',
				zIndex: 1000000,
			}}
		>
			<View
				style={{
					backgroundColor: 'white',
					width: width * 0.9,
					maxWidth: MAX_CHECKOUT_WIDTH - 200,
					minHeight: allowSave && digitalWalletType ? 450 : 400,
					maxHeight: showPayments ? 1000 : digitalWalletType ? 750 : 500,
					height: showPayments ? '80%' : digitalWalletType ? '55%' : '50%',
					paddingVertical: cardElementVisible ? 15 : 30,
					borderRadius: 20,
				}}
			>
				{!allowSave && cardElementVisible && (
					<ExitIcon
						onPress={() => {
							dispatch(setCheckoutState({ cardElementVisible: !cardElementVisible }))
						}}
					/>
				)}

				{showPayments ? (
					<View style={{ height: '100%' }}>
						<SelectPaymentMethodView toggleModal={toggleModal} />
					</View>
				) : (
					<AddPaymentMethodView
						toggleModal={
							cardElementVisible
								? () => {
										dispatch(
											setCheckoutState({
												useNewPaymentMethod: true,
												cardElementVisible: !cardElementVisible,
											})
										)
										toggleConfirmModal()
								  }
								: toggleModal
						}
						closeModal={() => {
							if (cardElementVisible) {
								dispatch(setCheckoutState({ cardElementVisible: false }))
							} else {
								dispatch(setCheckoutState({ isPaymentMethodModalVisible: false }))
							}
						}}
						isGuestCheckout={cardElementVisible ? true : false}
						allowSave={cardElementVisible ? allowSave : false}
					/>
				)}
			</View>
		</View>
	)
}
