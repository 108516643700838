import React, { useEffect, useRef, useState } from 'react'
import { Feather, Ionicons } from '@expo/vector-icons'
import { View, TouchableOpacity } from 'react-native'
import GlobalStyle, { headerTextSize, titleTextSize } from '../../../../constants/GlobalStyle'
import { getFunctions, httpsCallable } from 'firebase/functions'
import Colors from '../../../../constants/Colors'
import { Text } from '../../../../components/Themed'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { setCheckoutState } from '../../../../state/Slices/checkoutSlice'

/**
 * Shows the Awaiting Payment screen on kiosk (waiting for user to pay with card)
 */
export function AwaitingPayment({ title }) {
	const { cancelButton, checkoutId, items, paymentError } = useAppSelector(state => state.checkout)
	const { deviceHasInternet } = useAppSelector(state => state.user)
	const { selectedLocation } = useAppSelector(state => state.persisted)
	const { paymentProvider } = useAppSelector(state => state.settings)

	const [displayText, setDisplayText] = useState(title)
	const pingIntervalRef = useRef(null)

	const functions = getFunctions()
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (
			global.kiosk &&
			title === 'Please follow instructions on pinpad to pay now.' &&
			global.readerId === 'tmr_FJkwZAAXaN3B6Y'
		) {
			setTimeout(() => {
				const simulatePayment = httpsCallable(functions, 'processSimulatedStripeTerminalPayment')
				simulatePayment({
					orgId: global.org,
					readerId: global.readerId,
					isInterac: false,
				})
			}, 2500)
		}
	}, [])

	useEffect(() => {
		const checkTerminalStatus = async () => {
			let seconds = 0
			pingIntervalRef.current = setInterval(async () => {
				try {
					const getTerminalCheckout = httpsCallable(functions, 'getSquareTerminalCheckout')
					const terminalCheckout: any = await getTerminalCheckout({
						orgId: global.org,
						checkoutId,
						restaurantId: selectedLocation.id,
					})
					console.log('terminal checkout status', terminalCheckout?.data?.status)
					if (terminalCheckout?.data?.status === 'IN_PROGRESS') {
						clearInterval(pingIntervalRef.current)
						setDisplayText('Please follow instructions on pinpad to pay now.')
					} else if (seconds === 10) {
						setDisplayText(
							'The pinpad is responding slower than usual. Thank you for understanding.'
						)
					} else if (seconds === 30) {
						exitIntervalWithError()
						await cancelSquareTerminalCheckout()
					}
					console.log('seconds', seconds)
					seconds++
				} catch (error) {
					console.log('error checking terminal status', error)
					exitIntervalWithError()
				}
			}, 1000)
		}
		if (
			global.kiosk &&
			paymentProvider === 'square' &&
			!paymentError &&
			checkoutId &&
			deviceHasInternet
		) {
			checkTerminalStatus()
		} else if (!deviceHasInternet) {
			exitIntervalWithError()
		}
	}, [checkoutId, deviceHasInternet])

	useEffect(() => {
		if (cancelButton === 'Cancelling...') {
			cancelOrder()
			clearInterval(pingIntervalRef.current)
		}
	}, [cancelButton])

	const exitIntervalWithError = () => {
		clearInterval(pingIntervalRef.current)

		dispatch(
			setCheckoutState({
				awaitingTerminalInput: false,
				paymentError: true,
				spinner: false,
			})
		)
	}

	const cancelOrder = () => {
		dispatch(
			setCheckoutState({
				orderCreated: false,
				orderPlaced: false,
				awaitingTerminalInput: false,
				paymentError: false,
				spinner: false,
				isConfirmModalVisible: false,
				cancelButton: 'Cancel',
				checkoutId: '',
			})
		)

		if (!deviceHasInternet) return
		const cancelTerminalPayment = httpsCallable(functions, 'cancelStripeTerminalPayment')
		const cancelAction =
			paymentProvider === 'stripe'
				? cancelTerminalPayment({
						readerId: global.readerId,
						orgId: global.org,
					})
				: cancelSquareTerminalCheckout()

		cancelAction
			.then(() => {
				console.log('Payment cancelled.')
			})
			.catch(error => {
				console.log(error)
			})
	}

	const cancelSquareTerminalCheckout = async () => {
		const cancelTerminalCheckout = httpsCallable(functions, 'cancelSquareTerminalCheckout')
		cancelTerminalCheckout({ checkoutId, restaurantId: items[0].rId, orgId: global.org })
			.then(() => {
				console.log('Payment cancelled.')
			})
			.catch(error => {
				console.log(error)
			})
	}

	return (
		<View
			style={{
				alignItems: 'center',
				justifyContent: 'space-evenly',
				flex: 1,
			}}
		>
			<View
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					paddingLeft: '10%',
					paddingRight: '12%',
					marginTop: '10%',
				}}
			>
				{displayText === 'Payment error. Please cancel and try again.' ? (
					<Feather
						name={'alert-triangle'}
						size={titleTextSize * 3}
						color={Colors.custom.pinkRed}
						style={{ paddingTop: 15 }}
					/>
				) : (
					<Ionicons
						name={'information-circle'}
						size={titleTextSize * 3}
						color={global.orgColor ? global.orgColor : Colors.custom.restaurantMain}
						style={{ paddingTop: 15 }}
					/>
				)}

				<Text
					style={{
						textAlign: 'center',
						fontSize: titleTextSize,
						fontWeight: 'bold',
						marginLeft: 20,
					}}
				>
					{displayText}
				</Text>
			</View>
			<TouchableOpacity
				onPress={() => {
					dispatch(setCheckoutState({ cancelButton: 'Cancelling...' }))
				}}
				style={{
					borderWidth: 5,
					borderColor: '#eaeaea',
					borderRadius: 30,
					marginHorizontal: 15,
					flexDirection: 'row',
					width: '95%',
					minWidth: 300,
					justifyContent: 'center',
				}}
			>
				<Text
					style={[
						GlobalStyle.ctgTitle,
						{
							fontSize: headerTextSize,
							paddingHorizontal: 0,
						},
					]}
				>
					{cancelButton}
				</Text>
			</TouchableOpacity>
		</View>
	)
}
