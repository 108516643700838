import { logBeginCheckoutAnalytics } from '../../../firebase/analytics'
import { auth } from '../../../firebase/config'
import { Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Formatter from '../../../helpers/Formatter'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'
import { setDataState } from '../../../state/Slices/dataSlice'

export const useToggleConfirmModal = () => {
	const navigation = useNavigation<any>()

	const dispatch = useAppDispatch()
	const { isConfirmModalVisible, prices, analyticsItems, savedPhone } = useAppSelector(
		state => state.checkout
	)
	const { deliveryEnabled } = useAppSelector(state => state.settings)
	const { isDelivery } = useAppSelector(state => state.persisted)

	const toggleConfirmModal = () => {
		const email = auth.currentUser ? Formatter.decodedEmail(auth.currentUser.email) : ''
		let phone = savedPhone
		if (savedPhone.length === 11 && savedPhone.startsWith('1')) {
			phone = savedPhone.substring(1)
		}

		if (!isConfirmModalVisible) {
			logBeginCheckoutAnalytics(prices.total, analyticsItems)
		}

		dispatch(
			setCheckoutState({
				selectedMethods: {
					textMessage: false,
					email: false,
					print: false,
					textUpdates: true,
					showNumPad: false,
					phoneString: global.kiosk ? '' : phone,
					emailString: global.kiosk ? '' : email,
					phoneStringFormatted: global.kiosk ? '' : phone,
				},
				isCardValid: false,
				showPOSMethods: global.pos,
				typePOS: '',
				showDineInOrTakeout: true,
				tableNumber: null,
				phoneNumberInput: false,
				showAddTip: !global.kiosk,
				useNewPaymentMethod: false,
				isGuestCheckout: false,
			})
		)

		dispatch(setDataState({ disableIdleTimer: !isConfirmModalVisible }))

		if (auth.currentUser || (Platform.OS === 'web' && (!deliveryEnabled || !isDelivery))) {
			dispatch(setCheckoutState({ isConfirmModalVisible: !isConfirmModalVisible }))
		} else {
			navigation.navigate('AccountStack', { screen: 'Account', params: { onCheckout: true } })
		}
	}

	return { toggleConfirmModal }
}
