import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
	logoImage: '',
	heroImage: '',
	isLoaded: true,
	ctgTokens: {} as any,
	storeOpen: true,
	reset: [] as any,
	isSearchActive: false,
	showResults: false,
	showDropdown: false,
	searchText: '',
}

type StateTypes = typeof initialState

const slice = createSlice({
	name: 'menu',
	initialState,
	reducers: {
		setStates: (state, action: PayloadAction<Partial<StateTypes>>) => {
			Object.assign(state, action.payload)
		},
	},
})

const { setStates } = slice.actions

export const setMenuState = (updates: Partial<StateTypes>) => setStates(updates)

export default slice.reducer
