import { useEffect } from 'react'
import { auth } from '../../../firebase/config'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'

const useDataRefresh = ({ navigation, priceSet }) => {
	const functions = getFunctions()
	const dispatch = useAppDispatch()

	const { loadedData } = useAppSelector(state => state.data)
	const { cart, isDelivery, deliveryEstimate } = useAppSelector(state => state.persisted)
	const { items, couponRefresh, prices, couponCode } = useAppSelector(state => state.checkout)

	function checkMinSpend() {
		const itemInCart = cart.filter(
			c => c.appliedCoupon && c.appliedCoupon.type && c.appliedCoupon.type === 'freeItem'
		)
		itemInCart.forEach(item => {
			if (item.appliedCoupon.minSpend > prices.subTotal) {
				item.appliedCoupon = null
				navigation.navigate('restaurant')
				setTimeout(() => {
					navigation.navigate('TabCheckout')
				}, 100)
			}
		})
	}

	useEffect(() => {
		if (auth.currentUser) {
			const getUserCoupons = httpsCallable(functions, 'getUserCoupons')

			const fetchData = async () => {
				var data: any = await getUserCoupons({
					orgId: global.org,
					userId: auth.currentUser.uid,
				})
				dispatch(
					setCheckoutState({
						userAvailableCodes: data.data.available,
						userRedeemedCodes: data.data.redeemed,
					})
				)
			}

			fetchData()
		}
	}, [navigation, couponRefresh])

	useEffect(() => {
		dispatch(setCheckoutState({ reset: [] }))
	}, [prices])

	useEffect(() => {
		const unsubscribe = navigation.addListener('focus', () => {
			dispatch(setCheckoutState({ reset: [], allowOrder: true }))
		})
		return () => {
			unsubscribe()
		}
	}, [navigation])

	useEffect(() => {
		if (items.length > 0) {
			priceSet(couponCode)
		}
	}, [cart, items, couponCode, isDelivery, deliveryEstimate])

	useEffect(() => {
		checkMinSpend()
		// add loyalty points
		if (loadedData[global.org].loyaltyEnabled) {
			const pointsPerDollar = loadedData[global.org].loyaltyPointsPerDollar || 0

			const totalPoints = (prices.subTotal + prices.discount) * pointsPerDollar
			dispatch(setCheckoutState({ pointsEarned: Math.round(totalPoints) }))
		}
	}, [prices])
}

export default useDataRefresh
