import { StatusBar } from 'expo-status-bar'
import React, { useState } from 'react'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import useCachedResources from './hooks/useCachedResources'
import useColorScheme from './hooks/useColorScheme'
import Navigation from './navigation'
import { Provider } from 'react-redux'
import { store } from './state/store'
import { Linking, LogBox, Platform } from 'react-native'
import RetrieveData from './firebase/fbDataRetrieval/RetrieveData'
import qs from 'qs'
import { CardElement } from '@stripe/react-stripe-js'
import { CARD_ELEMENT_OPTIONS } from './components/Stripe/StripePayment.web'
import RetrieveLocation from './firebase/fbDataRetrieval/RetrieveLocation'
import { SquarePaymentForm } from './components/Square/SquarePaymentForm.web'
import InternetConnection from './components/InternetConnection'
import TestModeBanner from './components/TestModeBanner'

interface WebCardDetails {
	paymentProvider: string
	setIsCardValid?: (valid: boolean) => void
	title?: string
	onPress?: any
}

export function WebCardElement(props: WebCardDetails) {
	const { paymentProvider, setIsCardValid = null, title = '', onPress = null } = props
	if (Platform.OS === 'web') {
		return (
			<>
				{paymentProvider === 'stripe' ? (
					<CardElement
						options={CARD_ELEMENT_OPTIONS}
						onChange={cardDetails => {
							setIsCardValid(cardDetails.complete && cardDetails.error === undefined)
						}}
					/>
				) : (
					<SquarePaymentForm title={title} onPress={onPress} />
				)}
			</>
		)
	}
}

export function MobileCardElement() {
	return <></>
}

export default function App() {
	const [rCode, setRCode] = useState('')
	Linking.getInitialURL().then(url => {
		if (url) {
			const { referralCode } = qs.parse(url.split('?')[1])
			if (typeof referralCode === 'string') {
				setRCode(referralCode)
			}
		}
	})

	const isLoadingComplete = useCachedResources()

	// currently only light mode is supported.
	const colorScheme = useColorScheme()

	// This line will prevent displaying logs of this type in your emulator
	LogBox.ignoreLogs(['new NativeEventEmitter'])

	// This line will prevent displaying all warning logs in your emulator
	LogBox.ignoreAllLogs()
	if (!isLoadingComplete) {
		return null
	} else {
		return (
			<Provider store={store}>
				<SafeAreaProvider>
					<TestModeBanner />
					<InternetConnection>
						<RetrieveData referralCode={rCode} loadingTracker={null}>
							<RetrieveLocation />
							<Navigation colorScheme={colorScheme} />
							<StatusBar />
						</RetrieveData>
					</InternetConnection>
				</SafeAreaProvider>
			</Provider>
		)
	}
}
