export function orderQuantity(singleOrder) {
	let count = 0
	singleOrder.orderItems.forEach(item => {
		count += parseInt(item.qty)
	})
	return count
}

export function itemOrItems(len) {
	if (len === 1) {
		return 'item'
	} else {
		return 'items'
	}
}
