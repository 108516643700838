import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
	TouchableOpacity,
	FlatList,
	StyleSheet,
	Platform,
	Pressable,
	Dimensions,
} from 'react-native'
import { utcToZonedTime } from 'date-fns-tz'
import { format, add, set, intervalToDuration, isSameDay } from 'date-fns'
import { getOrgHours } from '../../screens/RestaurantSelect/Helpers/orgPrepHours'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { resetCartPrepState, setPersistedState } from '../../state/Slices/persistedSlice'
import { Text, View } from '../../components/Themed'
import RBSheet from 'react-native-raw-bottom-sheet'
import GlobalStyle, { MAX_CHECKOUT_WIDTH } from '../../constants/GlobalStyle'
import Button from '../Button'
import ScrollPicker from './ScrollViewPicker'
import Colors from '../../constants/Colors'
import { itemAvailabilityCheck } from '../../helpers/restaurantHours'
import { alertResponseText } from '../Alerts/Alerts'

const ScheduleOrder = ({ hoursRef }) => {
	const dispatch = useAppDispatch()
	const {
		cart = [],
		isDelivery,
		cartPrep,
		deliveryEstimate,
	} = useAppSelector(state => state.persisted)
	const { localTimezone, scheduleTimeInterval, scheduleDaysAhead } = useAppSelector(
		state => state.settings
	)
	const { restaurants, loadedData } = useAppSelector(state => state.data)

	const deliveryTime = deliveryEstimate?.estimate?.timeToDeliver || 60

	const [selectedDate, setSelectedDate] = useState<Date | null>(null)
	const [selectedTime, setSelectedTime] = useState<Date | null>(null)

	const getLongestPrepTime = useMemo(() => {
		const prepTimes = cart.map(item => {
			const restaurant = restaurants.find(r => r.id === item.rId)
			return restaurant ? restaurant.prepTime : 0
		})
		return Math.max(...prepTimes, 0)
	}, [cart, restaurants])

	function roundUpToNearestInterval(date, intervalMinutes) {
		const totalMinutes = date.getHours() * 60 + date.getMinutes()
		const remainder = totalMinutes % intervalMinutes
		const minutesToAdd = remainder === 0 ? 0 : intervalMinutes - remainder
		const roundedDate = add(date, { minutes: minutesToAdd })
		return set(roundedDate, { seconds: 0, milliseconds: 0 })
	}

	const restaurantHours = useMemo(() => {
		const restaurantIds = [...new Set(cart.map(item => item.rId))]
		const filteredRestaurants = restaurants.filter(r => restaurantIds.includes(r.id))
		return combineHours(filteredRestaurants)
	}, [cart, restaurants])

	const { availableDates, availableTimes } = useMemo(() => {
		const currentDate = utcToZonedTime(new Date(), localTimezone)
		const dates = []
		const times = []

		for (let i = 0; i < scheduleDaysAhead; i++) {
			const date = add(currentDate, { days: i })
			const dayHours = restaurantHours[date.getDay()]

			dayHours.startTimes.forEach((start, index) => {
				const startDate = set(date, {
					hours: Math.floor(start),
					minutes: Math.round((start % 1) * 60),
					seconds: 0,
					milliseconds: 0,
				})

				const endDate = set(date, {
					hours: Math.floor(dayHours.endTimes[index]),
					minutes: Math.round((dayHours.endTimes[index] % 1) * 60),
					seconds: 0,
					milliseconds: 0,
				})

				let earliestTime

				if (isSameDay(date, currentDate)) {
					earliestTime = add(currentDate, {
						minutes: isDelivery ? deliveryTime : getLongestPrepTime,
					})
					earliestTime = roundUpToNearestInterval(earliestTime, scheduleTimeInterval)

					if (earliestTime < startDate) {
						earliestTime = startDate
					}
				} else {
					earliestTime = add(startDate, { minutes: isDelivery ? deliveryTime : getLongestPrepTime })
					earliestTime = roundUpToNearestInterval(earliestTime, scheduleTimeInterval)
				}

				let time = earliestTime

				while (time < endDate) {
					if (time > currentDate) {
						if (!dates.some(d => d.getDate() === time.getDate())) {
							dates.push(time)
						}
						times.push(time)
					}
					time = add(time, { minutes: scheduleTimeInterval })
				}
			})
		}

		return { availableDates: dates, availableTimes: times }
	}, [restaurantHours, getLongestPrepTime, localTimezone, isDelivery])

	useEffect(() => {
		if (cart.length === 0) {
			resetScheduledOrder()
		}
	}, [cart])

	useEffect(() => {
		if (!cartPrep.isScheduled) {
			dispatch(
				resetCartPrepState({ localTimezone: localTimezone, getLongestPrepTime: getLongestPrepTime })
			)
		}
	}, [])
	const assignScheduledOrderTime = useCallback(() => {
		if (selectedDate && selectedTime) {
			const scheduledTime = set(selectedDate, {
				hours: selectedTime.getHours(),
				minutes: selectedTime.getMinutes(),
			})

			const duration = intervalToDuration({
				start: utcToZonedTime(new Date(), localTimezone),
				end: scheduledTime,
			})

			const canPayNowForScheduled = itemAvailabilityCheck(
				cart,
				loadedData[global.org].hours ?? null,
				false,
				localTimezone,
				scheduledTime
			)
			if (!canPayNowForScheduled) {
				alertResponseText(
					'Items in your cart are not available at this time',
					'Please choose a different time to receive your order.'
				)
			} else {
				dispatch(
					setPersistedState({
						cartPrep: {
							isScheduled: true,
							pickupTime: isDelivery ? null : scheduledTime.toISOString(),
							deliveryTime: isDelivery ? scheduledTime.toISOString() : null,
							prepTimeDuration: `P${duration.days}DT${duration.hours}H${duration.minutes}M${duration.seconds}S`,
							longestPrepTime: getLongestPrepTime,
							deliveryPrepTime: deliveryTime,
						},
					})
				)
			}
		}
		hoursRef.current.close()
	}, [selectedDate, selectedTime, localTimezone, isDelivery, dispatch, getLongestPrepTime])

	const resetScheduledOrder = useCallback(() => {
		dispatch(
			resetCartPrepState({ localTimezone: localTimezone, getLongestPrepTime: getLongestPrepTime })
		)
		setSelectedDate(null)
		setSelectedTime(null)
		hoursRef.current.close()
	}, [localTimezone, getLongestPrepTime, isDelivery, dispatch])

	const handleDateSelection = useCallback(
		date => {
			setSelectedDate(date)
			const timesForDate = availableTimes.filter(time => date && time.getDate() === date.getDate())
			if (timesForDate.length > 0) {
				setSelectedTime(timesForDate[0])
			}
		},
		[availableTimes]
	)

	const renderDateItem = useCallback(
		({ item }: { item: Date }) => (
			<TouchableOpacity style={{ paddingHorizontal: 15 }} onPress={() => handleDateSelection(item)}>
				<View
					style={{
						backgroundColor: selectedDate === item ? Colors.custom.lightGrey : null,
						borderRadius: 15,
						height: 50,
						width: 175,
					}}
				>
					<Text style={{ textAlign: 'center', marginTop: 15 }}>{format(item, 'EEE, MMM d')}</Text>
				</View>
			</TouchableOpacity>
		),
		[selectedDate]
	)

	const renderTimeItem = useCallback(
		({ item }: { item: Date }) => (
			<TouchableOpacity style={{ paddingHorizontal: 15 }} onPress={() => setSelectedTime(item)}>
				<View
					style={{
						backgroundColor: selectedTime === item ? Colors.custom.lightGrey : null,
						borderRadius: 15,
						height: 50,
						width: 175,
					}}
				>
					<Text style={{ textAlign: 'center', marginTop: 15 }}>{format(item, 'h:mm a')}</Text>
				</View>
			</TouchableOpacity>
		),
		[selectedTime]
	)

	return (
		<View>
			<RBSheet
				ref={hoursRef}
				onOpen={() => {
					if (availableDates.length > 0) {
						setSelectedDate(availableDates[0])
						const timesForDate = availableTimes.filter(
							time => availableDates[0] && time.getDate() === availableDates[0].getDate()
						)
						if (timesForDate.length > 0) {
							setSelectedTime(timesForDate[0])
						}
					}
				}}
				closeOnPressMask={false}
				height={325}
				customStyles={{
					container: styles.container,
				}}
			>
				<>
					<View
						style={[
							styles.heading,
							{
								flexDirection: 'row',
								justifyContent: 'space-between',
							},
						]}
					>
						<Text style={[GlobalStyle.headerText, { textAlign: 'center', flex: 1 }]}>
							{isDelivery ? 'SCHEDULE YOUR DELIVERY' : 'SCHEDULE YOUR PICKUP'}
						</Text>
						<Pressable onPress={resetScheduledOrder} style={styles.resetButton}>
							<Text style={{ color: '#007AFF', fontSize: 16 }}>Reset</Text>
						</Pressable>
					</View>

					<View style={{ justifyContent: 'center', flex: 1, alignItems: 'center' }}>
						{Platform.OS === 'web' ? (
							<View style={{ flexDirection: 'row', flex: 1 }}>
								<FlatList
									scrollEnabled={true}
									showsVerticalScrollIndicator={Platform.OS === 'web' ? true : false}
									data={availableDates}
									renderItem={renderDateItem}
									keyExtractor={(item, index) => item.toISOString() + index}
									contentContainerStyle={{
										marginTop: 20,
										marginHorizontal: 10,
									}}
									style={{ flex: 1 }}
								/>

								<FlatList
									scrollEnabled={true}
									showsVerticalScrollIndicator={Platform.OS === 'web' ? true : false}
									data={availableTimes.filter(
										time => selectedDate && time.getDate() === selectedDate.getDate()
									)}
									renderItem={renderTimeItem}
									keyExtractor={(item, index) => item.toISOString() + index}
									contentContainerStyle={{
										marginTop: 20,
										marginHorizontal: 10,
									}}
									style={{ flex: 1 }}
								/>
							</View>
						) : (
							<View style={{ flexDirection: 'row' }}>
								<ScrollPicker
									style={{ flex: 1, paddingRight: 100 }}
									dataSource={availableDates}
									selectedIndex={0}
									itemHeight={50}
									wrapperHeight={170}
									wrapperColor={'#ffffff'}
									highlightColor={'#d8d8d8'}
									renderItem={data => {
										return (
											<View>
												<Text>{format(data, 'EEE, MMM d')}</Text>
											</View>
										)
									}}
									onValueChange={data => {
										handleDateSelection(data)
									}}
								/>
								<ScrollPicker
									style={{ flex: 1 }}
									dataSource={availableTimes.filter(
										time => selectedDate && time.getDate() === selectedDate.getDate()
									)}
									selectedIndex={0}
									itemHeight={50}
									wrapperHeight={170}
									wrapperColor={'#ffffff'}
									highlightColor={'#d8d8d8'}
									renderItem={data => {
										if (data !== ' ') {
											return (
												<View>
													<Text>{format(data, 'h:mm aaa')}</Text>
												</View>
											)
										}
									}}
									onValueChange={(data, index) => {
										setSelectedTime(data)
									}}
								/>
							</View>
						)}
					</View>

					<Button
						icon={null}
						onPress={assignScheduledOrderTime}
						title="DONE"
						buttonStyle={styles.heading}
						textStyle={[
							GlobalStyle.headerText,
							{ paddingBottom: Platform.OS === 'ios' ? 50 : 10, paddingTop: 10 },
						]}
						isLoading={false}
					/>
				</>
			</RBSheet>
		</View>
	)
}

export function combineHours(restaurants) {
	const combinedHours = getOrgHours(restaurants)

	const rHours = {}

	const indexedHours = {
		0: [],
		1: [],
		2: [],
		3: [],
		4: [],
		5: [],
		6: [],
	}

	const combinedStarts = JSON.parse(JSON.stringify(indexedHours))
	const combinedEnds = JSON.parse(JSON.stringify(indexedHours))

	for (let i = 0; i < combinedHours.length; i++) {
		const sched = Object.entries(combinedHours[i])
		sched.forEach((day: any, i) => {
			day[1].startTimes?.forEach(start => {
				combinedStarts[i].push(start)
			})
			day[1].endTimes?.forEach(end => {
				combinedEnds[i].push(end)
			})

			combinedStarts[i].sort(function (a, b) {
				return a - b
			})
			combinedEnds[i].sort(function (a, b) {
				return a - b
			})
		})
	}

	const intersectedStarts = JSON.parse(JSON.stringify(indexedHours))
	const intersectedEnds = JSON.parse(JSON.stringify(indexedHours))

	for (let i = 0; i < 7; i++) {
		let currentStart = combinedStarts[i][0]
		let currentEnd = combinedEnds[i][0]
		for (let j = 1; j < combinedEnds[i].length; j++) {
			// represents intersected time between current end and indexed start time
			if (currentEnd >= combinedStarts[i][j]) {
				// Update the current intersection, looking for max for a start time, looking for min for an end time
				currentStart = Math.max(currentStart, combinedStarts[i][j])
				currentEnd = Math.min(currentEnd, combinedEnds[i][j])
			} else {
				// Not an intersection, assign new start and end to be indexed start and end
				intersectedStarts[i].push(currentStart)
				intersectedEnds[i].push(currentEnd)
				currentStart = combinedStarts[i][j]
				currentEnd = combinedEnds[i][j]
			}
		}
		// Push last intersection in case that intersection is last index of array
		intersectedStarts[i].push(currentStart)
		intersectedEnds[i].push(currentEnd)

		rHours[i] = {
			startTimes: intersectedStarts[i],
			endTimes: intersectedEnds[i],
		}
	}
	return rHours
}

const styles = StyleSheet.create({
	container: {
		borderTopLeftRadius: 12,
		borderTopRightRadius: 12,
		maxWidth: MAX_CHECKOUT_WIDTH,
		alignSelf: 'center',
	},
	heading: {
		justifyContent: 'center',
		alignItems: 'center',
		height: 65,
		backgroundColor: Colors.greyscale[1],
	},
	resetButton: {
		paddingLeft: 25,
		paddingTop: 25,
		paddingBottom: 20,
		paddingRight: 15,
		position: 'absolute',
		right: 0,
		top: 0,
	},
})

export default ScheduleOrder
