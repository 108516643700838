import {
	SQIPApplePay,
	SQIPCardEntry,
	SQIPCore,
	SQIPGooglePay,
} from 'react-native-square-in-app-payments'
import { SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID, APPLE_PAY_MERCHANT_ID } from '@env'
import { Platform } from 'react-native'
import { useEffect } from 'react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { auth } from '../../../firebase/config'
import { placeOrder } from '../../../screens/Checkout/Helpers/placeOrder'
import { alertResponseSingle } from '../../Alerts/Alerts'
import { useSquare } from './useSquare'
import { useToggleConfirmModal } from '../../../screens/Checkout/Hooks/useToggleConfirmModal'
import crashlytics from '../../Crashlytics/crashlyticsLog'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'

const functions = getFunctions()

interface CardDetails {
	nonce: string
	card: {
		prepaidType: string
		expirationYear: number
		brand: string
		postalCode: string
		expirationMonth: number
		type: string
		lastFourDigits: string
	}
}

export const useSquareCheckout = () => {
	const {
		items,
		orderNote,
		couponCode,
		useNewPaymentMethod,
		couponCodeBackup,
		restaurantDiscountAmount,
		tableNumber,
		takeOutName,
		isGuestCheckout,
		validCouponCodes,
		loading,
		tipCount,
		selectedMethods,
		posExtraData,
		saveCard,
		modalTimestamps,
		isDineIn,
		pointsEarned,
		prices,
	} = useAppSelector(state => state.checkout)
	const { selectedPickupPoint, restaurants, loadedData } = useAppSelector(state => state.data)
	const { paymentProvider } = useAppSelector(state => state.settings)
	const { cartPrep, redeemedRewards, isTestMode, isDelivery } = useAppSelector(
		state => state.persisted
	)
	const { paymentMethodId } = useAppSelector(state => state.user)

	const orgName = loadedData[global.org].organizationName
		? loadedData[global.org].organizationName
		: 'pickup'

	const { toggleConfirmModal } = useToggleConfirmModal()
	const uid = auth.currentUser ? auth.currentUser.uid : ''

	const dispatch = useAppDispatch()

	const userId = auth.currentUser ? auth.currentUser.uid : ''

	const data = {
		userId: userId,
		loadedData: loadedData,
		items: items,
		orderNote: orderNote,
		couponCode: couponCode,
		useNewPaymentMethod: useNewPaymentMethod,
		couponCodeBackup: couponCodeBackup,
		restaurantDiscountAmount: restaurantDiscountAmount,
		isDineIn: isDineIn,
		tableNumber: tableNumber,
		takeOutName: takeOutName,
		prices: prices,
		dispatch: dispatch,
		isGuestCheckout: isGuestCheckout,
		cardId: paymentMethodId,
		validCouponCodes: validCouponCodes,
		loading: loading,
		tipCount: tipCount,
		navigation: null,
		toggleConfirmModal: toggleConfirmModal,
		selectedMethods: selectedMethods,
		posExtraData: posExtraData,
		redeemedRewards: redeemedRewards,
		pointsEarned: pointsEarned,
		timestampData: modalTimestamps,
		pickupPointObject: selectedPickupPoint,
		cartPrep: cartPrep,
		restaurants: restaurants,
		ageVerificationRequired: false,
		isTestMode: isTestMode,
		isDelivery: isDelivery,
	}

	const { createCustomerCard } = useSquare()

	let showOrderPlaced = false

	useEffect(() => {
		if (paymentProvider === 'square') {
			initState()
		}
	}, [])

	const initState = async () => {
		await SQIPCore.setSquareApplicationId(SQUARE_APPLICATION_ID)
		let digitalWalletEnabled = false
		if (Platform.OS === 'ios') {
			try {
				await SQIPApplePay.initializeApplePay(`${APPLE_PAY_MERCHANT_ID}.square`)
				digitalWalletEnabled = await SQIPApplePay.canUseApplePay()
			} catch (e) {
				console.log(e)
				crashlytics().log(e)
			}
		} else if (Platform.OS === 'android') {
			await SQIPGooglePay.initializeGooglePay(SQUARE_LOCATION_ID, 1)
			try {
				digitalWalletEnabled = await SQIPGooglePay.canUseGooglePay()
			} catch (e) {
				console.log(e)
				crashlytics().log(e)
			}
		}
		dispatch(setCheckoutState({ isDigitalWalletEnabled: digitalWalletEnabled }))
	}

	/**
	 * Callback when successfully get the card nonce details for processing
	 * card entry is still open and waiting for processing card nonce details
	 * @param {*} cardDetails
	 */
	const onCardNonceRequestSuccess = async (cardDetails: CardDetails) => {
		try {
			data.useNewPaymentMethod = true
			const response: any = await chargeCardNonce(cardDetails.nonce)

			if (response.data.errorMessage) {
				throw new Error(response.data.errorMessage)
			}

			SQIPCardEntry.completeCardEntry(() => {
				crashlytics().log('SETTING ORDER PLACED: TRUE')
				dispatch(setCheckoutState({ orderPlaced: true }))
				if (saveCard) {
					createCustomerCard(cardDetails.nonce, response.data.id, true)
				}
			})
		} catch (error: any) {
			SQIPCardEntry.showCardNonceProcessingError(error.message)
		}
	}

	const hideSquareCard = () => {
		toggleConfirmModal()
		dispatch(
			setCheckoutState({
				cardElementVisible: false,
				showSquarePayment: false,
			})
		)
	}

	const chargeCardNonce = async (nonce: string) => {
		const processedOrder = await placeOrder(data, 'square')()
		const fbOrderId = processedOrder.data[0].orderId
		dispatch(setCheckoutState({ processedOrder: processedOrder, spinner: true }))
		const squareCharge = httpsCallable(functions, 'chargeSquareOrder')
		return await squareCharge({
			nonce,
			amount: parseFloat((prices.total - prices.serviceCharge).toFixed(2)) * 100,
			orgFee: 0,
			tipAmount: Math.round(prices.tip * 100),
			serviceCharge: Math.round(prices.serviceCharge * 100),
			firebaseOrderId: fbOrderId,
			orgId: global.org,
			orderData: processedOrder.data,
		})
	}

	const chargeWebCardNonce = async (nonce: string) => {
		try {
			const response: any = await chargeCardNonce(nonce)
			if (response.data.errorMessage) {
				throw new Error(response.data.errorMessage)
			}
			if (saveCard) {
				createCustomerCard(nonce, response.data.id, true)
			}
			crashlytics().log('SETTING ORDER PLACED: TRUE')
			dispatch(setCheckoutState({ orderPlaced: true }))
		} catch (error: any) {
			alertResponseSingle('Error charging card', error.message, 'OK', null, null)
		}
	}

	/**
	 * Callback when card entry is cancelled and UI is closed
	 */
	const onCardEntryCancel = () => {
		// Handle the cancel callback
		dispatch(setCheckoutState({ spinner: false }))
	}

	/**
	 * An event listener to start card entry flow
	 */
	const onStartCardEntry = async () => {
		const cardEntryConfig = {
			collectPostalCode: true,
		}
		await SQIPCardEntry.startCardEntryFlow(
			cardEntryConfig,
			onCardNonceRequestSuccess,
			onCardEntryCancel
		)
	}

	const chargeCustomerCard = async () => {
		const processedOrder = await placeOrder(data, 'square')()
		const fbOrderId = processedOrder.data[0].orderId
		dispatch(setCheckoutState({ processedOrder: processedOrder }))
		try {
			const customerCardCharge = httpsCallable(functions, 'chargeSquareCustomerCard')
			const response: any = await customerCardCharge({
				amount: parseFloat((prices.total - prices.serviceCharge).toFixed(2)) * 100,
				orgFee: 0,
				tipAmount: Math.round(prices.tip * 100),
				serviceCharge: Math.round(prices.serviceCharge * 100),
				cardId: paymentMethodId,
				uid,
				firebaseOrderId: fbOrderId,
				orgId: global.org,
				orderData: processedOrder.data,
			})

			if (response.data.errorMessage) {
				throw new Error(response.data.errorMessage)
			}

			return { status: 'Accepted', processedOrder }
		} catch (e) {
			console.log(e)
			crashlytics().log(e)
			alertResponseSingle('Error charging card on file', e.message, 'OK', null, null)
			return { status: 'Failed', processedOrder }
		}
	}

	/**
	 * Callback when successfully get the card nonce details for processig
	 * apple pay sheet is still open and waiting for processing card nonce details
	 * @param cardDetails
	 */
	const onApplePayNonceRequestSuccess = async cardDetails => {
		try {
			// take payment with the card nonce details
			// you can take a charge
			data.useNewPaymentMethod = true // note to self: please refactor this
			try {
				const response: any = await chargeCardNonce(cardDetails.nonce)
				if (response.data.errorMessage) {
					throw new Error(response.data.errorMessage)
				}
				//hideSquareCard()
				showOrderPlaced = true
				//setOrderPlaced(true)
			} catch (error: any) {
				alertResponseSingle('Error charging card', error.message, 'OK', null, null)
			}

			// you must call completeApplePayAuthorization to close apple pay sheet
			await SQIPApplePay.completeApplePayAuthorization(true)
		} catch (ex) {
			// handle card nonce processing failure

			// you must call completeApplePayAuthorization to close apple pay sheet
			await SQIPApplePay.completeApplePayAuthorization(false, ex.message)
		}
	}

	/**
	 * Callback when failed to get the card nonce
	 * apple pay sheet is still open and waiting for processing error information
	 */
	const onApplePayNonceRequestFailure = async errorInfo => {
		// handle this error before close the apple pay sheet
		console.log(errorInfo)
		crashlytics().log(errorInfo)
		// you must call completeApplePayAuthorization to close apple pay sheet
		await SQIPApplePay.completeApplePayAuthorization(false, errorInfo.message)
		dispatch(setCheckoutState({ spinner: false }))
	}

	/**
	 * Callback when the apple pay sheet is closed after
	 * call completeApplePayAuthorization or user tap to close apple pay sheet manually
	 */
	const onApplePayEntryComplete = () => {
		if (showOrderPlaced) {
			hideSquareCard()
			crashlytics().log('SETTING ORDER PLACED: TRUE')
			dispatch(setCheckoutState({ orderPlaced: true, showAddTip: false }))
		} else {
			dispatch(setCheckoutState({ spinner: false }))
		}
		dispatch(setCheckoutState({ isConfirmModalVisible: true }))
	}

	/**
	 * Callback when successfully get the card nonce details for processig
	 * google pay sheet has been closed when this callback is invoked
	 */
	const onGooglePayNonceRequestSuccess = async cardDetails => {
		data.useNewPaymentMethod = true // note to self: please refactor this
		try {
			const response: any = await chargeCardNonce(cardDetails.nonce)
			if (response.data.errorMessage) {
				throw new Error(response.data.errorMessage)
			}
			crashlytics().log('SETTING ORDER PLACED: TRUE')
			dispatch(setCheckoutState({ orderPlaced: true }))
		} catch (error: any) {
			alertResponseSingle('Error charging card', error.message, 'OK', null, null)
		}
	}

	/**
	 * Callback when google pay is canceled
	 * google pay sheet has been closed when this callback is invoked
	 */
	const onGooglePayCancel = () => {
		// handle google pay canceled
		dispatch(setCheckoutState({ spinner: false }))
	}

	/**
	 * Callback when failed to get the card nonce
	 * google pay sheet has been closed when this callback is invoked
	 */
	const onGooglePayNonceRequestFailure = errorInfo => {
		// handle google pay failure
		console.error(errorInfo)
		dispatch(setCheckoutState({ spinner: false }))
	}

	const onStartDigitalWallet = async () => {
		dispatch(setCheckoutState({ spinner: true }))
		if (Platform.OS === 'ios') {
			const applePayConfig = {
				price: prices.total.toString(),
				summaryLabel: `Order for ${orgName}`,
				countryCode: 'CA',
				currencyCode: 'CAD',
				paymentType: 2,
			}
			try {
				dispatch(setCheckoutState({ isConfirmModalVisible: false }))
				setTimeout(async () => {
					await SQIPApplePay.requestApplePayNonce(
						applePayConfig,
						onApplePayNonceRequestSuccess,
						onApplePayNonceRequestFailure,
						onApplePayEntryComplete
					)
				}, 150)
			} catch (e) {
				// Handle InAppPaymentsException
				console.error(e)
			}
		} else if (Platform.OS === 'android') {
			const googlePayConfig = {
				price: prices.total.toString(),
				currencyCode: 'CAD',
				priceStatus: 3,
			}
			try {
				await SQIPGooglePay.requestGooglePayNonce(
					googlePayConfig,
					onGooglePayNonceRequestSuccess,
					onGooglePayNonceRequestFailure,
					onGooglePayCancel
				)
			} catch (e) {
				// Handle InAppPaymentsException
				console.error(e)
			}
		}
	}

	return {
		hideSquareCard,
		onStartCardEntry,
		chargeCustomerCard,
		chargeWebCardNonce,
		onStartDigitalWallet,
	}
}
