import React from 'react'
import { View, StyleSheet, TouchableOpacity, Dimensions, Linking } from 'react-native'
import Colors from '../../constants/Colors'
import { Text } from '../Themed'
import { useAppSelector } from '../../state/hooks'

const CopyrightFooter = ({ showIcons = null }) => {
	const { loadedData } = useAppSelector(state => state.data)

	const overMaxWidth = Dimensions.get('window').width > 1200
	const screenWidth = overMaxWidth ? 1200 : Dimensions.get('window').width * 0.9

	const currentYear = new Date().getFullYear()
	const orgName = loadedData[global.org].organizationName.toUpperCase()

	const iconText = showIcons ? ' | ICONS BY FREEPIK' : ''

	return (
		<View style={styles.footerContainer}>
			<View
				style={{
					width: screenWidth,
					flexDirection: 'row',
					justifyContent: 'space-between',
					flexWrap: 'wrap',
				}}
			>
				<Text style={styles.footerText}>{`© ${currentYear} ${orgName}${iconText}`}</Text>
				<View style={styles.linkContainer}>
					<TouchableOpacity
						onPress={() => Linking.openURL('https://nextgenkitchens.com/app-privacy-policy/')}
					>
						<Text style={styles.linkText}>PRIVACY POLICY</Text>
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	footerContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: Colors.greyscale[4],
		height: 80,
		borderTopColor: Colors.greyscale[5],
		borderTopWidth: 0.25,
	},
	footerText: {
		color: Colors.greyscale[7],
		fontSize: 12,
	},
	linkContainer: {
		flexDirection: 'row',
		gap: 30,
		marginRight: 10,
	},
	linkText: {
		color: Colors.greyscale[7],
		fontSize: 12,
	},
})

export default CopyrightFooter
