import React from 'react'
import { View } from 'react-native'
import Colors from '../../../../constants/Colors'
import { NavButton } from '../../../../components/Headers/UIButtons'
import { auth } from '../../../../firebase/config'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { setCheckoutState } from '../../../../state/Slices/checkoutSlice'

/**
 * On web only, allow guest checkout
 * Show option to log in or continue as guest
 */
export function GuestCheckout({ navigation, toggleConfirmModal }) {
	const dispatch = useAppDispatch()
	const { paymentProvider } = useAppSelector(state => state.settings)

	return (
		<View
			style={{
				flex: 1,
				width: '80%',
				alignSelf: 'center',
				justifyContent: 'space-evenly',
				marginBottom: '5%',
			}}
		>
			<NavButton
				onPress={() => {
					toggleConfirmModal()
					navigation.navigate('AccountStack', {
						screen: 'Account',
						params: { onCheckout: true },
					})
				}}
				color={'black'}
				title={'LOG IN'}
				style={{ marginBottom: 20 }}
			/>
			<NavButton
				onPress={() => {
					if (paymentProvider === 'stripe') {
						toggleConfirmModal()
						dispatch(setCheckoutState({ cardElementVisible: true }))
					} else {
						dispatch(setCheckoutState({ useNewPaymentMethod: true }))
						if (auth.currentUser === null) {
							dispatch(setCheckoutState({ isGuestCheckout: true }))
						}
					}
					dispatch(setCheckoutState({ showGuestCheckoutName: true }))
				}}
				color={Colors.greyscale[5]}
				title={'CONTINUE AS GUEST'}
			/>
		</View>
	)
}
