import { getFunctions, httpsCallable } from 'firebase/functions'
import { auth, db } from '../../../firebase/config'
import { DATABASE_NAME } from '@env'
import { collection, doc, query, where, orderBy, onSnapshot } from 'firebase/firestore'

export const subscribeToPOSOrders = ({
	loadMore = false,
	mode = null,
	filterType,
	lastOrderDate,
	setLastOrderDate,
	setLoading,
	setOrdersView,
}) => {
	setLoading(true)
	const functions = getFunctions()
	const filterMode = mode || filterType
	if (auth.currentUser !== null) {
		// Call the cloud function
		const getAllOrdersForPOS = httpsCallable(functions, 'getAllOrdersForPOS')

		getAllOrdersForPOS({
			orgId: global.org,
			limit: 10,
			startAfter: loadMore ? lastOrderDate : null, // Use the last order date for pagination if loading more
		})
			.then(result => {
				const { orders }: any = result.data

				if (!orders || orders.length === 0) {
					if (!loadMore) setOrdersView([]) // No orders found
					setLoading(false)
					return
				}

				const newOrders = []
				let pos = 0

				orders.forEach(data => {
					const items = data.orderItems.map(orderItem => {
						const mods = orderItem.modifiers.map(itemMod => ({
							name: itemMod?.name,
							price: itemMod.priceMoney.amount / 100,
						}))

						return {
							name: orderItem?.name,
							qty: orderItem.quantity,
							mods: mods,
							note: orderItem.note,
							total: orderItem.priceMoney.amount,
							id: orderItem.id,
							rId: orderItem.restaurantId,
							noPromo: orderItem.priceMoney.amount,
						}
					})

					const orderObj = {
						pos: pos,
						orderDate: {
							seconds: data.orderDate?._seconds || 0,
						},
						pickupDate: {
							seconds: data.pickupDate?._seconds || 0,
						},
						orderID: data.id,
						orderNote: data.orderNote,
						orderItems: items,
						prepTime: data.prepTime,
						restaurants: items.map(item => item.rId),
						prices: data.priceData,
						pickupStatus: data.status,
						orderNumber: data.orderNumber,
						rawObject: data,
						deliveryStatus: data.channelData?.doordash?.deliveryStatus || '',
						deliveryTrackingURL: data.channelData?.doordash?.trackingUrl || '',
						scheduled: data.scheduled ?? false,
					}

					if (filterMode === 'pos_only') {
						if (data.userId === auth.currentUser.uid) {
							console.log(data.userId)
							console.log(data.channelData.appType)
							newOrders.push(orderObj)
							pos++
						}
					} else if (filterMode === 'app_only') {
						if (['kiosk', 'mobile', 'web', 'pos'].includes(data.channelData?.appType)) {
							newOrders.push(orderObj)
							pos++
						}
					} else {
						newOrders.push(orderObj)
						pos++
					}
				})

				if (loadMore) {
					setOrdersView(prevOrders => [...prevOrders, ...newOrders])
				} else {
					setOrdersView(newOrders)
				}

				setLastOrderDate(orders[orders.length - 1].orderDate) // Update lastOrderDate for pagination
				setLoading(false)
			})
			.catch(error => {
				console.error('Error fetching orders from cloud function: ', error)
				setLoading(false)
			})
	}
}

export const subscribeToUserOrders = ({ unsubscribeRef, setOrdersView, setLoading }) => {
	if (auth.currentUser !== null) {
		const orderRef = collection(doc(db, DATABASE_NAME, global.org), 'Orders')
		const userId = auth.currentUser.uid

		const orderQuery = query(orderRef, where('userId', '==', userId), orderBy('orderDate', 'desc'))

		// Set up the Firestore onSnapshot listener
		unsubscribeRef.current = onSnapshot(
			orderQuery,
			snapshot => {
				if (snapshot.empty) {
					setOrdersView([]) // No orders found
					setLoading(false)
					return
				}

				const newOrders = []
				let pos = 0

				snapshot.forEach(doc => {
					const data = doc.data()

					if (data.status !== 'Pending' && data.status !== 'Failed') {
						const items = data.orderItems.map(orderItem => {
							const mods = orderItem.modifiers.map(itemMod => ({
								name: itemMod?.name,
								price: itemMod.priceMoney.amount / 100,
							}))

							return {
								name: orderItem?.name,
								qty: orderItem.quantity,
								mods: mods,
								note: orderItem.note,
								total: orderItem.priceMoney.amount,
								id: orderItem.id,
								rId: orderItem.restaurantId,
								noPromo: orderItem.priceMoney.amount,
							}
						})

						const orderObj = {
							pos: pos,
							orderDate: data.orderDate,
							pickupDate: data.pickupDate,
							orderID: data.id,
							orderNote: data.orderNote,
							orderItems: items,
							prepTime: data.prepTime,
							restaurants: items.map(item => item.rId),
							prices: data.priceData,
							pickupStatus: data.status,
							orderNumber: data.orderNumber,
							rawObject: data,
							deliveryStatus: data.channelData?.doordash?.deliveryStatus || '',
							deliveryTrackingURL: data.channelData?.doordash?.trackingUrl || '',
							scheduled: data.scheduled ?? false,
						}

						newOrders.push(orderObj)
						pos++
					}
				})

				setOrdersView(newOrders)
				setLoading(false)
			},
			error => {
				console.error('Error listening to orders: ', error)
				setLoading(false)
			}
		)
	}
}
