import { auth } from '../../../firebase/config'
import { alertResponse, alertResponseSingle } from '../../../components/Alerts/Alerts'
import { getFCMToken, getNotificationPermission } from '../../../helpers/notificationHelpers'
import { addCouponForReferrer, processOrder } from './functions'
import { CheckoutItem, Prices } from './types'
import { getLogoutPrompt } from './prompts'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'
import { Platform } from 'react-native'

interface PlaceOrderData {
	userId: string
	loadedData: any
	items: CheckoutItem[]
	orderNote: string
	couponCode: string
	useNewPaymentMethod: boolean
	couponCodeBackup: string
	restaurantDiscountAmount: number
	isDineIn: boolean
	tableNumber: string
	takeOutName: string
	prices: Prices
	dispatch: any
	cardId: string
	validCouponCodes: string[]
	loading: boolean
	tipCount: number
	selectedMethods: any
	posExtraData: { name: string; firebaseId: string; note: string; type: string }
	timestampData: any
	toggleConfirmModal: () => void
	redeemedRewards: any
	pickupPointObject: any
	pointsEarned: any
	isGuestCheckout: boolean
	navigation: any
	cartPrep: any
	restaurants: any
	isTestMode: boolean
	isDelivery: boolean
}
/**
 *
 * @param data
 * @param paymentProvider
 * Places order by returning async function
 */
export function placeOrder(data: PlaceOrderData, paymentProvider) {
	return async () => {
		if (!auth.currentUser && !data.isGuestCheckout) {
			// not signed in
			data.toggleConfirmModal()
			alertResponse(
				'Not signed in',
				'You must sign in before placing your order',
				'Cancel',
				'Sign in',
				'cancel',
				'default',
				() => {
					return
				},
				() => {
					data.navigation.navigate('AccountStack', {
						screen: 'Account',
						params: { signup: false },
					})
				}
			)
			return null
		}

		data.dispatch(setCheckoutState({ spinner: true }))
		let couponVals = []
		if (data.validCouponCodes !== undefined) {
			couponVals = Object.values(data.validCouponCodes)
		}

		const i = couponVals
			.map(function (e) {
				return e.couponCode.trim().toLowerCase()
			})
			.indexOf(data.couponCode.trim().toLowerCase())

		if ((data.cardId === '' || data.loading) && !data.useNewPaymentMethod && !global.kiosk) {
			data.toggleConfirmModal()
			data.dispatch(setCheckoutState({ spinner: false }))
			return null
		}

		let FCMToken
		if (!global.kiosk && Platform.OS !== 'web') {
			const isNotificationPermitted = await getNotificationPermission()

			if (isNotificationPermitted) {
				FCMToken = await getFCMToken()
			} else {
				FCMToken = ''
			}
		}

		let processedOrder

		try {
			if (auth.currentUser || data.isGuestCheckout) {
				//get restaurant details for order
				//get tax details

				processedOrder = await processOrder({
					paymentProvider,
					prices: data.prices,
					items: data.items,
					orderNote: data.orderNote,
					discount: couponVals[i],
					tip: data.tipCount,
					userId: data.userId,
					isGuestCheckout: data.isGuestCheckout,
					restaurants: data.restaurants,
					tableNumber: data.tableNumber,
					isDineIn: data.isDineIn,
					pickupName: data.takeOutName,
					restaurantDiscountAmount: data.restaurantDiscountAmount,
					selectedMethods: data.selectedMethods,
					posExtraData: data.posExtraData,
					redeemedRewards: data.redeemedRewards,
					pointsEarned: data.pointsEarned,
					timestampData: data.timestampData,
					pickupPointObject: data.pickupPointObject,
					isSandbox: data.loadedData[global.org].environment === 'sandbox' || data.isTestMode,
					cartPrep: data.cartPrep,
					FCMToken,
					loadedData: data.loadedData,
					isDelivery: data.isDelivery,
				})
			} else {
				return getLogoutPrompt(data.navigation)
			}
		} catch (error) {
			console.log(error)
			data.toggleConfirmModal()
			data.dispatch(setCheckoutState({ spinner: false }))
			alertResponseSingle('Error placing order', 'Please try again.', 'OK', null, () => {
				null
			})
		}
		if (processedOrder !== 'PaymentError' && processedOrder.data) {
			data.dispatch(
				setCheckoutState({
					orderId: processedOrder.data[0].orderId,
					orderNumber: processedOrder.data[0].orderNumber,
					orderCreated: processedOrder.created,
				})
			)

			if (data.couponCodeBackup !== '') {
				await addCouponForReferrer(data.couponCodeBackup, data.userId)
			}
		}

		return processedOrder
	}
}
