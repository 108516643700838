import * as React from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import { lTextSize, xlTextSize } from '../../../../constants/GlobalStyle'
import { tipAmountButtons } from './TipModalFunctions'
import { useEffect } from 'react'
import { logAddCheckoutPaymentAnalytics } from '../../../../firebase/analytics'
import CheckoutNavFooter from '../../../../components/Footers/CheckoutNavFooter'
import Colors from '../../../../constants/Colors'
import { Text } from '../../../../components/Themed'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { setCheckoutState } from '../../../../state/Slices/checkoutSlice'

// screen that appears during checkout process & after any option to add a payment method
// does not appear on kiosk
export function AddTip({ prices, tipFunctions, isPOS = false, toggleConfirmModal = null }) {
	const { couponCode, analyticsPaymentMethod } = useAppSelector(state => state.checkout)
	const { paymentMethodId, paymentMethods } = useAppSelector(state => state.user)
	const { isDelivery } = useAppSelector(state => state.persisted)
	const dispatch = useAppDispatch()
	useEffect(() => {
		// screen that appears during checkout process & after any option to add a payment method
		// does not appear on kiosk
		if (analyticsPaymentMethod === null && paymentMethods) {
			if (paymentMethods.length > 0) {
				paymentMethods.forEach(paymentMethod => {
					if (paymentMethod.id === paymentMethodId) {
						logAddCheckoutPaymentAnalytics(couponCode, prices.total, [
							{
								item_brand: paymentMethod.card_brand,
								item_id: paymentMethod.last4,
							},
						])
					}
				})
			}
		} else {
			logAddCheckoutPaymentAnalytics(couponCode, prices.total, [
				{
					item_brand: analyticsPaymentMethod?.brand || '',
					item_id: analyticsPaymentMethod?.last4 || '',
				},
			])
		}
	}, [])

	return (
		<View
			style={{
				flex: 1,
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<Text
				style={{
					fontSize: xlTextSize,
					color: Colors.greyscale[8],
					textAlign: 'center',
					fontWeight: '600',
					marginVertical: 20,
					paddingTop: '5%',
				}}
			>{`ADD A TIP${isDelivery ? ' FOR YOUR DRIVER' : ''}`}</Text>
			{tipAmountButtons(prices, tipFunctions)}
			<Text style={[styles.totalText, isPOS && { marginBottom: 50 }]}>
				{'TOTAL: '}
				<Text style={[{ color: '#57B042' }]}>${prices.total.toFixed(2)}</Text>
			</Text>
			<View style={{ marginBottom: '10%' }}></View>
			{!isPOS && (
				<CheckoutNavFooter
					style={{
						marginBottom: Dimensions.get('window').height * 0.07,
						marginHorizontal: '5%',
					}}
					onBackPress={() => {
						if (toggleConfirmModal) {
							toggleConfirmModal()
						}
					}}
					onNextPress={() => {
						if (!global.pos) {
							dispatch(setCheckoutState({ showAddTip: false }))
						}
					}}
					nextText="NEXT"
				/>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	totalContainer: {
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row',
		flex: 0.5,
		width: '100%',
		//marginBottom: 20,
	},
	totalText: {
		color: 'black',
		fontWeight: '500',
		fontSize: lTextSize,
		letterSpacing: 1,
		flex: 0.5,
		alignSelf: 'flex-start',
		marginHorizontal: '5%',
	},
})
