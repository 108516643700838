import React from 'react'
import { View, Platform } from 'react-native'
import Modal from 'react-native-modal/dist/modal'
import { styles } from '../../Helpers/style'
import ExitIcon from '../../../../components/Headers/UIButtons'
import { useAppSelector } from '../../../../state/hooks'

export function PlaceOrderModal({
	toggleConfirmModal,
	height,
	allowClickAway,
	removePadding = false,
	children,
	showNumPad = false,
	style = {},
	smallHeight = false,
}) {
	const { isConfirmModalVisible } = useAppSelector(state => state.checkout)

	return (
		<Modal
			isVisible={isConfirmModalVisible}
			onBackButtonPress={allowClickAway ? toggleConfirmModal : null}
			onBackdropPress={allowClickAway ? toggleConfirmModal : null}
			avoidKeyboard={false}
			animationIn={'fadeIn'}
			animationOut={'fadeOut'}
			useNativeDriver={true}
			statusBarTranslucent={true}
		>
			<View style={[styles.centeredView, smallHeight && { marginVertical: '50%' }]}>
				<View style={styles.modalView}>
					{allowClickAway && <ExitIcon onPress={toggleConfirmModal} style={{ top: 20 }} />}
					<View
						style={{
							marginTop: Platform.OS === 'web' ? 15 : 30,
							width: '90%',
							height: '100%',
							justifyContent: 'space-between',
							marginBottom: Platform.OS === 'web' ? 100 : 0,
						}}
					>
						{children}
					</View>
				</View>
			</View>
		</Modal>
	)
}
