import * as React from 'react'
import { Dimensions, Platform, ScrollView, TouchableOpacity } from 'react-native'
import { View, Text } from '../../../components/Themed'
import { checkSnooze } from '../../../helpers/checkSnooze'
import ImageObject from '../../../components/Image/ImageObject'
import Colors from '../../../constants/Colors'
import { largeTextSize, mediumTextSize } from '../../../constants/GlobalStyle'
import { styles } from '../style'
import { logSelectMenuAnalytics } from '../../../firebase/analytics'
import { useAppSelector } from '../../../state/hooks'
import { useEffect, useState } from 'react'

/**
 * Brand section of the home page of the app
 */
export function BrandList({ navigation }) {
	const { restaurants } = useAppSelector(state => state.data)
	const { isTestMode } = useAppSelector(state => state.persisted)
	const { isSandbox } = useAppSelector(state => state.settings)

	const [filteredRestaurants, setFilteredRestaurants] = useState([])

	useEffect(() => {
		const filterRestaurants = () => {
			const updatedFilteredRestaurants = restaurants.filter(restaurant => {
				const isNotSnoozed = !checkSnooze(restaurant.isSnoozed, restaurant.snoozeUntil)
				const isValidTestRestaurant = !restaurant.isTest || isSandbox || isTestMode
				return isNotSnoozed && isValidTestRestaurant
			})
			setFilteredRestaurants(updatedFilteredRestaurants)
		}

		filterRestaurants()
	}, [isTestMode])

	const centerContent = restaurants.length < 4.5

	const width = Dimensions.get('window').width
	const overMaxWidth = width * 0.9 > 1200
	const screenWidth = overMaxWidth ? 1200 : width * 0.9

	const LOGO_SIZE = filteredRestaurants.length < 3.5 ? mediumTextSize * 12.5 : mediumTextSize * 10
	const offset_percent = (width / 2 - screenWidth / 2) / width

	if (filteredRestaurants.length <= 0) {
		return (
			<Text
				style={{
					textAlign: 'center',
					fontStyle: 'italic',
					color: Colors.greyscale[7],
					fontSize: largeTextSize,
					paddingTop: Platform.OS === 'web' ? 20 : 0,
				}}
			>
				{'We are unable to accept new orders at this time.'}
			</Text>
		)
	}
	return (
		<View style={{ width: width }}>
			<View
				style={{
					width: screenWidth,
					alignSelf: 'center',
				}}
			>
				<Text style={[styles.subHeaderText, { paddingTop: '5%', paddingBottom: '5%' }]}>
					{'SHOP BY BRAND'}
				</Text>
			</View>
			<ScrollView
				horizontal
				showsHorizontalScrollIndicator={false}
				contentContainerStyle={[
					{
						marginLeft: width * offset_percent,
						alignItems: 'flex-start',
						justifyContent: 'flex-start',
						paddingRight: '10%',
					},
					{
						...(centerContent && {
							justifyContent: 'center',
							flexGrow: 1,
						}),
					},
					{ justifyContent: 'flex-start' },
				]}
			>
				{filteredRestaurants.map((restaurant, index) => (
					<View
						key={index + restaurant.id}
						style={{
							width: LOGO_SIZE,
							height: LOGO_SIZE,
							borderRadius: LOGO_SIZE / 2,
							overflow: 'hidden',
							marginLeft: index === 0 ? 0 : 15,
							marginRight: index === filteredRestaurants.length - 1 ? 0 : 30,
						}}
					>
						<TouchableOpacity
							key={restaurant.id}
							onPress={() => {
								if (restaurant !== undefined) {
									logSelectMenuAnalytics('brand_menu', restaurant.id) //log analytics
									navigation.navigate('Menu', { rId: restaurant.id })
								} else {
									console.log('Could not load.')
								}
							}}
						>
							{restaurant.logoImg !== undefined && restaurant.logoImg !== '' ? (
								<ImageObject
									source={restaurant.logoImg}
									style={{ width: LOGO_SIZE, height: LOGO_SIZE }}
								/>
							) : (
								<View
									style={{
										width: LOGO_SIZE,
										height: LOGO_SIZE,
										backgroundColor: Colors.greyscale[2],
									}}
								></View>
							)}
						</TouchableOpacity>
					</View>
				))}
			</ScrollView>
		</View>
	)
}
