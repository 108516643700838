import { DATABASE_NAME } from '@env'
import { doc, getDoc } from 'firebase/firestore'
import { auth, db } from '../../firebase/config'
import '../../global'

export function serializeUserTimestamps(userData) {
	if (!userData || !userData.user) {
		return userData // Return original if userData or user is invalid
	}

	const deepClone = obj => JSON.parse(JSON.stringify(obj)) // Deep clone the userData to avoid mutation
	const updatedUserData = deepClone(userData)

	// Convert user.createdAt to Unix timestamp (milliseconds)
	if (updatedUserData.user.createdAt && updatedUserData.user.createdAt.seconds !== undefined) {
		updatedUserData.user.createdAt = updatedUserData.user.createdAt.seconds * 1000 // Convert to milliseconds
	}

	// Convert updatedAt in each deliveryAddress to Unix timestamp (milliseconds)
	if (Array.isArray(updatedUserData.user.deliveryAddresses)) {
		updatedUserData.user.deliveryAddresses = updatedUserData.user.deliveryAddresses.map(address => {
			if (address.updatedAt && address.updatedAt.seconds !== undefined) {
				return {
					...address,
					updatedAt: address.updatedAt.seconds * 1000, // Convert to milliseconds
				}
			}
			return address // If no updatedAt, return the original address
		})
	}

	return updatedUserData
}

export const getUserData = async uid => {
	const dataImport: any = {}
	const userDocRef = doc(db, DATABASE_NAME, global.org, 'Users', uid)

	await getDoc(userDocRef)
		.then(documentSnapshot => {
			if (documentSnapshot.exists()) {
				const data = documentSnapshot.data() // Get the document data
				if (data) {
					dataImport['user'] = data
					console.log('Setting user data')
				}
			} else {
				console.log('Not signed in.')
			}
		})
		.catch(error => {
			console.error('Error getting document:', error)
		})

	return dataImport
}
