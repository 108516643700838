import React, { useEffect, useRef, useState } from 'react'
import { View, StyleSheet, TouchableOpacity, Animated, Platform } from 'react-native'
import { SharpTriangle } from '../../../components/Headers/Triangle'
import GlobalStyle, { largeTextSize, titleTextSize } from '../../../constants/GlobalStyle'
import ImageObject from '../../../components/Image/ImageObject'
import Colors from '../../../constants/Colors'
import { startOfMinute } from 'date-fns'
import { Text } from '../../../components/Themed'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'

/**
 * Landing screen for kiosk that shows a promotion image/video with "touch to start" button
 * Will show organization logo if no promo image/video is available
 * Overlays the restaurant select screen, disappears on press
 */
const KioskStartScreen = ({ defaultLogo, imageList, setVisible }) => {
	return <></>
}

export default KioskStartScreen
