import { createSlice, PayloadAction } from '@reduxjs/toolkit'
const initialState = {
	userId: '',
	stripeId: '',
	referralCode: '',
	paymentMethodId: '',
	firstName: '',
	lastName: '',
	userEmail: '',
	userPhone: '',
	userData: { user: {} as any },
	deviceHasInternet: true,
	paymentMethods: [] as any[],
	userLocation: {} as any,
}

type StateTypes = typeof initialState

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setStates: (state, action: PayloadAction<Partial<StateTypes>>) => {
			Object.assign(state, action.payload)
		},
		resetState: state => {
			Object.assign(state, {
				...initialState,
				userLocation: state.userLocation,
			})
		},
		resetLocation: state => {
			state.userLocation = initialState.userLocation
		},
	},
})

const { setStates, resetState, resetLocation } = slice.actions

export const setUserState = (updates: Partial<StateTypes>) => setStates(updates)
export const resetUserState = () => resetState()
export const resetUserLocation = () => resetLocation()

export default slice.reducer
