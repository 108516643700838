import React, { useEffect } from 'react'
import { Platform } from 'react-native'
import { RootTabScreenProps } from '../../navigation/types'
import { auth } from '../../firebase/config'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { priceSetter } from './Helpers/priceFunctions'
import { Elements } from '@stripe/react-stripe-js'
import { parsePrepTime } from '../../helpers/prepTimeFunctions'
import { add, intervalToDuration } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { restaurantDiscountPercentages } from './Helpers/checkCouponCode'
import useCheckout from './Hooks/useCheckout'
import { CheckoutView } from './Components/UI'
import useStatusUpdate from './Hooks/useStatusUpdate'
import useIdleReset from './Hooks/useIdleReset'
import useDataRefresh from './Hooks/useDataRefresh'
import { logBeginCheckoutAnalytics } from '../../firebase/analytics'
import useFetchCards from './Hooks/useFetchCards'
import { useSquareCheckout } from '../../components/Square/Hooks/useSquareCheckout'
import {
	CheckDeliveryEligibilityParams,
	calculateAdditionalAmountForDelivery,
} from '../../helpers/getDeliveryMinimum'
import Formatter from '../../helpers/Formatter'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { clearCart, resetCartPrepState, setPersistedState } from '../../state/Slices/persistedSlice'
import { setCheckoutState } from '../../state/Slices/checkoutSlice'
import { setDataState } from '../../state/Slices/dataSlice'
import usePickupTimeCheck from './Hooks/usePickupTimeCheck'

export default function CheckoutScreen({ navigation }: RootTabScreenProps<'TabCheckout'>) {
	const { loadedData } = useAppSelector(state => state.data)
	const { localTimezone } = useAppSelector(state => state.settings)
	const { cart } = useAppSelector(state => state.persisted)

	const {
		items,
		couponCode,
		userRedeemedCodes,
		userAvailableCodes,
		isFreeItemDiscount,
		isConfirmModalVisible,
		isPromoModalVisible,
		validCouponCodes,
		bagFee,
		bagFeeSelected,
		isPaymentMethodModalVisible,
		analyticsItems,
		savedPhone,
		prices,
		tipCount,
	} = useAppSelector(state => state.checkout)

	const { delivery, posLoyaltyEnabled, deliveryEnabled, serviceFee, serviceFeeTax } =
		useAppSelector(state => state.settings)
	const { cartPrep, isDelivery, deliveryEstimate, deliveryEstimateList } = useAppSelector(
		state => state.persisted
	)

	const functions = getFunctions()

	let deliveryEstimateForRestaurant: any = deliveryEstimate

	if (
		deliveryEstimateList &&
		cart &&
		deliveryEstimateList.length > 0 &&
		cart.length > 0 &&
		!loadedData[global.org].singleBrandEnabled
	) {
		const estimate = deliveryEstimateList.find(est => est.restaurantId === cart[0].rId)
		if (estimate) {
			deliveryEstimateForRestaurant = estimate
		}
	}

	useCheckout({ navigation }) //load checkout items & data

	useFetchCards()

	useStatusUpdate() //update order status for kiosk

	useSquareCheckout() // initialize square sdk

	//useIdleReset({ goHome }) //checks for return to home after order confirmed modal

	useDataRefresh({ navigation, priceSet })

	usePickupTimeCheck({ navigation })

	const dispatch = useAppDispatch()

	var tipFuncData = {
		tip0: () => [priceSet(couponCode, 0), setTip(dispatch, 0)],
		tip5: () => [priceSet(couponCode, 5), setTip(dispatch, 5)],
		tip10: () => [priceSet(couponCode, 10), setTip(dispatch, 10)],
		tip15: () => [priceSet(couponCode, 15), setTip(dispatch, 15)],
		tip18: () => [priceSet(couponCode, 18), setTip(dispatch, 18)],
		tip20: () => [priceSet(couponCode, 20), setTip(dispatch, 20)],
		setCustomTip: amount => [priceSet(couponCode, amount, 'custom'), setTip(dispatch, 9999)],
		closeModal: () => [toggleConfirmModal(), setAllowOrderAndConfirmModal(dispatch, true)],
	}

	var promoFuncData = {
		closeModal: () => [
			togglePromoModal(),
			dispatch(setCheckoutState({ allowOrder: true, couponCodeDisplay: '' })),
		],
		setPromo: value => [priceSet(value)],
		toggleModal: () => togglePromoModal(),
	}

	const toggleConfirmModal = () => {
		const email = auth.currentUser ? Formatter.decodedEmail(auth.currentUser.email) : ''
		let phone = savedPhone

		if (savedPhone.length === 11 && savedPhone.startsWith('1')) {
			phone = savedPhone.substring(1)
		}

		if (!isConfirmModalVisible) {
			logBeginCheckoutAnalytics(prices.total, analyticsItems)
		}

		dispatch(
			setCheckoutState({
				selectedMethods: {
					textMessage: false,
					email: false,
					print: false,
					textUpdates: true,
					showNumPad: false,
					phoneString: global.kiosk ? '' : phone,
					emailString: global.kiosk ? '' : email,
					phoneStringFormatted: global.kiosk ? '' : phone,
				},
				showPOSMethods: global.pos,
				typePOS: '',
				showDineInOrTakeout: true,
				tableNumber: null,
				phoneNumberInput: false,
				showAddTip: !global.kiosk,
				//useNewPaymentMethod: false,
				showKioskLoyaltyEntry: posLoyaltyEnabled,
			})
		)
		dispatch(setDataState({ disableIdleTimer: !isConfirmModalVisible }))

		if (auth.currentUser || (Platform.OS === 'web' && (!deliveryEnabled || !isDelivery))) {
			dispatch(setCheckoutState({ isConfirmModalVisible: !isConfirmModalVisible }))
		} else {
			navigation.navigate('AccountStack', { screen: 'Account', params: { onCheckout: true } })
		}

		setShowGuestCheckout(dispatch, false)
	}

	const togglePromoModal = () => {
		dispatch(setCheckoutState({ couponCodeDisplay: '' }))

		if (!isPromoModalVisible) {
			dispatch(setCheckoutState({ couponRefresh: [] }))
		}

		dispatch(setCheckoutState({ isPromoModalVisible: !isPromoModalVisible }))
	}

	const togglePaymentMethodModal = () => {
		dispatch(
			setCheckoutState({
				selectedCard: null,
				isAddingCard: false,
				isPaymentMethodModalVisible: !isPaymentMethodModalVisible,
			})
		)
	}

	function priceSet(code, tipValue = null, tipType = null) {
		if (auth.currentUser) {
			const getUserCoupons = httpsCallable(functions, 'getUserCoupons')

			getUserCoupons({
				orgId: global.org,
				userId: auth.currentUser.uid,
			}).then((res: { data: any }) => {
				dispatch(
					setCheckoutState({
						userAvailableCodes: res.data.available,
						userRedeemedCodes: res.data.redeemed,
					})
				)
			})
		}
		let tipAmt = 0
		if (tipValue && tipType === 'custom') {
			tipAmt = tipAmount(tipValue)
		} else if (tipValue) {
			tipAmt = tipCalc(tipValue)
		}

		let couponVals = []
		if (validCouponCodes !== undefined) {
			couponVals = Object.values(validCouponCodes)
		}
		const promo = couponVals.filter(
			e => e.couponCode.toLowerCase().trim() === code.toLowerCase().trim()
		)

		if (promo[0] && promo[0].type === 'BOGO') {
			var token = promo[0].itemIds[0]
			const itemInCart = cart.filter(c => c.id === token && c.qty > 1)
			if (itemInCart.length <= 0) {
				togglePromoModal()
				navigation.navigate('Item', {
					token: token,
					editing: null,
					isOpen: true,
					menuId: global.menuId,
					menuItemCategory: 'BOGO',
					appliedCoupon: promo[0],
					category: 'BOGO',
				})
			} else {
				itemInCart[0].appliedCoupon = promo[0]
				togglePromoModal()
				navigation.navigate('restaurant')
				setTimeout(() => {
					navigation.navigate('TabCheckout')
				}, 100)
			}
		} else if (promo[0] && promo[0].type === 'freeItem') {
			if (promo[0].minSpend <= prices.subTotal) {
				var token = promo[0].freeItemIds[0]

				const itemInCart = cart.filter(c => c.id === token)
				if (itemInCart.length <= 0) {
					togglePromoModal()
					navigation.navigate('Item', {
						token: token,
						editing: null,
						isOpen: true,
						menuId: global.menuId,
						menuItemCategory: 'Free Item',
						appliedCoupon: promo[0],
						category: 'Free Item',
					})
				} else {
					itemInCart[0].appliedCoupon = promo[0]
					togglePromoModal()
					navigation.navigate('restaurant')
					setTimeout(() => {
						navigation.navigate('TabCheckout')
					}, 100)
				}
			}
		} else {
			const i = couponVals
				.map(function (e) {
					return e.couponCode.trim().toLowerCase()
				})
				.indexOf(code.trim().toLowerCase())

			dispatch(
				setCheckoutState({
					couponCodeBackup: code.trim().toLowerCase(),
					couponCode: code.trim().toLowerCase(),
					couponName: promo[0] ? promo[0].name : '',
					restaurantDiscountAmount: restaurantDiscountPercentages(couponVals[i], items),
				})
			)

			const freeDeliveryPrice =
				deliveryEstimateForRestaurant && deliveryEstimateForRestaurant.estimate
					? Math.round(
							deliveryEstimateForRestaurant.estimate.fee * delivery.freeDeliveryMultiplier +
								delivery.minSpend
						) / 100
					: -1

			return priceSetter({
				code: code.trim().toLowerCase(),
				couponVals: couponVals,
				userAvailableCodes: userAvailableCodes,
				userRedeemedCodes: userRedeemedCodes,
				prices: prices,
				items: items,
				tipCount: tipAmt,
				serviceFee: serviceFee,
				serviceFeeTax: serviceFeeTax,
				dispatch: dispatch,
				couponCode: couponCode,
				loadedData: loadedData,
				cart: cart,
				isFreeItemDiscount: isFreeItemDiscount,
				couponInfo: promo,
				bagFee: bagFeeSelected ? bagFee : null,
				isBankersRounding: loadedData[global.org].taxRoundingMethod === 'bankers' || false,
				deliveryFee:
					isDelivery && deliveryEstimateForRestaurant && deliveryEstimateForRestaurant.estimate
						? deliveryEstimateForRestaurant.estimate.fee
						: 0,
				deliveryFeeOrgCoverage:
					isDelivery && deliveryEstimateForRestaurant && deliveryEstimateForRestaurant.estimate
						? deliveryEstimateForRestaurant.estimate.totalFee -
							deliveryEstimateForRestaurant.estimate.fee
						: 0,
				freeDeliveryPrice: freeDeliveryPrice,
			})
		}
	}

	const tipCalc = x => {
		const tip = 0.01 * x * (prices.subTotal + prices.discount)
		dispatch(setCheckoutState({ tipCount: tip }))
		return tip
	}

	const tipAmount = x => {
		dispatch(setCheckoutState({ tipCount: x }))
		return x
	}

	function getSchedInterval(isCheck) {
		let prepReturn = ''
		let valid = true
		const currTime = utcToZonedTime(new Date(), localTimezone)
		if (cartPrep.isScheduled) {
			const parsed = parsePrepTime(cartPrep.prepTimeDuration)
			const pickupTime = add(currTime, parsed)

			if (currTime >= pickupTime) {
				valid = false
			} else {
				const adjustedPickupDuration = intervalToDuration({
					start: utcToZonedTime(new Date(), localTimezone),
					end: pickupTime,
				})

				prepReturn =
					'P' +
					adjustedPickupDuration.days +
					'DT' +
					adjustedPickupDuration.hours +
					'H' +
					adjustedPickupDuration.minutes +
					'M' +
					adjustedPickupDuration.seconds +
					'S'
			}
		} else {
			prepReturn = 'P0DT0H' + cartPrep.longestPrepTime + 'M0S'
		}

		return isCheck ? valid : prepReturn
	}
	function goHome(isAfterCheckout) {
		if (isAfterCheckout) {
			toggleConfirmModal()
			dispatch(resetCartPrepState({ localTimezone: localTimezone, getLongestPrepTime: 15 }))
		}

		dispatch(
			setCheckoutState({
				couponCode: '',
				orderNote: '',
				tipCount: 0,
				showNotes: false,
				isTaxesExpanded: false,
				phoneNumber: '',
				isGuestCheckout: false,
				orderCreated: false,
				couponCodeBackup: '',
				couponCodeDisplay: '',
				pointsEarned: 0,
				showKioskLoyaltyEntry: posLoyaltyEnabled,
				isCardValid: false,
			})
		)

		dispatch(setPersistedState({ redeemedRewards: [] }))

		dispatch(setDataState({ showKioskStartScreen: true }))

		dispatch(clearCart())

		if (Platform.OS === 'web') {
			navigation.replace('HomeStack', { screen: 'RestaurantSelect' })
		} else {
			navigation.reset({
				index: 0,
				routes: [{ name: 'HomeStack' }],
			})
		}
	}

	const retrieveSquareLoyaltyPointEstimate = httpsCallable(
		functions,
		'retrieveSquareLoyaltyPointEstimate'
	)
	useEffect(() => {
		async function fetchData() {
			const pointTotal = await retrieveSquareLoyaltyPointEstimate({
				orgId: global.org,
				restaurantId: cart.length > 0 ? cart[0].rId : '',
				orderTotal: prices.subTotal,
			})

			dispatch(setCheckoutState({ posLoyaltyPoints: pointTotal.data as number }))
		}

		if (prices.total > 0 && loadedData[global.org].posLoyaltyEnabled) {
			dispatch(setCheckoutState({ showKioskLoyaltyEntry: true }))
			fetchData()
		}

		const params: CheckDeliveryEligibilityParams = {
			totalOrderPrice: Math.round(prices.total * 100),
			subtotalOrderPrice: Math.round(prices.subTotal * 100),
			totalDeliveryFee: prices.deliveryFee + prices.deliveryFeeOrgCoverage,
			minimumDeliveryOrderAmount: delivery.minSpend,
		}

		const additionalAmountNeeded = calculateAdditionalAmountForDelivery(params)
		dispatch(setCheckoutState({ deliveryAmountToSpend: additionalAmountNeeded }))
	}, [prices])

	return Platform.OS === 'web' ? (
		<Elements stripe={global.stripePromise}>
			{
				<CheckoutView
					navigation={navigation}
					priceSet={priceSet}
					goHome={goHome}
					getSchedInterval={getSchedInterval}
					tipFuncData={tipFuncData}
					promoFuncData={promoFuncData}
					togglePromoModal={togglePromoModal}
					toggleConfirmModal={toggleConfirmModal}
					togglePaymentMethodModal={togglePaymentMethodModal}
				/>
			}
		</Elements>
	) : (
		<CheckoutView
			navigation={navigation}
			priceSet={priceSet}
			goHome={goHome}
			getSchedInterval={getSchedInterval}
			tipFuncData={tipFuncData}
			promoFuncData={promoFuncData}
			togglePromoModal={togglePromoModal}
			toggleConfirmModal={toggleConfirmModal}
			togglePaymentMethodModal={togglePaymentMethodModal}
		/>
	)
}

function setShowGuestCheckout(dispatch, value) {
	dispatch(setCheckoutState({ isGuestCheckout: value }))
}

function setAllowOrderAndConfirmModal(dispatch, value) {
	dispatch(setCheckoutState({ allowOrder: value, isConfirmModalVisible: !value }))
}

function setTip(dispatch, tipValue) {
	dispatch(setCheckoutState({ selectedTip: tipValue }))
}
