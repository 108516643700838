import React from 'react'
import { View, TouchableOpacity, Image, Linking, Dimensions } from 'react-native'

const width = Dimensions.get('window').width

const GooglePlayLink = ({ link }) => {
	const handlePress = () => {
		const url = link
		Linking.openURL(url).catch(err => console.error('An error occurred', err))
	}
	if (link !== '') {
		return (
			<TouchableOpacity onPress={handlePress}>
				<Image
					source={{
						uri: 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png',
					}}
					style={{ width: 205, height: 80 }}
					resizeMode="contain"
				/>
			</TouchableOpacity>
		)
	}
	return <></>
}

const AppleStoreLink = ({ link }) => {
	const handlePress = () => {
		const url = link
		Linking.openURL(url).catch(err => console.error('An error occurred', err))
	}
	if (link !== '') {
		return (
			<TouchableOpacity onPress={handlePress} style={{ borderRadius: 13 }}>
				<Image
					source={{
						uri: 'https://developer.apple.com/news/images/download-on-the-app-store-badge.png',
					}}
					style={{ width: 205, height: 60 }}
					resizeMode="contain"
				/>
			</TouchableOpacity>
		)
	}
	return <></>
}

const AppStoreLinks = ({ iosURL, androidURL }) => {
	return (
		<View>
			{androidURL !== '' && <GooglePlayLink link={androidURL} />}
			{iosURL !== '' && <AppleStoreLink link={iosURL} />}
		</View>
	)
}

export default AppStoreLinks
