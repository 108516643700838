import { Platform, StyleSheet } from 'react-native'
import Colors from '../../../constants/Colors'
import GlobalStyle from '../../../constants/GlobalStyle'

export const styles = StyleSheet.create({
	orderContainer: {
		borderBottomWidth: 2,
		borderLeftWidth: 2,
		borderRightWidth: 2,
		borderColor: Colors.custom.lightGrey,
		padding: 15,
	},
	sectionContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '2%',
		backgroundColor: 'white',
	},
	container: {
		maxWidth: 1250,
		width: '100%',
		alignSelf: 'center',
		backgroundColor: Colors.custom.white,
	},
	innerContainer: {
		marginTop: Platform.OS === 'web' ? 0 : -40,
		paddingHorizontal: 0,
		paddingTop: 0,
		paddingBottom: 0,
	},
	loadMoreButton: {
		padding: 15,
		borderRadius: 10,
		backgroundColor: global.orgColor,
		alignSelf: 'center',
		alignItems: 'center',
		width: 200,
		marginTop: 20,
		marginBottom: 40,
	},
	signedOutContainer: {
		...GlobalStyle.container,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: Colors.custom.appBackground,
	},
	filterContainer: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		paddingVertical: 10,
		backgroundColor: '#f8f8f8',
		borderRadius: 10,
		margin: 10,
		width: 500,
		alignSelf: 'center',
	},
	button: {
		paddingVertical: 10,
		paddingHorizontal: 20,
		backgroundColor: '#e0e0e0',
		borderRadius: 20,
	},
	noOrderContainer: {
		...GlobalStyle.container,
		alignItems: 'center',
		paddingTop: 30,
		backgroundColor: Colors.custom.appBackground,
	},

	buttonText: {
		color: '#000',
		fontWeight: '600',
	},
	activeButtonText: {
		color: '#fff',
	},
})
