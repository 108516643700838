import React, { useEffect } from 'react'
import { Platform, View } from 'react-native'
import { placeOrder } from '../../Helpers/placeOrder'
import StripeWebPayment from '../../../../components/Stripe/StripePayment.web'
import { auth } from '../../../../firebase/config'
import StripePayment from '../../../../components/Stripe/StripePayment'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { setCheckoutState } from '../../../../state/Slices/checkoutSlice'

/**
 * Returns "PAY NOW" button to place order
 */
export function ConfirmOrder({ navigation, toggleConfirmModal, getSchedInterval }) {
	const { loadedData, restaurants, selectedPickupPoint } = useAppSelector(state => state.data)
	const { cartPrep, redeemedRewards, isTestMode, isDelivery } = useAppSelector(
		state => state.persisted
	)
	const { stripeId, paymentMethodId, userData } = useAppSelector(state => state.user)
	const { paymentProvider } = useAppSelector(state => state.settings)
	const {
		saveCard,
		useSavedPaymentMethod,
		items,
		orderNote,
		couponCode,
		useNewPaymentMethod,
		couponCodeBackup,
		restaurantDiscountAmount,
		tableNumber,
		takeOutName,
		prices,
		isGuestCheckout,
		validCouponCodes,
		loading,
		tipCount,
		typePOS,
		selectedMethods,
		posExtraData,
		paymentRequest,
		modalTimestamps,
		pointsEarned,
		isDineIn,
	} = useAppSelector(state => state.checkout)

	const userId = auth.currentUser ? auth.currentUser.uid : ''

	const dispatch = useAppDispatch()

	//for placeOrder function
	const data = {
		userId: userId,
		loadedData: loadedData,
		items: items,
		orderNote: orderNote,
		couponCode: couponCode,
		dispatch: dispatch,
		useNewPaymentMethod: useNewPaymentMethod,
		couponCodeBackup: couponCodeBackup,
		restaurantDiscountAmount: restaurantDiscountAmount,
		isDineIn: isDineIn,
		tableNumber: tableNumber,
		takeOutName: takeOutName,
		prices: prices,
		isGuestCheckout: isGuestCheckout,
		cardId: paymentMethodId,
		validCouponCodes: validCouponCodes,
		loading: loading,
		tipCount: tipCount,
		navigation: navigation,
		toggleConfirmModal: toggleConfirmModal,
		selectedMethods: selectedMethods,
		posExtraData: posExtraData,
		redeemedRewards: redeemedRewards,
		pointsEarned: pointsEarned,
		timestampData: modalTimestamps,
		pickupPointObject: selectedPickupPoint,
		cartPrep: cartPrep,
		restaurants: restaurants,
		ageVerificationRequired: false,
		isTestMode: isTestMode,
		isDelivery: isDelivery,
	}
	useEffect(() => {
		console.log(prices.total + ' TOTAL')
		const dataCopy = { ...data }
		delete dataCopy.loadedData
		const hasAgeVerificationRequired = dataCopy.items.some(obj => obj.ageVerificationRequired)
		dataCopy.ageVerificationRequired = hasAgeVerificationRequired
		delete dataCopy.dispatch
		delete dataCopy.navigation
		delete dataCopy.toggleConfirmModal
		dispatch(setCheckoutState({ orderData: dataCopy }))
	}, [])

	return (
		<View>
			{Platform.OS === 'web' ? (
				<StripeWebPayment
					onPress={placeOrder(data, paymentProvider)}
					amount={prices.total}
					saveCard={saveCard}
					userStripeId={
						stripeId && stripeId !== ''
							? stripeId
							: userData && userData.user && userData.user.stripeId
								? userData.user.stripeId
								: ''
					}
					userId={userId}
					cardId={useSavedPaymentMethod ? paymentMethodId : ''}
					toggleModal={toggleConfirmModal}
					prices={prices}
					paymentProvider={paymentProvider}
					getSchedInterval={getSchedInterval}
					paymentRequest={paymentRequest}
					data={data}
				/>
			) : (
				<View style={{ bottom: 0, position: 'absolute', width: '100%' }}>
					<StripePayment
						onPress={placeOrder(data, paymentProvider)}
						amount={prices.total}
						saveCard={saveCard}
						userStripeId={
							stripeId && stripeId !== ''
								? stripeId
								: userData && userData.user && userData.user.stripeId
									? userData.user.stripeId
									: ''
						}
						userId={userId}
						cardId={useSavedPaymentMethod ? paymentMethodId : ''}
						toggleModal={toggleConfirmModal}
						prices={prices}
						isDineIn={isDineIn}
						posType={typePOS}
						paymentProvider={paymentProvider}
						toggleConfirmModal={toggleConfirmModal}
					/>
				</View>
			)}
		</View>
	)
}
