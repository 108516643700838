import React, { useState } from 'react'
import { View, TouchableOpacity, StyleSheet, Dimensions } from 'react-native'
import Colors from '../../constants/Colors'
import ImageObject from '../Image/ImageObject'
import { Feather, MaterialCommunityIcons } from '@expo/vector-icons'
import GlobalStyle from '../../constants/GlobalStyle'
import WebDropdownMenu from './WebMenuHeader'
import { auth } from '../../firebase/config'
import { Text } from '../Themed'
import DeliveryPickupToggle from '../Delivery/DeliveryPickupToggle'
import { useAppSelector } from '../../state/hooks'

export const WebHeader = ({ logo, navigation, returnToTop = null }) => {
	const { loadedData } = useAppSelector(state => state.data)
	const { cart } = useAppSelector(state => state.persisted)

	const [menuVisible, setMenuVisible] = useState(false)
	const cartCount = cart.reduce((count, item) => count + item.qty, 0) //get # of items in cart
	return (
		<>
			<View
				style={[
					styles.container,
					{ backgroundColor: global.orgColor ? global.orgColor : Colors.custom.restaurantMain },
				]}
			>
				<View style={styles.outerContainer}>
					<View style={styles.innerContainer}>
						<TouchableOpacity
							onPress={() => {
								if (returnToTop) {
									returnToTop()
								} else {
									navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
								}
							}}
							activeOpacity={1}
						>
							<ImageObject
								hideDefault={true}
								source={logo}
								style={styles.image}
								resizeMode={'contain'}
							/>
						</TouchableOpacity>

						<View style={{ flexDirection: 'row', alignItems: 'center', marginRight: '4%' }}>
							{!loadedData[global.org].singleBrandEnabled && (
								<View style={{ marginRight: 20, marginTop: -10 }}>
									<DeliveryPickupToggle textColor={'white'} homePage={true} />
								</View>
							)}

							<TouchableOpacity
								onPress={() => {
									navigation.navigate('TabCheckout')
								}}
								style={{ padding: 5 }}
							>
								{cartCount > 0 ? (
									<View
										style={[
											GlobalStyle.fabTO3,
											{ backgroundColor: global.orgColor, right: -3, top: 0 },
										]}
									>
										<Text style={{ fontSize: 11, color: 'white' }}>{cartCount}</Text>
									</View>
								) : null}
								<MaterialCommunityIcons name="shopping-outline" size={26} color="white" />
							</TouchableOpacity>
							<View style={{ width: 20 }}></View>
							<TouchableOpacity
								onPress={() => {
									setMenuVisible(!menuVisible)
								}}
								style={{ padding: 5 }}
							>
								<Feather name="menu" size={25} color="white" style={{ marginTop: 5 }} />
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</View>
			{menuVisible && (
				<WebDropdownMenu
					isVisible={menuVisible}
					onClose={() => setMenuVisible(false)}
					auth={auth}
					navigation={navigation}
				/>
			)}
		</>
	)
}

const styles = StyleSheet.create({
	container: {
		height: 100,
		marginLeft: 0,
		overflow: 'hidden',
		zIndex: 1000,
	},
	outerContainer: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	innerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		maxWidth: 1320,
		width: '100%',
	},
	image: {
		height: 65,
		flex: 1,
		marginLeft: Dimensions.get('window').width * 0.04, //4%
	},
})
