import { getFunctions, httpsCallable } from 'firebase/functions'
import { auth } from '../../../firebase/config'
import crashlytics from '../../Crashlytics/crashlyticsLog'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setUserState } from '../../../state/Slices/userSlice'

const functions = getFunctions()

export const useSquare = () => {
	const { paymentMethods } = useAppSelector(state => state.user)
	const { selectedLocation } = useAppSelector(state => state.persisted)
	const dispatch = useAppDispatch()
	const restaurantId = selectedLocation ? selectedLocation.id : null
	const uid = auth.currentUser ? auth.currentUser.uid : ''

	const createCustomerCard = async (nonce: string, paymentId: string, hasPayed: boolean) => {
		const createCard = httpsCallable(functions, 'createSquareCustomerCard')
		console.log('creating a card', uid)
		console.log('restaurantId while creating card', restaurantId)
		crashlytics().log('creating a card ' + uid)
		crashlytics().log('restaurantId while creating card' + restaurantId)

		const response: any = await createCard({
			nonce,
			uid,
			paymentId,
			hasPayed,
			orgId: global.org,
			restaurantId,
		})
		if (response.data.errorMessage) {
			throw new Error(response.data.errorMessage)
		}
		const customerCard = response.data
		dispatch(
			setUserState({
				paymentMethods: [...paymentMethods, customerCard],
			})
		)
	}

	const getCards = async () => {
		const retrieveSquareCards = httpsCallable(functions, 'retrieveSquareCards')
		const response: any = await retrieveSquareCards({ restaurantId, orgId: global.org, uid })
		console.log('CARDS', response.data.length)
		return response.data
	}

	const disableCard = async id => {
		const disableCard = httpsCallable(functions, 'disableSquareCard')
		await disableCard({ restaurantId, orgId: global.org, cardId: id })
		dispatch(
			setUserState({
				paymentMethods: paymentMethods.filter(item => item.id != id),
			})
		)
	}

	return { getCards, disableCard, createCustomerCard }
}
