import { Dimensions, Platform, StyleSheet } from 'react-native'
import Colors from './Colors'
import '../global.js'
import { RFPercentage, RFValue } from 'react-native-responsive-fontsize'

const width = Dimensions.get('window').width
const height = Dimensions.get('window').height
export const screenValue = value => (IS_LANDSCAPE ? RFValue(value, width) : RFValue(value, height))
// refactoring each of these, will comment finished ones
// fixing them one by one then gloss over case by case

/*
finished: 
	- deleting redundant code: code that wasn't used
todo: 
	- images on checkout for landscape mode still too big in MenuItem
	- check mobile/web version later gosh need a better method of testing kiosk and mobile
	- testing pay by cash on different screen sizes
	- modal needs to be debugged on app
	- button spacing is not equal on tablet for POS home screen*
	- icon sizes arent responsive
	* = app specific
*/
const overMaxWidth = width > 1200
export const IS_LANDSCAPE = width > height
export const MAX_CHECKOUT_WIDTH = width > 950 ? 925 : 800
export const xxsTextSize = overMaxWidth ? 12 : RFValue(7)
export const xsTextSize = overMaxWidth ? 13 : RFValue(8)
export const sTextSize = overMaxWidth ? 14 : RFValue(9)
export const smallTextSize = overMaxWidth ? 15 : RFValue(10)
export const mediumTextSize = overMaxWidth ? 16 : RFValue(11)
export const mlTextSize = overMaxWidth ? 17 : RFValue(12)
export const largeTextSize = overMaxWidth ? 18 : RFValue(13)
export const lTextSize = overMaxWidth ? 20 : RFValue(14)
export const xlTextSize = overMaxWidth ? 22 : RFValue(15)
export const xxlTextSize = overMaxWidth ? 24 : RFValue(16)
export const titleTextSize = overMaxWidth ? 28 : RFValue(20)
export const headerTextSize = overMaxWidth ? 34 : RFValue(24)
export const largeHeaderTextSize = overMaxWidth ? 50 : RFValue(42)
export const bigTextSize = width > 500 ? RFValue(50) : RFValue(42)
export const SMALL_WIDTH = width > 500 ? false : true
export const MED_WIDTH = width > 984 ? false : true
export const SMALL_HEIGHT = height > 888 ? false : true
export const LOYALTY_CARD_WIDTH = width * 0.72
export const SIDEBAR_WIDTH = 240
export const SMALL_LIST_WIDTH = width > 650 ? false : true
export const MED_LIST_WIDTH = width > 1200 ? false : true
export const LARGE_LIST_WIDTH = width > 1200 ? true : false // for larger than 1200 screens

const textMedium = global.kioskV2 ? 26 : global.kiosk ? 32 : 22 // mobile specific, only used in settings and signup
const textSmall = 15
const textSmallMed = global.kioskV2 ? 20 : global.kiosk ? 26 : 16

const textSize = global.kioskV2 ? 20 : global.kiosk ? 26 : 14
const descSize = global.kioskV2 ? 16 : global.kiosk ? 20 : 12

const GlobalStyle = StyleSheet.create({
	text: {
		// used in 8 places
		fontSize: textSize,
	},
	medBoldText: {
		// used in 2 places
		fontSize: textSize,
		fontWeight: '500',
	},
	medText: {
		// ued in 12 places
		fontSize: mediumTextSize,
		fontWeight: '500',
	},
	checkoutContainer: {
		// used in 2 places
		paddingBottom: 0,
		paddingHorizontal: global.kiosk ? 125 : 15,
		flex: 1,
	},
	container: {
		// used in 7 places
		paddingVertical: 15,
		paddingHorizontal: global.kioskV2 ? 40 : global.kiosk ? 125 : 15,
		flex: 1,
	},
	itemContainer: {
		width: '100%',
		height: '100%',
		borderColor: Colors.custom.lightGrey,
		borderWidth: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	itemNameText: {
		// used in 2 places
		fontSize: mediumTextSize,
		textAlign: 'left',
		fontWeight: 'bold',
		marginBottom: '5%',
	},
	loadImgGif: {
		width: 100,
		height: 100,
		alignSelf: 'center',
		justifyContent: 'center',
	},
	boldedText: {
		// used in 2 places
		fontSize: titleTextSize,
		textAlign: 'left',
		fontWeight: 'bold',
	},
	itemPriceText: {
		fontSize: mediumTextSize,
		textAlign: 'left',
		fontWeight: '600',
	},
	modalView: {
		height: Platform.OS === 'web' ? 500 : '100%',
		alignItems: 'center',
		flex: 1,
		borderRadius: 35,
		overflow: 'hidden',
		position: 'absolute',
		top: '10%',
		left: 0,
		right: 0,
		bottom: 0,
	},
	modalStyle: {
		flex: 1,
		alignSelf: 'center',
		width: '90%',
		marginTop: 0,
		maxWidth: MAX_CHECKOUT_WIDTH - 200,
		justifyContent: 'center',
	},
	whiteBg: {
		// todo: delete this
		backgroundColor: Colors.custom.appBackground,
	},
	title: {
		fontSize: textMedium,
		fontWeight: '600',
		marginVertical: 10,
	},
	ctgTitle: {
		paddingVertical: global.kiosk && !global.kioskV2 ? 20 : 5,
		paddingHorizontal: global.kioskV2 ? 35 : global.kiosk ? 50 : 15,
		fontSize: global.kioskV2 ? 26 : global.kiosk ? 38 : 20,
		fontWeight: 'bold',
		marginVertical: 10,
		color: Colors.custom.mediumGrey,
	},
	titleNoMargin: {
		fontSize: textMedium,
		fontWeight: 'bold',
	},
	responsiveTextSize: {
		fontSize: mediumTextSize,
		fontWeight: 'bold',
	},
	flexRow: {
		// delete this
		display: 'flex',
		flexDirection: 'row',
	},
	addItemBtn: {
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		borderRadius: 50,
		elevation: 3,
		borderColor: Colors.custom.restaurantMain,
		backgroundColor: '#fefefe',
		borderWidth: 2,
		paddingVertical: 9,
	},
	addItemBtnText: {
		fontSize: textSmall,
		color: 'black',
		fontWeight: '500',
	},
	pickupBtn: {
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 20,
		backgroundColor: Colors.custom.superLightGrey,
		paddingVertical: 10,
		marginTop: 10,
	},
	pickupBtnText: {
		fontSize: textSmall,
		color: Colors.custom.darkGrey,
	},
	orgLogoContainer: {
		width: width,
		height: 'auto',
		display: 'flex',
		alignItems: 'center',
		marginTop: height * 0.5 - height * 0.4,
	},
	orgLogoAcctContainer: {
		width: 'auto',
		height: 220,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		marginBottom: 20,
		marginTop: 45,
	},
	orgLogo: {
		width: width * 0.75 + 30,
		height: width * 0.75 + 30,
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
	},
	fabTO3: {
		backgroundColor: Colors.custom.lightGreen,
		position: 'absolute',
		width: 18,
		height: 18,
		borderRadius: 20,
		borderWidth: 0.8,
		borderColor: Colors.custom.white,
		alignItems: 'center',
		justifyContent: 'center',
		right: -6,
		top: -3,
		zIndex: 2,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 3,
		},
		shadowOpacity: 0.2,
		shadowRadius: 4,
		elevation: 3,
	},
	kioskFabTO3: {
		backgroundColor: Colors.custom.pinkRed,
		position: 'absolute',
		width: 38,
		height: 38,
		borderRadius: 20,
		alignItems: 'center',
		justifyContent: 'center',
		left: 55, //28
		top: 0, //-9
		zIndex: 2,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 3,
		},
		shadowOpacity: 0.2,
		shadowRadius: 4,
		elevation: 3,
	},
	collapsibleHeading: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: Colors.custom.lightGrey,
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},

	ordersHeading: {
		flex: 1,
		backgroundColor: Colors.custom.lightGrey,
	},
	titleText: {
		fontSize: headerTextSize,
		fontWeight: '700',
		letterSpacing: 1,
		paddingBottom: '5%',
		color: Colors.greyscale[6],
	},
	headerText: {
		fontSize: xlTextSize,
		fontWeight: '500',
		letterSpacing: 0.5,
		color: 'black',
	},
	button: {
		borderWidth: 3,
		borderRadius: 20,
		padding: height * 0.025,
		paddingHorizontal: width * 0.1,
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 10,
	},
	shadowContainer: {
		//for ios
		shadowOpacity: 0.15,
		shadowRadius: 8,
		shadowOffset: { height: 3, width: 0 },
		borderRadius: 15,
		backgroundColor: Colors.custom.appBackground,
	},
	cardContainer: {
		borderRadius: 15,
		backgroundColor: 'white',
		overflow: 'hidden',
		borderWidth: 0,
		borderColor: Colors.greyscale[5],
		elevation: 3,
		shadowOpacity: 0.15,
		shadowRadius: 8,
		shadowOffset: { height: 3, width: 0 },
	},
	v2ButtonRightArrow: {
		flexDirection: 'row',
		borderRadius: (height * 0.1) / 8,
		alignItems: 'center',
		maxHeight: 120,
		height: height * 0.06,
		minHeight: 50,
		//paddingHorizontal: '3.5%',
		justifyContent: 'center',
		paddingHorizontal: '6.5%', //30
	},
	v2ButtonText: {
		color: 'white',
		fontWeight: '400',
		fontSize: largeTextSize,
		marginRight: 10,
	},
})

export default GlobalStyle

export const formStyle = StyleSheet.create({
	container: {
		justifyContent: 'center',
		alignItems: 'center',
		paddingHorizontal: 10,
	},
	leftInput: {
		display: 'flex',
		flexDirection: 'column',
		flex: 0.5,
		marginRight: 5,
	},
	rightInput: {
		display: 'flex',
		flexDirection: 'column',
		flex: 0.5,
		marginLeft: 5,
	},
	textInput: {
		padding: 15,
		borderWidth: 1,
		backgroundColor: '#fcfcfc',
		borderColor: Colors.custom.transparentGrey,
		borderRadius: 16,
		fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont',
	},
	errorText: {
		color: 'crimson',
		paddingTop: 3,
	},
	btnContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	button: {
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		borderRadius: 15,
		height: 50,
		backgroundColor: Colors.custom.restaurantMain,
		elevation: 6,
	},
	btnText: {
		fontWeight: 'bold',
		color: 'white',
	},
	link: {
		color: 'mediumblue',
	},
	headerContainer: {
		marginVertical: 10,
		paddingVertical: 15,
		paddingHorizontal: 10,
		borderBottomWidth: 1,
		borderTopWidth: 1,
		borderColor: '#ccc',
		width: '100%',
		alignItems: 'center',
	},
	touchable: {
		padding: 10,
	},
	headerText: {
		fontSize: 18,
		fontWeight: 'bold',
		color: '#333', // Dark grey text
	},
})
