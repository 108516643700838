import { initializeApp } from 'firebase/app'
import {
	FIREBASE_APP_ID,
	FIREBASE_AUTH_DOMAIN,
	FIREBASE_MEASUREMENT_ID,
	FIREBASE_MESSAGING_SENDER_ID,
	FIREBASE_PROJECT_ID,
	FIREBASE_STORAGE_BUCKET,
	GOOGLE_API_KEY,
} from '@env'
import { getReactNativePersistence } from 'firebase/auth/react-native'
import { initializeAuth } from 'firebase/auth'
import { initializeFirestore, getFirestore } from 'firebase/firestore'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'
import { initializeRNFirebase } from './analytics'
import messaging from '@react-native-firebase/messaging'
import { PermissionsAndroid } from 'react-native'
import { useEffect } from 'react'

const firebaseConfig = {
	apiKey: GOOGLE_API_KEY,
	authDomain: FIREBASE_AUTH_DOMAIN,
	projectId: FIREBASE_PROJECT_ID,
	storageBucket: FIREBASE_STORAGE_BUCKET,
	messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
	appId: FIREBASE_APP_ID,
	measurementId: FIREBASE_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)
const auth = initializeAuth(firebaseApp, {
	persistence: getReactNativePersistence(AsyncStorage),
})
initializeFirestore(firebaseApp, {
	experimentalForceLongPolling: false,
})
const db = getFirestore(firebaseApp)

if (Platform.OS !== 'web') {
	//initialize react-native-firebase pkg to use analytics on mobile
	initializeRNFirebase(firebaseConfig)
}

// async function requestUserPermission() {
// 	const authStatus = await messaging().requestPermission()
// 	const enabled =
// 		authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
// 		authStatus === messaging.AuthorizationStatus.PROVISIONAL

// 	if (enabled) {
// 		console.log('Authorization status:', authStatus)
// 	}
// }

// if (Platform.OS === 'ios') {
// 	requestUserPermission()
// }

// if (Platform.OS === 'android') {
// 	PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.POST_NOTIFICATIONS)
// }

// getToken(messaging, {
// 	vapidKey:
// 		'BHetdwD4rFB9B7Eo3hyqI24TwoyGvGvVp89A-zf2lxfvCKvMVkMV3DRVrRmIZB98Cnr2BQ8hVQ8cbogxgFRV0dE',
// })

// messaging().sendMessage({
// 	to: 'BHetdwD4rFB9B7Eo3hyqI24TwoyGvGvVp89A-zf2lxfvCKvMVkMV3DRVrRmIZB98Cnr2BQ8hVQ8cbogxgFRV0dE',
// 	notification: {
// 		title: 'Basic Notification',
// 		body: 'This is a basic notification sent from the server!',
// 	},
// 	fcmOptions: {
// 		link: 'https://www.example.com',
// 	},
// })

// async function getFCMToken() {
// 	const token = await messaging().getToken()
// 	console.log('FCM Token:', token)
// 	return token
// }

// 	getFCMToken()

export { auth, db }
