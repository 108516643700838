import { FontAwesome5 } from '@expo/vector-icons'
import React, { useRef, useState } from 'react'
import { View, Text, TouchableOpacity, StyleSheet, Animated, Platform } from 'react-native'
import { largeTextSize, mediumTextSize } from '../../../constants/GlobalStyle'
import {
	InterfaceType,
	StarDeviceDiscoveryManager,
	StarDeviceDiscoveryManagerFactory,
	StarPrinter,
} from 'react-native-star-io10'
import { setDataState } from '../../../state/Slices/dataSlice'
import { useAppDispatch } from '../../../state/hooks'

const PrinterStatus = ({ receiptPrinter, printerType }) => {
	let EscPosPrinter = null
	if (Platform.OS !== 'web') {
		EscPosPrinter = require('react-native-esc-pos-printer')
	}
	const spinValueStar = useRef(new Animated.Value(0)).current
	const spinValueEpson = useRef(new Animated.Value(0)).current

	const [isEpson, setIsEpson] = useState(printerType === 'epson')
	const [isSpinningStar, setIsSpinningStar] = useState(false)
	const [isSpinningEpson, setIsSpinningEpson] = useState(false)

	const dispatch = useAppDispatch()

	const managerRef = useRef<StarDeviceDiscoveryManager | undefined>()

	async function findEpsonPrinters() {
		if (EscPosPrinter !== null) {
			console.log('discovering')
			await EscPosPrinter.discover()
				.then(printers => {
					console.log('done! found ' + printers.length)
					if (printers[0]) {
						dispatch(
							setDataState({
								receiptPrinter: printers[0],
								printerType: 'epson',
							})
						)
						setIsEpson(true)
					} else {
						dispatch(
							setDataState({
								receiptPrinter: null,
								printerType: '',
							})
						)
					}
				})
				.catch(e => console.log('EPSON ERROR: ' + e))
		}
	}

	async function findStarPrinters() {
		try {
			await managerRef.current?.stopDiscovery()
			if (Platform.OS === 'android') {
				managerRef.current = await StarDeviceDiscoveryManagerFactory.create([
					InterfaceType.Bluetooth,
				])
			} else {
				managerRef.current = await StarDeviceDiscoveryManagerFactory.create([
					InterfaceType.Bluetooth,
					InterfaceType.BluetoothLE,
				])
			}

			managerRef.current.discoveryTime = 10000

			managerRef.current.onPrinterFound = (printer: StarPrinter) => {
				dispatch(
					setDataState({
						receiptPrinter: printer,
						printerType: 'star',
					})
				)
				setIsEpson(false)
				console.log(`Found printer: ${printer.connectionSettings.identifier}.`)
			}

			managerRef.current.onDiscoveryFinished = () => {
				console.log(`Discovery finished.`)
			}

			await managerRef.current.startDiscovery()
		} catch (error) {
			console.log(`Error: ${String(error)}`)
		}
	}

	// Generic function to start spinning animation
	const startSpin = spinValue => {
		spinValue.setValue(0)
		return Animated.loop(
			Animated.timing(spinValue, {
				toValue: 1,
				duration: 1000, // one full rotation takes 1 second
				useNativeDriver: true,
			})
		).start()
	}

	// Generic function to stop spinning animation
	const stopSpin = spinValue => {
		spinValue.stopAnimation()
		spinValue.setValue(0) // Reset the value if you want to stop the spin at the original position
	}

	// Handle press for STAR printer button
	const handlePressStar = async () => {
		setIsSpinningStar(true)
		startSpin(spinValueStar)
		await Promise.all([
			findStarPrinters(),
			new Promise(resolve => setTimeout(resolve, 2000)), // Minimum 2-second wait
		])
		stopSpin(spinValueStar)
		setIsSpinningStar(false)
	}

	// Handle press for EPSON printer button
	const handlePressEpson = async () => {
		setIsSpinningEpson(true)
		startSpin(spinValueEpson)
		//await findEpsonPrinters()
		await Promise.all([
			findEpsonPrinters(),
			new Promise(resolve => setTimeout(resolve, 2000)), // Minimum 2-second wait
		])
		stopSpin(spinValueEpson)
		setIsSpinningEpson(false)
	}

	// Interpolate beginning and end values for each button
	const spinStar = spinValueStar.interpolate({
		inputRange: [0, 1],
		outputRange: ['0deg', '360deg'],
	})
	const spinEpson = spinValueEpson.interpolate({
		inputRange: [0, 1],
		outputRange: ['0deg', '360deg'],
	})

	return (
		<View style={styles.container}>
			<View style={styles.statusContainer}>
				<View style={[styles.statusRow, { marginBottom: 25 }]}>
					<View
						style={[
							styles.statusIndicator,
							printerType === 'epson' ? styles.connected : styles.disconnected,
						]}
					/>
					<Text style={styles.statusText}>
						EPSON :{' '}
						{isSpinningEpson
							? 'Searching for printers over Bluetooth...'
							: printerType === 'epson'
							? `Bluetooth connected to "${receiptPrinter.name}"`
							: 'not connected'}
					</Text>
					<TouchableOpacity
						onPress={handlePressEpson}
						disabled={isSpinningStar || isSpinningEpson}
						style={styles.syncButton}
					>
						<Animated.View style={{ transform: [{ rotate: spinEpson }] }}>
							<FontAwesome5 name={'sync-alt'} size={largeTextSize} />
						</Animated.View>
					</TouchableOpacity>
				</View>
				<View style={styles.statusRow}>
					<View
						style={[
							styles.statusIndicator,
							printerType !== 'star' ? styles.disconnected : styles.connected,
						]}
					/>
					<Text style={styles.statusText}>
						STAR :{' '}
						{isSpinningStar
							? 'Searching for printers over Bluetooth...'
							: printerType !== 'star'
							? 'not connected'
							: `Bluetooth connected to "${
									receiptPrinter._connectionSettings
										? receiptPrinter._connectionSettings.identifier
										: ''
							  }"`}
					</Text>

					<TouchableOpacity
						onPress={handlePressStar}
						disabled={isSpinningStar || isSpinningEpson}
						style={styles.syncButton}
					>
						<Animated.View style={{ transform: [{ rotate: spinStar }] }}>
							<FontAwesome5 name={'sync-alt'} size={largeTextSize} />
						</Animated.View>
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 20,
		borderWidth: 2,
		borderRadius: 10,
		marginHorizontal: '8%',
		marginBottom: '5%',
	},
	statusContainer: {
		flexDirection: 'column',
	},
	statusRow: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 4, // Adjust the space between rows if needed
	},
	statusIndicator: {
		height: 22,
		width: 22,
		borderRadius: 11,
		marginRight: 15,
	},
	disconnected: {
		backgroundColor: 'red',
	},
	connected: {
		backgroundColor: '#4BB543',
	},
	statusText: {
		fontSize: mediumTextSize,
		lineHeight: 24,
		flexGrow: 1,
		fontWeight: '500',
	},
	syncButton: {
		marginLeft: '10%',
	},
})

export default PrinterStatus
