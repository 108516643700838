import React from 'react'
import { Dimensions, Platform } from 'react-native'
import { Text } from '../../../components/Themed'
import GlobalStyle, { bigTextSize } from '../../../constants/GlobalStyle'
import { View } from '../../../components/Themed'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import Colors from '../../../constants/Colors'
import { ForwardButton } from '../../../components/Headers/UIButtons'
import CopyrightFooter from '../../../components/Footers/CopyrightFooter'
import TapForTestMode from '../../../components/OrderCart/TapForTestMode'

export default function NoItemsInCart({ navigation }) {
	return (
		<>
			<View
				style={{
					position: 'absolute',
					top: Dimensions.get('window').height * 0.25,
					bottom: 0,
					left: 0,
					right: 0,
					paddingHorizontal: '15%',
					justifyContent: 'center',
				}}
			>
				<View
					style={{
						marginTop: -Dimensions.get('window').height * 0.25,
						alignItems: 'center',
						alignSelf: 'center',
					}}
				>
					<MaterialCommunityIcons
						name="shopping-outline"
						size={bigTextSize * 1.5}
						color={Colors.greyscale[6]}
					/>
					<Text
						style={[
							GlobalStyle.headerText,
							{ color: Colors.greyscale[7], textAlign: 'center', marginTop: 15 },
						]}
					>
						{'You have not added anything to your order.'}
					</Text>
					<TapForTestMode />
				</View>
			</View>
			<ForwardButton
				onPress={() => navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })}
				color={'black'}
				title={'ADD ITEMS'}
				style={{
					width: '70%',
					bottom: Platform.OS === 'web' ? 120 : 40, //to accomodate footer on web
					position: 'absolute',
					alignSelf: 'center',
					marginBottom: global.pos ? 100 : 0,
				}}
			/>
			{Platform.OS === 'web' && <CopyrightFooter />}
		</>
	)
}
