import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import { Text } from './Themed'
import { SafeAreaView } from 'react-native-safe-area-context'
import { xsTextSize } from '../constants/GlobalStyle'
import { useAppSelector } from '../state/hooks'

const TestModeBanner = () => {
	const { isTestMode } = useAppSelector(state => state.persisted)
	if (!isTestMode) return null
	return (
		<SafeAreaView
			edges={['left', 'right', 'top']}
			style={[
				styles.container,
				{
					backgroundColor: global.orgColor,
					paddingTop: Platform.OS === 'web' ? 5 : 0,
					paddingBottom: 5,
				},
			]}
		>
			<Text style={styles.connectionText}>Test mode active</Text>
		</SafeAreaView>
	)
}

const styles = StyleSheet.create({
	container: {
		justifyContent: 'center',
		alignItems: 'center',
	},
	connectionText: {
		color: '#FFFFFF',
		fontSize: xsTextSize,
		fontWeight: 'bold',
	},
})

export default TestModeBanner
