import React from 'react'
import { StyleSheet, Dimensions, Platform } from 'react-native'
import Colors from '../../../constants/Colors'
import { Text, View } from '../../../components/Themed'
import ImageObject from '../../../components/Image/ImageObject'
import GlobalStyle, {
	mediumTextSize,
	sTextSize,
	smallTextSize,
	IS_LANDSCAPE,
} from '../../../constants/GlobalStyle'
import { useAppSelector } from '../../../state/hooks'

export type ItemProps = {
	id: string
	name: string
	desc: string
	price: string
	imageUri: string
	promoPrice: string
	storeOpen: boolean
	bogo?: any
	width: any
	variationMinPrice?: any
	variationMaxPrice?: any
}
/**
 * Item card for both brand and category menu
 */
export default function MenuItemCard(props: ItemProps) {
	const {
		id,
		name,
		desc,
		price,
		imageUri,
		width,
		promoPrice,
		variationMinPrice = -1,
		variationMaxPrice = -1,
	} = props
	const { cart } = useAppSelector(state => state.persisted)
	function Description() {
		return (
			<View style={{ width: '100%' }}>
				<Text numberOfLines={2} ellipsizeMode="tail" style={[styles.description]}>
					{desc}
				</Text>
			</View>
		)
	}

	let quantity = 0
	if (cart) {
		quantity = cart.reduce((total, item) => {
			if (item.id === id) {
				return total + item.qty
			}
			return total
		}, 0)
	}

	return (
		<>
			{quantity > 0 && (
				<View style={[styles.quantityBubble, { backgroundColor: global.orgColor }]}>
					<Text style={styles.quantityText}>{quantity}</Text>
				</View>
			)}
			<View style={GlobalStyle.shadowContainer}>
				<View style={GlobalStyle.cardContainer}>
					{imageUri !== undefined && imageUri !== '' ? (
						<ImageObject
							source={imageUri}
							style={{ resizeMode: 'contain', width: width, height: width * 0.8 }}
						/>
					) : (
						<View
							style={{ backgroundColor: Colors.greyscale[2], width: width, height: width * 0.8 }}
						></View>
					)}
					<View style={styles.textArea}>
						<Text numberOfLines={1} ellipsizeMode="tail" style={styles.name}>
							{name}
						</Text>
						<Description />
						<PriceText
							price={price}
							promoPrice={promoPrice}
							min={variationMinPrice}
							max={variationMaxPrice}
						/>
					</View>
				</View>
			</View>
		</>
	)
}

function PriceText({ price, promoPrice, min, max }) {
	let minPrice = 0
	let maxPrice = 0
	if (min !== -1) {
		minPrice = min
	} else {
		minPrice = price
	}
	if (max !== -1) {
		maxPrice = max
	} else {
		maxPrice = price
	}
	if (promoPrice !== -1 && promoPrice !== '$-0.01' && promoPrice) {
		return (
			<Text style={[styles.price, { color: Colors.custom.pinkRed }]} key="promoPrice">
				<Text style={{ textDecorationLine: 'line-through', color: 'green' }} key="oldPrice">
					{price}
				</Text>{' '}
				{promoPrice}
			</Text>
		)
	} else if (min !== -1 || max !== -1) {
		return <Text style={styles.price}>{`${minPrice} ${'\u2013'} ${maxPrice}`}</Text>
	} else {
		return <Text style={styles.price}>{`${price}`}</Text>
	}
}

export function getCheckoutImageMobile(imageUri, qty) {
	return (
		<View style={{ marginRight: 10 }}>
			<View
				style={[
					GlobalStyle.kioskFabTO3,
					{
						left: -10,
						top: -10,
						width: 30,
						height: 30,
						borderRadius: 30,
						backgroundColor: 'white',
					},
				]}
			>
				<Text
					style={{
						fontSize: 18,
						fontWeight: 'bold',
						color: 'black',
					}}
				>
					{qty}
				</Text>
			</View>
			{imageUri !== '' ? (
				<ImageObject
					source={imageUri}
					style={[
						styles.kioskItemImage,
						{
							width: IS_LANDSCAPE ? width * 0.15 : width * 0.2,
							height: IS_LANDSCAPE ? width * 0.12 : width * 0.17,
							maxWidth: 100,
							maxHeight: 80,
							borderRadius: 10,
						},
					]}
				/>
			) : (
				<View
					style={[
						styles.kioskItemImage,
						{
							backgroundColor: Colors.greyscale[4],
							width: width * 0.15,
							height: width * 0.12,
							maxWidth: 100,
							maxHeight: 80,
							borderRadius: 10,
						},
					]}
				></View>
			)}
		</View>
	)
}

// For kiosk
export function getCheckoutImage(imageUri, qty) {
	const width = Dimensions.get('window').width
	return (
		<View style={{ marginRight: '3%' }}>
			<View
				style={[
					GlobalStyle.kioskFabTO3,
					{
						left: -5,
						top: -8,
						width: width * 0.05,
						height: width * 0.05,
						borderRadius: width * 0.025,
						backgroundColor: 'white',
					},
				]}
			>
				<Text
					style={{
						fontSize: mediumTextSize,
						fontWeight: 'bold',
						color: 'black',
					}}
				>
					{qty}
				</Text>
			</View>
			{imageUri !== '' ? (
				<ImageObject
					source={imageUri}
					style={[
						styles.kioskItemImage,
						{
							width: width * 0.18,
							height: width * 0.15,
							borderRadius: width * 0.025,
						},
					]}
				/>
			) : (
				<View
					style={[
						styles.kioskItemImage,
						{
							backgroundColor: Colors.greyscale[4],
							width: width * 0.18,
							height: width * 0.15,
							borderRadius: width * 0.025,
						},
					]}
				></View>
			)}
		</View>
	)
}

var width = Dimensions.get('window').width

const nameSize = global.kioskV2 ? 20 : global.kiosk ? 25 : 14
const descSize = global.kioskV2 ? 18 : global.kiosk ? 20 : 12
const priceSize = global.kioskV2 ? 20 : global.kiosk ? 25 : 14

const styles = StyleSheet.create({
	quantityBubble: {
		position: 'absolute',
		top: -5,
		right: -5,
		width: 24,
		height: 24,
		borderRadius: 12,
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 10,
	},
	quantityText: {
		color: 'white',
		fontSize: smallTextSize,
		fontWeight: 'bold',
	},
	kioskItemContainer: {
		width: '100%',
		height: '100%',
		borderColor: Colors.custom.lightGrey,
		borderWidth: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	kioskItemContent: {
		marginVertical: 0,
		marginLeft: 15,
		width: global.kiosk ? width - 200 - 110 : width - 200,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		paddingVertical: global.kioskV2 ? 15 : 32,
	},
	kioskItemImage: {
		height: 175,
		width: 215,
		alignSelf: 'center',
		justifyContent: 'center',
		borderRadius: 25,
	},

	itemContainer: {
		width: Platform.OS === 'web' ? Dimensions.get('window').width - 70 : '100%',
		height: '100%',
		borderColor: Colors.custom.lightGrey,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginHorizontal: 10,
	},
	itemContent: {
		marginVertical: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		paddingVertical: 10,
	},
	itemImage: {
		height: global.kioskV2 ? 145 : 100,
		width: global.kioskV2 ? 175 : 105,
		alignSelf: 'center',
		justifyContent: 'center',
		borderRadius: 20,
		marginLeft: global.kioskV2 ? 20 : -20,
	},
	itemNameText: {
		fontSize: nameSize,
		textAlign: 'left',
		fontWeight: 'bold',
		paddingBottom: global.kiosk ? 20 : 10,
	},
	itemDescText: {
		fontSize: descSize,
		textAlign: 'left',
		paddingBottom: global.kiosk ? 20 : Platform.OS === 'web' ? 0 : 10,
		paddingLeft: 7,
		color: Colors.custom.darkGrey,
	},
	itemPriceText: {
		fontSize: priceSize,
		textAlign: 'left',
		fontWeight: '600',
	},

	name: {
		fontSize: smallTextSize,
		fontWeight: '600',
		marginTop: 3,
		marginBottom: 6,
	},
	description: {
		fontSize: sTextSize,
		color: Colors.greyscale[5],
		minHeight: sTextSize * 2.5,
	},
	price: {
		fontSize: smallTextSize,
		color: 'green',
		marginTop: 6,
	},
	textArea: {
		padding: '5%',
	},
})
