import React from 'react'
import CustomerName from './CustomerName.kiosk'
import CheckoutNavFooter from '../../../../components/Footers/CheckoutNavFooter'
import { Dimensions } from 'react-native'
import LoyaltyPhoneNumber from './LoyaltyPhoneNumber.kiosk'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { setCheckoutState } from '../../../../state/Slices/checkoutSlice'

/**
 * For kiosks, select order type then enter name or table number.
 * If only 1 order type available, ask for name only.
 */
export function DineInOrTakeout({ toggleConfirmModal, children }) {
	const { takeOutName, showKioskLoyaltyEntry, posLoyaltyPoints, isDineIn, isTakeOut } =
		useAppSelector(state => state.checkout)

	const { dineInEnabled, usesTableNumbers, paymentProvider } = useAppSelector(
		state => state.settings
	)

	const { posLoyaltyEnabled } = useAppSelector(state => state.settings)
	const dispatch = useAppDispatch()

	const setTakeoutName = value => {
		dispatch(setCheckoutState({ takeOutName: value }))
	}

	const handleBackPress = () => {
		if (!global.pos) {
			if (posLoyaltyEnabled && !showKioskLoyaltyEntry) {
				dispatch(setCheckoutState({ showKioskLoyaltyEntry: true }))
			} else {
				toggleConfirmModal()
			}
		} else {
			dispatch(setCheckoutState({ showPOSMethods: true }))
		}
	}

	const handleContinuePress = () => {
		if (showKioskLoyaltyEntry) {
			dispatch(setCheckoutState({ showKioskLoyaltyEntry: false }))
		} else {
			dispatch(setCheckoutState({ showDineInOrTakeout: false, phoneNumberInput: true }))
		}
	}
	return (
		<>
			{posLoyaltyEnabled && posLoyaltyPoints !== 0 && showKioskLoyaltyEntry ? (
				<LoyaltyPhoneNumber />
			) : (
				<CustomerName
					value={takeOutName}
					setValue={setTakeoutName}
					isTakeOut={!dineInEnabled ? true : isTakeOut}
					showTableNumber={usesTableNumbers}
				>
					<></>
				</CustomerName>
			)}

			{paymentProvider === 'square' && !showKioskLoyaltyEntry ? (
				children
			) : (
				<CheckoutNavFooter
					onBackPress={handleBackPress}
					onNextPress={
						!showKioskLoyaltyEntry &&
						(takeOutName === null || takeOutName === '') &&
						isDineIn === false
							? null
							: handleContinuePress
					}
					blurNext={
						!showKioskLoyaltyEntry &&
						(takeOutName === null || takeOutName === '') &&
						isDineIn === false
							? true
							: false
					}
					style={{ marginBottom: Dimensions.get('window').height * 0.07, marginHorizontal: '5%' }}
				/>
			)}
		</>
	)
}
