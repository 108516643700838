import React from 'react'
import { View, TouchableHighlight } from 'react-native'
import MenuItemCard from './MenuItemCard'
import Colors from '../../../constants/Colors'
import { getBrandCardsLayout, getCategoryCardsLayout } from '../Helpers/functions'

const RowItems = ({
	name,
	rowItems,
	searchResults = false,
	storeOpen,
	navigation,
	logSelectItemAnalytics,
	rId = null,
	rName = null,
	isSnoozedState = null,
	categoryName = null,
	isBrandMenu = false,
	singleBrandEnabled = false,
}) => {
	//Find screen width accounting for margins / padding
	const card = isBrandMenu
		? getBrandCardsLayout(singleBrandEnabled, searchResults)
		: getCategoryCardsLayout()

	return (
		<View
			style={{
				flexDirection: 'row',
				flexWrap: 'wrap',
				justifyContent: 'space-between',
				marginBottom: searchResults ? 0 : isBrandMenu ? '4%' : '8%',
			}}
		>
			{rowItems.map((token, index) => (
				<View key={token.id + (isBrandMenu ? '_branditem_' : '_ctgitem_') + index}>
					{token.invisible ? (
						<View style={{ width: card.width }}>
							<View style={{ width: '100%', height: 0 }} />
						</View>
					) : (
						<TouchableHighlight
							style={isBrandMenu ? { marginBottom: '15%' } : {}}
							key={token.id + index}
							underlayColor={Colors.custom.appBackground}
							onPress={() => {
								// if (global.kiosk && !storeOpen) {
								// 	console.log('Closed')
								// } else {
								logSelectItemAnalytics(
									isBrandMenu ? rId : 'category_' + categoryName,
									isBrandMenu ? rName : categoryName,
									token.price,
									{
										item_id: token.id,
										item_name: token.name,
										item_category: name,
									}
								)

								navigation.navigate('Item', {
									token: token.id,
									editing: null,
									isOpen: storeOpen,
									menuItemCategory: name,
									menuId: isBrandMenu ? rId : 'category_' + categoryName,
									...(isBrandMenu && { isRestaurantSnoozed: isSnoozedState }),
									category: !isBrandMenu,
								})
								//}
							}}
						>
							<View
								style={{
									width: card.width,
									marginBottom: '15%',
								}}
							>
								<MenuItemCard
									storeOpen={storeOpen}
									key={token.id + index}
									id={token.id}
									name={token.name}
									desc={token.desc}
									price={token.price}
									imageUri={token.image}
									promoPrice={token.promoPrice}
									width={card.width}
									variationMinPrice={token.variationMinPrice}
									variationMaxPrice={token.variationMaxPrice}
								/>
							</View>
						</TouchableHighlight>
					)}
				</View>
			))}
		</View>
	)
}

export default RowItems
