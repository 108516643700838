import { FontAwesome, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import React from 'react'
import { View, TextInput, StyleSheet } from 'react-native'
import { mlTextSize, titleTextSize } from '../../../../constants/GlobalStyle'
import { Text } from '../../../../components/Themed'
import Colors from '../../../../constants/Colors'
import { CheckBox } from 'react-native-elements'
import NumberPad from '../../../../components/NumberPad'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { setCheckoutState } from '../../../../state/Slices/checkoutSlice'

const formatPhoneNumber = inputString => {
	const numbers = inputString.replace(/\D/g, '') // Remove non-digits
	const char = { 0: '(', 3: ')-', 6: '-' }
	let phone = ''
	for (let i = 0; i < numbers.length; i++) {
		phone += (char[i] || '') + numbers[i]
	}
	return phone
}

const LoyaltyPhoneNumber = () => {
	const { posLoyaltyPoints, selectedMethods } = useAppSelector(state => state.checkout)
	const dispatch = useAppDispatch()

	const handleFocus = () => {
		dispatch(
			setCheckoutState({
				selectedMethods: {
					...selectedMethods,
					showNumPad: true,
				},
			})
		)
	}

	const handleBlur = () => {
		dispatch(
			setCheckoutState({
				selectedMethods: {
					...selectedMethods,
					showNumPad: false,
					textMessage: selectedMethods.phoneString === '' ? false : true,
				},
			})
		)
	}

	const handleTextUpdatesToggle = () => {
		dispatch(
			setCheckoutState({
				selectedMethods: {
					...selectedMethods,
					textUpdates: !selectedMethods.textUpdates,
				},
			})
		)
	}

	const handlePhoneNumberChange = value => {
		dispatch(
			setCheckoutState({
				selectedMethods: {
					...selectedMethods,
					phoneString: value,
					phoneStringFormatted: formatPhoneNumber(value),
				},
			})
		)
	}

	return (
		<View style={styles.container}>
			<Text style={styles.mainHeader}>
				{`YOUR PURCHASE QUALIFIES FOR`}
				<Text style={{ color: '#F3AC3C' }}>{` ${posLoyaltyPoints} POINTS`}</Text>
				{'!'}
			</Text>
			<Text style={styles.subHeader}>
				{`Enter your phone number to earn these points. You will be automatically enrolled if this is your first time.`}
			</Text>
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					marginTop: selectedMethods.showNumPad ? '-25%' : '-50%',
					alignItems: 'center',
				}}
			>
				<View style={styles.inputContainer}>
					<FontAwesome
						name="mobile-phone"
						size={titleTextSize * 1.15}
						color={Colors.greyscale[3]}
						style={styles.inputIcon}
					/>
					<TextInput
						style={styles.input}
						placeholder="Phone number"
						placeholderTextColor="#aaa"
						value={selectedMethods.phoneStringFormatted}
						showSoftInputOnFocus={false}
						maxLength={14}
						editable={true}
						selectTextOnFocus={false}
						onFocus={handleFocus}
						onBlur={handleBlur}
					/>
				</View>
				<CheckBox
					title="Also send order updates to this phone number."
					checked={selectedMethods.textUpdates}
					checkedColor={global.orgColor}
					checkedIcon={
						<MaterialCommunityIcons
							name="checkbox-marked"
							size={titleTextSize}
							color={global.orgColor}
						/>
					}
					uncheckedIcon={
						<MaterialCommunityIcons
							name="checkbox-blank-outline"
							size={titleTextSize}
							color="black"
						/>
					}
					onPress={handleTextUpdatesToggle}
					wrapperStyle={{
						paddingHorizontal: '10%',
					}}
					containerStyle={styles.checkboxContainer}
					textStyle={styles.checkboxText}
				/>
				<NumberPad
					style={{
						display: selectedMethods.showNumPad ? 'flex' : 'none',
						marginBottom: 0,
						width: '80%',
					}}
					numLength={12}
					buttonSize={60}
					buttonItemStyle={{ backgroundColor: '#d6d6d6', borderRadius: 12, width: '80%' }}
					activeOpacity={0.1}
					onValueChange={handlePhoneNumberChange}
					allowDecimal={false}
					rightBottomButton={<Ionicons name={'ios-backspace-outline'} size={28} color={'#000'} />}
				/>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		backgroundColor: '#fff',
	},
	subHeader: {
		fontSize: mlTextSize - 4,
		color: Colors.greyscale[7],
		textAlign: 'center',
		fontWeight: '500',
		//marginTop: '10%',
		width: '80%',
	},
	mainHeader: {
		fontSize: mlTextSize - 3,
		color: Colors.greyscale[8],
		textAlign: 'center',
		marginBottom: '10%',
		fontWeight: 'bold',
		marginTop: '25%',
	},
	inputContainer: {
		flexDirection: 'row',
		borderColor: 'grey',
		borderWidth: 1,
		borderRadius: 8,
		paddingVertical: '2%',
		alignItems: 'center',
		width: '75%',
	},
	inputIcon: {
		marginRight: 15,
		marginLeft: 15,
	},
	input: {
		flex: 1,
		paddingVertical: 10,
		fontSize: mlTextSize,
	},
	checkboxContainer: {
		backgroundColor: 'transparent',
		borderWidth: 0,
		paddingTop: '5%',
		alignSelf: 'flex-start',
	},
	checkboxText: {
		marginLeft: 25,
		fontWeight: '300',
		fontSize: mlTextSize - 3,
		color: 'black',
	},
})

export default LoyaltyPhoneNumber
