import React, { useEffect, useState } from 'react'
import { TextInput, TouchableOpacity, Image, StyleSheet } from 'react-native'
import DropDownPicker from 'react-native-dropdown-picker'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { setUserState } from '../../state/Slices/userSlice'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { add } from 'date-fns'
import { parsePrepTime } from '../../helpers/prepTimeFunctions'
import Colors from '../../constants/Colors'
import { formStyle, smallTextSize } from '../../constants/GlobalStyle'
import { Text, View } from '../Themed'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { auth } from '../../firebase/config'
import { provinceList } from './validProvinces'

// Address validation schema
const addressSchema = yup.object().shape({
	street: yup.string().required('Street is required'),
	street2: yup.string(),
	city: yup.string().required('City is required'),
	province: yup.string().required('Province is required'),
	postalCode: yup
		.string()
		.transform(value => value.replace(/\s+/g, '').toUpperCase())
		.required('A valid postal code is required')
		.matches(/^[0-9A-Z]+$/, 'Invalid postal code'),
	label: yup.string(),
	deliveryNote: yup.string(),
})

const capitalizeFirstLetterOfEachWord = text => {
	return text.replace(/\b\w/g, char => char.toUpperCase())
}

export default function AddressForm({
	userCurrentLocation,
	handleDeliveryEstimate,
	saveAddress,
	setSaveAddress,
	isLoading,
	setIsLoading,
	userId,
}) {
	const dispatch = useAppDispatch()

	const provinces = provinceList

	const [isFocus, setIsFocus] = useState(false)

	const handleSubmitNewAddress = async values => {
		const streetAddress = `${values.street}${values.street2 ? ', ' + values.street2 : ''}`
		const longAddress = `${streetAddress}, ${values.city}, ${values.province}, ${values.postalCode}`
		const id = Date.now()
		const newAddress = {
			id,
			longAddress,
			formattedAddress: streetAddress,
			label: values.label,
			instructions: values.deliveryNote,
			updatedAt: new Date().getMilliseconds(),
			addressObject: {
				streetAddress: values.street,
				streetAddress2: values.street2,
				city: values.city,
				province: values.province,
				postalCode: values.postalCode,
			},
		}

		setIsLoading(true)

		dispatch(setUserState({ userLocation: newAddress }))

		if (saveAddress) {
			const functions = getFunctions()
			const setDeliveryAddressForUser = httpsCallable(functions, 'setDeliveryAddressForUser')
			await setDeliveryAddressForUser({
				userId,
				address: newAddress,
				orgId: global.org,
				isEdit: false,
				isDelete: false,
			})
		}

		await handleDeliveryEstimate(longAddress)
	}

	return (
		<Formik
			initialValues={{
				label: '',
				street: userCurrentLocation?.addressObject?.streetAddress || '',
				street2: '',
				city: userCurrentLocation?.addressObject?.city || '',
				province: userCurrentLocation?.addressObject?.province || '',
				postalCode: userCurrentLocation?.addressObject?.postalCode || '',
				deliveryNote: '',
			}}
			enableReinitialize={true}
			validationSchema={addressSchema}
			onSubmit={handleSubmitNewAddress}
		>
			{({ handleChange, handleBlur, handleSubmit, setFieldValue, values, touched, errors }) => (
				<View style={styles.formContainer}>
					<TextInput
						style={[formStyle.textInput, styles.input]}
						placeholder="Street address"
						onChangeText={handleChange('street')}
						value={capitalizeFirstLetterOfEachWord(values.street)}
						onBlur={handleBlur('street')}
					/>
					<Text style={formStyle.errorText}>
						<>{touched.street && errors.street}</>
					</Text>

					<TextInput
						style={[formStyle.textInput, styles.input]}
						placeholder="Apt / Suite"
						onChangeText={handleChange('street2')}
						value={values.street2}
						onBlur={handleBlur('street2')}
					/>
					<Text style={formStyle.errorText}>
						<></>
					</Text>

					<TextInput
						style={[formStyle.textInput, styles.input]}
						placeholder="City"
						onChangeText={handleChange('city')}
						value={capitalizeFirstLetterOfEachWord(values.city)}
						onBlur={handleBlur('city')}
					/>
					<Text style={formStyle.errorText}>
						<>{touched.city && errors.city}</>
					</Text>

					<DropDownPicker
						listMode="SCROLLVIEW"
						open={isFocus}
						value={values.province}
						items={provinces}
						setOpen={setIsFocus}
						setValue={callback => {
							const selectedValue = callback(values.province)
							setFieldValue('province', selectedValue)
						}}
						placeholder="Province"
						style={[formStyle.textInput, styles.input]}
					/>
					<Text style={formStyle.errorText}>
						<>{touched.province && errors.province}</>
					</Text>

					<TextInput
						style={[formStyle.textInput, styles.input]}
						placeholder="Postal code"
						onChangeText={handleChange('postalCode')}
						value={values.postalCode.toUpperCase()}
						onBlur={handleBlur('postalCode')}
					/>
					<Text style={formStyle.errorText}>
						<>{touched.postalCode && errors.postalCode}</>
					</Text>

					<TextInput
						style={[formStyle.textInput, styles.input]}
						placeholder="Delivery instructions (optional)"
						onChangeText={handleChange('deliveryNote')}
						value={values.deliveryNote}
						onBlur={handleBlur('deliveryNote')}
					/>
					<Text style={formStyle.errorText}>
						<></>
					</Text>

					<TextInput
						style={[formStyle.textInput, styles.input]}
						placeholder="Label (optional)"
						onChangeText={handleChange('label')}
						value={values.label}
						onBlur={handleBlur('label')}
					/>

					{auth.currentUser && (
						<TouchableOpacity
							onPress={() => setSaveAddress(!saveAddress)}
							style={styles.saveAddressToggle}
						>
							<MaterialCommunityIcons
								name={saveAddress ? 'checkbox-marked-circle' : 'checkbox-blank-circle-outline'}
								size={26}
								color={saveAddress ? global.orgColor : '#818181'}
							/>
							<Text style={styles.saveAddressText}>Save address for future orders</Text>
						</TouchableOpacity>
					)}

					<TouchableOpacity
						style={[
							formStyle.button,
							{
								backgroundColor: isLoading ? Colors.greyscale[6] : global.orgColor,
								marginTop: 10,
							},
						]}
						onPress={() => {
							handleSubmit()
						}}
						disabled={isLoading}
					>
						{isLoading ? (
							<Image
								style={styles.loadingImage}
								source={require('../../assets/images/loadImg.gif')}
							/>
						) : (
							<Text style={formStyle.btnText}>
								{saveAddress ? 'SAVE & USE ADDRESS' : 'USE ADDRESS'}
							</Text>
						)}
					</TouchableOpacity>
				</View>
			)}
		</Formik>
	)
}

const styles = StyleSheet.create({
	formContainer: {
		width: '95%',
		alignSelf: 'center',
	},
	input: {
		marginVertical: 5,
	},
	loadingImage: {
		width: 90,
		height: 90,
		marginTop: -34,
		marginBottom: -34,
	},
	saveAddressToggle: {
		flexDirection: 'row',
		alignItems: 'center',
		marginVertical: 10,
	},
	saveAddressText: {
		marginLeft: 5,
		fontSize: smallTextSize,
		fontWeight: '700',
	},
})
