import * as React from 'react'
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Text } from '../../components/Themed'
import { FontAwesome5 } from '@expo/vector-icons'
import Modal from 'react-native-modal/dist/modal'
import { useState } from 'react'
import { largeTextSize, mediumTextSize, titleTextSize } from '../../constants/GlobalStyle'
import AppStoreLinks from '../../components/AppStoreButtons'
import { WebHeader } from '../../components/Headers/WebHeader'
import { auth } from '../../firebase/config'
import CopyrightFooter from '../../components/Footers/CopyrightFooter'
import { RewardsDescription } from '../../components/Rewards/RewardsDescription'
import { useAppSelector } from '../../state/hooks'

export default function QRLoyaltyScreen({ navigation, route }) {
	const orderId = route.params?.id ? route.params.id.trim() : ''

	const { loadedData } = useAppSelector(state => state.data)

	const iosURL = loadedData[global.org].iosStoreURL || ''
	const androidURL = loadedData[global.org].googlePlayStoreURL || ''

	const [isModalVisible, setModalVisible] = useState(true)

	const orgName = loadedData[global.org].organizationName
		? loadedData[global.org].organizationName
		: ''

	const orgLogo = loadedData[global.org].organizationLogo
		? loadedData[global.org].organizationLogo
		: ''

	/**
	 * 1. check if user is signed in
	 * 2. handle sign up/login: pass 'backTo' function & 'loyaltyOrderId' parameter to Account screen
	 * 3. call cloud function to add to order object:
	 *     loyaltyData: {
	 *          userId: string,
	 *          pointsEarned: number,
	 *          pointsRedeemed: number
	 *          orderItemIdRedeemed?: string
	 *          fixedDiscountAmountRedeemed?: number
	 *      }
	 * Also add to user object:
	 *    loyaltyPoints: number
	 */

	const SuccessText = () => {
		return (
			<>
				<Text
					style={{
						fontSize: titleTextSize,
						color: 'green',
						marginTop: 40,
						textAlign: 'center',
						fontWeight: '500',
					}}
				>
					{`SUCCESS!`}
				</Text>
				<Text
					style={{
						fontSize: largeTextSize,
						margin: 20,
						textAlign: 'center',
					}}
				>
					{`You earned ${pointsEarned} points on your purchase from ${orgName}.`}
				</Text>
			</>
		)
	}

	const FailedText = () => {
		return (
			<>
				<Text
					style={{
						fontSize: titleTextSize,
						color: 'red',
						marginTop: 40,
						textAlign: 'center',
						fontWeight: '500',
					}}
				>
					{`FAILED TO ADD POINTS`}
				</Text>
				<Text
					style={{
						fontSize: largeTextSize,
						margin: 20,
						textAlign: 'center',
					}}
				>
					{`An error occurred, please try again.`}
				</Text>
			</>
		)
	}

	const pointsEarned = 0
	const loyaltyEnabled = loadedData[global.org].loyaltyEnabled || false
	if (loyaltyEnabled) {
		if (auth.currentUser !== null && orderId !== '') {
			return (
				<View style={{ flex: 1 }}>
					<Modal
						isVisible={isModalVisible}
						onBackButtonPress={() => setModalVisible(false)}
						onBackdropPress={() => setModalVisible(false)}
						avoidKeyboard={false}
					>
						<View
							style={{
								justifyContent: 'space-between',
								alignItems: 'center',
								backgroundColor: 'white',
								borderRadius: 15,
							}}
						>
							<SuccessText />

							<NextButton
								style={{
									backgroundColor: global.orgColor,
									margin: 20,
									marginBottom: 40,
									width: '40%',
								}}
								text="Go Home"
								onPress={() => {
									setModalVisible(false)
									navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
								}}
							/>
						</View>
					</Modal>
				</View>
			)
		} else if (Platform.OS === 'web') {
			return (
				<>
					<WebHeader logo={orgLogo} navigation={navigation} />

					<View
						style={{
							flex: 1,
							justifyContent: 'space-between',
							alignSelf: 'center',
							marginTop: 50,
							width: '80%',
							maxWidth: 800,
						}}
					>
						<RewardsDescription orgName={orgName} />

						<View style={{ alignItems: 'center' }}>
							<Text
								style={{
									fontSize: titleTextSize,
									marginBottom: 20,
									textAlign: 'center',
								}}
							>
								{`DOWNLOAD THE APP`}
							</Text>
							<AppStoreLinks iosURL={iosURL} androidURL={androidURL} />
						</View>
						<View style={{ marginVertical: 30 }}>
							{auth.currentUser === null ? (
								<NextButton
									style={{ backgroundColor: global.orgColor }}
									text="SIGN UP TO START EARNING POINTS"
									onPress={() => {
										navigation.navigate('AccountStack', {
											screen: 'Account',
											params: { signup: true, returnTo: 'QRLoyalty', loyaltyId: orderId },
										})
									}}
								/>
							) : (
								<NextButton
									style={{ backgroundColor: global.orgColor }}
									text="ORDER NOW TO EARN POINTS"
									onPress={() => {
										navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
									}}
								/>
							)}
						</View>
					</View>
					<CopyrightFooter />
				</>
			)
		} else {
			navigation.navigate('AccountStack', {
				screen: 'Account',
				params: { signup: false, returnTo: 'QRLoyalty', loyaltyId: orderId },
			})
			return <></>
		}
	} else {
		navigation.navigate('HomeStack', {
			screen: 'RestaurantSelect',
		})
		return <></>
	}
}

function NextButton({ text, onPress, style }: { text: string; onPress: any; style?: any }) {
	return (
		<TouchableOpacity onPress={onPress} style={[styles.nextButtonContainer, style]}>
			<Text style={styles.nextButtonText}>{text}</Text>
			<FontAwesome5 name="angle-double-right" size={20} color="#FFF" style={styles.nextIcon} />
		</TouchableOpacity>
	)
}

const styles = StyleSheet.create({
	button: {
		alignItems: 'center',
		justifyContent: 'center',
		paddingVertical: 12,
		paddingHorizontal: 32,
		borderRadius: 4,
		elevation: 3,
		backgroundColor: 'black',
	},
	text: {
		fontSize: mediumTextSize,
		lineHeight: 21,
		fontWeight: 'bold',
		letterSpacing: 0.25,
		color: 'white',
	},
	exitButton: {
		margin: 5,
		zIndex: 100,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#ffffff',
		borderRadius: 50,
		width: 45,
		height: 45,
		shadowColor: '#000', // Shadow settings for iOS
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22,
		elevation: 3, // Shadow settings for Android
	},
	nextButtonContainer: {
		backgroundColor: 'black',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingVertical: 20,
		borderRadius: 20,
		marginBottom: 30,
	},
	nextButtonText: {
		color: '#FFF',
		fontSize: largeTextSize,
		fontWeight: '500',
		marginRight: 10,
		textAlign: 'center',
	},
	nextIcon: {
		position: 'absolute',
		right: 30,
		marginLeft: 5,
	},
	receiptContainer: {
		padding: 20,
		backgroundColor: '#fff',
		borderRadius: 8,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		textAlign: 'center',
		marginBottom: 20,
	},
	orderNumber: {
		fontSize: 16,
		marginBottom: 20,
	},
	itemContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	itemName: {
		fontSize: 16,
	},
	itemPrice: {
		fontSize: 16,
	},
	modifiersContainer: {
		marginTop: 5,
		marginLeft: 20,
	},
	modifierText: {
		fontSize: 14,
		color: '#555',
	},
	subTotal: {
		fontSize: 18,
		marginTop: 5,
	},
	serviceCharge: {
		fontSize: 18,
		marginTop: 10,
	},
	total: {
		fontSize: 20,
		fontWeight: 'bold',
		marginTop: 10,
	},
	centerText: {
		fontSize: 16,
		textAlign: 'center',
		marginBottom: 10,
	},
})
