import { alertResponseText } from '../../components/Alerts/Alerts'
import { addItemToCart, removeItemFromCart } from '../../state/Slices/persistedSlice'
import { checkSnooze } from '../../helpers/checkSnooze'

//VALIDATE ITEMS/MODS IN CART FOR DATA COMPATIBILITY --> AUTO DELETE / FIX --> ALERT FOR PRICE CHANGE / MOD DELETE
export function validateItems(
	menuItemObject,
	modifierItemObject,
	restaurantObject,
	dispatch,
	cart
) {
	let alertUpdate: boolean
	let modDeleteAlert: boolean
	let removedUpdate: boolean
	const keys = Object.keys(menuItemObject)
	cart.forEach(item => {
		if (!keys.includes(item.id)) {
			console.log('ITEM NOT IN OBJECT', item.id)
			dispatch(removeItemFromCart(item.id, item.idempotencyKey))
		} else {
			const dataItem = menuItemObject[item.id]
			const restaurantItem = restaurantObject[item.rId]

			const itemCheck =
				dataItem &&
				dataItem.restaurantId === item.rId &&
				!checkSnooze(dataItem.isSnoozed, dataItem.snoozeUntil) &&
				dataItem.id === item.id &&
				!dataItem.isDeleted

			const restaurantCheck =
				restaurantItem && !checkSnooze(restaurantItem.isSnoozed, restaurantItem.snoozeUntil)

			if (itemCheck && restaurantCheck) {
				const updatedItem = { ...item } // Spread item into a new object
				let update = false

				if (dataItem.price !== item.amount) {
					alertUpdate = true
					update = true
					updatedItem.amount = dataItem.price // Update new object
				}
				if (dataItem.promo !== item.promoPrice) {
					alertUpdate = true
					update = true
					updatedItem.promoPrice = dataItem.promo
				}
				if (dataItem.name !== item.name) {
					update = true
					updatedItem.name = dataItem.name
				}

				// Validate modifiers and create a new modList if needed
				const modCheck = validateModItems(item.modList, modifierItemObject)
				updatedItem.modList = modCheck.list

				if (modCheck.isUpdate === true) {
					update = true
				}
				if (modCheck.isAlert === true) {
					alertUpdate = true
				}
				if (modCheck.isModAlert === true) {
					modDeleteAlert = true
				}

				if (update === true) {
					dispatch(removeItemFromCart(item.id, item.idempotencyKey))
					dispatch(addItemToCart(updatedItem)) // Dispatch updated item
				}
			} else {
				removedUpdate = true
				console.log('DELETING DUE TO DATA MISMATCH')
				dispatch(removeItemFromCart(item.id, item.idempotencyKey))
			}
		}
	})

	if (removedUpdate === true) {
		alertResponseText(
			'Items in your cart have been removed',
			'These items are no longer available.'
		)
	} else if (alertUpdate === true && !modDeleteAlert) {
		alertResponseText(
			'Prices have changed for items in your cart',
			'Please review your cart before checking out.'
		)
	} else if (modDeleteAlert === true && !alertUpdate) {
		alertResponseText(
			'Modifier selections have changed for items in your cart',
			'Please review your cart before checking out.'
		)
	} else if (modDeleteAlert === true && alertUpdate === true) {
		alertResponseText(
			'Items in your cart have been changed',
			'Please review your cart before checking out.'
		)
	}
}

function validateModItems(modList, modifierItemObject) {
	let update = false
	let priceAlert = false
	let modAlert = false
	if (modList !== undefined && modList.length > 0) {
		const updatedModList = modList.map(list => {
			const updatedOptions = list.options
				.map(mod => {
					const dataItem = modifierItemObject['mod_item_' + mod.id]
					if (
						dataItem &&
						dataItem.id === mod.id &&
						!checkSnooze(dataItem.isSnoozed, dataItem.snoozeUntil)
					) {
						const updatedMod = { ...mod }
						if (mod.price !== dataItem.amount) {
							updatedMod.price = dataItem.amount
							update = true
							priceAlert = true
						}
						if (mod.name !== dataItem.name) {
							updatedMod.name = dataItem.name
							update = true
						}
						return updatedMod
					} else if (mod.isSelected === true) {
						console.log('DELETING MOD ITEM DUE TO DATA MISMATCH', mod.id)
						modAlert = true
						return null
					} else {
						return null
					}
				})
				.filter(Boolean) // Filter out null items (mods that were deleted)

			// Return a new list object with updated options
			return {
				...list,
				options: updatedOptions,
			}
		})

		return {
			list: updatedModList || [],
			isUpdate: update,
			isAlert: priceAlert,
			isModAlert: modAlert,
		}
	}
	return { list: modList || [], isUpdate: update, isAlert: priceAlert, isModAlert: modAlert }
}
