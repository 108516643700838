import React from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import ImageObject from '../../../components/Image/ImageObject'
import GlobalStyle, { mediumTextSize, smallTextSize } from '../../../constants/GlobalStyle'
import Colors from '../../../constants/Colors'
import { Text } from '../../../components/Themed'
import { useAppSelector } from '../../../state/hooks'

function CategoryCard({ name, imageUri, restaurantIds, cardWidth }) {
	const { loadedData } = useAppSelector(state => state.data)

	return (
		<View style={[GlobalStyle.shadowContainer, { marginBottom: '15%' }]}>
			<View style={[GlobalStyle.cardContainer]}>
				{imageUri !== null && imageUri !== '' ? (
					<ImageObject source={imageUri} style={[styles.image, { height: cardWidth * 0.75 }]} />
				) : (
					<View
						style={[
							styles.image,
							{ backgroundColor: Colors.greyscale[2], height: cardWidth * 0.75 },
						]}
					></View>
				)}
				<View style={styles.logoContainer}>
					{restaurantIds.map((id, index) => (
						<ImageObject
							key={index}
							source={loadedData[id]?.logoImg || null}
							style={[
								styles.logo,
								{
									display: loadedData[id]?.logoImg ? 'flex' : 'none',
									bottom: 0,
									right: index * 25 - 5,
									width: cardWidth * 0.25,
									height: cardWidth * 0.25,
									borderRadius: cardWidth * 0.125,
									borderWidth: 0,
								}, // offset each logo for stacked effect
							]}
						/>
					))}
				</View>
				<View style={styles.textArea}>
					<Text
						numberOfLines={1}
						ellipsizeMode="tail"
						style={[styles.name, { width: restaurantIds.length > 1 ? '58%' : '75%' }]}
					>
						{name.toUpperCase()}
					</Text>
				</View>
			</View>
		</View>
	)
}
const styles = StyleSheet.create({
	logoContainer: {
		position: 'absolute',
		bottom: 10,
		right: 10,
		zIndex: 1,
	},

	logo: {
		position: 'absolute',
		right: 0,
		borderRadius: (Dimensions.get('window').width * 0.08) / 2,
		borderWidth: 1,
		borderColor: Colors.greyscale[4],
		resizeMode: 'cover',
	},
	image: {
		width: '101%',
		resizeMode: 'cover',
	},
	name: {
		fontSize: mediumTextSize,
		fontWeight: '600',
		margin: '2%',
		width: '75%',
	},
	price: {
		fontSize: smallTextSize - 1,
		color: 'green',
		marginTop: 6,
	},
	textArea: {
		padding: 10,
	},
})

export default CategoryCard
