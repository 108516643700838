import React, { useEffect, useState } from 'react'
import { StyleSheet, Dimensions, TouchableOpacity } from 'react-native'
import Colors from '../../../constants/Colors'
import { Text, View } from '../../../components/Themed'
import { Ionicons } from '@expo/vector-icons'
import ImageObject from '../../../components/Image/ImageObject'
import GlobalStyle, { sTextSize, smallTextSize, xsTextSize } from '../../../constants/GlobalStyle'
import Formatter from '../../../helpers/Formatter'
import ModifierSelection from './ModifierSelection'
import { useAppSelector } from '../../../state/hooks'

export type ItemProps = {
	id: string
	name: string
	price: string
	imageUrl: string
	isRecommended: boolean
	isMulti: boolean
	showImage: boolean
	isSelected: boolean
	selectionType: string
	handleSelection: any
	modList: any
	mod: any
	cardWidth: any
	isMaxIncrement: boolean
	isVariation: boolean
}

export const ModifierItemCard = React.memo(
	(props: ItemProps) => {
		const {
			name,
			price,
			imageUrl,
			isRecommended,
			showImage,
			selectionType,
			handleSelection,
			modList,
			mod,
			cardWidth,
			isMaxIncrement,
			isSelected,
			isVariation,
		} = props
		const { itemPrice, singleSelectionId } = useAppSelector(state => state.item)
		const selected =
			modList.selectionType === 'SINGLE' ? singleSelectionId[modList.id] === mod.id : mod.isSelected

		const [quantity, setQuantity] = useState(mod.quantity ?? 0)

		// useEffect(() => {
		// 	console.log(mod.quantity)
		// }, [mod.quantity])

		const Price = () => {
			if (isVariation) {
				const totalAmount = (Number(price) * (quantity > 0 ? quantity : 1)) / 100
				return (
					<Text style={[styles.price, { opacity: isMaxIncrement && !selected ? 0.65 : 1 }]}>
						{`${Formatter.currencyFormat(Math.abs(totalAmount + itemPrice / 100))}`}
					</Text>
				)
			} else {
				const totalAmount = (Number(price) * (quantity > 0 ? quantity : 1)) / 100
				return Number(price) === 0 && (!selected || selectionType !== 'MULTIPLE') ? (
					<Text
						style={[
							styles.price,
							{
								color: Colors.greyscale[5],
							},
						]}
					>
						{'NO CHARGE'}
					</Text>
				) : (
					<Text style={[styles.price, { opacity: isMaxIncrement && !selected ? 0.65 : 1 }]}>
						{`${totalAmount >= 0 ? '+' : '-'}${Formatter.currencyFormat(Math.abs(totalAmount))}`}
					</Text>
				)
			}
		}
		return (
			<TouchableOpacity
				activeOpacity={selected ? 1 : 0.85}
				key={mod.id + mod.name}
				onPress={
					selected
						? null
						: () => handleSelection(modList.id, mod.id, modList, mod, true, quantity, setQuantity)
				}
			>
				<View style={[GlobalStyle.shadowContainer, { width: cardWidth, marginVertical: '5%' }]}>
					<View style={[GlobalStyle.cardContainer]}>
						<>
							{showImage && imageUrl !== undefined && imageUrl !== '' ? (
								<ImageObject
									source={imageUrl}
									style={[styles.image, { width: cardWidth, height: cardWidth * 0.75 }]}
								/>
							) : showImage ? (
								<View
									style={[
										styles.image,
										{
											backgroundColor: Colors.greyscale[2],
											width: cardWidth,
											height: cardWidth * 0.75,
										},
									]}
								></View>
							) : null}
							{selected && selectionType == 'MULTIPLE' ? (
								<>
									<ModifierSelection
										isSelected={selected}
										selectionType={selectionType}
										onPress={handleSelection}
										list={modList}
										mod={mod}
										showImage={showImage}
										cardWidth={cardWidth}
										quantity={quantity}
										setQuantity={setQuantity}
									/>
								</>
							) : (
								<View
									style={[
										styles.selectionCircle,
										{
											width: Dimensions.get('window').height * 0.025,
											height: Dimensions.get('window').height * 0.025,
											borderRadius: Dimensions.get('window').height * 0.0125,
											opacity: isMaxIncrement && !selected ? 0.3 : 1,
										},
										selected ? { borderColor: 'black', borderWidth: 1.5 } : {},
									]}
								>
									{selected && selectionType !== 'MULTIPLE' && (
										<Ionicons
											name="checkmark"
											size={Dimensions.get('window').height * 0.022}
											color="green"
											style={{}}
										/>
									)}
								</View>
							)}

							<View
								style={[
									styles.textArea,
									showImage
										? {
												height: smallTextSize * 6,
											}
										: {
												marginRight:
													selected && selectionType === 'MULTIPLE'
														? 0
														: Dimensions.get('window').height * 0.025 + 5,
												height: smallTextSize * 6,
											},
								]}
							>
								<Text
									numberOfLines={2}
									ellipsizeMode="tail"
									style={[styles.name, { opacity: isMaxIncrement && !selected ? 0.65 : 1 }]}
								>
									{name /* toUpperCase */}
								</Text>

								<Price />
							</View>
							{isRecommended && (
								<Text
									style={[
										styles.recommended,
										showImage
											? {}
											: {
													display: selected && selectionType === 'MULTIPLE' ? 'none' : 'flex',
												},
									]}
								>
									{'RECOMMENDED'}
								</Text>
							)}
						</>
					</View>
				</View>
			</TouchableOpacity>
		)
	},
	(prevProps, nextProps) => {
		return (
			prevProps.id === nextProps.id &&
			prevProps.isSelected === nextProps.isSelected &&
			prevProps.isMaxIncrement === nextProps.isMaxIncrement
		)
	}
)

ModifierItemCard.displayName = 'ModifierItemCard'

const styles = StyleSheet.create({
	image: {
		height: Dimensions.get('window').width * 0.2,
		resizeMode: 'center',
	},
	name: {
		fontSize: smallTextSize,
		fontWeight: '600',
	},
	price: {
		fontSize: sTextSize,
		color: 'green',
	},
	textArea: {
		padding: 10,
		paddingRight: '5%',
		justifyContent: 'space-between',
	},
	imageContainer: {
		position: 'relative',
	},
	selectionCircle: {
		position: 'absolute',
		top: 10,
		right: 10,
		width: Dimensions.get('window').width * 0.05,
		height: Dimensions.get('window').width * 0.05,
		borderRadius: (Dimensions.get('window').width * 0.05) / 2,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
		borderColor: Colors.greyscale[7],
		borderWidth: 1,
		zIndex: 1,
	},
	smallScreenPrice: {
		position: 'absolute',
		top: 10,
		right: 10,
		width: Dimensions.get('window').width * 0.1,
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1,
	},
	recommended: {
		position: 'absolute',
		bottom: '3%',
		right: '5%',
		padding: '2%',
		paddingHorizontal: '6%',
		borderRadius: 5,
		backgroundColor: '#48A22F',
		color: 'white',
		fontSize: xsTextSize,
		letterSpacing: 0.75,
		zIndex: 100,
	},
})
