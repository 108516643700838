import * as yup from 'yup'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { TextInput, View, TouchableOpacity, Image, Platform } from 'react-native'
import { auth } from '../../firebase/config'
import GlobalStyle, { formStyle } from '../../constants/GlobalStyle'
import Colors from '../../constants/Colors'
import { Spacer } from '../Spacer'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { alertResponseText } from '../Alerts/Alerts'
import { logLoginAnalytics } from '../../firebase/analytics'
import crashlytics from '../Crashlytics/crashlyticsLog'
import { Text } from '../Themed'
import Formatter from '../../helpers/Formatter'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { resetUserState, setUserState } from '../../state/Slices/userSlice'

// Yup schema for login form validation
const loginSchema = yup.object({
	email: yup.string().email('Invalid email').required('Email is required'),
	password: yup.string().required('Password is required'),
})

type LoginProps = {
	onCheckout: boolean
	navigation: any
	returnTo?: string
	loyaltyId?: string
}

export default function EmailPasswordLogin(props: LoginProps) {
	const { onCheckout, navigation, returnTo = null, loyaltyId = null } = props

	const { deviceHasInternet } = useAppSelector(state => state.user)

	const [errorText, setErrorText] = useState('')

	const [loginFailure, setLoginFailure] = useState(false)

	const [loadingUser, setLoadingUser] = useState(false)

	const functions = getFunctions()

	const dispatch = useAppDispatch()

	function errorMsg() {
		if (loginFailure) {
			return <Text style={[formStyle.errorText, { marginTop: 20 }]}>{errorText}</Text>
		}
	}

	/**
	 * Login using email and password
	 * @param email Login email
	 * @param password Login password
	 */
	async function emailAuth(email: string, password: string) {
		try {
			setLoadingUser(true)
			const generatedEmail = global.kiosk ? email : Formatter.encodedEmail(email)

			try {
				const res = await signInWithEmailAndPassword(auth, generatedEmail, password)
				await handleSuccessfulLogin(res)
			} catch (error) {
				console.log('Sign-in with generated email failed, attempting with original email:', error)
				crashlytics().log('Sign-in with generated email failed, attempting with original email')
				try {
					const res = await signInWithEmailAndPassword(auth, email, password)
					await handleSuccessfulLogin(res)
				} catch (innerError) {
					handleLoginError(innerError)
				}
			}
			setLoadingUser(false)
		} catch (outerError) {
			handleLoginError(outerError)
		}
	}

	async function handleSuccessfulLogin(res: any) {
		const getUserInDB = httpsCallable(functions, 'getUserInDB')
		const user: any = await getUserInDB({
			orgId: global.org,
			userId: res.user.uid,
		})

		if (user.data !== null) {
			dispatch(
				setUserState({
					userId: res.user.uid,
					stripeId: user.data.stripeId,
				})
			)
			crashlytics().setUserId(res.user.uid)

			console.log('User successfully signed in')
			crashlytics().log('User successfully signed in')

			logLoginAnalytics()

			if (returnTo !== null) {
				if (loyaltyId !== null) {
					navigation.navigate(returnTo, { id: loyaltyId })
				} else {
					navigation.navigate(returnTo)
				}
			} else if (onCheckout) {
				navigation.navigate('TabCheckout')
			} else if (Platform.OS === 'web') {
				navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
			}
		} else {
			setLoginFailure(true)
			dispatch(resetUserState())
			setErrorText('Incorrect email or password.')

			auth.signOut()
			alertResponseText('', 'Invalid email or password.')
			console.log('Organization mismatch')
			crashlytics().log('Email exists in other organization')
		}
	}

	function handleLoginError(error: any) {
		setLoadingUser(false)
		setLoginFailure(true)
		setErrorText('Incorrect email or password.')

		console.log(error)
		crashlytics().log(error)
	}

	return (
		<View>
			<Formik
				initialValues={{
					email: '',
					password: '',
				}}
				validationSchema={loginSchema}
				onSubmit={values => {
					// On-submit attempt log-in using provided email and password
					emailAuth(values.email, values.password)
				}}
			>
				{props => (
					<View>
						<TextInput
							returnKeyType={'done'}
							style={[
								formStyle.textInput,
								{
									fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont',
								},
							]}
							placeholder="Email"
							placeholderTextColor={Colors.custom.black}
							onChangeText={props.handleChange('email')}
							value={props.values.email}
							onBlur={props.handleBlur('email')}
							onKeyPress={() => {
								setErrorText('')
							}}
							autoComplete="email"
						/>
						<Text style={formStyle.errorText}>{props.touched.email && props.errors.email}</Text>
						<Spacer size={10} />
						<TextInput
							returnKeyType={'done'}
							style={[
								formStyle.textInput,
								{ fontFamily: Platform.OS === 'web' ? 'System' : 'DefaultFont' },
							]}
							placeholder="Password"
							placeholderTextColor={Colors.custom.black}
							onChangeText={props.handleChange('password')}
							secureTextEntry={true}
							value={props.values.password}
							onBlur={props.handleBlur('password')}
							autoComplete="current-password"
						/>
						<Text style={formStyle.errorText}>
							{props.touched.password && props.errors.password}
						</Text>
						<Spacer size={10} />
						<View style={formStyle.btnContainer}>
							<TouchableOpacity
								style={[
									formStyle.button,
									{ backgroundColor: !deviceHasInternet ? Colors.greyscale[6] : global.orgColor },
								]}
								onPress={loadingUser ? null : (props.handleSubmit as any)}
								disabled={!deviceHasInternet}
							>
								{loadingUser ? (
									<Image
										style={GlobalStyle.loadImgGif}
										source={require('../../assets/images/loadImg.gif')}
									/>
								) : (
									<Text style={formStyle.btnText}>LOG IN</Text>
								)}
							</TouchableOpacity>
						</View>
						<View style={{ flexDirection: 'row', justifyContent: 'center' }}>{errorMsg()}</View>
					</View>
				)}
			</Formik>
		</View>
	)
}
