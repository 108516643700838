import { useEffect, useMemo } from 'react'
import { add, intervalToDuration, isAfter, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setPersistedState } from '../../../state/Slices/persistedSlice'
import { alertResponseSingle } from '../../../components/Alerts/Alerts'

const usePickupTimeCheck = ({ navigation }) => {
	const { cart, cartPrep, isDelivery, deliveryEstimate } = useAppSelector(state => state.persisted)
	const { restaurants } = useAppSelector(state => state.data)
	const { localTimezone } = useAppSelector(state => state.settings)

	const currTime = utcToZonedTime(new Date(), localTimezone)
	const dispatch = useAppDispatch()

	function hasScheduledTimePassed() {
		let passed = false
		if (cartPrep.isScheduled) {
			const scheduledTime = cartPrep.pickupTime || cartPrep.deliveryTime
			if (scheduledTime) {
				const scheduledDate = parseISO(scheduledTime)
				const localCurrTime = utcToZonedTime(currTime, localTimezone)
				const localScheduledTime = utcToZonedTime(scheduledDate, localTimezone)

				if (isAfter(localCurrTime, localScheduledTime)) {
					console.log('Scheduled time has passed, clearing schedule time')
					passed = true
				}
			}
		}
		return passed
	}

	const getLongestPrepTime = useMemo(() => {
		const prepTimes = cart.map(item => {
			const restaurant = restaurants.find(r => r.id === item.rId)
			return restaurant ? restaurant.prepTime : 0
		})
		return Math.max(...prepTimes, 0)
	}, [cart, restaurants])

	useEffect(() => {
		const isPassed = hasScheduledTimePassed()
		if (isPassed || !cartPrep.isScheduled) {
			const pickupTime = add(currTime, { minutes: getLongestPrepTime })

			const duration = intervalToDuration({
				start: currTime,
				end: pickupTime,
			})
			const deliveryTime =
				deliveryEstimate && deliveryEstimate.estimate ? deliveryEstimate.estimate.timeToDeliver : 60

			dispatch(
				setPersistedState({
					cartPrep: {
						isScheduled: false,
						pickupTime: isDelivery ? null : pickupTime.toISOString(),
						deliveryTime: isDelivery ? pickupTime.toISOString() : null,
						prepTimeDuration: `P${duration.days}DT${duration.hours}H${duration.minutes}M${duration.seconds}S`,
						longestPrepTime: getLongestPrepTime,
						deliveryPrepTime: deliveryTime,
					},
				})
			)
		}
	}, [navigation])
}

export default usePickupTimeCheck
