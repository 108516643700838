import React, { useMemo } from 'react'
import { View, Image, FlatList, Dimensions, Platform } from 'react-native'
import { Text } from '../../../components/Themed'
import GlobalStyle from '../../../constants/GlobalStyle'
import { MenuHeader } from './Headers'
import { styles } from '../Helpers/style'
import { responsiveWidth } from 'react-native-responsive-dimensions'
import NavFooter from '../../../components/Footers/NavFooter'
import Colors from '../../../constants/Colors'
import { logSelectItemAnalytics } from '../../../firebase/analytics'
import ExitIcon from '../../../components/Headers/UIButtons'
import { addExtraComponents, getCategoryCardsLayout } from '../Helpers/functions'
import RowItems from './RowItems'

/**
 * Menu page for a specific category, can be from multiple brands
 * where rId is category_{categoryName}
 */
const CategoryMenu = ({
	navigation,
	ctgTokens,
	storeOpen,
	isCategory,
	heroImageURI,
	logoImageURI,
	categoryName,
}) => {
	const card = getCategoryCardsLayout()

	const buildCategoryList = useMemo(() => {
		const categoryList = []
		Object.entries(ctgTokens).forEach(([name, tokens]) => {
			categoryList.push({
				name: name,
				items: addExtraComponents(tokens, card.cardsPerRow),
			})
		})
		return categoryList
	}, [ctgTokens])

	return (
		<>
			<View
				style={{
					flexDirection: 'row',
					flex: 1,
				}}
			>
				<ExitIcon
					onPress={() => {
						navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
					}}
				/>

				<View style={{ flex: 2 }}>
					{ctgTokens && Object.entries(ctgTokens).length !== 0 ? (
						<FlatList
							data={buildCategoryList}
							initialNumToRender={3}
							windowSize={41}
							renderItem={({ item }) => (
								<View key={item.name + item.items[0].id} style={{ marginHorizontal: '5%' }}>
									{item.name && (
										<Text
											style={[GlobalStyle.titleText, { color: global.orgColor2, paddingTop: 20 }]}
										>
											{item.name.toUpperCase()}
										</Text>
									)}
									{isCategory && global.kiosk && !global.pos && (
										<Text style={styles.subHeaderText}>
											{`WHAT ${item.name.toUpperCase()} WOULD YOU LIKE TODAY?`}
										</Text>
									)}
									<RowItems
										name={item.name}
										rowItems={item.items}
										storeOpen={storeOpen}
										navigation={navigation}
										logSelectItemAnalytics={logSelectItemAnalytics}
										isBrandMenu={false}
										categoryName={categoryName}
									/>
								</View>
							)}
							keyExtractor={(item, index) => item.items[0].id + '_' + index}
							ListHeaderComponent={() => (
								<MenuHeader
									HeroImageURI={heroImageURI}
									LogoImageURI={isCategory ? null : logoImageURI}
									navigation={navigation}
									fill={Colors.custom.appBackground}
								/>
							)}
							ListHeaderComponentStyle={{
								marginBottom:
									Platform.OS === 'web'
										? -Dimensions.get('window').height * 0.05
										: -responsiveWidth(10),
							}}
							style={{
								marginBottom:
									Platform.OS === 'web'
										? Dimensions.get('window').height * 0.1
										: responsiveWidth(15),
							}}
						/>
					) : (
						<View style={{ alignItems: 'center', justifyContent: 'center' }}>
							<Image
								style={{
									width: 100,
									height: 100,
								}}
								source={require('../../../assets/images/loadImg.gif')}
							/>
						</View>
					)}
				</View>
			</View>
			<NavFooter navigation={navigation} isMenu={true} />
		</>
	)
}

export default CategoryMenu
