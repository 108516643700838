import React, { useState, useEffect } from 'react'
import { View, ScrollView, Platform, Image } from 'react-native'
import Separator from '../../components/Separator'
import { Spacer } from '../../components/Spacer'
import GlobalStyle from '../../constants/GlobalStyle'
import { RootStackScreenProps } from '../../navigation/types'
import { getFunctions, httpsCallable } from 'firebase/functions'
import PaymentMethodsList from '../../components/Stripe/PaymentMethodsList'
import Spinner from '../../components/LoadingSpinner'
import { Elements } from '@stripe/react-stripe-js'
import { MobileCardElement } from '../../App'
import StripeSaveCard from '../../components/Stripe/StripeSave'
import StripeWebSaveCard from '../../components/Stripe/StripeSave.web'
import stripeGetCards from '../../components/Stripe/StripeGetCards'
import { alertResponse } from '../../components/Alerts/Alerts'
import { TriangleHeader } from '../../components/Headers/TriangleHeader'
import CopyrightFooter from '../../components/Footers/CopyrightFooter'
import { WebCardElement } from '../../App.web'
import { useSquare } from '../../components/Square/Hooks/useSquare'
import { Text } from '../../components/Themed'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { setUserState } from '../../state/Slices/userSlice'
import { setCheckoutState } from '../../state/Slices/checkoutSlice'

/**
 * Displays the user's saved payment methods, accessed from the Account page.
 */
export default function WalletScreen({ navigation }: RootStackScreenProps<'Wallet'>) {
	const [spinner, setSpinner] = useState(false)
	const [loading, setLoading] = useState(false)

	const { deviceHasInternet, paymentMethods, userData, stripeId } = useAppSelector(
		state => state.user
	)

	const { paymentProvider } = useAppSelector(state => state.settings)

	const { organizationLogo } = useAppSelector(state => state.data)

	const dispatch = useAppDispatch()

	const { getCards, disableCard, createCustomerCard } = useSquare()

	let loadCard = true

	// Get cards saved in Stripe
	useEffect(() => {
		const unsubscribe = navigation.addListener('focus', async () => {
			if (!deviceHasInternet) {
				return
			}
			setLoading(true)
			if (
				paymentProvider === 'stripe' &&
				userData &&
				userData.user &&
				userData.user.stripeId &&
				userData.user.stripeId !== ''
			) {
				var stripeCards = await stripeGetCards(userData.user.stripeId)
				dispatch(setUserState({ paymentMethods: stripeCards }))
			} else {
				const squareCards = await getCards()
				dispatch(setUserState({ paymentMethods: squareCards }))
			}
			setLoading(false)
		})

		return () => {
			unsubscribe()
			loadCard = false
		}
	}, [navigation, deviceHasInternet])

	async function updateCardList() {
		if (stripeId !== '' || (userData && userData.user && userData.user.stripeId)) {
			const stripeCards = await stripeGetCards(
				userData && userData.user && userData.user.stripeId ? userData.user.stripeId : stripeId
			)
			dispatch(setUserState({ paymentMethods: stripeCards }))
		}
		return null
	}

	const functions = getFunctions()

	const removeStripeCard = httpsCallable(functions, 'removeStripeCard')

	async function handleDeleteCard(id: string) {
		alertResponse(
			'Delete payment method?',
			'',
			'Cancel',
			'Yes',
			'cancel',
			'default',
			() => {
				null
			},
			async () => {
				setSpinner(true)
				if (paymentProvider === 'stripe') {
					await removeStripeCard({
						paymentMethodId: id,
						orgId: global.org,
					})
					setTimeout(() => {
						setSpinner(false)
						updateCardList()
					}, 350)
				} else {
					await disableCard(id)
					setSpinner(false)
				}
			}
		)
	}

	const setIsCardValid = value => {
		dispatch(setCheckoutState({ isCardValid: value }))
	}

	function returnView() {
		return (
			<>
				<Spinner
					visible={spinner}
					overlayColor="rgba(0, 0, 0, 0.65)"
					headerHeight={0}
					footerHeight={0}
					color="black"
					customIndicator={
						<Image
							style={{ width: 100, height: 100, alignSelf: 'center', marginTop: -20 }}
							source={require('../../assets/images/loadImg.gif')}
						/>
					}
				/>
				<TriangleHeader
					navigation={navigation}
					logo={organizationLogo}
					title="Wallet"
					backFunction={() => {
						if (Platform.OS === 'web') {
							navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
						} else {
							navigation.navigate('AccountStack', { screen: 'Account' })
						}
					}}
				/>
				<ScrollView style={[GlobalStyle.container, GlobalStyle.whiteBg, { marginTop: 0 }]}>
					<Text style={GlobalStyle.title}>Your Payment Methods</Text>
					<Spacer size={5} />
					<PaymentMethodsList
						paymentMethods={paymentMethods}
						loading={loading}
						isCheckout={false}
						onPress={handleDeleteCard}
						cardId={''}
						isDefault={false}
						selectedCard={null}
						digitalWalletType={null}
					/>
					<Separator />

					<Text style={GlobalStyle.title}>Add New Payment Method</Text>

					{Platform.OS === 'web' ? (
						<>
							{loadCard ? (
								<View style={{ paddingVertical: 20 }}>
									<WebCardElement
										setIsCardValid={setIsCardValid}
										paymentProvider={paymentProvider}
										title={'ADD CARD'}
										onPress={async token => {
											setSpinner(true)
											await createCustomerCard(token, null, false)
											setSpinner(false)
										}}
									/>
								</View>
							) : null}
							{paymentProvider === 'stripe' ? (
								<StripeWebSaveCard
									onPress={() => {
										updateCardList()
									}}
									stripeCustomerId={
										stripeId && stripeId !== ''
											? stripeId
											: userData && userData.user && userData.user.stripeId
												? userData.user.stripeId
												: ''
									}
									title="ADD CARD"
									buttonStyle={[
										GlobalStyle.addItemBtn,
										{
											flex: 1,
											borderRadius: 25,
											marginLeft: 15,
											marginRight: 30,
											width: 150,
										},
									]}
									textStyle={GlobalStyle.addItemBtnText}
								/>
							) : null}
						</>
					) : (
						<>
							<View
								style={{
									width: '110%',
									alignSelf: 'center',
									alignContent: 'center',
									marginLeft: '5%',
									display: paymentProvider === 'square' ? 'none' : 'flex',
								}}
							>
								<MobileCardElement setIsCardValid={setIsCardValid} />
							</View>
							<StripeSaveCard
								onPress={() => {
									updateCardList()
									navigation.navigate('AccountStack', { screen: 'Account' })
									navigation.navigate('AccountStack', { screen: 'Wallet' })
								}}
								stripeCustomerId={
									stripeId && stripeId !== ''
										? stripeId
										: userData && userData.user && userData.user.stripeId
											? userData.user.stripeId
											: ''
								}
								title="ADD CARD"
							/>
						</>
					)}
					<Separator />
				</ScrollView>
				{Platform.OS === 'web' && <CopyrightFooter />}
			</>
		)
	}

	return Platform.OS === 'web' ? (
		<>
			<Elements stripe={global.stripePromise}>{returnView()}</Elements>
		</>
	) : (
		<>{returnView()}</>
	)
}
