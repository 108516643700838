import * as React from 'react'
import { Dimensions, Platform, TouchableOpacity } from 'react-native'
import { View, Text } from '../../../components/Themed'
import CategoryCard from './CategoryCard'
import { styles } from '../style'
import Banner from '../../../components/Banner'
import { calculateOptimalCardWidth } from '../../../helpers/calculateOptimalCardWidth'
import { SMALL_WIDTH } from '../../../constants/GlobalStyle'
import { logSelectMenuAnalytics } from '../../../firebase/analytics'
import { checkSnooze } from '../../../helpers/checkSnooze'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setMenuState } from '../../../state/Slices/menuSlice'

/**
 * Categories section of the home page of the app
 */
export function Categories({ navigation }) {
	const { loadedData } = useAppSelector(state => state.data)

	const dispatch = useAppDispatch()

	const menuItemCategories = loadedData['menuItemCategories']

	// Pre-filter categories based on item presence
	let nonEmptyCategories = menuItemCategories.filter(
		category => category.items && category.items.length > 0
	)

	// Further filter categories based on snooze status
	nonEmptyCategories = nonEmptyCategories.filter(category => {
		// Keep restaurants that are not snoozed
		return Array.from(category.restaurantIds).some((restaurantId: string) => {
			const restaurantData = loadedData[restaurantId]
			if (restaurantData) {
				return !checkSnooze(restaurantData.isSnoozed, restaurantData.snoozeUntil)
			}
			return false
		})
	})

	const overMaxWidth = Dimensions.get('window').width > 1200
	const screenWidth = overMaxWidth ? 1200 : Dimensions.get('window').width * 0.9

	const card = calculateOptimalCardWidth(screenWidth)
	const CARD_WIDTH = card.width

	let bannerMessage = ''
	if (loadedData && loadedData[global.org] && loadedData[global.org].bannerMessage) {
		bannerMessage = loadedData[global.org].bannerMessage
	}
	//const CARD_WIDTH = widthOfCard()

	const PlaceholderComponents = () => {
		const placeholders = []
		const extraSpots = nonEmptyCategories.length % card.cardsPerRow
		if (extraSpots >= 1) {
			const num =
				extraSpots === 1 ? card.cardsPerRow - extraSpots + 1 : card.cardsPerRow - extraSpots

			for (let i = 0; i < num; i++) {
				placeholders.push(
					<View key={'placeholder_' + i} style={{ opacity: 0, width: CARD_WIDTH }} />
				)
			}
			return <>{placeholders}</>
		}
		return <>{placeholders}</>
	}
	if (nonEmptyCategories.length <= 0) {
		return <></>
	}
	return (
		<View
			style={{
				width: Dimensions.get('window').width,
				maxWidth: screenWidth,
				alignSelf: 'center',
			}}
		>
			<Text
				style={[
					styles.subHeaderText,
					{
						marginHorizontal: 0,
						paddingTop: 0,
						paddingBottom: SMALL_WIDTH ? '5%' : '3%',
						display: global.kiosk && !global.pos ? 'none' : 'flex',
						marginTop: loadedData[global.org].loyaltyEnabled
							? '4%'
							: Platform.OS === 'web'
								? 40
								: -30,
					},
				]}
			>
				{'SELECT A CATEGORY'}
			</Text>
			<View style={[{ display: global.kiosk && !global.pos ? 'flex' : 'none' }]}>
				{!global.kiosk && <Banner text={bannerMessage} />}
				<Text style={[styles.welcomeText, { marginTop: global.kiosk ? 0 : -20 }]}>
					{'WELCOME!'}
				</Text>
				<Text style={styles.subHeaderText}>{'WHAT WOULD YOU LIKE TO ORDER TODAY?'}</Text>
			</View>

			<View
				style={{
					//flex: 1,
					flexDirection: 'row',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
				}}
			>
				{nonEmptyCategories.map(
					(
						category: { name: string; image: any; items: string[]; restaurantIds: Set<any> },
						index
					) => (
						<TouchableOpacity
							style={{ width: CARD_WIDTH }}
							activeOpacity={0.92}
							key={category.name + index}
							onPress={() => {
								if (category !== undefined) {
									dispatch(
										setMenuState({
											logoImage: loadedData[Array.from(category.restaurantIds)[0]].logoImg,
											heroImage: category.image ? category.image.url : null,
										})
									)
									const ctgId = 'category_' + category.name.toLowerCase().replace(/\s/g, '+')
									logSelectMenuAnalytics('category_menu', ctgId) //log analytics

									navigation.navigate('Menu', {
										rId: ctgId,
									})
								} else {
									console.log('Could not load.')
								}
							}}
						>
							<CategoryCard
								cardWidth={CARD_WIDTH}
								name={category.name}
								imageUri={category.image ? category.image.url : null}
								restaurantIds={Array.from(category.restaurantIds)}
							/>
						</TouchableOpacity>
					)
				)}

				<PlaceholderComponents />
			</View>
		</View>
	)
}
