import React, { useRef, useState } from 'react'
import {
	Platform,
	ScrollView,
	View,
	KeyboardAvoidingView,
	Dimensions,
	Animated,
} from 'react-native'
import { Text } from '../../components/Themed'
import { RootTabScreenProps, RootStackParamList } from '../../navigation/types'
import { RouteProp, useRoute } from '@react-navigation/native'
import GlobalStyle, { mediumTextSize, xlTextSize } from '../../constants/GlobalStyle'
import {
	getCombinedModPrice,
	generateUniqueId,
	countQtySelected,
	getModListQuantity,
} from './Helpers/functions'
import useItem from './Hooks/useItem'
import { ModifierLists } from './Components/ModifierLists'
import { ItemImage } from './Components/ItemImage'
import NavFooter from '../../components/Footers/NavFooter'
import Colors from '../../constants/Colors'
import { ItemAdded } from './Components/ItemAdded'
import { logAddItemAnalytics } from '../../firebase/analytics'
import { formatRestaurantId } from '../../helpers/formatRestaurantId'
import ExitIcon from '../../components/Headers/UIButtons'
import { alertResponseText } from '../../components/Alerts/Alerts'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import {
	addItemToCart,
	redeemLoyaltyReward,
	removeItemFromCart,
} from '../../state/Slices/persistedSlice'
import { setItemState } from '../../state/Slices/itemSlice'

export default function ItemScreen({ navigation }: RootTabScreenProps<any>) {
	const token = useRoute<RouteProp<RootStackParamList, 'Item'>>().params.token //item id
	const editing = JSON.parse(useRoute<RouteProp<RootStackParamList, 'Item'>>().params.editing)
	const menuId = useRoute<RouteProp<RootStackParamList, 'Item'>>().params.menuId
	const menuItemCategory = useRoute<RouteProp<RootStackParamList, 'Item'>>().params.menuItemCategory
	const appliedCoupon = useRoute<RouteProp<RootStackParamList, 'Item'>>().params.appliedCoupon
	const category = useRoute<RouteProp<RootStackParamList, 'Item'>>().params.category
	const loyaltyPrice = useRoute<RouteProp<RootStackParamList, 'Item'>>().params.loyaltyPrice
	const loyaltyPoints = useRoute<RouteProp<RootStackParamList, 'Item'>>().params.loyaltyPoints
	const loyaltyId = useRoute<RouteProp<RootStackParamList, 'Item'>>().params.loyaltyId
	const isRestaurantSnoozed: any =
		useRoute<RouteProp<RootStackParamList, 'Item'>>().params.isRestaurantSnoozed

	const isRSnoozed = isRestaurantSnoozed === 'true' ? true : false

	const {
		itemId,
		itemName,
		itemTaxes,
		itemNote,
		idempotencyKey,
		itemDesc,
		posId,
		itemPrice,
		promoPrice,
		quantity,
		imageUri,
		modList,
		restId,
		visibleModListIndex,
		totalModPrice,
		ageVerificationRequired,
		itemHours,
	} = useAppSelector(state => state.item)

	const rIdToken = category ? 'category_' + menuItemCategory.toLowerCase() : restId

	const { deliveryEnabled, isSingleBrand } = useAppSelector(state => state.settings)
	const { loadedData } = useAppSelector(state => state.data)
	const { cart, isDelivery } = useAppSelector(state => state.persisted)
	const singleBrandEnabled = loadedData[global.org].singleBrandEnabled || false

	const dispatch = useAppDispatch()

	const [yPos, setYPos] = useState(0)
	const [visible, setVisible] = useState(false)
	const fadeAnim = useState(new Animated.Value(0.1))[0]
	const slideAnim = useState(new Animated.Value(Dimensions.get('window').height * 0.7))[0]
	const scaleAnim = useRef(new Animated.Value(0)).current
	const [bagAnim] = useState(new Animated.Value(1)) // Starting scale for bag
	const [checkmarkAnim] = useState(new Animated.Value(0))
	const [checkmarkTranslateY] = useState(
		new Animated.Value(Dimensions.get('window').height * -0.05)
	)
	const scrollViewRef = useRef(null)
	const navigationTimeoutRef = useRef(null)
	let isEdit = false
	if (editing && editing.isEditing) {
		isEdit = true
	}

	const ANIMATION_DURATION = Platform.OS === 'web' ? 425 : 500

	//get item data, build modifier lists
	useItem({ appliedCoupon, token, editing, loyaltyPrice })

	const handleAddedToOrderAnimation = () => {
		setVisible(true)

		// Fade in animation
		Animated.timing(fadeAnim, {
			toValue: 0.95,
			duration: ANIMATION_DURATION - 200,
			useNativeDriver: true,
		}).start()

		// Slide up animation
		Animated.timing(slideAnim, {
			toValue: 0,
			duration: ANIMATION_DURATION - 100,
			useNativeDriver: true,
		}).start()

		// Hide the view after a delay
		setTimeout(() => {
			setVisible(false)
		}, ANIMATION_DURATION * 4)
	}
	const handleCheckmarkAnimation = () => {
		Animated.parallel([
			Animated.timing(bagAnim, {
				delay: ANIMATION_DURATION - 200,
				toValue: 1.1,
				duration: ANIMATION_DURATION - 200,
				useNativeDriver: true,
			}),
			Animated.timing(checkmarkAnim, {
				delay: ANIMATION_DURATION - 100,
				toValue: 0.9,
				duration: ANIMATION_DURATION,
				useNativeDriver: true,
			}),
			Animated.timing(checkmarkTranslateY, {
				delay: ANIMATION_DURATION - 200,
				toValue: Dimensions.get('window').height * -0.1, // Move up
				duration: ANIMATION_DURATION,
				useNativeDriver: true,
			}),
		]).start(() => {
			// Shrink bag and checkmark back to original size
			Animated.parallel([
				Animated.timing(bagAnim, {
					toValue: 1,
					duration: ANIMATION_DURATION - 200,
					useNativeDriver: true,
				}),
				Animated.timing(checkmarkAnim, {
					toValue: 0,
					duration: ANIMATION_DURATION,
					useNativeDriver: true,
				}),
				Animated.timing(checkmarkTranslateY, {
					toValue: Dimensions.get('window').height * -0.07, // Move back to original position
					duration: ANIMATION_DURATION,
					useNativeDriver: true,
				}),
			]).start()
		})
	}

	const skipAnimationAndNavigate = () => {
		if (visible) {
			if (navigationTimeoutRef.current) {
				if (singleBrandEnabled) {
					if (Platform.OS === 'web') {
						// use timeout to stop skipping animation causing press on item in menu on mobile web
						setTimeout(() => {
							navigation.navigate('HomeStack', { screen: 'Menu', params: { rId: menuId } })
						}, 150)
					} else if (!global.kiosk) {
						navigation.navigate('HomeStack', { screen: 'Menu', params: { rId: menuId } })
					} else {
						navigation.popToTop()
					}
				} else {
					navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
				}

				clearTimeout(navigationTimeoutRef.current)
				navigationTimeoutRef.current = null
			}
		}
	}

	function canAddToCartForDelivery(itemRestaurantId) {
		if (cart.length === 0) {
			return true
		}
		return cart.every(item => item.rId === itemRestaurantId)
	}

	const addItemDispatch = selectedId => {
		dispatch(
			addItemToCart({
				name: itemName,
				amount: itemPrice,
				id: itemId,
				qty: quantity,
				rId: restId,
				modList: modList,
				modPriceSum: getCombinedModPrice(modList),
				note: itemNote,
				taxes: itemTaxes.length > 0 ? itemTaxes[0] : [],
				promoPrice: promoPrice,
				menuItemCategory: menuItemCategory,
				menuId: menuId,
				idempotencyKey: generateUniqueId(),
				imageUri: imageUri,
				appliedCoupon: loyaltyId,
				posId: selectedId ? selectedId : posId,
				ageVerificationRequired: ageVerificationRequired,
				hours: itemHours,
			})
		)
	}

	function handleAddToCartClick(): void {
		const minReached = isMinReached(modList, modList[visibleModListIndex])
		const canAddToCart =
			loadedData[global.org].singleBrandEnabled || !deliveryEnabled || !isDelivery
				? true
				: canAddToCartForDelivery(restId)
		if (minReached && canAddToCart) {
			// Find the first mod in modList with "variation" set to true
			const variationMod = modList.find(mod => mod.variation === true)

			// From the "options" of the found mod, find the option where "isSelected" is true
			const selectedOption = variationMod?.options.find(option => option.isSelected === true)

			// Retrieve the id from the selected option, if it exists
			const selectedId = selectedOption ? selectedOption.id : null
			handleAddedToOrderAnimation()
			handleCheckmarkAnimation()

			dispatch(setItemState({ showMinErrorText: false }))

			if (editing && editing.isEditing) {
				dispatch(removeItemFromCart(itemId, idempotencyKey))
				addItemDispatch(selectedId)

				navigation.navigate('TabCheckout')
			} else {
				// Log firebase analytics
				const listInfo = formatRestaurantId(rIdToken, loadedData)
				logAddItemAnalytics((itemPrice + totalModPrice) / 100, {
					item_id: itemId,
					item_name: itemName,
					item_location_id: restId,
					quantity: quantity,
					price: (itemPrice + totalModPrice) / 100,
					item_category: menuItemCategory,
					item_list_id: listInfo.id,
					item_list_name: listInfo.name,
				})

				addItemDispatch(selectedId)

				// Update loyalty redemption details
				if (loyaltyPrice !== null && menuItemCategory === 'loyaltyReward') {
					dispatch(redeemLoyaltyReward(loyaltyId, loyaltyPoints, itemPrice, restId, itemId))
				}

				// Show item added animation
				if (navigationTimeoutRef.current) {
					clearTimeout(navigationTimeoutRef.current)
				}
				navigationTimeoutRef.current = setTimeout(() => {
					if (singleBrandEnabled) {
						if (!global.kiosk) {
							navigation.navigate('HomeStack', { screen: 'Menu', params: { rId: menuId } })
						} else {
							navigation.popToTop()
						}
					} else {
						navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
					}

					navigationTimeoutRef.current = null
				}, ANIMATION_DURATION * 4)
			}
		} else if (!canAddToCart) {
			alertResponseText('Can only deliver from 1 restaurant.', 'Please check your cart.')
		} else {
			dispatch(setItemState({ showMinErrorText: true }))
		}
	}

	function isMinReached(modList, list) {
		if (list !== undefined) {
			const selected = countQtySelected(list, getModListQuantity(modList, 0, list))
			const minNotReached = list.min > 0 && selected < list.min

			return !minNotReached
		}
		return true
	}

	const findNextVisibleModListIndex = index => {
		for (let i = index + 1; i < modList.length; i++) {
			const mod = modList[i]

			const parentModifierId = mod.id.split('-')[1]
			const showNested = modList.some(modifierList =>
				modifierList.options.some(
					modifier => modifier.isSelected && modifier.id === parentModifierId
				)
			)

			if (mod.isNested && !showNested) {
				continue
			}

			return i
		}
	}

	const findPreviousVisibleModListIndex = index => {
		for (let i = index - 1; i >= 0; i--) {
			const mod = modList[i]

			const parentModifierId = mod.id.split('-')[1]
			const showNested = modList.some(modifierList =>
				modifierList.options.some(
					modifier => modifier.isSelected && modifier.id === parentModifierId
				)
			)

			if (mod.isNested && !showNested) {
				continue
			}

			return i
		}
	}

	return (
		<>
			<View
				onPointerDown={Platform.OS === 'web' ? skipAnimationAndNavigate : null}
				onTouchStart={Platform.OS !== 'web' ? skipAnimationAndNavigate : null}
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: 'rgba(0,0,0,0.5)',
				}}
			>
				{visible && (
					<ItemAdded
						price={promoPrice !== -1 ? promoPrice + totalModPrice : itemPrice + totalModPrice}
						fadeAnim={fadeAnim}
						slideAnim={slideAnim}
						scaleAnim={scaleAnim}
						bagAnim={bagAnim}
						checkmarkAnim={checkmarkAnim}
						checkmarkTranslateY={checkmarkTranslateY}
						isLoyalty={loyaltyPrice !== null && menuItemCategory === 'loyaltyReward'}
					/>
				)}
				<KeyboardAvoidingView
					behavior="padding"
					style={{
						overflow: 'hidden',
						maxWidth: 720,
						width: Dimensions.get('window').width > 500 ? '90%' : '100%',
						height: Dimensions.get('window').width > 500 ? '90%' : '100%',
						backgroundColor: Colors.custom.appBackground,
						borderRadius: Dimensions.get('window').width > 500 ? 10 : 0,
					}}
				>
					<ExitIcon
						onPress={() => {
							if (isEdit) {
								navigation.navigate('TabCheckout')
							} else if (
								loyaltyPrice !== null &&
								menuItemCategory === 'loyaltyReward' &&
								isSingleBrand
							) {
								navigation.navigate('TabCheckout')
							} else if (loyaltyPrice !== null && menuItemCategory === 'loyaltyReward') {
								navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
							} else {
								navigation.navigate('HomeStack', { screen: 'Menu', params: { rId: menuId } })
							}
						}}
					/>
					<ScrollView ref={scrollViewRef} showsVerticalScrollIndicator={false}>
						<ItemImage
							imageUri={imageUri}
							navigation={navigation}
							menuId={rIdToken}
							isEdit={editing && editing.isEditing}
							isLoyalty={loyaltyPrice !== null && menuItemCategory === 'loyaltyReward'}
						/>

						<View
							style={{
								height: '100%',
								marginBottom: Dimensions.get('window').height * 0.1,
							}}
						>
							<View style={{ marginHorizontal: '3%' }}>
								<Text
									style={[
										GlobalStyle.titleText,
										{
											color: global.orgColor2,
											marginTop: Dimensions.get('window').height * -0.05,
											width: '65%',
											paddingBottom:
												menuItemCategory === 'loyaltyReward' && loyaltyPoints !== undefined
													? 0
													: 10,
										},
									]}
								>
									{itemName /* toUpperCase */}
								</Text>
								{menuItemCategory === 'loyaltyReward' && loyaltyPoints !== undefined && (
									<Text
										style={{
											color: 'green',
											fontSize: xlTextSize,
											fontWeight: '700',
											marginBottom: 10,
											marginTop: 5,
											opacity: 0.9,
										}}
									>
										{`LOYALTY REWARD (${loyaltyPoints} POINTS)`}
									</Text>
								)}

								<Text
									style={{
										color: Colors.greyscale[5],
										fontSize: mediumTextSize,
										paddingBottom: 30,
									}}
								>
									{itemDesc}
								</Text>
							</View>
							<ModifierLists
								setYPos={setYPos}
								scrollViewRef={scrollViewRef}
								isLoyalty={menuItemCategory === 'loyaltyReward' && loyaltyPoints !== undefined}
							/>
						</View>
					</ScrollView>
					<NavFooter
						isEdit={isEdit}
						rId={rIdToken}
						navigation={navigation}
						price={itemPrice + totalModPrice}
						showNext={modList[visibleModListIndex + 1] === undefined ? false : true}
						showAddToOrder={modList[visibleModListIndex + 1] === undefined ? true : false}
						isRestaurantSnoozed={isRSnoozed}
						onNextPress={() => {
							const minReached = isMinReached(modList, modList[visibleModListIndex])
							if (minReached) {
								//setYPos(yPos + Dimensions.get('window').height * 0.25)
								if (scrollViewRef.current) {
									scrollViewRef.current.scrollTo({
										x: 0,
										y: yPos,
										animated: true,
									})
								}

								const nextIndex = findNextVisibleModListIndex(visibleModListIndex)

								dispatch(
									setItemState({
										showMinErrorText: false,
										visibleModListIndex: nextIndex,
										visibleModList: modList[nextIndex].id,
									})
								)
							} else {
								dispatch(setItemState({ showMinErrorText: true }))
							}
						}}
						blurNext={!isMinReached(modList, modList[visibleModListIndex])}
						onAddToOrderPress={handleAddToCartClick}
						onBackPress={
							visibleModListIndex > 0
								? () => {
										if (scrollViewRef.current) {
											scrollViewRef.current.scrollTo({
												x: 0,
												y: yPos,
												animated: true,
											})
										}

										const prevIndex = findPreviousVisibleModListIndex(visibleModListIndex)

										dispatch(
											setItemState({
												showMinErrorText: false,
												visibleModListIndex: prevIndex,
												visibleModList: modList[prevIndex].id,
											})
										)
									}
								: null
						}
						returnToCheckout={editing && editing.isEditing}
					/>
				</KeyboardAvoidingView>
			</View>
		</>
	)
}
