import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LocationManager } from '../types'

// not persisted
// set once in RetrieveData and then not changed
const initialState = {
	isSingleBrand: false,
	homeLayout: '',
	showCuisines: false,
	posLoyaltyEnabled: false,
	dineInEnabled: false,
	usesTableNumbers: false,
	posProvider: '',
	paymentProvider: '',
	pickupPointsEnabled: false,
	allowOrdersOutsideOrgHours: false,
	delivery: {
		freeDeliveryMultiplier: -1,
		isSandbox: false,
		orgCoverage: -1,
		orgCoverageType: '',
		actionIfUndeliverable: 'dispose',
		pickupInstructions: '',
		minSpend: -1,
	},
	deliveryEnabled: false,
	localTimezone: '',
	scheduleTimeInterval: 15,
	scheduleDaysAhead: 14,
	serviceFee: 0,
	serviceFeeTax: 0,
	orgDeliveryCoverageType: '',
	orgDeliveryCoverage: 0,
	deliveryIsSandbox: false,
	isSandbox: false,
}

type StateTypes = typeof initialState

const slice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setStates: (state, action: PayloadAction<Partial<StateTypes>>) => {
			Object.assign(state, action.payload)
		},
	},
})

const { setStates } = slice.actions

export const setSettingsState = (updates: Partial<StateTypes>) => setStates(updates)

export default slice.reducer
