import { useEffect, useState } from 'react'
import { auth, db } from '../../../firebase/config'
import { DATABASE_NAME } from '@env'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { doc, onSnapshot } from 'firebase/firestore'
import crashlytics from '../../../components/Crashlytics/crashlyticsLog'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'

const useStatusUpdate = () => {
	const functions = getFunctions()
	const dispatch = useAppDispatch()
	const [shouldRetry, setShouldRetry] = useState(false) // New state variable to trigger retries
	const {
		orderCreated,
		status,
		orderId,
		orderPlaced,
		terminalOrder,
		typePOS,
		checkoutId,
		items,
		selectedMethods,
		processedOrder,
		isGuestCheckout,
		takeOutName,
		prices,
		isDineIn,
	} = useAppSelector(state => state.checkout)
	const { paymentProvider, posProvider } = useAppSelector(state => state.settings)
	const { selectedLocation } = useAppSelector(state => state.persisted)
	const { userLocation } = useAppSelector(state => state.user)

	const userId = auth.currentUser ? auth.currentUser.uid : ''
	const isPinpad = typePOS === 'manual' ? false : global.kiosk

	useEffect(() => {
		if (orderId !== '' && global.kiosk && orderCreated && auth.currentUser) {
			const orderRef = doc(db, DATABASE_NAME, global.org, 'Orders', orderId)
			try {
				const unsubscribe = onSnapshot(orderRef, snap => {
					const data = snap.data()
					console.log('Current status: ' + data.status)
					crashlytics().log('Current status: ' + data.status)
					dispatch(setCheckoutState({ status: data.status }))
				})

				return () => unsubscribe()
			} catch (e) {
				crashlytics().log('Error getting snapshot, retrying: ' + JSON.stringify(e))
				console.log(e)
				console.log('Retrying snapshot...')
				setShouldRetry(true)
			}
		}
	}, [orderCreated, shouldRetry])

	useEffect(() => {
		const statusUpdate = async () => {
			if (!orderId) {
				return
			}

			if (global.kiosk && status === 'Payment Accepted') {
				const statusObj = { status: 'In Progress' }
				dispatch(setCheckoutState({ orderPlaced: true }))
				crashlytics().log('SETTING ORDER PLACED: TRUE')
				console.log('Updating status...')
				crashlytics().log('KIOSK ORDER - UPDATING STATUS')

				const setOrderStatusInDB = httpsCallable(functions, 'setOrderStatusInDB')
				setOrderStatusInDB({
					orgId: global.org,
					orderId: orderId,
					status: statusObj.status,
				}).then(() => {
					console.log('Status updated to ' + statusObj.status)
					crashlytics().log('Status updated to ' + statusObj.status)
				})

				if (paymentProvider === 'square') {
					console.log('GETTING SQUARE TERMINAL CHECKOUT...')
					crashlytics().log('GETTING SQUARE TERMINAL CHECKOUT...')
					const getSquareTerminalCheckout = httpsCallable(functions, 'getSquareTerminalCheckout')
					const terminalCheckout = (await getSquareTerminalCheckout({
						checkoutId,
						restaurantId: items[0].rId,
						orgId: global.org,
					})) as any
					console.log('GETTING SQUARE PAYMENT...')
					crashlytics().log('GETTING SQUARE PAYMENT...')

					const getSquarePayment = httpsCallable(functions, 'getSquarePayment')
					const terminalPayment = (await getSquarePayment({
						paymentId: terminalCheckout.data.paymentIds[0],
						restaurantId: items[0].rId,
						orgId: global.org,
					})) as any
					console.log('PROCESSING SQUARE PAYMENT...')
					crashlytics().log('PROCESSING SQUARE PAYMENT...')

					const processPaymentForSquare = httpsCallable(functions, 'processPaymentForSquare')
					await processPaymentForSquare({
						orgId: global.org,
						orderData: terminalOrder.data,
						uid: userId,
						paymentIntentId: null,
						isKiosk: isPinpad,
						isDineIn: isDineIn,
						tipMoney: terminalPayment.data.tipMoney?.amount ?? 0,
					})
					crashlytics().log('PAYMENT PROCESSED')

					const phoneNumber =
						selectedMethods.phoneString !== '' ? '+1' + selectedMethods.phoneString : ''

					if (terminalOrder.data && terminalOrder.data.length > 0 && phoneNumber !== '') {
						terminalOrder.data.forEach(async order => {
							//ADD LOYALTY POINTS
							const addSquareLoyaltyPointsFromOrder = httpsCallable(
								functions,
								'addSquareLoyaltyPointsFromOrder'
							)
							//Loop for each restaurant ID to add points
							console.log('ADDING LOYALTY POINTS')
							crashlytics().log('ADDING LOYALTY POINTS')
							const pointTotal = await addSquareLoyaltyPointsFromOrder({
								orgId: global.org,
								restaurantId: order.restaurantId,
								posOrderId: order.posOrderId,
								phoneNumber: phoneNumber,
							})
							console.log('POS LOYALTY POINTS EARNED:')
							crashlytics().log('POS LOYALTY POINTS EARNED: ' + pointTotal.data)
							console.log(pointTotal.data)
							order.pointTotal = pointTotal.data
							dispatch(setCheckoutState({ posLoyaltyPointsAccrued: pointTotal.data as number }))
						})
					}
				}
			} else if (global.kiosk && orderId !== '' && status === 'Failed') {
				dispatch(setCheckoutState({ paymentError: true }))
				crashlytics().log('PAYMENT FAILED')
			}
		}
		statusUpdate()
	}, [status])

	useEffect(() => {
		const makeNewDeliverectOrder = async () => {
			if (posProvider === 'deliverect' && orderPlaced) {
				const orderInterval = setInterval(async () => {
					const isLoaded = await checkDoordashLoaded(orderId)
					if (isDineIn || prices.deliveryFee <= 0 || isLoaded) {
						const channelLinkId = selectedLocation ? selectedLocation.id : null
						crashlytics().log('CREATING DELIVERECT ORDER')

						try {
							const createDeliverectOrder = httpsCallable(functions, 'createDeliverectOrder')
							await createDeliverectOrder({
								orgId: global.org,
								orderId: processedOrder?.data?.[0]?.orderId,
								channelLinkId,
								guestName: isGuestCheckout ? takeOutName : '',
								address: userLocation?.addressObject,
							})
							console.log('Deliverect order created')
						} catch (e) {
							console.log('Error creating Deliverect order: ' + e)
						}

						clearInterval(orderInterval)
					}
				}, 1000)
				crashlytics().log('Deliverect order created')
			}
		}
		makeNewDeliverectOrder()
	}, [orderPlaced])

	const checkDoordashLoaded = async orderId => {
		const retrieveOrder = httpsCallable(functions, 'getOrderFromDB')
		const order = (await retrieveOrder({ orgId: global.org, orderId })) as any
		console.log('Checking DoorDash data: ' + order.data.channelData.doordash)
		return order.data.channelData.doordash
	}
}

export default useStatusUpdate
