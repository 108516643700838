export const prepText = (cartPrep, isDelivery, showPreText = false) => {
	const { pickupTime, deliveryTime, isScheduled, longestPrepTime, deliveryPrepTime } = cartPrep

	// Function to format date and time for scheduled orders
	function formatDateTime(dateString) {
		const options = {
			weekday: 'long' as const,
			month: 'short' as const,
			day: 'numeric' as const,
			hour: 'numeric' as const,
			minute: 'numeric' as const,
		}
		const date = new Date(dateString)
		return date.toLocaleString('en-US', options)
	}
	const scheduledTime = isDelivery ? deliveryTime : pickupTime
	if (isScheduled && scheduledTime !== null) {
		const formattedTime = formatDateTime(scheduledTime)
		return showPreText
			? `${isDelivery ? 'Delivery' : 'Pickup'} on ${formattedTime}`
			: `${formattedTime}`
	} else {
		return showPreText
			? `${isDelivery ? 'Delivery' : 'Pickup'} in ${isDelivery ? deliveryPrepTime : longestPrepTime} minutes`
			: `ASAP (${longestPrepTime} minutes)`
	}
}
