import React from 'react'
import { View, Dimensions } from 'react-native'
import { SharpTriangle } from './Triangle'
import Colors from '../../constants/Colors'
import ImageObject from '../Image/ImageObject'
import DeliveryPickupToggle from '../Delivery/DeliveryPickupToggle'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useAppSelector } from '../../state/hooks'
export const OrgLogoHeader = ({ logo, padding = 50 }) => {
	const { activeOrder } = useAppSelector(state => state.data)
	const { deliveryEnabled } = useAppSelector(state => state.settings)

	const orderBannerVisible =
		activeOrder &&
		activeOrder.channelData &&
		activeOrder.channelData.doordash &&
		activeOrder.channelData.doordash.deliveryStatus !== 'DASHER_DROPPED_OFF' &&
		activeOrder.channelData.doordash.deliveryStatus !== 'DELIVERY_CANCELLED' &&
		deliveryEnabled

	const topMargin = orderBannerVisible ? -500 * 1.1 : -500
	const height = Dimensions.get('window').height
	let add = 0
	if (height < 1200) {
		add = 20
	}

	const insets = useSafeAreaInsets()

	if (global.kiosk) {
		return (
			<>
				<View
					style={{
						height: height * 0.25 + 500,
						marginTop: -500, //handles overscrolling
						backgroundColor: global.orgColor ? global.orgColor : Colors.custom.restaurantMain,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-start',
						marginLeft: 0,
					}}
				>
					<ImageObject
						hideDefault={true}
						source={logo}
						useAspectRatio={true}
						style={{
							height: height * 0.18,
							maxWidth: '50%',
							marginTop: height * -0.05 + 500,
							marginLeft: '5%',
						}}
						resizeMode={'contain'}
					/>
				</View>
				<View style={{ backgroundColor: Colors.custom.appBackground, paddingBottom: 50 }}>
					<SharpTriangle />
				</View>
			</>
		)
	} else {
		return (
			<>
				<View
					style={{
						height: height * 0.22 + 500,
						marginTop: topMargin, //handles overscrolling
						backgroundColor: global.orgColor ? global.orgColor : Colors.custom.restaurantMain,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						marginLeft: 0,
					}}
				>
					<ImageObject
						hideDefault={true}
						source={logo}
						style={{
							height: 95,
							width: undefined,
							maxWidth: '50%',
							aspectRatio: 1,
							marginTop: height * -0.09 + 500 + insets.top + 2,
							marginLeft: '5%',
							resizeMode: 'contain',
						}}
					/>
					<View
						style={{
							marginTop: height * -0.09 + 500 + insets.top + 2,
							marginRight: '5%',
						}}
					>
						<DeliveryPickupToggle textColor={'white'} homePage={true} />
					</View>
				</View>
				<View style={{ paddingBottom: padding, backgroundColor: Colors.custom.appBackground }}>
					<SharpTriangle />
				</View>
			</>
		)
	}
}
