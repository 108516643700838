import React from 'react'
import { TouchableOpacity, View, StyleSheet } from 'react-native'
import StripeSaveCard from '../../../../components/Stripe/StripeSave'
import { ForwardButton, NavButton } from '../../../../components/Headers/UIButtons'
import { auth } from '../../../../firebase/config'
import { mediumTextSize } from '../../../../constants/GlobalStyle'
import stripeGetCards from '../../../../components/Stripe/StripeGetCards'
import Colors from '../../../../constants/Colors'
import { Text } from '../../../../components/Themed'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { setUserState } from '../../../../state/Slices/userSlice'
import { setCheckoutState } from '../../../../state/Slices/checkoutSlice'

export function ButtonContainer({ allowSave, stripeId, toggleModal, closeModal }) {
	const { isCardValid } = useAppSelector(state => state.checkout)
	const { paymentProvider } = useAppSelector(state => state.settings)

	const dispatch = useAppDispatch()

	async function updateCardList() {
		if (stripeId && stripeId !== '') {
			var stripeCards = await stripeGetCards(stripeId)
			dispatch(setUserState({ paymentMethodId: stripeCards[0].id, paymentMethods: stripeCards }))
		}
		return null
	}

	return (
		<View style={styles.buttonContainer}>
			{auth.currentUser && !allowSave && paymentProvider === 'stripe' ? (
				<View style={styles.innerContainer}>
					<StripeSaveCard
						onPress={() => {
							updateCardList()
							toggleModal()
						}}
						stripeCustomerId={stripeId}
						title={'ADD & SELECT CARD'}
					/>

					<TouchableOpacity style={styles.cancel} onPress={closeModal}>
						<Text style={styles.cancelText}>CANCEL</Text>
					</TouchableOpacity>
				</View>
			) : allowSave && paymentProvider === 'stripe' ? (
				<View style={styles.row}>
					<View style={styles.flexButton}>
						<ForwardButton
							onPress={() => dispatch(setCheckoutState({ cardElementVisible: false }))}
							color={'white'}
							title={'BACK'}
						/>
					</View>
					<View style={[styles.flexButton, styles.marginLeft]}>
						<ForwardButton
							onPress={() => {
								if (isCardValid) {
									toggleModal()
								} else {
									null
								}
							}}
							color={isCardValid ? global.orgColor : Colors.greyscale[6]}
							opacity={isCardValid ? 0.6 : 0.9}
							title={'NEXT'}
						/>
					</View>
				</View>
			) : paymentProvider === 'stripe' ? (
				<View style={{ paddingVertical: 20, width: '90%' }}>
					<NavButton
						onPress={() => {
							if (isCardValid) {
								dispatch(setCheckoutState({ useNewPaymentMethod: true }))
								toggleModal()
								if (auth.currentUser === null) {
									dispatch(setCheckoutState({ isGuestCheckout: true }))
								}
							} else {
								null
							}
						}}
						title={'CONTINUE'}
						color={isCardValid ? global.orgColor : Colors.greyscale[6]}
					/>
				</View>
			) : null}
		</View>
	)
}

const styles = StyleSheet.create({
	buttonContainer: {
		flexDirection: 'column',
		width: '100%',
		alignItems: 'center',
		paddingBottom: 10,
	},
	innerContainer: {
		width: '90%',
	},
	row: {
		flexDirection: 'row',
		width: '90%',
		paddingTop: 20,
		paddingBottom: 10,
	},
	flexButton: {
		flex: 1,
	},
	marginLeft: {
		marginLeft: 20,
	},
	button: {
		minHeight: 45,
		width: '100%',
	},
	cancel: {
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 20,
	},
	cancelText: {
		color: 'black',
		fontWeight: '300',
		fontSize: mediumTextSize,
	},
})
