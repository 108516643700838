import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import '../../../global'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { formatModItems } from '../getModifierItemData'
import { DATABASE_NAME } from '@env'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setDataState } from '../../../state/Slices/dataSlice'

const useModItemListener = () => {
	const { loadedData } = useAppSelector(state => state.data)

	const [modItemData, setModItemData] = useState({})
	const dispatch = useAppDispatch()

	useEffect(() => {
		const modItemRef = collection(doc(db, DATABASE_NAME, global.org), 'ModifierItems')
		const unsubscribe = onSnapshot(modItemRef, snapshot => {
			const newModItemData = { ...modItemData }
			let updated = false

			snapshot.docChanges().forEach(change => {
				const data = change.doc.data()
				if (change.type === 'modified' && data !== undefined && !data.isDeleted) {
					const modItemReturn = formatModItems(data)
					const key = 'items_for_' + data.modifierListId

					if (!loadedData[key]) {
						newModItemData[key] = []
					} else {
						newModItemData[key] = [...loadedData[key]]
					}

					const index = newModItemData[key].findIndex(i => i.id === data.id)
					if (index >= 0) {
						newModItemData[key][index] = modItemReturn
					} else {
						newModItemData[key].push(modItemReturn)
					}
					updated = true
				}
			})

			if (updated) {
				const updatedData = _.merge({}, loadedData, newModItemData)
				setModItemData(newModItemData)
				dispatch(
					setDataState({
						loadedData: updatedData,
					})
				)
				// if (Object.keys(newModItemData).length > 0) {
				// 	//setUpdateData([])
				// }
			}
		})

		return () => {
			unsubscribe()
		}
	}, [loadedData, modItemData, dispatch])

	return null
}

export default useModItemListener
