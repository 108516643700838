import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import '../../../global'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { formatMenus } from '../getMenuData'
import { DATABASE_NAME } from '@env'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setDataState } from '../../../state/Slices/dataSlice'

const useMenuListener = () => {
	const { loadedData } = useAppSelector(state => state.data)

	const [menuData] = useState({})

	const dispatch = useAppDispatch()

	useEffect(() => {
		const menuRef = collection(doc(db, DATABASE_NAME, global.org), 'Menus')

		const unsubscribe = onSnapshot(menuRef, snapshot => {
			snapshot.docChanges().forEach(change => {
				const data = change.doc.data()
				if (change.type === 'modified' && data !== undefined && !data.isDeleted) {
					const menuReturn = formatMenus(data)
					const key = 'menus_for_' + data.restaurantId
					if (!loadedData[key]) {
						menuData[key] = []
					} else {
						menuData[key] = loadedData[key]
					}

					const index = menuData[key].findIndex(i => i.id === data.id)
					if (index >= 0) {
						menuData[key].splice(index, 1)
					}

					menuData[key].push(menuReturn)
				}
			})

			const updatedData = _.merge({}, loadedData, menuData)
			dispatch(
				setDataState({
					loadedData: updatedData,
				})
			)
			// if (Object.keys(menuData).length > 0) {
			// 	//setUpdateData([])
			// }
		})

		return () => {
			unsubscribe()
		}
	}, [])
}

export default useMenuListener
