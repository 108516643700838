import React, { useState } from 'react'
import { Dimensions, Platform, TextInput, TouchableOpacity } from 'react-native'
import { Text, View } from '../../../../components/Themed'
import GlobalStyle, { formStyle, mediumTextSize } from '../../../../constants/GlobalStyle'
import { auth } from '../../../../firebase/config'
import { userCanRedeem } from '../../Helpers/checkCouponCode'
import ExitIcon from '../../../../components/Headers/UIButtons'
import Colors from '../../../../constants/Colors'
import { useAppSelector } from '../../../../state/hooks'
import Separator from '../../../../components/Separator'

export function AddPromo({ promoFuncData, staffMeal = true }) {
	const { deviceHasInternet } = useAppSelector(state => state.user)
	const { couponCodeDisplay, userRedeemedCodes, userAvailableCodes, validCouponCodes } =
		useAppSelector(state => state.checkout)
	const [promoCode, setPromoCode] = useState('')
	const promoReturn = []

	if (validCouponCodes && validCouponCodes.length > 0) {
		validCouponCodes.forEach(promo => {
			if (!promo.codeOnly && promo.type !== 'staffMeal') {
				if (!promo.userSpecific) {
					promoReturn.push(
						<TouchableOpacity
							onPress={() => {
								promoFuncData.setPromo(promo.couponCode)
							}}
							style={{
								justifyContent: 'center',
								alignItems: 'center',
								borderWidth: 4,
								borderColor: global.orgColor,
								borderRadius: 15,
								marginHorizontal: 5,
								marginVertical: 5,
								height: Dimensions.get('window').height / 10,
								width:
									Dimensions.get('window').width >= 700
										? Dimensions.get('window').width / 4
										: Dimensions.get('window').width / 2.75,
							}}
						>
							<Text
								style={[
									GlobalStyle.ctgTitle,
									{
										textAlign: 'center',
										textAlignVertical: 'center',
										fontSize: Dimensions.get('window').width >= 700 ? 20 : 14,
										paddingHorizontal: 5,
									},
								]}
							>
								{promo.name}
							</Text>
						</TouchableOpacity>
					)
				} else if (auth.currentUser && userCanRedeem(promo, userRedeemedCodes)) {
					if (userAvailableCodes.length > 0) {
						userAvailableCodes.forEach(code => {
							if (promo.id == code) {
								promoReturn.push(
									<TouchableOpacity
										onPress={() => {
											promoFuncData.setPromo(promo.couponCode)
										}}
										style={{
											borderWidth: 4,
											borderColor: '#eaeaea',
											borderRadius: 15,
											marginHorizontal: 5,
											marginVertical: 5,
											height: Dimensions.get('window').height / 10,
											width:
												Dimensions.get('window').width >= 700
													? Dimensions.get('window').width / 4
													: Dimensions.get('window').width / 2.75,
										}}
									>
										<Text
											style={[
												GlobalStyle.ctgTitle,
												{
													textAlign: 'center',
													textAlignVertical: 'center',
													fontSize: Dimensions.get('window').width >= 700 ? 20 : 14,
													paddingHorizontal: 5,
													marginTop: Platform.OS === 'ios' ? 5 : 0,
												},
											]}
										>
											{promo.name}
										</Text>
									</TouchableOpacity>
								)
							}
						})
					}
				}
			}
		})
	}
	return (
		<>
			{!staffMeal && (
				<ExitIcon
					onPress={() => {
						promoFuncData.closeModal()
					}}
					style={{ top: -10 }}
				/>
			)}

			<View style={{ height: promoReturn.length > 0 ? 0 : 25 }}></View>
			{promoReturn.length > 0 ? (
				<Text
					style={[
						GlobalStyle.headerText,
						{
							textAlign: 'center',
							marginVertical: staffMeal ? 0 : 20,
							paddingTop: staffMeal ? 0 : '5%',
						},
					]}
				>
					SELECT A {staffMeal ? 'STAFF MEAL' : 'PROMOTION'}
				</Text>
			) : null}
			<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
				{promoReturn}
			</View>

			<Text
				style={[
					GlobalStyle.headerText,
					{
						textAlign: 'center',
						marginVertical: 20,
					},
				]}
			>
				ENTER PROMO CODE
			</Text>

			<View
				style={{
					flexDirection: 'row',
					alignSelf: 'center',
					marginVertical: 10,
					width: staffMeal ? '100%' : '90%',
				}}
			>
				<TextInput
					returnKeyType={'done'}
					style={[
						formStyle.textInput,
						{
							flex: 1,
							fontSize: mediumTextSize,
							marginRight: 10,
						},
					]}
					placeholder={'Promo code'}
					value={promoCode}
					onChangeText={setPromoCode}
					onSubmitEditing={() => {
						promoFuncData.setPromo(promoCode)
					}}
				/>
				<TouchableOpacity
					style={[
						formStyle.textInput,
						!deviceHasInternet && { backgroundColor: Colors.greyscale[6] },
					]}
					onPress={() => {
						promoFuncData.setPromo(promoCode)
					}}
					disabled={!deviceHasInternet}
				>
					<Text style={{ fontSize: mediumTextSize }}>ADD</Text>
				</TouchableOpacity>
			</View>

			{couponCodeDisplay ? (
				<View>
					<Text
						style={{
							textAlign: 'center',
							color: couponCodeDisplay.endsWith('was applied to your order.') ? 'green' : 'red',
							fontSize: mediumTextSize,
						}}
					>
						{couponCodeDisplay}
					</Text>
					<Separator />
				</View>
			) : (
				<View style={{ height: mediumTextSize * 2.7 }}></View>
			)}
		</>
	)
}
