import * as React from 'react'
import { ScrollView, StyleSheet, TouchableOpacity, View, Text as DefaultText } from 'react-native'
import { RootStackScreenProps } from '../../navigation/types'
import { FontAwesome5 } from '@expo/vector-icons'
import Modal from 'react-native-modal/dist/modal'
import { useEffect, useState } from 'react'
import { ReceiptContent } from './ReceiptContent'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { largeHeaderTextSize } from '../../constants/GlobalStyle'
import AppStoreLinks from '../../components/AppStoreButtons'
import Colors from '../../constants/Colors'
import { useAppSelector } from '../../state/hooks'

/**
 * Screen on web that shows a user's receipt for a previous order
 * User is redirected here from the link in their texted / emailed receipt at checkout.
 */
export default function ReceiptScreen({ navigation, route }: RootStackScreenProps<'receipt'>) {
	const receiptId = route.params?.receiptId.trim()

	const { loadedData } = useAppSelector(state => state.data)

	const iosURL = loadedData[global.org].iosStoreURL || ''
	const androidURL = loadedData[global.org].googlePlayStoreURL || ''

	const [isModalVisible, setModalVisible] = useState(!iosURL && !androidURL ? false : true)
	const [showReceipt, setShowReceipt] = useState(!iosURL && !androidURL ? true : false)
	const [orderData, setOrderData] = useState(null)
	const [docError, setDocError] = useState(false)

	const functions = getFunctions()

	const orgName = loadedData[global.org].organizationName
		? loadedData[global.org].organizationName
		: ''
	let orgPhone = loadedData[global.org].organizationPhoneNumber
		? loadedData[global.org].organizationPhoneNumber
		: ''

	if (orgPhone !== '') {
		if (orgPhone.length === 11 && orgPhone[0] === '1') {
			orgPhone = orgPhone.slice(1)
		}
		const match = orgPhone.match(/^(\d{3})(\d{3})(\d{4})$/)
		if (match) {
			orgPhone = '(' + match[1] + ')-' + match[2] + '-' + match[3]
		}
	}

	const orgLogo = loadedData[global.org].organizationLogo
		? loadedData[global.org].organizationLogo
		: ''

	const orgLogoDark = loadedData[global.org].organizationLogoDark
		? loadedData[global.org].organizationLogoDark
		: ''

	useEffect(() => {
		const fetchData = async () => {
			try {
				const getOrderFromDB = httpsCallable(functions, 'getOrderFromDB')
				const data = await getOrderFromDB({ orgId: global.org, orderId: receiptId })
				console.log(data)
				if (data.data !== null) {
					setOrderData(data.data)
					console.log(data.data)
				} else {
					setDocError(true)
					console.log('No such document!')
				}
			} catch (error) {
				setDocError(true)
				console.error('Error fetching document:', error)
			}
		}

		fetchData()
	}, [])

	return (
		<View style={{ flex: 1 }}>
			<Modal
				isVisible={isModalVisible}
				onBackButtonPress={() => setModalVisible(false)}
				onBackdropPress={() => setModalVisible(false)}
				avoidKeyboard={false}
			>
				<View
					style={{
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'white',
					}}
				>
					<Text
						style={{
							fontSize: 22,
							color: global.orgColor,
							marginTop: 40,
							marginBottom: 20,
							textAlign: 'center',
						}}
					>
						Get the app for quicker ordering and special promos!
					</Text>
					<AppStoreLinks iosURL={iosURL} androidURL={androidURL} />
					<NextButton
						style={{ backgroundColor: global.orgColor, marginVertical: 40, width: '40%' }}
						text="Skip"
						onPress={() => {
							setModalVisible(false)
							setShowReceipt(true)
						}}
					/>
				</View>
			</Modal>

			{showReceipt && (
				<ScrollView
					contentContainerStyle={{
						justifyContent: 'center',
						alignItems: 'center',
						marginTop: 50,
					}}
				>
					<Text
						style={{
							fontSize: largeHeaderTextSize,
							fontWeight: '100',
							marginBottom: 20,
							color: 'rgb(0, 0, 0)',
						}}
					>
						YOUR RECEIPT
					</Text>

					<ReceiptContent
						orderData={orderData}
						orgLogo={orgLogoDark ? orgLogoDark : orgLogo}
						styles={styles}
						orgName={orgName}
						docError={docError}
						navigation={navigation}
					/>

					{/* <View style={{ marginTop: 30 }}>
						<NextButton
							style={{ backgroundColor: global.orgColor }}
							text="ORDER ONLINE"
							onPress={() => {
								navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
							}}
						/>
					</View> */}
				</ScrollView>
			)}
		</View>
	)
}

export function Text({ style, children }) {
	return (
		<DefaultText
			style={[
				style,
				{
					fontFamily:
						'-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif',
				},
			]}
		>
			{children}
		</DefaultText>
	)
}

export function Separator({ style = null }) {
	return (
		<View
			style={[style, { borderBottomWidth: 1, borderBottomColor: '#ddd', marginVertical: 10 }]}
		/>
	)
}

export function NextButton({ text, onPress, style }: { text: string; onPress: any; style?: any }) {
	return (
		<TouchableOpacity onPress={onPress} style={[styles.nextButtonContainer, style]}>
			<Text style={styles.nextButtonText}>{text}</Text>
			<FontAwesome5 name="angle-double-right" size={20} color="#FFF" style={styles.nextIcon} />
		</TouchableOpacity>
	)
}

const styles = StyleSheet.create({
	button: {
		alignItems: 'center',
		justifyContent: 'center',
		paddingVertical: 12,
		paddingHorizontal: 32,
		borderRadius: 4,
		elevation: 3,
		backgroundColor: 'black',
	},
	text: {
		fontSize: 16,
		lineHeight: 21,
		fontWeight: 'bold',
		letterSpacing: 0.25,
		color: 'white',
	},
	exitButton: {
		margin: 5,
		zIndex: 100,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#ffffff',
		borderRadius: 50,
		width: 45,
		height: 45,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22,
		elevation: 3,
	},
	nextButtonContainer: {
		backgroundColor: 'black',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingVertical: 20,
		borderRadius: 20,
		marginBottom: 30,
	},
	nextButtonText: {
		color: '#FFF',
		fontSize: 24,
		fontWeight: '500',
		marginRight: 10,
		textAlign: 'center',
	},
	nextIcon: {
		position: 'absolute',
		right: 30,
		marginLeft: 5,
	},
	receiptContainer: {
		padding: 20,
		backgroundColor: '#fff',
		borderRadius: 8,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		paddingBottom: 30,
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		textAlign: 'center',
		marginBottom: 20,
	},
	orderNumber: {
		fontSize: 16,
		marginBottom: 20,
	},
	itemContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	itemName: {
		fontSize: 16,
		marginRight: 10,
	},
	itemPrice: {
		fontSize: 16,
	},
	modifiersContainer: {
		marginTop: 5,
		marginLeft: 20,
	},
	modifierText: {
		fontSize: 14,
		color: '#555',
	},
	subFee: {
		fontSize: 18,
		marginTop: 5,
	},
	serviceCharge: {
		fontSize: 18,
		marginTop: 10,
	},
	total: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	centerText: {
		fontSize: 16,
		textAlign: 'center',
		marginBottom: 10,
		color: Colors.greyscale[6],
	},
	priceContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 10,
	},
})
