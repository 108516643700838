import { Platform } from 'react-native'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { logSignUpAnalytics } from '../../firebase/analytics'

export type User = {
	displayName: string
	firstName: string
	lastName: string
	email: string
	generatedEmail?: string
	phone?: string
	uid: string
	role: string
}
/**
 * Creates in firebase auth, adds user to 'Users' table, and creates customer in Square & Stripe
 * Create referral coupon if applicable.
 */
export default async function registerUser(user, paymentProvider, orgName, orgLogo) {
	const functions = getFunctions()
	const createCustomer = httpsCallable(functions, 'createCustomer')

	const platform = Platform.OS === 'web' ? 'web app' : 'mobile app'
	try {
		await createCustomer({
			orgId: global.org,
			user: user,
			orgName: orgName,
			orgLogo: orgLogo,
			platform: platform,
			paymentProvider: paymentProvider,
		})
	} catch (error) {
		console.log(error)
		return false
	}

	logSignUpAnalytics()

	return true
}
