import React, { useEffect, useRef, useState } from 'react'
import { getRestaurantData } from './getRestaurantData'
import { getMenuData } from './getMenuData'
import { getMenuItemData } from './getMenuItemData'
import { getModifierItemData } from './getModifierItemData'
import { getModifierListData } from './getModifierListData'
import '../../global'
import Spinner from '../../components/LoadingSpinner'
import { preLoadImage } from '../../components/Image/ImageObject'
import { Platform, Image } from 'react-native'
import { getOrgData } from './getOrgData'
import { alertResponse, alertResponseSingle } from '../../components/Alerts/Alerts'
import RNRestart from 'react-native-restart'
import { auth } from '../../firebase/config'
import { StripeLoader } from '../../components/Stripe/StripeLoader'
import { getTaxData } from './getTaxData'
import { getMenuItemCategories } from './getMenuItemCategories'
import { getPromoImageData } from './getPromoImageData'
import { getThirdPartyPOSTypes } from './getThirdPartyPOSTypes'
import { getLoyaltyRewardData } from './getLoyaltyRewardData'
import { Helmet } from 'react-helmet'
import { getPickupPoints } from './getPickupPoints'
import { getCuisines } from './getCuisines'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { setDataState } from '../../state/Slices/dataSlice'
import { setSettingsState } from '../../state/Slices/settingsSlice'
import { setPersistedState } from '../../state/Slices/persistedSlice'
import { validateItems } from './validateItems'
import useAuthOrders from './useAuthOrders'

// Function to add restaurantObject to arrayOfObjects
function arrayFromObjects(restaurantObject) {
	var arrayOfObjects = []
	for (const id in restaurantObject) {
		if (id !== 'defaultRestaurant') {
			const restaurant = restaurantObject[id]
			const newObject = { id, ...restaurant }
			arrayOfObjects.push(newObject)
		}
	}
	return arrayOfObjects
}

export default function RetrieveData({ children, referralCode, loadingTracker }) {
	const dispatch = useAppDispatch()

	const { loadedData, allDataLoaded, stripePublishableKey } = useAppSelector(state => state.data)
	const { cart, activeRID } = useAppSelector(state => state.persisted)

	const _ = require('lodash')

	const [stripePublishableKeyLocalState, setStripePublishableKey] = useState('')

	const [loading, setLoading] = useState(true)
	const [spinner, setSpinner] = useState(true)
	const [appleAppId, setAppleAppId] = useState('')

	const ref = useRef(null)
	global.payRef = ref

	useAuthOrders()

	useEffect(() => {
		const fetchData = async () => {
			try {
				var orgObject = {}
				console.log('LOADING DATA ' + new Date().toLocaleString())

				// Run all data fetching operations in parallel
				// wait for all data to be retrieved before continuing
				await getCuisines()
				const [
					restaurantObject,
					result,
					menuObject,
					taxObject,
					menuItemCategoryObject,
					modifierItemObject,
					modifierListObject,
					promoImageObject,
					thirdPartyPOSTypes,
					loyaltyRewardObject,
					pickupPoints,
					cuisineObject,
				] = await Promise.all([
					getRestaurantData(),
					getOrgData(),
					getMenuData(),
					getTaxData(),
					getMenuItemCategories(),
					getModifierItemData(),
					getModifierListData(),
					getPromoImageData(),
					getThirdPartyPOSTypes(),
					getLoyaltyRewardData(),
					getPickupPoints(),
					getCuisines(),
				])
				const menuItemObject = await getMenuItemData(
					menuItemCategoryObject,
					modifierListObject,
					modifierItemObject,
					restaurantObject
				)
				console.log('VALIDATING CART')
				validateItems(menuItemObject, modifierItemObject, restaurantObject, dispatch, cart)

				const data: any = result
				const restaurantArray = arrayFromObjects(restaurantObject)

				setAppleAppId(data.iosAppId || '')
				global.dineIn = data.dineInEnabled || false
				orgObject[global.org] = data
				if (data.stripe && data.stripe.publishableKey) {
					if (data.environment === 'production') {
						setStripePublishableKey(data.stripe.publishableKey.production)
					} else {
						setStripePublishableKey(data.stripe.publishableKey.sandbox)
					}
				}

				if (restaurantArray.length === 1) {
					dispatch(setPersistedState({ activeRID: restaurantArray[0].id }))
				}
				const updatedData = _.merge(
					{},
					menuObject,
					taxObject,
					orgObject,
					loadedData,
					restaurantObject,
					menuItemCategoryObject,
					modifierItemObject,
					modifierListObject,
					menuItemObject,
					promoImageObject,
					thirdPartyPOSTypes,
					loyaltyRewardObject,
					pickupPoints,
					cuisineObject,
					{
						restaurants: restaurantArray,
					}
				)
				if (data.pickupPointsEnabled) {
					if (updatedData['pickupPoints'].length === 1) {
						dispatch(setPersistedState({ selectedPickupPoint: updatedData['pickupPoints'][0] }))
					}
				}

				let orgLogo = data.organizationLogo || ''
				if (global.kiosk && data.kioskOrganizationLogo !== undefined) {
					orgLogo = data.kioskOrganizationLogo
				}

				if (global.kiosk && data.kioskOrganizationColor !== undefined) {
					global.orgColor = data.kioskOrganizationColor
				} else {
					global.orgColor = data.organizationColor
				}

				global.orgColor2 = data.organizationTextColor || '#848484'

				if (Platform.OS === 'web') {
					document.documentElement.style.setProperty('--safe-area-color', data.organizationColor)
				}
				dispatch(
					setSettingsState({
						delivery: {
							freeDeliveryMultiplier: data.delivery?.freeDeliveryMultiplier || -1,
							isSandbox: data.delivery?.isSandbox || data.environment === 'sandbox' ? true : false,
							orgCoverage: data.delivery?.organizationCoverage?.amount || 0,
							orgCoverageType: data.delivery?.organizationCoverage?.type || '',
							actionIfUndeliverable:
								data.delivery?.actionIfUndeliverable === 'dispose' ? 'dispose' : 'return_to_pickup',
							pickupInstructions: data.delivery?.pickupInstructions || '',
							minSpend: data.delivery?.minimumOrder || 0,
						},
						serviceFee: data.serviceFee ?? 0,
						serviceFeeTax: data.serviceFeeTax ?? 0,
						scheduleDaysAhead: data.scheduleDaysAhead ?? 14,
						scheduleTimeInterval: data.scheduleTimeInterval ?? 15,
						localTimezone: data.localTimezone ?? 'America/Vancouver',
						allowOrdersOutsideOrgHours: data.allowOrdersOutsideOrgHours || false,
						pickupPointsEnabled: data.pickupPointsEnabled || false,
						isSingleBrand: data.singleBrandEnabled || false,
						posLoyaltyEnabled: data.posLoyaltyEnabled || false,
						dineInEnabled: data.dineInEnabled || false,
						usesTableNumbers: data.usesTableNumbers || false,
						homeLayout: data?.appDisplayOptions?.homeLayout || 'categories',
						showCuisines: data?.appDisplayOptions?.showCuisines || false,
						posProvider: data.posProvider || '',
						paymentProvider: data.paymentProvider || 'stripe',
						isSandbox: data.environment === 'sandbox',
						deliveryEnabled:
							data.delivery?.enabled && !global.kiosk ? data.delivery?.enabled : false,
					})
				)
				dispatch(
					setDataState({
						loadedData: updatedData,
						restaurants: restaurantArray,
						webAppURL: data.webURL || '',
						iosAppId: data.iosAppId || '',
						organizationLogo: orgLogo,
						organizationLogoDark: data.organizationLogoDark || '',
						stripePublishableKey:
							data.stripe && data.stripe.publishableKey
								? data.environment === 'production'
									? data.stripe.publishableKey.production
									: data.stripe.publishableKey.sandbox
								: '',
						restaurantList: restaurantArray,
						allDataLoaded: true,
					})
				)

				if (global.kiosk) {
					dispatch(
						setPersistedState({
							isDelivery: false,
						})
					)
				}

				if (Platform.OS !== 'web' && data.organizationHeader) {
					preLoadImage(data.organizationHeader)
				}
				if (Platform.OS !== 'web' && data.organizationLogo && data.organizationLogoDark) {
					preLoadImage(data.organizationLogo)
					preLoadImage(data.organizationLogoDark)
				}
				if (
					Platform.OS !== 'web' &&
					data.kioskOrganizationLogo !== undefined &&
					data.kioskOrganizationLogo !== ''
				) {
					preLoadImage(data.kioskOrganizationLogo)
				}

				setSpinner(false)
				setLoading(false)
				console.log('ALL DATA LOADED ' + new Date().toLocaleString())
				if (loadingTracker !== null) {
					loadingTracker(true) //data is done loading
					//use for kiosk since data load happens after initial render
				}
			} catch (error) {
				console.log(error)
				if (global.kiosk) {
					alertResponse(
						'Error loading data',
						'',
						'Refresh',
						'Logout',
						'default',
						'default',
						() => {
							RNRestart.Restart()
						},
						() => {
							auth.signOut()
							RNRestart.Restart()
						}
					)
				} else {
					alertResponseSingle('Error loading data', '', 'Refresh', null, () => {
						RNRestart.Restart()
					})
				}
			}
		}
		fetchData()
	}, [])
	return (
		<>
			{Platform.OS === 'web' && appleAppId !== '' && (
				<>
					<Helmet>
						<meta name="apple-itunes-app" content={`app-id=${appleAppId}`} />
					</Helmet>
				</>
			)}
			<Spinner
				visible={global.kiosk ? !allDataLoaded : spinner}
				overlayColor="rgba(255, 255, 255, 1)"
				headerHeight={0}
				footerHeight={0}
				color="black"
				customIndicator={
					<Image
						style={{ width: 100, height: 100, alignSelf: 'center', marginTop: -20 }}
						source={require('../../assets/images/loadImg.gif')}
					/>
				}
			/>
			{loading ? (
				<></>
			) : (
				<StripeLoader stripePublishableKey={stripePublishableKey ?? stripePublishableKeyLocalState}>
					{children}
				</StripeLoader>
			)}
		</>
	)
}
