import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CheckoutItem } from '../../screens/Checkout/Helpers/types'
import { Item } from '../types'

const initialState = {
	prices: {
		discount: 0,
		subTotal: 0,
		deliveryFee: 0,
		serviceCharge: 0,
		taxes: 0,
		taxesList: [] as any[],
		total: 0,
		tip: 0,
		noCodeDiscount: 0,
		organizationDiscount: 0,
		bagFee: 0,
		bagFeeTax: 0,
		subTotalAfterDiscount: 0,
		deliveryFeeOrgCoverage: 0,
	},
	items: [] as CheckoutItem[],
	orderNote: '',
	allowOrder: true,
	couponCode: '',
	couponCodeData: [] as any[],
	couponCodeDisplay: null as any,
	userRedeemedCodes: [] as any[],
	userAvailableCodes: [] as any[],
	saveCard: true,
	useSavedPaymentMethod: true,
	useNewPaymentMethod: false,
	phoneNumber: '',
	phoneNumberInput: false,
	showPhoneNumber: false,
	couponCodeBackup: '',
	modalTimestamps: {} as any,
	restaurantDiscountAmount: null as any,
	orderCreated: false,
	status: '',
	orderId: '',
	orderNumber: '',
	awaitingTerminalInput: false,
	paymentError: false,
	savedPhone: '',
	isFreeItemDiscount: false,
	showDineInOrTakeout: true,
	showPOSMethods: global.pos as boolean,
	analyticsItems: [] as any[],
	typePOS: '',
	posExtraData: {
		name: '',
		firebaseId: '',
		note: '',
		type: '',
	},
	tableNumber: null as any,
	takeOutName: null as any,
	couponRefresh: [] as any[],
	collapsibles: {} as any,
	tipCount: 0,
	spinner: false,
	orderPlaced: false,
	expanded: true,
	userStoredAddresses: [] as any[],
	isConfirmModalVisible: false,
	isTipModalVisible: false,
	isPromoModalVisible: false,
	isGuestCheckout: false,
	isUser: false,
	showNotes: false,
	validCouponCodes: [] as any[],
	loading: true,
	paymentMethods: [] as any[],
	allPrepTimes: [] as any[],
	isTaxesExpanded: false,
	modalKey: 0,
	reset: [] as any[],
	timer: null as any,
	cancelButton: 'Cancel',
	selectedCard: null as any,
	bagFeeEnabled: false,
	bagFee: {} as any,
	bagFeeSelected: true,
	couponName: '',
	showAddTip: !global.kiosk,
	cardElementVisible: false,
	visiblePromos: 0,
	selectedTip: null as any,
	isOpen: true,
	analyticsPaymentMethod: null as any,
	selectedMethods: {
		textMessage: false,
		email: false,
		print: global.kiosk,
		textUpdates: true,
		showNumPad: false,
		phoneString: '',
		emailString: '',
		phoneStringFormatted: '',
	},
	visibleCouponCount: 0,
	showSquarePayment: false,
	checkoutId: '',
	isDigitalWalletEnabled: false,
	isDigitalPaymentWindowOpen: false,
	paymentRequest: null as any,
	digitalWalletType: null as any,
	terminalOrder: null as any,
	processedOrder: {} as any,
	posLoyaltyPoints: -1,
	posLoyaltyPointsAccrued: 0,
	showKioskLoyaltyEntry: false,
	isRestaurantSnoozed: false,
	showGuestCheckoutName: false,
	deliveryAmountToSpend: 0,
	isPaymentMethodModalVisible: false,
	isAddingCard: false,
	pointsEarned: 0,
	isCardValid: false,
	orderData: {} as any,
	isDineIn: false,
	isTakeOut: false,
	showTips: false,
}

type StateTypes = typeof initialState

const slice = createSlice({
	name: 'checkout',
	initialState,
	reducers: {
		setStates: (state, action: PayloadAction<Partial<StateTypes>>) => {
			Object.assign(state, action.payload)
		},
		resetSelectedMethods: state => {
			Object.assign(state.selectedMethods, initialState.selectedMethods)
		},
		setSelectedMethod: (state, action: PayloadAction<{ method: string; value: any }>) => {
			const { method, value } = action.payload
			state.selectedMethods[method] = value
		},
	},
})

const { setStates, resetSelectedMethods, setSelectedMethod } = slice.actions

export const setCheckoutState = (updates: Partial<StateTypes>) => setStates(updates)
export const resetReceiptMethods = () => resetSelectedMethods()
export const updateSelectedMethod = (method: string, value: any) =>
	setSelectedMethod({ method, value })

export default slice.reducer
