import * as React from 'react'
import { Dimensions, Platform, TouchableOpacity, StyleSheet } from 'react-native'
import { View, Text } from '../../../components/Themed'
import ImageObject from '../../../components/Image/ImageObject'
import Colors from '../../../constants/Colors'
import GlobalStyle, {
	sTextSize,
	smallTextSize,
	largeTextSize,
	LARGE_LIST_WIDTH,
	MED_LIST_WIDTH,
	SMALL_LIST_WIDTH,
	mediumTextSize,
} from '../../../constants/GlobalStyle'
import { logSelectMenuAnalytics } from '../../../firebase/analytics'
import Formatter from '../../../helpers/Formatter'
import { Ionicons } from '@expo/vector-icons'
import { useAppSelector } from '../../../state/hooks'

/**
 * Restaurant view on the home page of the app if appDisplayOptions.homeLayout == "restaurants"
 */
export function RestaurantList({ navigation, visibleRestaurants }) {
	const { deliveryEnabled } = useAppSelector(state => state.settings)
	const { isDelivery, deliveryEstimateList } = useAppSelector(state => state.persisted)

	const width = Dimensions.get('window').width
	const overMaxWidth = width * 0.9 > 1200
	const screenWidth = overMaxWidth ? 1200 : width * 0.9

	const numColumns = LARGE_LIST_WIDTH ? 3 : MED_LIST_WIDTH && !SMALL_LIST_WIDTH ? 2 : 1

	if (visibleRestaurants.length <= 0) {
		return (
			<Text
				style={{
					textAlign: 'center',
					fontStyle: 'italic',
					color: Colors.greyscale[7],
					fontSize: largeTextSize,
					paddingTop: Platform.OS === 'web' ? 20 : 0,
				}}
			>
				{'We are unable to accept new orders at this time.'}
			</Text>
		)
	}

	const renderItem = (restaurant, index) => {
		const isLastInRow = (index + 1) % numColumns === 0
		const isFirstInRow = index % numColumns === 0
		const isSingleItemInRow =
			numColumns > 1 && index === visibleRestaurants.length - 1 && index % numColumns !== 0
		const deliveryEstimate = deliveryEstimateList.find(
			deliveryEstimate => deliveryEstimate.restaurantId === restaurant.id
		)
		let fee = -1
		let deliveryText = ''
		if (deliveryEstimate && isDelivery && deliveryEnabled) {
			let minTime = Math.round((deliveryEstimate.estimate.timeToDeliver * 0.7) / 5) * 5 + 5 //round to nearest 5 minutes
			const maxTime = deliveryEstimate.estimate.timeToDeliver + 5
			if (minTime === maxTime) {
				minTime -= 5
			}
			fee = deliveryEstimate.estimate.fee <= 0 ? 0 : deliveryEstimate.estimate.fee / 100
			deliveryText = `${minTime}-${maxTime} min. ${Formatter.currencyFormat(fee)}`
		}

		return (
			<View
				key={index + restaurant.id}
				style={[
					{
						width: `${100 / numColumns}%`,
						marginVertical: smallTextSize,
					},
					isFirstInRow
						? { paddingRight: smallTextSize }
						: isLastInRow
							? { paddingLeft: smallTextSize }
							: { paddingHorizontal: smallTextSize / 2 },
					isSingleItemInRow && {
						marginRight: (numColumns - (index % numColumns) - 1) * (smallTextSize / 2),
					},
				]}
			>
				<TouchableOpacity
					key={restaurant.id}
					disabled={!deliveryEstimate && isDelivery && deliveryEnabled}
					onPress={() => {
						if (!deliveryEstimate && isDelivery && deliveryEnabled) {
							console.log('Delivery unavailable')
						} else if (restaurant !== undefined) {
							logSelectMenuAnalytics('brand_menu', restaurant.id) // log analytics
							//setRestaurant(dispatch, restaurant)
							navigation.navigate('Menu', { rId: restaurant.id })
						} else {
							console.log('Could not load.')
						}
					}}
				>
					<View style={GlobalStyle.shadowContainer}>
						<View style={GlobalStyle.cardContainer}>
							{restaurant?.heroImg ? (
								<ImageObject
									source={restaurant.heroImg}
									style={{ height: Platform.OS === 'web' ? 200 : 170 }}
								/>
							) : (
								<View
									style={{
										height: Platform.OS === 'web' ? 200 : 170,
										backgroundColor: Colors.greyscale[2],
									}}
								></View>
							)}
							{/* Show greyed-out overlay with "Delivery unavailable" message if delivery is unavailable */}
							{!deliveryEstimate && deliveryEnabled && isDelivery && (
								<View
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: Platform.OS === 'web' ? 200 : 170,
										backgroundColor: Colors.greyscale[5],
										justifyContent: 'center',
										alignItems: 'center',
										opacity: 0.9,
									}}
								>
									<View style={{ alignItems: 'center', flexDirection: 'row' }}>
										<Ionicons name="moon" size={mediumTextSize} color={Colors.greyscale[9]} />
										<Text
											style={{
												marginLeft: 5,
												color: Colors.greyscale[9],
												fontSize: mediumTextSize,
												textAlign: 'center',
											}}
										>
											Delivery unavailable
										</Text>
									</View>
								</View>
							)}
							<View style={styles.textArea}>
								<Text numberOfLines={1} ellipsizeMode="tail" style={styles.name}>
									{restaurant.name}
								</Text>
								<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
									<Description address={restaurant.address} />
									<Text style={styles.description}>{deliveryText}</Text>
								</View>
							</View>
						</View>
					</View>
				</TouchableOpacity>
			</View>
		)
	}

	function Description({ address }) {
		return (
			<View>
				<Text numberOfLines={1} ellipsizeMode="tail" style={[styles.description]}>
					{`${address.addressLine1} ${address.addressLine2 ? address.addressLine2 : ''}`}
				</Text>
			</View>
		)
	}

	return (
		<View
			style={{
				flex: 1,
				flexDirection: 'row',
				flexWrap: 'wrap',
				marginLeft: Dimensions.get('window').width * 0.04,
				marginRight: Dimensions.get('window').width * 0.04 - 12.5,
				marginTop: smallTextSize,
				justifyContent: 'flex-start',
			}}
		>
			{visibleRestaurants.map((restaurant, index) => renderItem(restaurant, index))}
		</View>
	)
}

const styles = StyleSheet.create({
	name: {
		fontSize: smallTextSize,
		fontWeight: '600',
		minHeight: sTextSize * 1.75,
	},
	description: {
		fontSize: sTextSize,
		color: Colors.greyscale[5],
		minHeight: sTextSize * 1.25,
	},
	textArea: {
		padding: sTextSize,
	},
})
