import * as React from 'react'
import { Animated, TextInput, TouchableOpacity, View, StyleSheet } from 'react-native'
import { largeTextSize } from '../../../constants/GlobalStyle'
import { useEffect, useState } from 'react'
import Colors from '../../../constants/Colors'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from '../../../components/Themed'
import { useAppDispatch } from '../../../state/hooks'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'

export default function ThirdPartyPaymentList({ brands }) {
	const [selectedBrand, setSelectedBrand] = useState(null)
	const [note, setNote] = useState('')
	const [fadeAnim] = useState(new Animated.Value(0))
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(
			setCheckoutState({
				posExtraData: {
					name: selectedBrand ? selectedBrand.name : '',
					firebaseId: selectedBrand ? selectedBrand.id : '',
					note: note,
					type: 'thirdParty',
				},
			})
		)
	}, [selectedBrand, note])

	const handleSelectBrand = brand => {
		setSelectedBrand(brand)
		Animated.timing(fadeAnim, {
			toValue: 1,
			duration: 500,
			useNativeDriver: true,
		}).start()
	}

	function CardRow({ name, id }) {
		return (
			<View style={styles.inputRow}>
				<View style={styles.input}>
					<View style={styles.cardInfoContainer}>
						<Text style={styles.buttonText}>{name}</Text>
					</View>
					{selectedBrand && selectedBrand.id === id ? (
						<MaterialCommunityIcons
							name="checkbox-marked-circle"
							size={largeTextSize}
							color={'black'}
							style={{ paddingLeft: 15 }}
						/>
					) : (
						<MaterialCommunityIcons
							name="checkbox-blank-circle-outline"
							size={largeTextSize}
							color="grey"
							style={{ paddingLeft: 15 }}
						/>
					)}
				</View>
			</View>
		)
	}

	return (
		<View style={{ flex: 1, paddingBottom: 30, width: '100%' }}>
			<View style={{ justifyContent: 'center' }}>
				{brands && brands.length > 0 ? (
					brands.map(brand => (
						<View key={brand.id} style={{ width: '100%', paddingBottom: 15 }}>
							<TouchableOpacity onPress={() => handleSelectBrand(brand)} style={{ width: '100%' }}>
								<CardRow name={brand.name} id={brand.id} />
							</TouchableOpacity>
						</View>
					))
				) : (
					<Text
						style={{
							fontStyle: 'italic',
							color: global.orgColor2,
							fontWeight: '500',
							fontSize: largeTextSize,
						}}
					>
						No 3rd party brands have been added yet.
					</Text>
				)}
			</View>
			<Animated.View style={{ opacity: fadeAnim }}>
				<TextInput
					placeholder="Additional Note"
					value={note}
					numberOfLines={3}
					multiline={true}
					onChangeText={setNote}
					style={{
						fontSize: largeTextSize,
						borderWidth: 2,
						padding: 10,
						borderColor: Colors.greyscale[4],
						borderRadius: 10,
						height: 100,
					}}
				/>
			</Animated.View>
		</View>
	)
}

const styles = StyleSheet.create({
	inputRow: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	input: {
		minHeight: 48,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexGrow: 1,
		padding: 10,
		paddingHorizontal: 15,
		borderWidth: 1,
		borderColor: Colors.greyscale[5],
		borderRadius: 8,
		backgroundColor: '#fff',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	cardInfoContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
	},

	buttonText: {
		marginRight: 10,
		color: 'black',
		fontWeight: '500',
		fontSize: largeTextSize,
	},
})
