import React, { useCallback, useMemo } from 'react'
import { View, Text, TouchableOpacity, StyleSheet, Dimensions } from 'react-native'
import { useAppSelector, useAppDispatch } from '../../../state/hooks'
import { incrementItemQuantity, decrementItemQuantity } from '../../../state/Slices/itemSlice'
import { largeTextSize, mlTextSize, titleTextSize } from '../../../constants/GlobalStyle'
import Colors from '../../../constants/Colors'

const QuantitySelector = React.memo(() => {
	const quantity = useAppSelector(state => state.item.quantity)
	const dispatch = useAppDispatch()

	const incrementQuantity = useCallback(() => {
		dispatch(incrementItemQuantity())
	}, [dispatch])

	const decrementQuantity = useCallback(() => {
		dispatch(decrementItemQuantity())
	}, [dispatch])

	const isDecrementDisabled = useMemo(() => quantity === 1, [quantity])

	return (
		<>
			<Text style={styles.stepTextStyle}>{'QUANTITY'}</Text>
			<View style={styles.wrapper}>
				<View style={styles.container}>
					<TouchableOpacity
						style={[styles.button, isDecrementDisabled && styles.disabledButton]}
						onPress={decrementQuantity}
						disabled={isDecrementDisabled}
					>
						<Text style={[styles.buttonText, isDecrementDisabled && styles.disabledButtonText]}>
							–
						</Text>
					</TouchableOpacity>
					<View style={styles.quantityContainer}>
						<Text style={styles.quantityText}>{quantity}</Text>
					</View>
					<TouchableOpacity style={styles.button} onPress={incrementQuantity}>
						<Text style={styles.buttonText}>+</Text>
					</TouchableOpacity>
				</View>
			</View>
		</>
	)
})

QuantitySelector.displayName = 'QuantitySelector'

export default QuantitySelector

const styles = StyleSheet.create({
	wrapper: {
		alignItems: 'flex-start',
		justifyContent: 'center',
	},
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: '#fff',
		borderRadius: 15,
		paddingVertical: 5,
		paddingHorizontal: 10,
		shadowColor: '#000',
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 5,
		elevation: 5,
		width: 'auto',
		height: Dimensions.get('window').height * 0.065,
		minHeight: 50,
		maxHeight: 60,
		marginBottom: 30,
	},
	button: {
		paddingHorizontal: 15,
		marginTop: -3,
	},
	disabledButton: {
		opacity: 0.5,
	},
	buttonText: {
		fontSize: largeTextSize,
		color: '#000',
	},
	disabledButtonText: {
		color: Colors.greyscale[5],
	},
	quantityContainer: {
		minWidth: 60,
		alignItems: 'center',
	},
	quantityText: {
		fontSize: mlTextSize,
		textAlign: 'center',
	},
	stepTextStyle: {
		fontSize: titleTextSize - 6,
		fontWeight: '400',
		color: 'black',
		marginBottom: 10,
	},
})
